import * as React from 'react';
import {useEffect, useState} from 'react';
import {Alert, AlertDescription, AlertIcon, AlertTitle, Center, Link, Spinner, Text, VStack} from "@chakra-ui/react";
import {Redirect, useHistory} from "react-router-dom";
import {AuthenticationApi} from "../../api/authentication.api";
import ErrorModel from "../../models/error.model";
import {AxiosError} from "axios";
import {SideBarComponent} from "../../shared/SideBarComponent";

interface IVerifyEmail {
}

export const VerifyEmail: React.FunctionComponent<IVerifyEmail> = props => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('busy');

    // Load settings
    useEffect(() => {
        setLoading(true);

        if (token) {
            AuthenticationApi.verifyEmail({token})
                .then(result => {
                    setLoading(true);
                    if (result.data.result.length === 0) {
                        setLoading(false);
                        setSuccess('success');
                        setTimeout(() => {
                            history.push("/signin")
                        }, 5000);
                    }
                })
                .catch((error: AxiosError<ErrorModel>) => {
                    setLoading(false);
                    setSuccess('failed');
                });
        }
    }, [history, token]);

    // This means, the login came from social login
    if (token) {
        return (
            <SideBarComponent>
                <Center h={'100%'}>
                    <VStack>
                        {
                            loading &&
                            <VStack>
                                <Spinner color={'primary.500'} size={'xl'} thickness="4px"/>
                                <Text color={'gray.600'} fontSize={'3xl'}>
                                    Verifying your email...
                                </Text>
                            </VStack>
                        }
                        {
                            success === 'success' &&
                            <Alert
                                borderRadius={'md'}
                                status="success"
                                variant="subtle"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                textAlign="center"
                                height="200px"
                                width={"100%"}
                            >
                                <AlertIcon boxSize="40px" mr={0}/>
                                <AlertTitle mt={4} mb={1} fontSize="lg">
                                    Email verified!
                                </AlertTitle>
                                <AlertDescription maxWidth="sm">
                                    Thanks for verifying your email-address. You will be redirected to the <Link>Sign
                                    in</Link> page in 5s.
                                </AlertDescription>
                            </Alert>
                        }
                        {
                            success === 'failed' &&
                            <Alert
                                borderRadius={'md'}
                                status="error"
                                variant="subtle"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                textAlign="center"
                                height="200px"
                                width={"100%"}
                            >
                                <AlertIcon boxSize="40px" mr={0}/>
                                <AlertTitle mt={4} mb={1} fontSize="lg">
                                    Email verification failed!
                                </AlertTitle>
                                <AlertDescription maxWidth="sm">
                                    Please contact the Entelect Challenge engineers.
                                </AlertDescription>
                            </Alert>
                        }
                    </VStack>
                </Center>
            </SideBarComponent>
        )
    }

    return (
        <Redirect to={"/signin"}/>
    )
};