export default interface EntryModel {
    entryId: string;
    playerId: string;
    entryType: EntryType;
    entryYear: number;
}

export enum EntryType {
    MAIN = 'main',
    COMPANY = 'company',
    UNIVERSITY = 'university',
    IITPSA = 'iitpsa',
}