const images2016 ={
    "Charl_van_Niekerk":require('./Charl_van_Niekerk.jpg').default,
    "Christian_Reeve":require('./Christian_Reeve.jpg').default,
    "Faruk_Can_Kaya":require('./Faruk_Can_Kaya.jpg').default,
    "Ralf_Kistner":require('./Ralf_Kistner.jpg').default,
    "Rethabile_Mokoena":require('./Rethabile_Mokoena.jpg').default,
    "Riaan_Nel":require('./Riaan_Nel.jpg').default,
    "Thinus_Potgieter":require('./Thinus_Potgieter.jpg').default,
    "Willie_Theron":require('./Willie_Theron.jpg').default,
}
export default images2016;