const images2014 ={
    "bernhard_haussermann":require('./bernhard_haussermann.jpg').default,
    "gregory_doukas":require('./gregory_doukas.jpg').default,
    "lindsay_hans":require('./lindsay_hans.jpg').default,
    "nicholas_scheltema":require('./nicholas_scheltema.jpg').default,
    "pierre":require('./pierre.jpg').default,
    "raymond_claasen":require('./raymond_claasen.jpg').default,
    "rethabile_mokoena":require('./rethabile_mokoena.jpg').default,
    "ruan_liebenberg":require('./ruan_liebenberg.jpg').default,
}
export default images2014;