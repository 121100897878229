import { Box, Divider, Flex, Image, Square, Text, useBreakpointValue } from "@chakra-ui/react";
import moment from "moment/moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { TournamentApi } from "../../../api/tournament.api";
import tournament1Image from "../../../assets/images/tournament/tournament_1_image.png";
import tournament2Image from "../../../assets/images/tournament/tournament_2_image.png";
import tournament3Image from "../../../assets/images/tournament/tournament_3_image.png";
import tournament4Image from "../../../assets/images/tournament/tournament_4_image.png";
import tournament_no_data from "../../../assets/images/tournament/tournament_no_data.png";
import bug_hunt_tournaments from "../../../assets/images/tournament/syntax_error_in_the_force.png";
import { getSetting, SETTING_NAMES_ENTELECT_CHALLENGE } from "../../../atoms/settings.atom";
import TournamentItemModel from "../../../models/tournament-item.model";
import TournamentModel from "../../../models/tournament.model";
import { IntermediateGlobalLoading } from "../../../shared/loading/IntermediateGlobalLoading";
import { TournamentDetails } from "./TournamentDetails";
import background from "../../../assets/images/main/main_background.png";

interface IUnauthenticatedTournaments {
}

const mapToTournamentItemModel = (tournamentModels: TournamentModel[]): TournamentItemModel[] => {
    const tournamentItemModels: TournamentItemModel[] = [];

    tournamentModels.forEach((tournamentModel: TournamentModel) => {
        tournamentItemModels.push({
            tournamentId: tournamentModel.tournamentId,
            name: tournamentModel.tournamentName,
            deadline: (moment(tournamentModel.submissionDeadline)).format("DD MMMM YYYY HH:mm") ?? "TBA",
            prizeList: tournamentModel?.prizeList ?? ["TBC"]
        } as TournamentItemModel);
    });

    return tournamentItemModels;
};

export const UnauthenticatedTournaments: React.FunctionComponent<IUnauthenticatedTournaments> = props => {
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [tournamentItems, setTournamentItems] = useState<TournamentItemModel[]>([]);

    const currentTournamentId = useRecoilValue(getSetting(SETTING_NAMES_ENTELECT_CHALLENGE.OFFICIAL_TOURNAMENT_ID)).value;

    let textColumnMaxWidth = "96em";
    let titleFontSize = 45;
    let minViewH = "50vh";
    let otherTextFontSize = "calc(1.2em + 1vmin)";
    let tbcFontSize = "calc(2em + 5vmin)";
    let mainFontColor = "navigation.700";
    let linkColor = "navigation.800";
    let tournamentImageSize = "22em";
    const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});

    let tournamentImages = [tournament1Image, tournament2Image, tournament3Image, tournament4Image];

    useEffect(() => {
        setLoading(true);

        TournamentApi.getTournaments()
            .then(result => {
                const tournamentModels = result.data;

                const mappedTournamentItemModels = mapToTournamentItemModel(tournamentModels);
                setTournamentItems(mappedTournamentItemModels);

                setLoading(false);
            })
            .catch(reason => {
                setLoading(false);
                return reason;
            });
    }, [setLoading]);

    const handleLeaderboardNavigation = (tournamentId: string): void => {
        if (tournamentId.length > 0) {
            history.push(`/portal/entelect-challenge/leaderboards?tournament=` + tournamentId);
        }
    };

    if (loading) {
        return (
            <IntermediateGlobalLoading isNotFull={true} displayWithoutText={true}/>
        );
    }

    function TournamentImage(props) {
        if (!isMobile) {
            return <Square
                width={"50%"}
                h={"0vh"}>
                <Image
                    h={tournamentImageSize}
                    src={tournamentImages[props.index]}
                />
            </Square>;
        } else {
            return null;
        }
    }

    return (
        <Box
            w="100%"
            h="100%"
            background={`url(${background})`}
            backgroundSize={'cover'}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={"center"}
            as="main">
            <Flex flexDirection={"column"}>
                <Square
                    p={5} shadow="md" borderWidth="0.1em"
                    background={"whiteAlpha.800"}
                    borderRadius={"0.5em"}
                    mt={25}
                    width={"100%"}
                    pb={5}
                    mb={5}
                    maxWidth={textColumnMaxWidth}
                    minH={minViewH}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Box
                        align={"center"}
                        py={5}
                        width={"80%"}>
                        <Text
                            fontWeight={"bold"}
                            fontSize={titleFontSize}
                            color={"accent.600"}
                            align={"center"}
                            maxWidth={textColumnMaxWidth}
                            colorScheme="primary"
                            as={"span"}>
                            Tournaments
                        </Text>
                    </Box>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"left"}
                        colorScheme="primary">
                        <strong>The Entelect Challenge</strong> consists of <strong>three phases</strong>, two tournaments and the final.
                        The two tournaments will determine the top 8 participants that go through to the final.<br/><br/>
                        If you miss the first tournament, it's not too late, you could still participate in the second tournament for your share of <strong>R200K</strong>.<br/><br/>
                        Each tournament your bot will face a series of games determined by the size of the participant pool.
                        Your bot will be competing against 3 other players in each official match.<br/><br/>
                        You will earn points for the position you place and the scoring will be detailed in the game rules.
                        At the end of the all the matches your points will be tallied up and the overall leaderboard will be calculated.
                    </Text>

                    <Square
                        width={"90%"}
                        maxWidth={textColumnMaxWidth}
                        h={"0vh"}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                        <Image
                            h={"5em"}
                            objectFit={"contain"}
                            pos="relative" top="1vw" left="-50%"
                            fit={"contain"}
                            src={bug_hunt_tournaments}
                        />
                    </Square>

                    {tournamentItems && tournamentItems.length > 0 ? (
                        <>
                            <Divider/>
                            <Text
                                fontSize={otherTextFontSize}
                                color={linkColor}
                                py={5}
                                width={"80%"}
                                fontWeight={"bold"}
                                maxWidth={textColumnMaxWidth}
                                align={"center"}
                                colorScheme="primary">
                                The following tournaments will have specific rules for placements:
                            </Text>
                        </>
                    ) : (
                        <>
                            <Divider/>
                            <Text
                                fontSize={otherTextFontSize}
                                color={linkColor}
                                py={5}
                                width={"80%"}
                                fontWeight={"bold"}
                                maxWidth={textColumnMaxWidth}
                                align={"center"}
                                colorScheme="primary">
                                We are finalising tournaments
                            </Text>

                            <Box
                                background={`url(${tournament_no_data})`}
                                backgroundSize={"cover"}
                                backgroundAttachment={"fixed"}
                                backgroundRepeat={"no-repeat"}
                                backgroundPosition={"center"}
                                width={"100%"}
                                height={"30em"}
                            >
                                <Text
                                    textAlign={"center"}
                                    fontSize={tbcFontSize}
                                    fontWeight={"extrabold"}
                                    color={mainFontColor}
                                    textShadow="0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #00b3ff, 0 0 70px #00b3ff, 0 0 80px #00b3ff, 0 0 100px #00b3ff"
                                    py={5}
                                    width={"80%"}
                                    maxWidth={textColumnMaxWidth}
                                    position={"relative"}
                                    transform="translate(-50%, -50%)"
                                    top={"50%"}
                                    left={"50%"}
                                >
                                    TBA Soon...
                                </Text>
                            </Box>
                        </>
                    )}
                </Square>

                {
                    tournamentItems && tournamentItems.length > 0 &&
                    (
                        <>
                            {tournamentItems.map((tournamentItem, index) => (
                                <Square
                                    key={tournamentItem.tournamentId}
                                    p={5} shadow="md" borderWidth="0.1em"
                                    background={"whiteAlpha.800"}
                                    borderRadius={"0.5em"}
                                    mt={25}
                                    mb={5}
                                    width={"80%"}
                                    maxWidth={textColumnMaxWidth}
                                    minH={minViewH}
                                    alignSelf={"center"}
                                    flexDirection={"row"}>

                                    {index % 2 === 0 &&
                                        <TournamentDetails
                                            currentTournamentId={currentTournamentId}
                                            tournamentItem={tournamentItem}
                                            onLeaderboardNavigation={(tournamentId) => handleLeaderboardNavigation(tournamentId)}
                                        />
                                    }
                                    {index % 2 === 1 && <TournamentImage index={index}/>}

                                    {index % 2 === 0 && <TournamentImage index={index}/>}
                                    {index % 2 === 1 &&
                                        <TournamentDetails
                                            currentTournamentId={currentTournamentId}
                                            tournamentItem={tournamentItem}
                                            onLeaderboardNavigation={(tournamentId) => handleLeaderboardNavigation(tournamentId)}
                                        />
                                    }
                                </Square>
                            ))}
                        </>
                    )}
            </Flex>
        </Box>

    );
};
