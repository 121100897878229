import {Text} from "@chakra-ui/layout";
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Grid,
    GridItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
    VStack
} from "@chakra-ui/react";
import {AxiosError} from "axios";
import * as React from 'react';
import {useState} from 'react';
import {DropEvent, FileRejection} from "react-dropzone";
import {HackathonApi} from "../../../api/hackathon.api";
import ErrorModel from "../../../models/error.model";
import {FileDrop} from "../../../shared/FileDrop";

interface IQuestionUploadModal {
    questionNumberSelected: number;
    isOpen: boolean;
    onClose: () => void;
    entryType: string;
    teamId: string;
}

interface SolutionUpload {
    sourceCodeFile: File | null;
    solutionFile: File | null;
}

export const HackathonQuestionUploadModal: React.FunctionComponent<IQuestionUploadModal> = props => {

    const toast = useToast();
    const [uploadingError, setUploadingError] = useState<string>('');
    const [uploading, setUploading] = useState<boolean>(false);
    const [solution, setSolution] = useState<SolutionUpload>({
        sourceCodeFile: null,
        solutionFile: null
    });

    const onSourceCodeDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        if (acceptedFiles.length === 1) {
            setSolution((oldVal) => {
                return {
                    ...oldVal,
                    sourceCodeFile: acceptedFiles[0]
                }
            });
        }

    };

    const onSolutionDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        if (acceptedFiles.length === 1) {
            setSolution((oldVal) => {
                return {
                    ...oldVal,
                    solutionFile: acceptedFiles[0]
                }
            });
        }
    };

    const uploadSolution = () => {
        setUploading(true);

        if (solution.sourceCodeFile && solution.solutionFile) {
            HackathonApi.getUploadSolutionUrls(props.entryType, {
                teamId: props.teamId,
                questionNumber: props.questionNumberSelected
            })
                .then((response) => response.data)
                .then((data) => {
                    HackathonApi.uploadFile(data.sourceCodeUploadUrl, solution.sourceCodeFile)
                        .then(() => {
                            setUploadingError('');
                        })
                        .catch(() => {
                            setUploadingError('Problem during upload. Please contact the Entelect Challenge Engineers.');
                            setUploading(false);
                        });
                    return data;
                })
                .then((data) => {
                    HackathonApi.uploadFile(data.solutionUploadUrl, solution.solutionFile)
                        .then(() => {
                            setUploadingError('');
                        })
                        .catch(() => {
                            setUploadingError('Problem during upload. Please contact the Entelect Challenge Engineers.');
                            setUploading(false);
                        });
                    setUploading(false);
                    toast({
                        title: `Solution uploaded. Processing your solution now.`,
                        position: 'top-right',
                        status: 'success'
                    });
                    props.onClose();
                })
                .catch((error: AxiosError<ErrorModel>) => {
                    if (error.response?.data.code === 'SubmissionsClosedException') {
                        setUploadingError('Solution uploading is closed. Either because the hackathon reached its end, or have not started yet.');
                        setUploading(false);
                    } else {
                        setUploadingError('Problem during upload. Please contact the Entelect Challenge Engineers.');
                        setUploading(false);
                    }
                });
        }
    };

    return (
        <Box>
            <Modal isOpen={props.isOpen} onClose={props.onClose} size={'lg'}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Question {props.questionNumberSelected}: Upload Solution</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Text fontSize={'sm'} color={'gray.700'}>Please select or drag your <strong>source code .zip </strong> file and <strong>solution .txt</strong> file here to
                            upload them.</Text>
                        <VStack mt={4} spacing={4}>
                            <Box w={'100%'}>
                                <Grid templateColumns={'repeat(12, 1fr)'}>
                                    <GridItem colSpan={5}>
                                        <Text color={'gray.700'} fontSize={'lg'} fontWeight={'semibold'}>Source Code
                                            File:</Text>
                                    </GridItem>
                                    <GridItem colSpan={7}>
                                        <FileDrop
                                            onDrop={onSourceCodeDrop}
                                            text={'Drag source code here, or click to select a file.'}
                                            acceptedMimes={['application/zip', 'application/x-zip-compressed', 'multipart/x-zip']}/>
                                    </GridItem>
                                </Grid>
                            </Box>
                            <Box w={'100%'}>
                                <Grid templateColumns={'repeat(12, 1fr)'}>
                                    <GridItem colSpan={5}>
                                        <Text color={'gray.700'} fontSize={'lg'} fontWeight={'semibold'}>Solution
                                            File:</Text>
                                    </GridItem>
                                    <GridItem colSpan={7}>
                                        <FileDrop
                                            onDrop={onSolutionDrop}
                                            text={'Drag solution file here, or click to select a file.'}
                                            acceptedMimes={'text/plain'}/>
                                    </GridItem>
                                </Grid>
                            </Box>
                        </VStack>
                        {
                            uploadingError.length !== 0 &&
                            <Alert status="error" mt={5}>
                                <AlertIcon/>
                                {uploadingError}
                            </Alert>
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="primary" mr={3} variant={'ghost'} onClick={props.onClose}>
                            Close
                        </Button>
                        <Button
                            colorScheme="primary"
                            variant="solid"
                            isDisabled={solution.solutionFile === null || solution.sourceCodeFile === null}
                            onClick={() => uploadSolution()}
                            isLoading={uploading}
                            loadingText={'Uploading solution'}
                        >Upload solution</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
};
