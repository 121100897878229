import * as Yup from "yup";
import {southAfricanIdNumberPattern} from "../../utils/constants";

export const universityRegistrationInfoValidation = Yup.object().shape({
    selectedIdType: Yup.string(),

    playerIdType: Yup.string()
        .required('Required'),
    playerIdNumber: Yup.string()
        .when('playerIdType', {
            is: 'ID',
            then: Yup.string()
                .required()
                .length(13, 'ID must be 13 characters')
                .matches(southAfricanIdNumberPattern, 'Invalid SA ID number'),
            otherwise: Yup.string()
                .required()
                .min(5, 'Passport number must be at least 5 characters')
        }),
    studentNumber: Yup.string()
        .required('Required')
        .min(5, 'Student number must be at least 5 characters'),
    university: Yup.string()
        .required('Required')
        .min(2, 'University must be at least 2 characters'),
    fieldOfStudy: Yup.string()
        .required('Required')
        .min(2, 'Field of study must be at least 2 characters')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabet characters are allowed'),
    currentYearOfStudy: Yup.string()
        .required('Required')
        .matches(/^\d+$/, 'Only digits are allowed')
});
