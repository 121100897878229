import { Box, Center, Grid, GridItem, VStack } from "@chakra-ui/react";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { HackathonApi } from "../../../api/hackathon.api";
import { PlayerApi } from "../../../api/player.api";
import { hackathonProfileAtom } from "../../../atoms/hackathon-profile.atom";
import { playerAtom } from "../../../atoms/player.atom";
import { CreateTeam } from "../../../models/company.model";
import { EntryType } from "../../../models/entry.model";
import ErrorModel from "../../../models/error.model";
import RegistrationInfoIitpsaModel from "../../../models/registration-info-iitpsa.model";
import RegistrationInfoUniversityModel from "../../../models/registration-info-university.model";
import TournamentRegisterModel from "../../../models/tournament-register.model";
import { FancyHeading } from "../../../shared/FancyHeading";
import { getCurrentYear, getValueForEntryType } from "../../../utils/hooks/general-utils";
import { HackathonRegisterFormCompany } from "./HackathonRegisterFormCompany";
import { HackathonRegisterFormIITPSA } from "./HackathonRegisterFormIITPSA";
import { HackathonRegisterFormUniversity } from "./HackathonRegisterFormUniversity";

interface IHackathonRegister {
}

export const HackathonRegister: React.FunctionComponent<IHackathonRegister> = props => {

    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const setPlayer = useSetRecoilState(playerAtom);
    const setHackathonProfile = useSetRecoilState(hackathonProfileAtom);

    const { type } = useParams();

    const register = (values: TournamentRegisterModel | RegistrationInfoUniversityModel | RegistrationInfoIitpsaModel) => {
        setLoading(true);

        PlayerApi.register(values, type)
            .then(result => {
                if (type !== EntryType.IITPSA) {
                    setLoading(false);
                }
                setPlayer((prevState) => {
                    return {
                        ...prevState,
                        playerEntries: [
                            ...prevState.playerEntries,
                            {
                                entryType: type,
                                entryId: '',
                                playerId: prevState.playerId,
                                entryYear: getCurrentYear()
                            }
                        ]
                    }
                });
                if (type === EntryType.IITPSA) {
                    const iitpsaCreateTeam = {
                        teamName: values.playerIdNumber,
                        hackathonType: type
                    } as CreateTeam;

                    HackathonApi.createTeam(iitpsaCreateTeam)
                        .then(result => {
                            setLoading(false);
                            setHackathonProfile(result.data);
                            //console.info(hackathonProfile);
                        })
                        .catch((error: AxiosError<ErrorModel>) => {
                            setLoading(false);
                        });
                }
                history.push(`/portal/hackathon/${type}/team`);
            })
            .catch(reason => {
                setLoading(false);
            });
    };

    return (
        <Box
            w="100%"
            h="100%"
            backgroundPosition={"center"}
            position="relative"
        >
            <Grid templateColumns={'repeat(11, 1fr)'} h={'100%'}>
                <GridItem colSpan={[1, 1, 2, 4]}/>
                <GridItem colSpan={[9, 9, 7, 3]} mb={4}>
                    <Center h={'100%'}>
                        <VStack align={'-moz-initial'} w={'100%'} py={5}>
                            <FancyHeading heading={`Hackathon registration - ${type.toUpperCase()}`} headingSize={'3xl'}/>
                            {
                                getValueForEntryType(type, {
                                    company: <HackathonRegisterFormCompany companyCupRegistrationModel={null} register={register} loading={loading}/>,
                                    university: <HackathonRegisterFormUniversity registrationInfoUniversityModel={null} register={register} loading={loading}/>,
                                    iitpsa: <HackathonRegisterFormIITPSA registrationInfoIitpsaModel={null} register={register} loading={loading}/>
                                })
                            }
                        </VStack>
                    </Center>
                </GridItem>
            </Grid>
        </Box>
    )
};
