import * as React from 'react';
import { AspectRatio, Box, Center, Flex, Square, Text } from "@chakra-ui/react";
import background from "../../../assets/images/main/main_background.png";
import { useParams } from 'react-router-dom';
import { HISTORY_CONTENT } from './LegacyAssets/LegacyYearDetails';
import { LegacyYearFinalists } from './LegacyAssets/LegacyYearFinalists';
import parse from 'html-react-parser';
import { LegacyYearImages } from './LegacyAssets/LegacyYearImages';

interface ILegacyYearDetail {
}

export const LegacyYearDetail: React.FunctionComponent<ILegacyYearDetail> = props => {
    const {year} = useParams();
    let textColumnMaxWidth = '92em';
    let subTitleFontSize = 40;
    let minViewH = '50vh';
    let otherTextFontSize = 'calc(1em + 1vmin)';
    let mainFontColor = 'navigation.700';

    const yearDetail = HISTORY_CONTENT[year];

    return (
        <Box
            background={`url(${background})`}
            backgroundSize={'cover'}
            backgroundAttachment={'fixed'}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={'center'}
            as="main">
            <Flex flexDirection={'column'}>
                <Square p={5} shadow='md' borderWidth='0.1em'
                        background={'whiteAlpha.800'}
                        borderRadius={'0.5em'}
                        mt={10}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                    <Text
                        fontWeight={"bold"}
                        fontSize={subTitleFontSize}
                        color={"accent.600"}
                        align={"center"}
                        w={'100%'}
                        pb={5}
                        colorScheme="primary">
                        {year} - {yearDetail.title}
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        {parse(yearDetail.content)}
                    </Text>
                    {/* <CountDown date={countdownDate}/> */}
                </Square>
                <Square p={5} shadow='md' borderWidth='0.1em'
                        background={'whiteAlpha.800'}
                        borderRadius={'0.5em'}
                        mt={10}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                    <Text
                        fontWeight={"bold"}
                        fontSize={subTitleFontSize}
                        color={"accent.600"}
                        align={"center"}
                        w={'100%'}
                        pb={5}
                        colorScheme="primary">
                        Finalists
                    </Text>
                    <Box mt={4}>
                        <Center>
                            <LegacyYearFinalists year={year}/>
                        </Center>
                    </Box>
                </Square>
                <Square p={5} shadow='md' borderWidth='0.1em'
                        background={'whiteAlpha.800'}
                        borderRadius={'0.5em'}
                        mt={10}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                    <Text
                        fontWeight={"bold"}
                        fontSize={subTitleFontSize}
                        color={"accent.600"}
                        align={"center"}
                        w={'100%'}
                        pb={5}
                        colorScheme="primary">
                        Playoffs
                    </Text>
                    <Box w={'50%'}>
                        <AspectRatio ratio={16 / 9} zIndex={0}>
                            <iframe src={yearDetail.playOffVideoIds[0]}
                                    style={{
                                        borderRadius: "0.5em",
                                        border: "0"
                                    }}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </AspectRatio>
                    </Box>
                </Square>
                <Square p={5} shadow='md' borderWidth='0.1em'
                        background={'whiteAlpha.800'}
                        borderRadius={'0.5em'}
                        mt={10}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                    <Text
                        fontWeight={"bold"}
                        fontSize={subTitleFontSize}
                        color={"accent.600"}
                        align={"center"}
                        w={'100%'}
                        pb={5}
                        colorScheme="primary">
                        Gallery
                    </Text>
                    <Box mt={4}>
                        <LegacyYearImages year={year}/>
                    </Box>
                </Square>
            </Flex>
        </Box>
    );
};
