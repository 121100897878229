import AwsSubmissionModel from "./aws-submissions.model";
import {StatusType, StatusTypeDescription} from "./status.type.enum";

export const defaultSubmissionModel: SubmissionsModel = {
    botId: '',

    uploadDate: 0,
    uploadStatus: '',
    botSourceUrl: '',

    buildStatus: '',
    buildStatusType: StatusType.pending,
    buildDate: null,
    buildDataUrl: '',

    matchStatus: '',
    matchStatusType: StatusType.pending,
    matchDate: null,
    matchLogsUrl: '',
    botLogsUrl: '',

    submissionType: '',
    matchId: '',
    isReported: false,
    inQueueDate: new Date()
}

export interface SubmissionsModel {
    botId: string;

    uploadDate: number;
    uploadStatus: string;
    botSourceUrl: string;

    buildStatus: string;
    buildStatusType: StatusType;
    buildDate: Date | null;
    buildDataUrl: string;

    matchStatus: string;
    matchStatusType: StatusType;
    matchDate: Date | null;
    matchLogsUrl: string;
    botLogsUrl: string;

    submissionType: string;
    matchId: string;
    isReported: boolean;
    inQueueDate: Date;
}

const mainPlayerLogApiBaseUrl = `${process.env.REACT_APP_API_URL}/player/logs/main/`;
const minDate = new Date(0);

export const mapToSubmissionsModel = (awsSubmissionsModel: AwsSubmissionModel[]): SubmissionsModel[] => {
    return awsSubmissionsModel.map((submission: AwsSubmissionModel) => {
        const uploadStatus = getUploadStatus(submission);
        const buildStatus = getBuildStatus(submission);
        const matchStatus = getMatchStatus(submission);

        return {
            botId: submission.submissionId,
            uploadStatus: uploadStatus,
            uploadDate: submission.uploadedDate.valueOf(),
            botSourceUrl: `${mainPlayerLogApiBaseUrl}${submission.submissionType}/${submission.submissionId}`,
            buildStatus: buildStatus.description,
            buildStatusType: buildStatus.statusType,
            buildDate: buildStatus.date,
            buildDataUrl: `${mainPlayerLogApiBaseUrl}build_logs/${submission.submissionId}`,
            matchStatus: matchStatus.description,
            matchStatusType: matchStatus.statusType,
            matchDate: matchStatus.date,
            matchLogsUrl: `${mainPlayerLogApiBaseUrl}match_logs/${submission.matchId}`,
            botLogsUrl: `${mainPlayerLogApiBaseUrl}bot_logs/${submission.matchId}`,
            submissionType: submission.submissionType,
            matchId: submission.matchId,
            isReported: submission.reported,
            inQueueDate: submission.inQueueDate
        }
    });
}

function getUploadStatus(submission: AwsSubmissionModel) {
    let uploadDate = (submission.uploadedDate) ? submission.uploadedDate : null;

    if (!hasValidDate(uploadDate)) {
        uploadDate = null;
    }

    let description = 'Pending';
    if (submission.submissionProcessingStatus === 'match_started'
        || submission.submissionProcessingStatus === 'match_success'
        || submission.submissionProcessingStatus === 'match_failed'
        || submission.submissionProcessingStatus === 'in_queue'
        || submission.submissionProcessingStatus === 'codebuild_success') {
        description = 'Success';
    }

    if (submission.submissionProcessingStatus === 'codebuild_failed') {
        description = 'Failed';
    } else if (submission.submissionProcessingStatus === 'codebuild_started') {
        description = 'Building';
    }

    return description;
}

const getBuildStatus = (submission: AwsSubmissionModel): { description: string, statusType: StatusType, date: Date | null } => {
    let buildDate = (submission.buildDate) ? submission.buildDate : null;

    if (!hasValidDate(buildDate)) {
        buildDate = null;
    }

    let description = 'Pending';
    let statusType = StatusType.pending;

    if (submission.submissionProcessingStatus === 'internal_exception') {
        description = 'Internal Exception';
        statusType = StatusType.fail;
    }

    if (submission.submissionProcessingStatus === 'match_started'
        || submission.submissionProcessingStatus === 'match_success'
        || submission.submissionProcessingStatus === 'match_failed'
        || submission.submissionProcessingStatus === 'in_queue') {
        description = 'Success';
        statusType = StatusType.success;
    }

    if (submission.submissionProcessingStatus === 'codebuild_success') {
        description = 'Success';
        statusType = StatusType.success;
    } else if (submission.submissionProcessingStatus === 'codebuild_failed') {
        description = 'Failed';
        statusType = StatusType.fail;
    } else if (submission.submissionProcessingStatus === 'codebuild_started') {
        description = 'Building';
        statusType = StatusType.busy;
    }

    return { description: description, statusType: statusType, date: buildDate };
}

const hasValidDate = (date: Date | null): boolean => {
    return date !== undefined && date !== null && date >= minDate;
}

const getMatchStatus = (submission: AwsSubmissionModel): { description: string, statusType: StatusType, date: Date | null } => {
    let matchDate = (submission.matchDate) ? submission.matchDate : null;
    if (!hasValidDate(matchDate)) {
        matchDate = null;
    }

    let description = StatusTypeDescription.Pending;
    let statusType = StatusType.pending;

    if (submission.submissionProcessingStatus === 'codebuild_failed') {
        description = StatusTypeDescription.CodeBuildFailed;
        statusType = StatusType.pending;
    } else if (submission.submissionProcessingStatus === 'match_started') {
        description = StatusTypeDescription.MatchStarted;
        statusType = StatusType.busy;
    } else if (submission.submissionProcessingStatus === 'match_failed') {
        description = StatusTypeDescription.MatchFailed;
        statusType = StatusType.fail;
    } else if (submission.submissionProcessingStatus === 'match_success') {
        description = StatusTypeDescription.MatchSuccess;
        statusType = StatusType.success;
    } else if (submission.submissionProcessingStatus === 'in_queue') {
        description = StatusTypeDescription.InQueue;
        statusType = StatusType.queue;
    }

    return { description: description, statusType: statusType, date: matchDate };
}
