import axiosInstance from "./axios-instance.api";
import PlayerModel from "../models/player.model";
import {AxiosResponse} from "axios";
import TournamentRegisterModel from "../models/tournament-register.model";
import RegisterResultModel from "../models/register-result.model";
import {ProfileModelRequest, SubmissionRequest} from "../models/profile.model";
import RegistrationInfoUniversityModel from "../models/registration-info-university.model";
import RegistrationInfoIitpsaModel from "../models/registration-info-iitpsa.model";
import RegistrationInfoMainModel from "../models/registration-info-main.model";
import {EntryType} from "../models/entry.model";

export const PlayerApi = {

    getPlayer: (): Promise<AxiosResponse<PlayerModel>> => axiosInstance.get(`/player/profile`),

    getPlayerMainRegistrationInfo: (): Promise<AxiosResponse<RegistrationInfoMainModel>> => axiosInstance.get(`/player/profile/registration/${EntryType.MAIN}`),

    updatePlayerRegistrationInfo: (model: RegistrationInfoMainModel): Promise<AxiosResponse<string>> => axiosInstance.post(`/player/profile/registration/${EntryType.MAIN}`, model),

    register: (register: TournamentRegisterModel | RegistrationInfoUniversityModel | RegistrationInfoIitpsaModel, entryType: string): Promise<AxiosResponse<RegisterResultModel>> => axiosInstance.post(`/player/register/${entryType}`, register),

    updatePlayerProfile: (playerProfile: ProfileModelRequest): Promise<AxiosResponse<PlayerModel>> => axiosInstance.put('/player/profile', playerProfile),

    updateSubmissionFlow: (submission: SubmissionRequest): Promise<AxiosResponse<PlayerModel>> => axiosInstance.put('/player/profile', submission)


}
