import { ArrowBackIcon, ArrowForwardIcon, BellIcon, CheckCircleIcon, ChevronDownIcon, HamburgerIcon, Icon } from "@chakra-ui/icons";
import {
    Avatar,
    Box,
    BoxProps,
    CloseButton,
    Divider,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    Flex,
    FlexProps,
    IconButton,
    Image,
    Link as UILink,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text,
    Tooltip,
    useBreakpointValue,
    useColorMode,
    useDisclosure,
    Wrap
} from "@chakra-ui/react";
import * as React from "react";
import { ReactText, useEffect, useState } from "react";
import { IconType } from "react-icons";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { NotificationApi } from "../../api/notification.api";
import logo from "../../assets/images/entelect/EntelectChallenge_Logo_BW.svg";
import { doesHaveHackathonProfile } from "../../atoms/hackathon-profile.atom";
import { ILocalState, localStateAtom } from "../../atoms/local-state.atom";
import { playerHaveEntry, playerIsEntelectEmployee } from "../../atoms/player.atom";
import {
    getSettingAsBoolean,
    getSettingAsHackathonData,
    getSettingAsMap,
    SETTING_NAME_OPERATIONAL,
    SETTING_NAME_UNAUTHENTICATED,
    SETTING_NAMES_ENTELECT_CHALLENGE,
    SETTING_NAMES_HACKATHON
} from "../../atoms/settings.atom";
import { isTokenValid } from "../../atoms/token.atom";
import { EntryType } from "../../models/entry.model";
import HackathonTournamentModel from "../../models/hackathon-tournament.model";
import NotificationModel from "../../models/notification.model";
import PlayerModel from "../../models/player.model";
import { NavigationNotificationsBar } from "./NavigationNotificationsBar";
import { GiMagicPortal } from "react-icons/gi";
import { FiBell, FiDownload, FiGithub, FiSettings, FiTrendingUp } from "react-icons/fi";
import { BiSupport } from "react-icons/bi";
import { IoMdLogOut } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { GoBook } from "react-icons/go";

interface INavigationBar {
    player: PlayerModel;
}

interface LinkItemProps {
    name: string;
    icon?: IconType;
    linkTo: string;
    toggleMapKey?: string;
    shouldEvenShow?: boolean | undefined | null;
}

interface SelectedCup {
    path: string;
    name: string;
}

interface HackathonItemProps {
    cupName: string;
    shouldShow: boolean;
    pathTo: string;
    shouldShowEntered: boolean | undefined | null;
}

const PlayerPortalSideMenuLinkItems: Array<LinkItemProps> = [
    {name: "Portal", icon: GiMagicPortal, linkTo: "/portal"},
    {name: "Getting Started", icon: FiTrendingUp, linkTo: "/getting-started", toggleMapKey: "side-started"},
    {name: "Notifications", icon: FiBell, linkTo: "/notifications", toggleMapKey: "notifications"},
    {name: "CI/CD Download", icon: FiDownload, linkTo: "/cicd-download"},
    {
        name: "Git Help",
        icon: FiGithub,
        linkTo: "https://forum.entelect.co.za/c/ec-2024/githelp-24/62",
        toggleMapKey: "side-git-help"
    },
    {
        name: "Technical Support",
        icon: BiSupport,
        linkTo: "https://forum.entelect.co.za/c/ec-2024/technical-support/59"
    }
];

const main_bg: string = "navigation.800";
const navigation_selected_bg: string = "navigation.700";
const button_hover_bg: string = "navigation.700";
const text_button_hover_color: string = "navigation.800";

export const NavigationBar: React.FunctionComponent<INavigationBar> = (props) => {
    const {path} = useRouteMatch();
    const {pathname} = useLocation();
    const tokenValid = useRecoilValue(isTokenValid);

    const isEmployee = useRecoilValue(playerIsEntelectEmployee);
    const haveEntelectChallengeEntry = useRecoilValue(playerHaveEntry(EntryType.MAIN));
    const haveUniversityEntry = useRecoilValue(playerHaveEntry(EntryType.UNIVERSITY));
    const haveIITPSAEntry = useRecoilValue(playerHaveEntry(EntryType.IITPSA));
    const haveCompanyEntry = useRecoilValue(playerHaveEntry(EntryType.COMPANY));
    const enableIITPSA = useRecoilValue(getSettingAsBoolean(SETTING_NAMES_ENTELECT_CHALLENGE.IITPSA_ENABLE_SUBMISSION));

    const isEntelectChallenge = useRouteMatch(`/portal/entelect-challenge`);

    const isHackathon = useRouteMatch(`/portal/hackathon/:type`);
    const isHackathonRegistration = useRouteMatch(`/portal/hackathon/:type/register`);
    const isHackathonUniversity = useRouteMatch(`/portal/hackathon/university`);
    const isHackathonCompany = useRouteMatch(`/portal/hackathon/company`);
    const isHackathonIITPSA = useRouteMatch(`/portal/hackathon/iitpsa`);

    const enabledNotifications = useRecoilValue(getSettingAsBoolean(SETTING_NAME_OPERATIONAL.ENABLED_NOTIFICATIONS));

    const enabledEntelectChallenge = useRecoilValue(getSettingAsBoolean(SETTING_NAMES_ENTELECT_CHALLENGE.ENABLED));
    const hackathonCompanyTournament: HackathonTournamentModel = useRecoilValue(getSettingAsHackathonData(SETTING_NAMES_HACKATHON.COMPANY_TOURNAMENT_DATA));
    const hackathonUniversityTournament: HackathonTournamentModel = useRecoilValue(
        getSettingAsHackathonData(SETTING_NAMES_HACKATHON.UNIVERSITY_TOURNAMENT_DATA)
    );
    const hackathonIitpsaTournament: HackathonTournamentModel = useRecoilValue(getSettingAsHackathonData(SETTING_NAMES_HACKATHON.IITPSA_TOURNAMENT_DATA));

    const doesPlayerHaveHackathonProfile = useRecoilValue(doesHaveHackathonProfile);

    const [localState, setLocalState] = useRecoilState<ILocalState>(localStateAtom);

    const [newNotifications, setNewNotifications] = useState(false);
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [notificationsLoading, setNotificationsLoading] = useState(false);
    const [notifications, setNotifications] = useState<Array<NotificationModel>>([]);

    const isMobilePortrait = useBreakpointValue({base: true, sm: true, md: true, lg: false});
    const isMobileLandscape = useBreakpointValue({base: true, sm: true, md: true, lg: false});
    const navBarToggleMap = useRecoilValue(getSettingAsMap(SETTING_NAME_UNAUTHENTICATED.NAVBAR_ENTRY_TOGGLE_MAP));
    const [submenuOpen, setSubmenuOpen] = useState(false);
    const location = useLocation();

    const {isOpen, onOpen, onClose} = useDisclosure();

    useEffect(() => {
        if (pathname.startsWith("/portal")) {
            setNotificationsLoading(true);
            NotificationApi.getNotifications().then((response) => {
                setNotifications(response.data);
                setNewNotifications(response.data.filter((value) => value.creationDate > localState.notificationsLastOpenedDate).length !== 0);
                setNotificationsLoading(false);
            });
        }
    }, [setNotifications, setNotificationsLoading, setNewNotifications, localState.notificationsLastOpenedDate, pathname]);

    let selectedCup: SelectedCup;
    if (isEmployee) {
        selectedCup = {path: "company", name: "Company"};
    } else {
        selectedCup = {path: "entelect-challenge", name: "Entelect Challenge"};
    }
    if (isEntelectChallenge) {
        selectedCup = {path: "entelect-challenge", name: "Entelect Challenge"};
    } else if (isHackathonUniversity) {
        selectedCup = {path: "university", name: "University"};
    } else if (isHackathonCompany) {
        selectedCup = {path: "company", name: "Company"};
    } else if (isHackathonIITPSA) {
        selectedCup = {path: "iitpsa", name: "IITPSA"};
    }

    const {colorMode} = useColorMode();

    const listOfHackathonItems: Array<HackathonItemProps> = [
        {
            cupName: "Entelect Challenge Cup",
            shouldShow: !isEmployee && enabledEntelectChallenge,
            pathTo: haveEntelectChallengeEntry ? `${path}/entelect-challenge` : `${path}/entelect-challenge/register`,
            shouldShowEntered: isEntelectChallengeCheck()
        },
        {
            cupName: "University Cup",
            shouldShow: !isEmployee && hackathonUniversityTournament.enabled,
            pathTo: haveUniversityEntry ? `${path}/hackathon/university/solutions` : `${path}/hackathon/university/register`,
            shouldShowEntered: isHackathonUniversity && location.pathname.startsWith(isHackathonUniversity.path)
        },
        {
            cupName: "IITPSA Cup",
            shouldShow: !isHackathonIITPSA && hackathonIitpsaTournament.enabled,
            pathTo: haveIITPSAEntry ? `${path}/hackathon/iitpsa/solutions` : `${path}/hackathon/iitpsa/register`,
            shouldShowEntered: isHackathonIITPSA && location.pathname.startsWith(isHackathonIITPSA.path)
        },
        {
            cupName: "Company Cup",
            shouldShow: isEmployee && hackathonCompanyTournament.enabled,
            pathTo: haveCompanyEntry ? `${path}/hackathon/company/team` : `${path}/hackathon/company/register`,
            shouldShowEntered: isHackathonCompany && location.pathname.startsWith(isHackathonCompany.path)
        }
    ];

    function isEntelectChallengeCheck() {
        return isEntelectChallenge && location.pathname.startsWith(isEntelectChallenge.path);
    }

    function isHackathonCheck() {
        return isHackathon && !isHackathonRegistration && doesPlayerHaveHackathonProfile && enableIITPSA;
    }

    function isHackathonNoProfileCheck() {
        return isHackathon && !isHackathonRegistration && !doesPlayerHaveHackathonProfile;
    }

    function isIITPSACheck() {
        return selectedCup.path === EntryType.IITPSA;
    }

    function shouldShowSubmissions() {
        return isEntelectChallengeCheck();
    }

    function shouldShowLeaderBoards() {
        return isEntelectChallengeCheck() || isHackathonCheck();
    }

    function shouldShowSolutions() {
        return isHackathonCheck();
    }

    function shouldShowStream() {
        return isHackathonCheck() || isHackathonNoProfileCheck();
    }

    function shouldShowTeam() {
        return isHackathonCheck() && !isIITPSACheck();
    }

    function shouldShowPlayer() {
        return isHackathonCheck() && isIITPSACheck();
    }

    function getLeaderBoardsPath() {
        if (isEntelectChallengeCheck()) {
            return "/portal/entelect-challenge/leaderboards";
        }
        if (isHackathonCheck()) {
            return "/portal/hackathon/" + selectedCup.path + "/leaderboard";
        }
        return "";
    }

    const MainNavLinkItems: Array<LinkItemProps> = [
        {name: "The Game", linkTo: "/game", toggleMapKey: "game", shouldEvenShow: true},
        {name: "Tournaments", linkTo: "/tournaments", toggleMapKey: "tournament", shouldEvenShow: true},
        {name: "University Cup", linkTo: "/university", toggleMapKey: "uni", shouldEvenShow: true},
        {name: "Our Team", linkTo: "/team", shouldEvenShow: true},
        {name: "Our Legacy", linkTo: "/legacy", shouldEvenShow: true},
        {
            name: "Submissions",
            linkTo: "/portal/entelect-challenge/submissions",
            shouldEvenShow: shouldShowSubmissions()
        },
        {name: "Leaderboards", linkTo: getLeaderBoardsPath(), shouldEvenShow: shouldShowLeaderBoards()},
        {
            name: "Solutions",
            linkTo: "/portal/hackathon/" + selectedCup.path + "/solutions",
            shouldEvenShow: shouldShowSolutions()
        },
        {
            name: "Stream",
            linkTo: "/portal/hackathon/" + selectedCup.path + "/stream",
            shouldEvenShow: shouldShowStream()
        },
        {name: "Team", linkTo: "/portal/hackathon/" + selectedCup.path + "/team", shouldEvenShow: shouldShowTeam()},
        {name: "Player", linkTo: "/portal/hackathon/" + selectedCup.path + "/team", shouldEvenShow: shouldShowPlayer()},
        {
            name: "Create Team",
            linkTo: "/portal/hackathon/" + selectedCup.path + "/create",
            shouldEvenShow: isHackathonNoProfileCheck()
        }
    ];

    return (
        <Box
            bg={main_bg}
            h={"4em"}
            as="nav"
            position="fixed"
            w={"100%"}
            zIndex={1000}>
            <NavigationNotificationsBar
                isOpen={notificationsOpen}
                onClose={() => setNotificationsOpen(false)}
                notifications={notifications}
                isLoading={notificationsLoading}
                isMobile={isMobilePortrait}
            />
            <Flex display={{base: "none", lg: "flex"}}>
                <Wrap
                    display={"flex"}
                    alignItems={"center"}
                    alignContent={"center"}
                    ml={8}>
                    <Link to={`/home`}>
                        <Image
                            py={2}
                            height={"4em"}
                            objectFit={"contain"}
                            fit={"contain"}
                            src={logo}
                        />
                    </Link>
                </Wrap>
                <Spacer/>
                {location.pathname.startsWith("/portal") && <Spacer/>}
                <Wrap
                    display={"flex"}
                    spacing={4}
                    alignItems={"center"}
                    alignContent={"center"}>
                    {MainNavLinkItems.map(
                        (item) =>
                            (!item.toggleMapKey || navBarToggleMap.get(item.toggleMapKey)) &&
                            item.shouldEvenShow && (
                                <Link
                                    to={item.linkTo}
                                    key={item.name}>
                                    <UILink
                                        fontWeight={"semibold"}
                                        fontSize={18}
                                        color={"whiteAlpha.900"}
                                        bg={location.pathname === item.linkTo ? navigation_selected_bg : "transparent"}
                                        _hover={{bg: button_hover_bg}}
                                        py={5}
                                        px={3}
                                        colorScheme="primary">
                                        {item.name}
                                    </UILink>
                                </Link>
                            )
                    )}
                </Wrap>
                <Spacer/>
                {location.pathname.startsWith("/portal") && (
                    <Wrap
                        display={"flex"}
                        alignItems={"center"}
                        alignContent={"center"}
                        pr={5}>
                        <Box
                            alignItems={"center"}
                            _hover={{cursor: "pointer"}}>
                            <Menu>
                                {({isOpen}) => (
                                    <>
                                        <MenuButton
                                            color={"white"}
                                            variant={"ghost"}
                                            rightIcon={<ChevronDownIcon/>}
                                            _hover={{bg: button_hover_bg}}
                                            _expanded={{bg: "whiteAlpha.900"}}
                                            transition="all 0.2s"
                                            borderRadius="md"
                                            colorScheme="navigation">
                                            <Flex alignItems={"center"}>
                                                <Text
                                                    fontWeight={"semibold"}
                                                    fontSize={18}
                                                    _hover={{bg: button_hover_bg}}
                                                    py={4}
                                                    px={3}
                                                    color={isOpen ? text_button_hover_color : "whiteAlpha.900"}>
                                                    Selected Cup: {selectedCup.name}
                                                </Text>
                                                <ChevronDownIcon
                                                    ml={1}
                                                    color={isOpen ? text_button_hover_color : "whiteAlpha.900"}
                                                    fontSize={22}
                                                    h={"1.4em"}
                                                />
                                            </Flex>
                                        </MenuButton>
                                        <MenuList>
                                            {listOfHackathonItems.map(
                                                (item) =>
                                                    item.shouldShow && (
                                                        <Link to={item.pathTo}>
                                                            <MenuItem icon={<ArrowForwardIcon/>}>
                                                                {item.cupName}{" "}
                                                                {item.shouldShowEntered && (
                                                                    <CheckCircleIcon
                                                                        ml={2}
                                                                        color={colorMode === "light" ? text_button_hover_color : "navigation.200"}
                                                                    />
                                                                )}
                                                            </MenuItem>
                                                        </Link>
                                                    )
                                            )}
                                        </MenuList>
                                    </>
                                )}
                            </Menu>
                        </Box>
                    </Wrap>
                )}
                {!tokenValid && (
                    <Wrap
                        display={"flex"}
                        alignItems={"center"}
                        alignContent={"center"}
                        px={5}>
                        <Text
                            fontWeight={"semibold"}
                            fontSize={18}
                            color={"whiteAlpha.900"}
                            _hover={{bg: button_hover_bg}}
                            py={4}
                            px={3}
                            onClick={onOpen}>
                            Player Portal
                        </Text>
                    </Wrap>
                )}
                {tokenValid && (
                    <Wrap
                        display={"flex"}
                        alignItems={"center"}
                        alignContent={"center"}
                        px={5}
                        onClick={onOpen}
                        _hover={{bg: button_hover_bg}}>
                        <Avatar
                            borderColor={"white"}
                            borderWidth={1}
                            size={"sm"}
                            name={props.player.displayName}
                            src={props.player.imageUrl}
                            mr={1}
                        />
                        <ChevronDownIcon
                            color={isOpen ? text_button_hover_color : "whiteAlpha.900"}
                            fontSize={22}
                            h={"1.4em"}
                        />
                    </Wrap>
                )}
            </Flex>
            <Flex display={{base: "flex", lg: "none"}}>
                <Spacer/>
                <Wrap
                    display={"flex"}
                    alignItems={"center"}
                    alignContent={"center"}>
                    <Link to={`/home`}>
                        <Image
                            height={"4em"}
                            objectFit={"contain"}
                            fit={"contain"}
                            src={logo}
                        />
                    </Link>
                </Wrap>
                <Spacer/>

                <Wrap
                    display={"flex"}
                    alignItems={"center"}
                    alignContent={"center"}>
                    <IconButton
                        onClick={onOpen}
                        color={"whiteAlpha.900"}
                        _hover={{backgroundColor: "whiteAlpha.900", color: button_hover_bg}}
                        aria-label={"show hidden menu"}
                        variant={"ghost"}
                        icon={<HamburgerIcon fontSize={"2xl"}/>}
                    />
                </Wrap>
            </Flex>
            <Box>
                <Drawer
                    autoFocus={false}
                    isOpen={isOpen}
                    placement="right"
                    size={isMobilePortrait ? "full" : "xs"}
                    onClose={() => handleSubmenuAndClose(submenuOpen, setSubmenuOpen, onClose)}
                    returnFocusOnClose={false}
                    blockScrollOnMount={false}
                    onOverlayClick={() => handleSubmenuAndClose(submenuOpen, setSubmenuOpen, onClose)}>
                    <DrawerOverlay>
                        <DrawerContent>
                            <SidebarContent
                                onClose={onClose}
                                isMobile={isMobileLandscape}
                                submenuOpen={submenuOpen}
                                setSubmenuOpen={setSubmenuOpen}
                                navBarToggleMap={navBarToggleMap}
                                location={location}
                                mainNavLinkItems={MainNavLinkItems}
                                player={props.player}
                                tokenValid={tokenValid}
                                localState={localState}
                                setLocalState={setLocalState}
                                setNotificationsOpen={setNotificationsOpen}
                                enabledNotifications={enabledNotifications}
                                notificationsOpen={notificationsOpen}
                                newNotifications={newNotifications}
                                notificationsLoading={notificationsLoading}
                                overflow={"scroll"}
                            />
                        </DrawerContent>
                    </DrawerOverlay>
                </Drawer>
            </Box>
        </Box>
    );
};

const handleSubmenuAndClose = (subMenuOpen: boolean, setSubmenuOpen: any, onClose: any) => {
    if (subMenuOpen) {
        setSubmenuOpen(!subMenuOpen);
    } else {
        onClose();
    }
};

interface SidebarProps extends BoxProps {
    onClose: () => void;
    isMobile: boolean | undefined;
    submenuOpen: boolean;
    setSubmenuOpen: any;
    navBarToggleMap: Map<string, string>;
    location: any;
    mainNavLinkItems: Array<LinkItemProps>;
    player: PlayerModel;
    tokenValid: boolean;
    localState: any;
    setLocalState: any;
    setNotificationsOpen: any;
    enabledNotifications: boolean;
    notificationsOpen: boolean;
    newNotifications: boolean;
    notificationsLoading: boolean;
}

const SidebarContent = ({
                            onClose,
                            isMobile,
                            submenuOpen,
                            setSubmenuOpen,
                            navBarToggleMap,
                            location,
                            mainNavLinkItems,
                            player,
                            tokenValid,
                            localState,
                            setLocalState,
                            setNotificationsOpen,
                            enabledNotifications,
                            notificationsOpen,
                            newNotifications,
                            notificationsLoading,
                            ...rest
                        }: SidebarProps) => {
    let showPlayerPortalOptions = (!isMobile && !submenuOpen) || (isMobile && submenuOpen);
    let showMainOptions = isMobile && !submenuOpen;
    const history = useHistory();

    const signOut = () => {
        localStorage.clear();
        history.push("/signin");
    };

    const openNotifications = () => {
        // Update the time the user last opened notifications
        setLocalState({...localState, notificationsLastOpenedDate: new Date()});

        setNotificationsOpen(true);
    };

    const navigateAndCloseDrawer = (link: string): void => {
        handleSubmenuAndClose(submenuOpen, setSubmenuOpen, onClose);
        history.push(link);
    };

    return (
        <Box
            transition="3s ease"
            bg={main_bg}
            color="whiteAlpha.900"
            h="full"
            {...rest}>
            <Flex
                h="20"
                alignItems="center"
                align="center"
                mx="8"
                justifyContent="space-between">
                <Image
                    height={"4em"}
                    objectFit={"contain"}
                    fit={"contain"}
                    src={logo}
                />
                {!submenuOpen && (
                    <CloseButton
                        display={{base: "flex"}}
                        onClick={() => handleSubmenuAndClose(submenuOpen, setSubmenuOpen, onClose)}
                    />
                )}
                {submenuOpen && (
                    <IconButton
                        aria-label={"previous side menu"}
                        size={"lg"}
                        colorScheme={"whiteAlpha.900"}
                        icon={<ArrowBackIcon/>}
                        variant={"ghost"}
                        display={{base: "flex"}}
                        pl={3}
                        onClick={() => handleSubmenuAndClose(submenuOpen, setSubmenuOpen, onClose)}
                    />
                )}
            </Flex>
            {tokenValid && player.playerId !== "" && (
                <>
                    <Flex
                        alignItems="center"
                        flexDirection={"row"}
                        justifyContent={"center"}>
                        <Text
                            fontWeight={"bold"}
                            textAlign={"center"}
                            fontSize={"xl"}
                            color={"whiteAlpha.900"}
                            mb={2}>
                            {player.firstName + " " + player.lastName}
                        </Text>
                        {enabledNotifications && (
                            <Tooltip
                                hasArrow
                                isOpen={!notificationsOpen && newNotifications}
                                label="New Notifications"
                                bg="primary.500"
                                color={"whiteAlpha.900"}
                                placement="bottom">
                                <IconButton
                                    aria-label={"notifications"}
                                    backgroundColor={notificationsOpen ? "whiteAlpha.900" : button_hover_bg}
                                    color={notificationsOpen ? text_button_hover_color : "whiteAlpha.900"}
                                    icon={
                                        <BellIcon
                                            fontSize={"2xl"}
                                            mx={4}
                                        />
                                    }
                                    _hover={{bg: button_hover_bg}}
                                    ml={2}
                                    mb={2}
                                    isLoading={notificationsLoading}
                                    onClick={() => openNotifications()}
                                />
                            </Tooltip>
                        )}
                    </Flex>
                    <Divider my={1}/>
                    <NavItem
                        key={"Sign Out"}
                        onClick={() => signOut()}
                        icon={IoMdLogOut}
                        newTab={false}>
                        Sign out
                    </NavItem>
                    <NavItem
                        key={"Profile"}
                        onClick={() => navigateAndCloseDrawer("/portal/settings/profile")}
                        icon={CgProfile}
                        newTab={false}
                        bg={location.pathname === "/portal/settings/profile" ? navigation_selected_bg : "transparent"}>
                        Profile
                    </NavItem>
                    <NavItem
                        key={"Settings"}
                        onClick={() => navigateAndCloseDrawer("/portal/settings")}
                        icon={FiSettings}
                        newTab={false}
                        bg={location.pathname === "/portal/settings" ? navigation_selected_bg : "transparent"}>
                        Settings
                    </NavItem>
                    <NavItem
                        key={"Wiki"}
                        onClick={() => navigateAndCloseDrawer("/wiki")}
                        icon={GoBook}
                        newTab={false}
                        bg={location.pathname === "/wiki" ? navigation_selected_bg : "transparent"}>
                        Wiki
                    </NavItem>
                    <Divider my={1}/>
                </>
            )}
            {showPlayerPortalOptions &&
                PlayerPortalSideMenuLinkItems.map(
                    (link) =>
                        (!link.toggleMapKey || navBarToggleMap.get(link.toggleMapKey)) && (
                            <NavItem
                                key={link.name}
                                {...(link.linkTo.startsWith("https://") && {href: link.linkTo})}
                                {...(!link.linkTo.startsWith("https://") && {onClick: () => navigateAndCloseDrawer(link.linkTo)})}
                                newTab={link.linkTo.startsWith("https://")}
                                bg={location.pathname === link.linkTo ? navigation_selected_bg : "transparent"}
                                {...(link.icon && {icon: link.icon})}>
                                {link.name}
                            </NavItem>
                        )
                )}
            {showMainOptions &&
                mainNavLinkItems.map(
                    (item) =>
                        (!item.toggleMapKey || navBarToggleMap.get(item.toggleMapKey)) &&
                        item.shouldEvenShow && (
                            <NavItem
                                key={item.name}
                                {...(item.linkTo.startsWith("https://") && {href: item.linkTo})}
                                {...(!item.linkTo.startsWith("https://") && {onClick: () => navigateAndCloseDrawer(item.linkTo)})}
                                bg={location.pathname === item.linkTo ? navigation_selected_bg : "transparent"}
                                newTab={item.linkTo.startsWith("https://")}
                                {...(item.icon && {icon: item.icon})}>
                                {item.name}
                            </NavItem>
                        )
                )}
            {showMainOptions && (
                <NavItem
                    key="Player Portal"
                    onClick={() => setSubmenuOpen(!submenuOpen)}
                    newTab={false}>
                    Player Portal
                </NavItem>
            )}
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon?: IconType;
    href?: string;
    children: ReactText;
    newTab: boolean;
}

const NavItem = ({icon, href, children, newTab = false, ...rest}: NavItemProps) => {
    return (
        <UILink
            {...(href && {href: href})}
            style={{textDecoration: "none"}}
            _focus={{boxShadow: "none"}}
            isExternal={newTab}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                color="whiteAlpha.900"
                _hover={{
                    bg: button_hover_bg,
                    color: "whiteAlpha.900"
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="18"
                        _groupHover={{
                            color: "whiteAlpha.900"
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </UILink>
    );
};
