const gallaryImages ={
    2019_1:require('./2019_1.jpg').default,
    2019_2:require('./2019_2.jpg').default,
    2019_3:require('./2019_3.jpg').default,
    2019_4:require('./2019_4.jpg').default,
    2019_5:require('./2019_5.jpg').default,
    2019_6:require('./2019_6.jpg').default,
    2019_7:require('./2019_7.jpg').default,
    2019_8:require('./2019_8.jpg').default,
    2019_9:require('./2019_9.jpg').default,
    2019_10:require('./2019_10.jpg').default,
    2019_11:require('./2019_11.jpg').default,
    2019_12:require('./2019_12.jpg').default,
    2019_13:require('./2019_13.jpg').default,
    2019_14:require('./2019_14.jpg').default,
    2019_15:require('./2019_15.jpg').default,
    2019_16:require('./2019_16.jpg').default,
    2019_17:require('./2019_17.jpg').default,
    2019_18:require('./2019_18.jpg').default,
	2019_19:require('./2019_19.jpg').default,
    2019_20:require('./2019_20.jpg').default,
    2019_21:require('./2019_21.jpg').default,
    2019_22:require('./2019_22.jpg').default,
    2019_23:require('./2019_23.jpg').default,
    2019_24:require('./2019_24.jpg').default,
    2019_25:require('./2019_25.jpg').default,
    2019_26:require('./2019_26.jpg').default,
    2019_27:require('./2019_27.jpg').default,
	2019_28:require('./2019_28.jpg').default,
    2019_29:require('./2019_29.jpg').default,
    2019_30:require('./2019_30.jpg').default,
    2019_31:require('./2019_31.jpg').default,
	2019_32:require('./2019_32.jpg').default,
	2019_33:require('./2019_33.jpg').default,
    2019_34:require('./2019_34.jpg').default,
    2019_35:require('./2019_35.jpg').default,
    2019_36:require('./2019_36.jpg').default,
    2019_37:require('./2019_37.jpg').default,
	2019_38:require('./2019_38.jpg').default,
    2019_39:require('./2019_39.jpg').default,
	2019_40:require('./2019_40.jpg').default,
    2019_41:require('./2019_41.jpg').default,
	2019_42:require('./2019_42.jpg').default,
	2019_43:require('./2019_43.jpg').default,
    2019_44:require('./2019_44.jpg').default,
    2019_45:require('./2019_45.jpg').default,
    2019_46:require('./2019_46.jpg').default,
    2019_47:require('./2019_47.jpg').default,
}
export default gallaryImages;