import * as React from 'react';
import {Box, Divider, Grid, GridItem, Text, useColorMode} from "@chakra-ui/react";

interface ISettingItemSection {
    heading: string;
    subTexts?: string[];
    colSpan?: number;
}

export const SettingItemSection: React.FunctionComponent<ISettingItemSection> = props => {
    const {colorMode} = useColorMode();

    const colSpan = (props.colSpan && (props.colSpan > 0 && props.colSpan < 11)) ? props.colSpan : 7;

    return (
        <Box mb={14} h={'100%'}>
            <Text fontSize="2xl">{props.heading}</Text>
            <Divider mt={2} mb={2}/>
            {
                props.subTexts &&
                props.subTexts.map((text, index) => <Text key={index} mb={1} fontSize="md"
                                                          color={colorMode === 'light' ? 'gray.500' : 'whiteAlpha.600'}>{text}</Text>)
            }
            <Box mt={1}>
                <Grid templateColumns="repeat(10, 1fr)" gap={10}>
                    <GridItem colSpan={colSpan}>
                        {props.children}
                    </GridItem>
                </Grid>
            </Box>
        </Box>
    )
};