import gallary from '../gallary/gallaryIndex';

export const photos2021 = [
    {
        src: gallary[202111],
        width: 4,
        height: 3
    },
    {
        src: gallary[20212],
        width: 4,
        height: 3
    },
    {
        src: gallary[20213],
        width: 4,
        height: 3
    },
    {
        src: gallary[20214],
        width: 4,
        height: 3
    },
    {
        src: gallary[20215],
        width: 4,
        height: 3
    },
    {
        src: gallary[20216],
        width: 4,
        height: 3
    },
    {
        src: gallary[20217],
        width: 4,
        height: 3
    },
    {
        src: gallary[20218],
        width: 4,
        height: 3
    },
    {
        src: gallary[20219],
        width: 4,
        height: 3
    },
    {
        src: gallary[202110],
        width: 4,
        height: 3
    },
    {
        src: gallary[202111],
        width: 4,
        height: 3
    }
];