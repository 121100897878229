import {AxiosResponse} from "axios";
import AwsSubmissionModel from "../models/aws-submissions.model";
import axiosInstance from "./axios-instance.api";

// TODO: Maybe allow text & zip files - this is only for zip files, for now
const headers = {
    'Content-Type': 'text/plain; charset=utf-8'
}

const responseType = 'text' as 'json';

export const SubmissionsApi = {
    getSubmissions: (entryType: string): Promise<AxiosResponse<AwsSubmissionModel[]>> => axiosInstance.get(`/player/submissions/${entryType}`),
    getLog: (downloadUrl: string): Promise<AxiosResponse<string>> => axiosInstance.get(downloadUrl, { headers, responseType })
}
