const gallaryImages ={
    2014_1:require('./2014_1.jpg').default,
    2014_2:require('./2014_2.jpg').default,
    2014_3:require('./2014_3.jpg').default,
    2014_4:require('./2014_4.jpg').default,
    2014_5:require('./2014_5.jpg').default,
    2014_6:require('./2014_6.jpg').default,
    2014_7:require('./2014_7.jpg').default,
    2014_8:require('./2014_8.jpg').default,
    2014_9:require('./2014_9.jpg').default,
    2014_10:require('./2014_10.jpg').default,
    2014_11:require('./2014_11.jpg').default,
    2014_12:require('./2014_12.jpg').default,
    2014_13:require('./2014_13.jpg').default,
    2014_14:require('./2014_14.jpg').default,
    2014_15:require('./2014_15.jpg').default,
    2014_16:require('./2014_16.jpg').default,
    2014_17:require('./2014_17.jpg').default,
    2014_18:require('./2014_18.jpg').default,
}
export default gallaryImages;