import * as React from 'react';
import {useState} from 'react';
import {Box, Button, Grid, GridItem, HStack, Link as UILink, Spacer, Stack, Switch, useToken} from "@chakra-ui/react";
import {HackathonLeaderboardGridTable} from "./HackathonLeaderboardGridTable";
import {HackathonLeaderboardModel} from "../../../models/hackathon-leaderboard.model";
import {Text} from "@chakra-ui/layout";
import {getCurrentYear, groupQuestions} from "../../../utils/hooks/general-utils";
import {Link, useHistory} from "react-router-dom";
import {IntermediateMessageLoader} from "../../../shared/loading/IntermediateMessageLoader";
import {HackathonCountdown} from "./HackathonCountdown";

interface IHackathonPublicLeaderboard {
    leaderboardLoading: boolean;
    refreshLeaderboard: Function;
    leaderboardQuestions: Array<HackathonLeaderboardModel>;
    openDate: Date;
    closeDate: Date;
}

export const HackathonPublicLeaderboard: React.FunctionComponent<IHackathonPublicLeaderboard> = props => {

    const [primary50] = useToken("colors", ["primary.50"]);

    const history = useHistory();

    // const [rotateLeaderboard, setRotateLeaderboard] = useState(false);
    // const toggleRotateLeaderboard = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setRotateLeaderboard(event.target.checked);
    // }

    const [cumulativeLeaderboard, setCumulativeLeaderboard] = useState(true);
    const toggleLeaderboard = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCumulativeLeaderboard(event.target.checked);
    }

    // useEffect(() => {
    //     const rotateInterval = setInterval(() => {
    //         if (rotateLeaderboard) {
    //             setCumulativeLeaderboard(!cumulativeLeaderboard);
    //         }
    //     }, 10000);
    //
    //     return () => clearInterval(rotateInterval);
    // }, [cumulativeLeaderboard, rotateLeaderboard]);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         props.refreshLeaderboard();
    //     }, 60000);
    //
    //     // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    //     return () => clearInterval(interval);
    // }, [props]);

    const goToMainPage = () => {
        history.push("/portal")
    };

    const leaderboardQuestionOne = props.leaderboardQuestions.filter(value => value.hackathonSubmission.questionNumber === 1);
    const leaderboardQuestionTwo = props.leaderboardQuestions.filter(value => value.hackathonSubmission.questionNumber === 2);
    const leaderboardQuestionThree = props.leaderboardQuestions.filter(value => value.hackathonSubmission.questionNumber === 3);
    const leaderboardQuestionFour = props.leaderboardQuestions.filter(value => value.hackathonSubmission.questionNumber === 4);
    const leaderboardQuestionFive = props.leaderboardQuestions.filter(value => value.hackathonSubmission.questionNumber === 5);
    const leaderboardCumulative = groupQuestions(props.leaderboardQuestions);

    return (
        <Box>
            <Stack px={10} direction={['column', 'column', 'row', 'row']} alignItems={'center'} backgroundColor={'navigation.500'}>
                <Text _hover={{ cursor: 'pointer'}} onClick={() => goToMainPage()} color={'whiteAlpha.900'} fontWeight={'bold'} fontSize={'3xl'} display={'inline'} mr={3}
                      textAlign={['center', 'center', 'start', 'start']}>Entelect
                    Challenge {getCurrentYear()}</Text>
                <Spacer/>
                <Box pr={5}>
                    <Link to={`/signin`}>
                        <UILink fontWeight={'semibold'}
                                fontSize={18}
                                color={'whiteAlpha.900'}>Sign in</UILink>
                    </Link>
                </Box>
                <Box>
                    <Link to={`/register`}>
                        <UILink fontWeight={'semibold'}
                                fontSize={18}
                                color={'whiteAlpha.900'}>Register</UILink>
                    </Link>
                </Box>
            </Stack>
            <Grid templateColumns={'repeat(12, 1fr)'} h={'100%'} mb={10} backdropFilter={'blur(10px)'}>
                <GridItem colSpan={[1, 1, 1, 1]}/>
                <GridItem colSpan={[10, 10, 10, 10]}>

                    <Box mt={3} w={'100%'}>
                        <Stack alignItems={'baseline'} direction={['column', 'column', 'row', 'row']}>
                            <Text
                                color={'gray.700'}
                                fontWeight={'bold'}
                                fontSize={'2xl'}
                                mr={3}
                                textAlign={['center', 'center', 'start', 'start']}
                            >Hackathon Public
                                Leaderboard</Text>
                            <Text
                                color={'gray.500'}
                                fontSize={'sm'}
                                w={['100%', '100%', 'auto', 'auto']}
                                textAlign={['center', 'center', 'start', 'start']}
                            >Please refresh the leaderboard manually.</Text>
                            <Box>
                                <Button onClick={() => props.refreshLeaderboard()} variant={'link'} _hover={{ textDecoration: 'none', color: 'primary.800' }} transition={'0.10s all'} size={'sm'} colorScheme={'primary'}>Refresh</Button>
                            </Box>
                        </Stack>
                        <Stack mt={2} alignItems={'baseline'} direction={['column', 'column', 'row', 'row']} spacing={8} >
                            <HStack alignItems={'center'} >
                                <Switch isChecked={cumulativeLeaderboard} onChange={toggleLeaderboard}
                                        colorScheme="primary" size={'sm'}/>
                                <Text as={'span'} color={'gray.500'} fontSize={'sm'}>Cumulative Leaderboard</Text>
                            </HStack>
                            {/*<HStack alignItems={'center'}>*/}
                            {/*    <Switch isChecked={rotateLeaderboard} onChange={toggleRotateLeaderboard}*/}
                            {/*            colorScheme="primary" size={'sm'}/>*/}
                            {/*    <Text as={'span'} color={'gray.500'} fontSize={'sm'}>Rotate leaderboards</Text>*/}
                            {/*</HStack>*/}
                            <Spacer/>
                            <HackathonCountdown date={props.closeDate}/>
                        </Stack>
                    </Box>
                    {
                        props.leaderboardLoading ?
                            <IntermediateMessageLoader loadingMessage={'Fetching Leaderboard'}/> :
                            cumulativeLeaderboard ?
                                <Grid templateColumns={'repeat(12, 1fr)'} gap={8} mt={4} mb={4}>
                                    <GridItem colSpan={[1, 1, 2, 2]} />
                                    <GridItem colSpan={[11, 11, 8, 8]} borderColor={'primary.50'} borderWidth={1}
                                              borderRadius={'md'} boxShadow={`${primary50} 0px 3px 10px 0px`} p={2}>
                                        <Text w={'100%'} textAlign={'center'} fontSize={'2xl'} fontWeight={'semibold'}
                                              color={'gray.700'} py={2}>CUMULATIVE LEADERBOARD</Text>
                                        <HackathonLeaderboardGridTable leaderboardItems={leaderboardCumulative}/>
                                    </GridItem>
                                </Grid> :
                                <Grid templateColumns={'repeat(12, 1fr)'} gap={8} mt={4} mb={4}>
                                    {
                                        leaderboardQuestionOne.length !== 0 &&
                                        <GridItem colSpan={[11, 11, 4, 4]} borderColor={'primary.50'} borderWidth={1}
                                                  borderRadius={'md'} boxShadow={`${primary50} 0px 3px 10px 0px`} p={2}>
                                            <Text w={'100%'} textAlign={'center'} fontSize={'2xl'} fontWeight={'semibold'}
                                                  color={'gray.700'} py={2}>Track 1</Text>
                                            <HackathonLeaderboardGridTable leaderboardItems={leaderboardQuestionOne} hideOrigin={true}/>
                                        </GridItem>
                                    }
                                    {
                                        leaderboardQuestionTwo.length !== 0 &&
                                        <GridItem colSpan={[11, 11, 4, 4]} borderColor={'primary.50'} borderWidth={1}
                                                  borderRadius={'md'} boxShadow={`${primary50} 0px 3px 10px 0px`} p={2}>
                                            <Text w={'100%'} textAlign={'center'} fontSize={'2xl'} fontWeight={'semibold'}
                                                  color={'gray.700'} py={2}>Track 2</Text>
                                            <HackathonLeaderboardGridTable leaderboardItems={leaderboardQuestionTwo} hideOrigin={true}/>
                                        </GridItem>
                                    }
                                    {
                                        leaderboardQuestionThree.length !== 0 &&
                                        <GridItem colSpan={[11, 11, 4, 4]} borderColor={'primary.50'} borderWidth={1}
                                                  borderRadius={'md'} boxShadow={`${primary50} 0px 3px 10px 0px`} p={2}>
                                            <Text w={'100%'} textAlign={'center'} fontSize={'2xl'} fontWeight={'semibold'}
                                                  color={'gray.700'} py={2}>Track 3</Text>
                                            <HackathonLeaderboardGridTable leaderboardItems={leaderboardQuestionThree} hideOrigin={true}/>
                                        </GridItem>
                                    }
                                    {
                                        leaderboardQuestionFour.length !== 0 &&
                                        <GridItem colSpan={[11, 11, 4, 4]} borderColor={'primary.50'} borderWidth={1}
                                                  borderRadius={'md'} boxShadow={`${primary50} 0px 3px 10px 0px`} p={2}>
                                            <Text w={'100%'} textAlign={'center'} fontSize={'2xl'} fontWeight={'semibold'}
                                                  color={'gray.700'} py={2}>Track 4</Text>
                                            <HackathonLeaderboardGridTable leaderboardItems={leaderboardQuestionFour} hideOrigin={true}/>
                                        </GridItem>
                                    }
                                    {
                                        leaderboardQuestionFive.length !== 0 &&
                                        <GridItem colSpan={[11, 11, 4, 4]} borderColor={'primary.50'} borderWidth={1}
                                                  borderRadius={'md'} boxShadow={`${primary50} 0px 3px 10px 0px`} p={2}>
                                            <Text w={'100%'} textAlign={'center'} fontSize={'2xl'} fontWeight={'semibold'}
                                                  color={'gray.700'} py={2}>Track 5</Text>
                                            <HackathonLeaderboardGridTable leaderboardItems={leaderboardQuestionFive} hideOrigin={true}/>
                                        </GridItem>
                                    }
                                </Grid>

                    }

                </GridItem>
            </Grid>
        </Box>

    )
};