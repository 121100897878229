import * as React from 'react';
import {Center, Spinner, Text, VStack} from "@chakra-ui/react";

interface IIntermediateMessageLoader {
    loadingMessage: string;
    mt?: number;
}

export const IntermediateMessageLoader: React.FunctionComponent<IIntermediateMessageLoader> = props => {

    const marginTop = props.mt ? props.mt : 5;

    return (
        <Center h={'100%'} mt={marginTop}>
            <VStack m={5}>
                <Spinner color={'primary.500'} size={'xl'} thickness={'3px'} />
                <Text color={'gray.600'} fontSize={'3xl'} pt={2} textAlign={'center'}>
                    {props.loadingMessage}...
                </Text>
            </VStack>
        </Center>
    )
};