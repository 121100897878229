const images2017 ={
    "1_Ryan_Louw":require('./1_Ryan_Louw.jpg').default,
    "2_Dion_van_Huysteen":require('./2_Dion_van_Huysteen.jpg').default,
    "3_Anesu_Jairosi":require('./3_Anesu_Jairosi.jpg').default,
    "4_Jaco_Mosert":require('./4_Jaco_Mosert.jpg').default,
    "5_Willie_Theron":require('./5_Willie_Theron.jpg').default,
    "6_Wynand_Wolmarans":require('./6_Wynand_Wolmarans.jpg').default,
    "7_Andre_Nel":require('./7_Andre_Nel.jpg').default,
    "8_Mark_Anthony_Fouche":require('./8_Mark_Anthony_Fouche.jpg').default,
}
export default images2017;