import * as React from 'react';
import {Box, useToken, VStack} from "@chakra-ui/react";
import {Text} from "@chakra-ui/layout";
import {DropEvent, FileRejection, useDropzone} from "react-dropzone";

interface IFileDrop {
    text: string;
    acceptedMimes: Array<string> | string;
    onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
}

export const FileDrop: React.FunctionComponent<IFileDrop> = props => {

    const {getRootProps, getInputProps, isDragActive, isDragAccept, acceptedFiles} = useDropzone({
        accept: props.acceptedMimes,
        maxFiles: 1,
        onDrop: props.onDrop
    });

    const [primaryAlpha100, primaryAlpha200, red100, red200] = useToken("colors", ["primaryAlpha.100", "primaryAlpha.200", "red.100", 'red.200']);

    return (
        <Box>
            <VStack
                {...getRootProps()}
                borderColor={isDragActive ? isDragAccept ? 'primary.500' : 'red' : 'primary.300'}
                transition={'all 0.25s'}
                borderWidth={1}
                borderRadius={'md'}
                borderStyle={'dashed'}
                py={4}
                px={12}
                cursor={'pointer'}
                background={
                    isDragActive ?
                        isDragAccept ?
                            `repeating-linear-gradient(45deg, ${primaryAlpha100}, ${primaryAlpha100} 10px, ${primaryAlpha200} 10px, ${primaryAlpha200} 20px)`
                            : `repeating-linear-gradient(45deg, ${red100}, ${red100} 10px, ${red200} 10px, ${red200} 20px)`
                        : 'white'
                }
            >
                <input {...getInputProps()} />
                {
                    acceptedFiles.length !== 0 ?
                        acceptedFiles.map((file) => <Text key={file.name} color={'gray.700'} textAlign={'center'} fontSize={'xl'} >{file.name}</Text>)
                        : <Text color={'gray.700'} textAlign={'center'}>
                            {props.text}
                        </Text>
                }

            </VStack>
        </Box>
    )
};