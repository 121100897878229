import * as React from 'react';
import {Box, Grid, GridItem, Text, AlertIcon, Alert} from "@chakra-ui/react";
import {StreamVideoPortal} from "../../../shared/stream-portal/StreamVideoPortal";
import {FancyHeading} from "../../../shared/FancyHeading";
import {StreamChatPortal} from "../../../shared/stream-portal/StreamChatPortal";
import StreamConfigModel from "../../../models/stream-config.model";
import {useRecoilValue} from "recoil";
import {
    getSettingAsHackathonData,
    getSettingAsStreamConfig,
    SETTING_NAMES_HACKATHON
} from "../../../atoms/settings.atom";
import {getValueForEntryType} from "../../../utils/hooks/general-utils";
import {useParams} from "react-router-dom";
import HackathonTournamentModel from "../../../models/hackathon-tournament.model";
import moment from "moment";

interface IHackathonStream {
}

export const HackathonStream: React.FunctionComponent<IHackathonStream> = props => {

    const {type} = useParams();

    const streamConfig: StreamConfigModel | null = useRecoilValue(
        getSettingAsStreamConfig(
            getValueForEntryType(type, {university: SETTING_NAMES_HACKATHON.UNIVERSITY_STREAM.toString(), company: SETTING_NAMES_HACKATHON.COMPANY_STREAM}) as string
        )
    );

    const hackathonTournament: HackathonTournamentModel = useRecoilValue(
        getSettingAsHackathonData(
            getValueForEntryType(type, {university: SETTING_NAMES_HACKATHON.UNIVERSITY_TOURNAMENT_DATA, company: SETTING_NAMES_HACKATHON.COMPANY_TOURNAMENT_DATA}) as string
        )
    );

    if (streamConfig && streamConfig.enabled && hackathonTournament.enabledStreaming) {
        return (
            <Box h={'100%'}>
                <Grid templateColumns={'repeat(12, 1fr)'} h={'100%'}>
                    <GridItem colSpan={[1, 1, 2, 2]}/>
                    <GridItem colSpan={[10, 10, 8, 8]}>
                        <Box mt={10} >
                            <Box>
                                <FancyHeading heading={'Hackathon Stream'} headingSize={'3xl'}/>
                            </Box>
                            <Box mt={4} mb={[4, 4, 4, 4]}>
                                <Text mr={[0, 0, 32, 32]} color={'gray.600'}
                                      textAlign={['center', 'center', 'start', 'start']}>
                                    We bring the hackathon stream directly to your screen! Without any additional
                                    software or sign up.
                                    Sit back and relax and we will go live once the event start!
                                </Text>
                                <Text mr={[0, 0, 32, 32]} color={'gray.600'}
                                      textAlign={['center', 'center', 'start', 'start']}>
                                    You can make your screen fullscreen or you can popout the video and watch the stream while you submit or work on your code!
                                </Text>
                            </Box>
                            <Grid templateColumns={'repeat(12, 1fr)'} alignContent={'space-around'} gap={1}>
                                <GridItem colSpan={[10, 10, 8, 8]} >
                                    <StreamVideoPortal height={550} width={'100%'} config={streamConfig.video}/>
                                </GridItem>
                                <GridItem colSpan={[10, 10, 4, 4]}  >
                                    <StreamChatPortal height={550} width={430} config={streamConfig.chat}/>
                                </GridItem>
                            </Grid>
                        </Box>
                    </GridItem>
                </Grid>
            </Box>
        );
    }

    return (
        <Box h={'100%'}>
            <Grid templateColumns={'repeat(12, 1fr)'} h={'100%'}>
                <GridItem colSpan={[1, 1, 2, 2]}/>
                <GridItem colSpan={[10, 10, 8, 8]}>
                    <Box mt={10} >
                        <Box>
                            <FancyHeading heading={'Hackathon Stream'} headingSize={'3xl'}/>
                        </Box>
                        <Box mt={4} mb={[4, 4, 4, 4]}>
                            <Alert status="info" variant="left-accent">
                                <AlertIcon />
                                Streaming is currently disabled. Streaming will commence on the {moment(hackathonTournament.hackathonDate).format('YYYY-MM-DD HH:mm')}
                            </Alert>
                        </Box>
                    </Box>
                </GridItem>
            </Grid>
        </Box>
    )
};