import {atom, RecoilState} from "recoil";
import RegistrationInfoMainModel from "../models/registration-info-main.model";
import {getCurrentYear} from "../utils/hooks/general-utils";

export const registrationInfoMainAtom: RecoilState<RegistrationInfoMainModel> = atom<RegistrationInfoMainModel>({
    key: 'registration_info_main_atom',
    default: {
        playerId: '',
        mainRegistrationInfoId: '',
        playerIdType: 'ID',
        playerIdNumber: '',
        contactNumber: '',
        occupation: '',
        experienceLevel: '',
        challengeFoundSource: '',
        hadCompetedPreviously: false,
        yearEntered: getCurrentYear(),
        optInTournament: true,
        optInFriendly: true,
    } as RegistrationInfoMainModel
});
