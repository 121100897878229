import * as React from 'react';
import {Box, Divider, HStack} from "@chakra-ui/react";
import {Text} from "@chakra-ui/layout";

interface IWikiSubSection {
    heading: string;
}

export const WikiSubSection: React.FunctionComponent<IWikiSubSection> = props => {
    return (
        <Box px={3} py={4} pr={5}>
            <Text fontWeight={'semibold'} fontSize={'xl'} color={'accent.700'}>{props.heading}</Text>
            <HStack spacing={3} ml={1} mt={2} alignItems={'start'}>
                <Divider h={10} borderWidth={'1px'} orientation={'vertical'} borderColor={'primary.300'} />
                <Box>
                    {
                        props.children
                    }
                </Box>
            </HStack>
        </Box>
    )
};
