import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, ExpandedIndex, Heading, Spinner, Text, useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { TournamentApi } from '../../../api/tournament.api';
import { getSetting, SETTING_NAMES_ENTELECT_CHALLENGE } from '../../../atoms/settings.atom';
import ErrorModel from '../../../models/error.model';
import MatchModel from '../../../models/match.model';
import TournamentModel, { TournamentType } from '../../../models/tournament.model';
import { IntermediateMessageLoader } from '../../../shared/loading/IntermediateMessageLoader';
import { TournamentMatches } from './TournamentMatches';

interface IEntelectChallengeMatches {
}

export const EntelectChallengeMatches: FunctionComponent<IEntelectChallengeMatches> = _ => {
    const [loading, setLoading] = useState(true);
    const [tournaments, setTournaments] = useState<TournamentModel[]>([]);
    const [selectedTournamentAccordionItem, setSelectedTournamentAccordionItem] = useState<ExpandedIndex>(0);
    const [selectedTournamentMatches, setSelectedTournamentMatches] = useState<MatchModel[]>([]);
    const [isLeaderboardDetailsLoading, setIsLeaderboardDetailsLoading] = useState(false);
    const currentTournamentId = useRecoilValue(getSetting(SETTING_NAMES_ENTELECT_CHALLENGE.OFFICIAL_TOURNAMENT_ID)).value;
    const toast = useToast();

    let query = useQuery();

    function useQuery() {
        const {search} = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    const getTournamentMatches = useCallback((tournamentId: string) => {
        setIsLeaderboardDetailsLoading(true);

        TournamentApi.getMatches(tournamentId)
            .then((result) => {
                setSelectedTournamentMatches(result.data);
                setIsLeaderboardDetailsLoading(false);
            })
            .catch((error: AxiosError<ErrorModel>) => {
                toast({
                  title: error.message,
                  position: 'top-right',
                  status: 'error'
                });
                setSelectedTournamentMatches([]);
                setIsLeaderboardDetailsLoading(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCurrentTournamentLoad = useCallback((tournamentsData: TournamentModel[]) => {
        const currentTournamentIndex = tournamentsData.findIndex(value => value.tournamentId === currentTournamentId);
        const foundCurrentTournament = tournamentsData.find(x => x.tournamentId === currentTournamentId);

        if (foundCurrentTournament !== null && foundCurrentTournament !== undefined && currentTournamentIndex > -1) {
            setSelectedTournamentAccordionItem(currentTournamentIndex);
            getTournamentMatches(foundCurrentTournament.tournamentId);
        } else if (tournamentsData.length > 0 && tournamentsData[0] && tournamentsData[0].tournamentId ) {
            setSelectedTournamentAccordionItem(0);
            getTournamentMatches(tournamentsData[0].tournamentId);
        }
    }, [currentTournamentId, getTournamentMatches]);

    useEffect(() => {
        setLoading(true);
        TournamentApi.getTournaments()
            .then(result => {
                const tournamentModels = result.data.filter(value => value.tournamentType === TournamentType.FRIENDLY);

                setTournaments(tournamentModels);

                handleCurrentTournamentLoad(tournamentModels);

                if (query.get('tournament')) {
                    const queryTournamentID = query.get('tournament');
                    const index = tournamentModels.findIndex(tournament => tournament.tournamentId === queryTournamentID);
                    setSelectedTournamentAccordionItem(index);
                }
                setLoading(false);
            })
            .catch(reason => {
                setLoading(false);
                return reason;
            });
    }, [setLoading, handleCurrentTournamentLoad, query]);

    const handleAccordionItemChange = (expandedIndex: number) => {
        setSelectedTournamentAccordionItem(expandedIndex);

        const selectedTournament = tournaments[expandedIndex];
        getTournamentMatches(selectedTournament.tournamentId);
    };

    if (loading) {
        return (
            <IntermediateMessageLoader loadingMessage={'Fetching latest leaderboard'}/>
        );
    }

    const displayMatchDetails = (index: number) => {
        if (isLeaderboardDetailsLoading) {
            return <Spinner color={'teal'}/>;
        } else {
            return <TournamentMatches
                key={index}
                tournamentMatchItems={selectedTournamentMatches}
            />;
        }
    };

    return (
        <Box>
            <Heading
                textAlign={'center'}
                fontSize={'3xl'}
                fontWeight={'bold'}
                color={'navigation.800'}
                py={7}
                colorScheme="accent">
                Friendlies
            </Heading>

            {tournaments && tournaments.length > 0 ? (
                <>
                    <Accordion
                        index={selectedTournamentAccordionItem}
                        onChange={handleAccordionItemChange}
                    >
                        {tournaments.map((item, index) => (
                            <AccordionItem key={`tournament_panel_${++index}`}>
                                <h2>
                                    <AccordionButton _expanded={{bg: 'primary.700', color: 'white'}}>
                                        <Box flex="1" textAlign="left">
                                            {item.tournamentName}
                                        </Box>
                                        <AccordionIcon/>
                                    </AccordionButton>
                                </h2>

                                <AccordionPanel pb={4}>

                                    {displayMatchDetails(index)}

                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </>
            ) : (
                <>
                    <Text
                        fontSize={'2em'}
                        color={'navigation.800'}
                        p={5}
                        fontWeight={'semibold'}>
                        No matches yet.
                    </Text>
                </>
            )}
        </Box>
    );
};
