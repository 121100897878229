export const finalists2017 = [
    {
        id:1,
        finalist: 'Ryan Louw',
        image: '1_Ryan_Louw'
    },
    {
        id:2,
        finalist: 'Dion van Huysteen',
        image: '2_Dion_van_Huysteen'
    },
    {
        id:3,
        finalist: 'Anesu Jairosi',
        image: '3_Anesu_Jairosi'
    },
    {
        id:4,
        finalist: 'Jaco Mosert',
        image: '4_Jaco_Mosert'
    },
    {
        id:5,
        finalist: 'Willie Theron',
        image: '5_Willie_Theron'
    },
    {
        id:6,
        finalist: 'Wynand Wolmarans',
        image: '6_Wynand_Wolmarans'
    },
    {
        id:7,
        finalist: 'Andre Nel',
        image: '7_Andre_Nel'
    },
    {
        id:8,
        finalist: 'Mark-Anthony Fouche',
        image: '8_Mark_Anthony_Fouche'
    }
]