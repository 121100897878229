import * as Yup from "yup";
import {contactNumber, southAfricanIdNumberPattern} from "../../utils/constants";

export const registrationInfoValidation = Yup.object().shape({
    selectedIdType: Yup.string(),

    playerIdType: Yup.string()
        .required('Required'),
    playerIdNumber: Yup.string()
        .when('playerIdType', {
            is: 'ID',
            then: Yup.string()
                .required()
                .length(13, 'ID must be 13 characters')
                .matches(southAfricanIdNumberPattern, 'Invalid SA ID number'),
            otherwise: Yup.string()
                .required()
                .min(5, 'Passport number must be at least 5 characters')
        }),
    contactNumber: Yup.string()
        .required('Contact number is required')
        .min(9, 'Contact number must be at least 9 characters')
        .max(14, 'Contact number must be at most 14 characters')
        .matches(contactNumber, 'Invalid contact number'),
    occupation: Yup.string()
        .required('Occupation is required')
        .min(2, 'Occupation must be at least 2 characters')
        .max(50, 'Occupation must be at least 50 characters'),
    experienceLevel: Yup.string()
        .required('Experience level is required')
        .min(2, 'Experience level must be at least 2 characters')
        .max(50, 'Experience level must be at least 50 characters'),
    challengeFoundSource: Yup.string()
        .required('Source found is required'),
});
