const gallaryImages ={
    2015_1:require('./2015_1.jpg').default,
    2015_2:require('./2015_2.jpg').default,
    2015_3:require('./2015_3.jpg').default,
    2015_4:require('./2015_4.jpg').default,
    2015_5:require('./2015_5.jpg').default,
    2015_6:require('./2015_6.jpg').default,
    2015_7:require('./2015_7.jpg').default,
    2015_8:require('./2015_8.jpg').default,
    2015_9:require('./2015_9.jpg').default,
    2015_10:require('./2015_10.jpg').default,
    2015_11:require('./2015_11.jpg').default,
    2015_12:require('./2015_12.jpg').default,
    2015_13:require('./2015_13.jpg').default,
    2015_14:require('./2015_14.jpg').default,
    2015_15:require('./2015_15.jpg').default,
    2015_16:require('./2015_16.jpg').default,
    2015_17:require('./2015_17.jpg').default,
    2015_18:require('./2015_18.jpg').default,
	2015_19:require('./2015_19.jpg').default,
    2015_20:require('./2015_20.jpg').default,
    2015_21:require('./2015_21.jpg').default,
    2015_22:require('./2015_22.jpg').default,
    2015_23:require('./2015_23.jpg').default,
    2015_24:require('./2015_24.jpg').default,
    2015_25:require('./2015_25.jpg').default,
    2015_26:require('./2015_26.jpg').default,
    2015_27:require('./2015_27.jpg').default,
	2015_28:require('./2015_28.jpg').default,
    2015_29:require('./2015_29.jpg').default,
    2015_30:require('./2015_30.jpg').default,
    2015_31:require('./2015_31.jpg').default,
	2015_32:require('./2015_32.jpg').default,
}
export default gallaryImages;