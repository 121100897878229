const images2019 = {
    "WillieTheron":require('./WillieTheron.jpeg').default,
    "MarvinThobejane":require('./MarvinThobejane.jpeg').default,
    "JeanPaulDaConceicao":require('./JeanPaulDaConceicao.jpg').default,
    "AndreMakaal":require('./AndreMakaal.jpg').default,
    "MallinMoolman":require('./MallinMoolman.jpg').default,
    "GawieJooste":require('./GawieJooste.jpg').default,
    "LeonWessels":require('./LeonWessels.jpg').default,
    "RiaanNel":require('./RiaanNel.jpg').default,
}
export default images2019;