import {atom, selectorFamily} from "recoil";
import SettingModel from "../models/setting.model";
import {getAsBoolean} from "../utils/hooks/general-utils";
import MessageBoardModel from "../models/message-board.model";
import StreamConfigModel from "../models/stream-config.model";
import HackathonTournamentModel from "../models/hackathon-tournament.model";

export const settingsAtom = atom<Array<SettingModel>>({
    key: 'settings_atom',
    default: [],
});

export enum SETTING_NAMES_HACKATHON {
    COMPANY_TOURNAMENT_ID = "hackathon_company_tournament_id",
    COMPANY_TOURNAMENT_DATA = "hackathon_company_tournament_data",
    COMPANY_STREAM = "hackathon_company_stream",

    UNIVERSITY_TOURNAMENT_ID = "hackathon_university_tournament_id",
    UNIVERSITY_TOURNAMENT_DATA = "hackathon_university_tournament_data",
    UNIVERSITY_STREAM = "hackathon_university_stream",

    IITPSA_TOURNAMENT_ID = "hackathon_iitpsa_tournament_id",
    IITPSA_TOURNAMENT_DATA = "hackathon_iitpsa_tournament_data",
    IITPSA_STREAM = "hackathon_iitpsa_stream",

    VOTING_PAGE_TOURNAMENT_ID = "voting_page_tournament_id",
    VOTING_PAGE_VOTING_DATA="voting_page_voting_data",
    VOTING_PAGE_BETTING_DATA="voting_page_betting_data",
    VOTING_PAGE_STARTING_VALUE="voting_page_starting_value",
}

export enum SETTING_NAMES_ENTELECT_CHALLENGE {
    ENABLED = "entelect_challenge_enabled",
    OFFICIAL_TOURNAMENT_ID = "entelect_challenge_official_tournament_id",
    OFFICIAL_TOURNAMENT_DATE = "entelect_challenge_official_tournament_date",
    OFFICIAL_TOURNAMENT_NAME = "entelect_challenge_official_tournament_name",
    OFFICIAL_TOURNAMENT_START_DATE = "entelect_challenge_official_tournament_start_date",
    OFFICIAL_TOURNAMENT_VISIBLE = "tournament_visible",
    FRIENDLY_TOURNAMENT_ID = "entelect_challenge_friendly_tournament_id",
    IITPSA_ENABLE_SUBMISSION = "iitpsa_enable_submission"
}

export enum SETTING_NAME_OPERATIONAL {
    MESSAGE_BOARD = "operational_message_board",
    ENABLED_REGISTER = "operational_enabled_register",
    LEGACY_ENABLED = "operational_legacy_enabled",
    ENABLED_NOTIFICATIONS = "operational_enabled_notifications",
}

export enum SETTING_NAME_UNAUTHENTICATED {
    NAVBAR_ENTRY_TOGGLE_MAP = "unauthenticated_navbar_entry_toggle",
    YOUTUBE_VIDEO_MAP = "unauthenticated_youtube_video_map",
    COMING_SOON_COUNTDOWN_DATE = "unauthenticated_coming_soon_countdown_date"
}

export const getSetting = selectorFamily<SettingModel, string>({
    key: 'single_setting_selector',
    get: settingName => ({get}) => {
        const setting: SettingModel | undefined = get(settingsAtom).find(setting => setting.settingName === settingName);

        if (setting) {
            return setting;
        } else {
            return {
                settingName: '',
                moduleType: '',
                value: ''
            } as SettingModel;
        }
    }
});

export const getSettingAsBoolean = selectorFamily<boolean, string>({
    key: 'single_setting_selector_as_boolean',
    get: settingName => ({get}) => {
        const setting: SettingModel | undefined = get(settingsAtom).find(setting => setting.settingName === settingName);

        if (setting) {
            return getAsBoolean(setting.value);
        } else {
            return false;
        }
    }
});

export const getSettingAsDate = selectorFamily<Date, string>({
    key: 'single_setting_selector_as_date',
    get: settingName => ({get}) => {
        const setting: SettingModel | undefined = get(settingsAtom).find(setting => setting.settingName === settingName);

        if (setting) {
            return new Date(setting.value);
        } else {
            return new Date(2099, 0, 0, 0, 0, 0, 0);
        }
    }
});

// #====================| JSON Parsers |====================#

export const getSettingAsMap = selectorFamily<Map<string, string>, string>({
    key: 'single_setting_selector_as_map',
    get: settingName => ({get}) => {
        const setting: SettingModel | undefined = get(settingsAtom).find(setting => setting.settingName === settingName);

        if (setting) {
            return new Map(Object.entries(JSON.parse(setting.value)));
        } else {
            return new Map<string, string>();
        }
    }
});

export const getSettingAsMessageBoard = selectorFamily<MessageBoardModel | null, string>({
    key: 'single_setting_selector_as_message_board',
    get: settingName => ({get}) => {
        const setting: SettingModel | undefined = get(settingsAtom).find(setting => setting.settingName === settingName);

        if (setting) {
            return JSON.parse(setting.value) as MessageBoardModel;
        } else {
            return null;
        }
    }
});

export const getSettingAsStreamConfig = selectorFamily<StreamConfigModel | null, string>({
    key: 'single_setting_selector_as_stream_config',
    get: settingName => ({get}) => {
        const setting: SettingModel | undefined = get(settingsAtom).find(setting => setting.settingName === settingName);

        if (setting) {
            return JSON.parse(setting.value) as StreamConfigModel;
        } else {
            return null;
        }
    }
});

export const getSettingAsHackathonData = selectorFamily<HackathonTournamentModel, string>({
    key: 'single_setting_selector_as_hackathon_data',
    get: settingName => ({get}) => {
        const setting: SettingModel | undefined = get(settingsAtom).find(setting => setting.settingName === settingName);

        if (setting) {
            return JSON.parse(setting.value) as HackathonTournamentModel;
        } else {
            return {
                hackathonDate: new Date(),
                enabled: false,
            } as HackathonTournamentModel;
        }
    }
});
