import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	ExpandedIndex,
	Heading,
	Spinner,
	Text
} from '@chakra-ui/react';
import axios, { AxiosError } from 'axios';
import moment from 'moment/moment';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { TournamentApi } from '../../../api/tournament.api';
import { getSetting, SETTING_NAMES_ENTELECT_CHALLENGE } from '../../../atoms/settings.atom';
import ErrorModel from '../../../models/error.model';
import { LeaderboardItemModel } from '../../../models/leaderboard-item.model';
import TournamentModel from '../../../models/tournament.model';
import { IntermediateMessageLoader } from '../../../shared/loading/IntermediateMessageLoader';
import { TournamentLeaderboard } from './TournamentLeaderboard';

interface IEntelectChallengeLeaderboards {
}

export const EntelectChallengeLeaderboards: FunctionComponent<IEntelectChallengeLeaderboards> = props => {
	const [loading, setLoading] = useState(true);
	const [tournaments, setTournaments] = useState<TournamentModel[]>([]);
	const [selectedTournamentAccordionItem, setSelectedTournamentAccordionItem] = useState<ExpandedIndex>(0);
	const [selectedTournamentLeaderboardResults, setSelectedTournamentLeaderboardResults] = useState<LeaderboardItemModel[]>([]);
	const [isLeaderboardDetailsLoading, setIsLeaderboardDetailsLoading] = useState(false);
	const currentTournamentId = useRecoilValue(getSetting(SETTING_NAMES_ENTELECT_CHALLENGE.OFFICIAL_TOURNAMENT_ID)).value;

	let query = useQuery();

	function useQuery() {
		const { search } = useLocation();

		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	const getTournamentLeaderboard = useCallback((tournamentId: string, selectedTournamentDate: Date) => {
		setIsLeaderboardDetailsLoading(true);

		const apiKey = '123456789abcdefghijklmnopq';

		const headers = {
			'X-Api-Key': apiKey
		};

		const tournamentUrl = `${process.env.REACT_APP_TOURNAMENT_BASE_URL}`;

		// TODO: Find out why this doesn't work
		//TournamentApi.getTournamentResults(defaultTournamentId, defaultTournamentDate)
		let dateString = moment(selectedTournamentDate)
		.format('D-M-YYYY');
		axios.get(`${tournamentUrl}/tournament/${tournamentId}/${dateString}`, { headers: headers })
		     .then((result) => {
			     setSelectedTournamentLeaderboardResults(result.data);
			     setIsLeaderboardDetailsLoading(false);
		     })
		     .catch((error: AxiosError<ErrorModel>) => {
			     //console.error(error);
			     setIsLeaderboardDetailsLoading(false);
		     });
	}, []);

	const handleCurrentTournamentLoad = useCallback((tournamentsData: TournamentModel[]) => {
		const currentTournamentIndex = tournamentsData.findIndex(value => value.tournamentId === currentTournamentId);
		const foundCurrentTournament = tournamentsData.find(x => x.tournamentId === currentTournamentId);

		if (foundCurrentTournament !== null && foundCurrentTournament !== undefined && currentTournamentIndex > -1) {
			setSelectedTournamentAccordionItem(currentTournamentIndex);
			getTournamentLeaderboard(foundCurrentTournament.tournamentId, tournamentsData[currentTournamentIndex].startDate);
		}
	}, [currentTournamentId, getTournamentLeaderboard]);

	useEffect(() => {
		setLoading(true);
		TournamentApi.getTournaments()
		             .then(result => {
			             const tournamentModels = result.data;
			             setTournaments(tournamentModels);

			             //displayInProgressTournamentsAccordionItems(tournamentModels);
			             handleCurrentTournamentLoad(tournamentModels);

			             if (query.get('tournament')) {
				             var queryTournamentID = query.get('tournament');
				             var index = tournamentModels.findIndex(tournament => tournament.tournamentId === queryTournamentID);
				             setSelectedTournamentAccordionItem(index);
			             }
			             setLoading(false);
		             })
		             .catch(reason => {
			             setLoading(false);
			             return reason;
		             });
	}, [setLoading, handleCurrentTournamentLoad, query]);

	const handleAccordionItemChange = (expandedIndex: number) => {
		setSelectedTournamentAccordionItem(expandedIndex);

		const tournamentFromAccordion = tournaments[expandedIndex];
		getTournamentLeaderboard(tournamentFromAccordion.tournamentId, tournamentFromAccordion.startDate);
	};

	if (loading) {
		return (
			<IntermediateMessageLoader loadingMessage={'Fetching latest leaderboard'}/>
		);
	}

	const displayLeaderBoardDetails = (index: number) => {
		if (isLeaderboardDetailsLoading) {
			return <Spinner color={'teal'}/>;
		} else {
			return <TournamentLeaderboard
				key={index}
				leaderBoardItems={selectedTournamentLeaderboardResults}
			/>;
		}
	};

	return (
		<Box>
			<Heading
				textAlign={'center'}
				fontSize={'3xl'}
				fontWeight={'bold'}
				color={'navigation.800'}
				py={7}
				colorScheme="accent">
				Leaderboards
			</Heading>

			{tournaments && tournaments.length > 0 ? (
				<>
					<Accordion
						index={selectedTournamentAccordionItem}
						onChange={handleAccordionItemChange}
					>
						{tournaments.map((item, index) => (
							<AccordionItem>
								<h2>
									<AccordionButton _expanded={{ bg: 'primary.700', color: 'white' }}>
										<Box flex="1" textAlign="left">
											{item.tournamentName}
										</Box>
										<AccordionIcon/>
									</AccordionButton>
								</h2>

								<AccordionPanel pb={4}>

									{displayLeaderBoardDetails(index)}

								</AccordionPanel>
							</AccordionItem>
						))}
					</Accordion>
				</>
			) : (
				 <>
					 <Text
						 fontSize={'2em'}
						 color={'navigation.800'}
						 p={5}
						 fontWeight={'semibold'}>
						 No results yet. Be the first on the board!
					 </Text>
				 </>
			 )}
		</Box>
	);
};
