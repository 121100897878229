import {Box, Button, Divider, Grid, GridItem, Text, useBreakpointValue, useToast} from "@chakra-ui/react"
import * as React from "react"
import {useState} from "react"
import {FancyHeading} from "../../shared/FancyHeading"
import {HackathonTeamModelWithVotes} from "../../models/hackathon-team.model";
import {useRecoilState} from "recoil";
import {playerAtom} from "../../atoms/player.atom";
import HackathonTournamentModel from "../../models/hackathon-tournament.model";
import {HackathonApi} from "../../api/hackathon.api";
import HackathonFunVoteModel from "../../models/hackathon-fun-vote.model";
import HackathonFunModel from "../../models/hackathon-fun.model";

interface IVotingComponent {
    isVotingEnabled: boolean;
    tournament: HackathonTournamentModel;
    category: VotingCategory;
    validTeams: HackathonTeamModelWithVotes[] | undefined;
    votes: HackathonFunModel[] | undefined;
    setVotes: React.Dispatch<React.SetStateAction<HackathonFunModel[] | undefined>>;
}

interface VotingCategory {
    id: string;
    title: string;
    description: string;
}

export const VotingComponent: React.FunctionComponent<IVotingComponent> = ({isVotingEnabled, tournament, category, validTeams, votes, setVotes}) => {
    const [player] = useRecoilState(playerAtom);
    const [loading, setLoading] = useState(false);

    const hasVoted = votes!.filter(vote => vote.playerId === player.playerId && vote.votingType === category.id).length > 0;

    const [voted, setVoted] = useState(hasVoted);

    const toast = useToast();

    const textFontSize = 'calc(1em + 1vmin)';
    const descriptionMinHeight = 'calc(3em + 3vmin)';
    const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});

    const submitVote = (e: React.MouseEvent<HTMLButtonElement>) => {
        setLoading(true);
        if (voted === false) {
            const teamName = e.currentTarget.id;
            const team = validTeams?.find(team => team.teamName === teamName);
            if (team) {
                const vote = {
                    hackathonTournamentId: tournament.hackathonTournamentId,
                    hackathonTeamId: team.hackathonTeamId,
                    playerId: player.playerId,
                    votingType: category.id
                } as HackathonFunVoteModel;
                HackathonApi.castVote(vote)
                    .then((_) => {
                        setVoted(true);
                        setVotes([...votes, vote]);
                    })
                    .catch((error) => {
                        let errorToast = "Something went wrong. Please let us know.";
                        if (error.response) {
                            if (error.response.status === 403) {
                                errorToast = "You have already cast your vote.";
                            }
                        }
                        toast({
                            title: errorToast,
                            position: 'top-right',
                            status: 'error'
                        });
                    })
                    .finally(() => setLoading(false));
            }
        }
    }

    return (
        <Box key={category.id} flex={'1 1 45%'}>
            <FancyHeading heading={category.title} headingSize={'4xl'} headingColor={'primary.700'}/>
            <Text
                mt={2}
                color='navigation.500'
                w='100%'
                fontSize={textFontSize}
                minHeight={descriptionMinHeight}
                textAlign={['center', 'center', 'left', 'left']}
            >
                {category.description}
            </Text>
            <Grid
                templateColumns={`repeat(${isMobile ? 1 : 2}, 1fr)`}
                gap={6}
            >
                {validTeams && validTeams.map((team) => {
                    const teamVotes = votes!.filter(vote => vote.hackathonTeamId === team.hackathonTeamId && vote.votingType === category.id).length;
                    return (
                        <GridItem key={team.teamName}>
                            <Button
                                id={team.teamName}
                                w='100%'
                                size='lg'
                                p={2}
                                colorScheme={'primary'}
                                isLoading={loading}
                                onClick={submitVote}
                                disabled={!isVotingEnabled || voted}
                            >
                                {voted ? `${team.teamName} - ${teamVotes} ${teamVotes > 1 ? 'votes' : 'vote'}` : team.teamName}
                            </Button>
                        </GridItem>
                    )
                })}
            </Grid>
            <Divider/>
        </Box>
    );
}