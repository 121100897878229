import * as React from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Grid,
    GridItem, HStack, Spacer,
    Stack, Switch,
    Text
} from "@chakra-ui/react";
import {FancyHeading} from "../../../shared/FancyHeading";
import {IntermediateMessageLoader} from "../../../shared/loading/IntermediateMessageLoader";
import {HackathonLeaderboardGridTable} from "./HackathonLeaderboardGridTable";
import {HackathonLeaderboardModel} from "../../../models/hackathon-leaderboard.model";
import {useParams} from "react-router-dom";
import {HackathonCountdown} from "./HackathonCountdown";
import {useState} from "react";
import {groupQuestions} from "../../../utils/hooks/general-utils";

interface IHackathonPrivateLeaderboard {
    leaderboardLoading: boolean;
    refreshLeaderboard: Function;
    leaderboardQuestions: Array<HackathonLeaderboardModel>;
    openDate: Date;
    closeDate: Date;
}

export const HackathonPrivateLeaderboard: React.FunctionComponent<IHackathonPrivateLeaderboard> = props => {

    // const history = useHistory();
    const {type} = useParams();

    const [cumulativeLeaderboard, setCumulativeLeaderboard] = useState(true);

    const leaderboardQuestionOne = props.leaderboardQuestions.filter(value => value.hackathonSubmission.questionNumber === 1);
    const leaderboardQuestionTwo = props.leaderboardQuestions.filter(value => value.hackathonSubmission.questionNumber === 2);
    const leaderboardQuestionThree = props.leaderboardQuestions.filter(value => value.hackathonSubmission.questionNumber === 3);
    const leaderboardQuestionFour = props.leaderboardQuestions.filter(value => value.hackathonSubmission.questionNumber === 4);
    const leaderboardQuestionFive = props.leaderboardQuestions.filter(value => value.hackathonSubmission.questionNumber === 5);
    const leaderboardCumulative: Array<HackathonLeaderboardModel> = groupQuestions(props.leaderboardQuestions);

    const toggleLeaderboard = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCumulativeLeaderboard(event.target.checked);
    }

    // const goToPublicLeaderboard = () => {
    //     history.push(`/public/leaderboard/hackathon/${type}`);
    // }

    return (
        <Box h={'100%'}>
            <Grid templateColumns={'repeat(12, 1fr)'} h={'100%'}>
                <GridItem colSpan={[1, 1, 1, 3]}/>
                <GridItem colSpan={[10, 10, 10, 6]}>
                    <Box mt={10} h={'100%'}>
                        <FancyHeading heading={'Hackathon Leaderboard'} headingSize={'3xl'} cProps={{mb: 2}} action={
                            <Stack direction={['column', 'column', 'row', 'row']}>
                                {/*<Button*/}
                                {/*    colorScheme={'primary'}*/}
                                {/*    variant={"ghost"}*/}
                                {/*    onClick={() => goToPublicLeaderboard()}*/}
                                {/*>Public Leaderboard</Button>*/}
                                <Button
                                    colorScheme={'primary'}
                                    variant={"ghost"}
                                    isLoading={props.leaderboardLoading}
                                    loadingText={'Refreshing Leaderboard'}
                                    onClick={() => props.refreshLeaderboard()}
                                >Refresh Leaderboard</Button>
                            </Stack>
                        }/>
                        <Text mb={1}>Upload your solutions to see your score climbs, making your way to number
                            1! </Text>
                        <Box mb={2} mt={3}>
                            <Stack direction={['column', 'column', 'row', 'row']}>
                                <HStack alignItems={'center'}>
                                    <Switch isChecked={cumulativeLeaderboard} onChange={toggleLeaderboard}
                                            colorScheme="primary" size={'sm'}/>
                                    <Text as={'span'} color={'gray.500'} fontSize={'sm'}>Cumulative Leaderboard</Text>
                                </HStack>
                                <Spacer/>
                                <HackathonCountdown date={props.closeDate}/>
                            </Stack>
                        </Box>
                        {
                            props.leaderboardLoading ?
                                <IntermediateMessageLoader loadingMessage={'Fetching leaderboard'}/> :
                                cumulativeLeaderboard ?
                                    <Box>
                                        <HackathonLeaderboardGridTable
                                            type={type}
                                            hideOrigin={type === "iitpsa"}
                                            leaderboardItems={leaderboardCumulative}/>
                                    </Box> :
                                    <Box>
                                        <Accordion allowToggle>
                                            {
                                                leaderboardQuestionOne.length !== 0 &&
                                                <AccordionItem>
                                                    <AccordionButton>
                                                        <Box flex="1" textAlign="left">
                                                            <Text color={'gray.700'} fontWeight={'semibold'}
                                                                  fontSize={'xl'}>
                                                                Track 1
                                                            </Text>
                                                        </Box>
                                                        <AccordionIcon/>
                                                    </AccordionButton>
                                                    <AccordionPanel pb={6}>
                                                        <HackathonLeaderboardGridTable
                                                            type={type}
                                                            hideOrigin={type === "iitpsa"}
                                                            leaderboardItems={leaderboardQuestionOne}/>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            }
                                            {
                                                leaderboardQuestionTwo.length !== 0 &&
                                                <AccordionItem>
                                                    <AccordionButton>
                                                        <Box flex="1" textAlign="left">
                                                            <Text color={'gray.700'} fontWeight={'semibold'}
                                                                  fontSize={'xl'}>
                                                                Track 2
                                                            </Text>
                                                        </Box>
                                                        <AccordionIcon/>
                                                    </AccordionButton>
                                                    <AccordionPanel pb={6}>
                                                        <HackathonLeaderboardGridTable
                                                            type={type}
                                                            hideOrigin={type === "iitpsa"}
                                                            leaderboardItems={leaderboardQuestionTwo}/>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            }
                                            {
                                                leaderboardQuestionThree.length !== 0 &&
                                                <AccordionItem>
                                                    <AccordionButton>
                                                        <Box flex="1" textAlign="left">
                                                            <Text color={'gray.700'} fontWeight={'semibold'}
                                                                  fontSize={'xl'}>
                                                                Track 3
                                                            </Text>
                                                        </Box>
                                                        <AccordionIcon/>
                                                    </AccordionButton>
                                                    <AccordionPanel pb={6}>
                                                        <HackathonLeaderboardGridTable
                                                            type={type}
                                                            hideOrigin={type === "iitpsa"}
                                                            leaderboardItems={leaderboardQuestionThree}/>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            }
                                            {
                                                leaderboardQuestionFour.length !== 0 &&
                                                <AccordionItem>
                                                    <AccordionButton>
                                                        <Box flex="1" textAlign="left">
                                                            <Text color={'gray.700'} fontWeight={'semibold'}
                                                                  fontSize={'xl'}>
                                                                Track 4
                                                            </Text>
                                                        </Box>
                                                        <AccordionIcon/>
                                                    </AccordionButton>
                                                    <AccordionPanel pb={6}>
                                                        <HackathonLeaderboardGridTable
                                                            type={type}
                                                            hideOrigin={type === "iitpsa"}
                                                            leaderboardItems={leaderboardQuestionFour}/>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            }
                                            {
                                                leaderboardQuestionFive.length !== 0 &&
                                                <AccordionItem>
                                                    <AccordionButton>
                                                        <Box flex="1" textAlign="left">
                                                            <Text color={'gray.700'} fontWeight={'semibold'}
                                                                  fontSize={'xl'}>
                                                                Track 5
                                                            </Text>
                                                        </Box>
                                                        <AccordionIcon/>
                                                    </AccordionButton>
                                                    <AccordionPanel pb={6}>
                                                        <HackathonLeaderboardGridTable
                                                            type={type}
                                                            hideOrigin={type === "iitpsa"}
                                                            leaderboardItems={leaderboardQuestionFive}/>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            }
                                        </Accordion>
                                    </Box>
                        }
                    </Box>
                </GridItem>
            </Grid>
        </Box>
    )
};
