import {Popover, PopoverTrigger, Button, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, Flex, IconButton, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Text, Box, Img} from "@chakra-ui/react"
import * as React from 'react';
import {useState} from "react"
import {GoTriangleLeft, GoTriangleRight} from "react-icons/go"
import {HackathonTeamModel} from "../../models/hackathon-team.model";
import coin from '../../assets/images/fun/coin.png'
import enteloot from '../../assets/svg/enteloot.svg';
import HackathonFunModel from "../../models/hackathon-fun.model";

interface IBettingPopover {
	isBettingEnabled: boolean;
	team: HackathonTeamModel;
	bets: HackathonFunModel[] | undefined;
	remaining: number;
	bettingType: string;
	placeBet: (hackathonTeamId: string, bettingType: string, bettingValue: number, onClose: () => void, setLoading: React.Dispatch<React.SetStateAction<boolean>> ) => void;
  winner: string;
}

export const BettingPopover: React.FunctionComponent<IBettingPopover> = ({ isBettingEnabled, team, bets, remaining, bettingType, placeBet, winner }) => {
	const alreadyBet = bets?.reduce((part, a) => part + a.bettingValue, 0) || 0;

	const [bettingValue, setBettingValue] = useState(alreadyBet);
  const [loading, setLoading] = useState(false);

	const updateBettingValue = (value: number) => {
		var currentBettingValue = bettingValue;
		var newBettingValue = Math.min(Math.max(0, currentBettingValue + value), bettingValue + remaining);
		setBettingValue(newBettingValue);
	}

	return (
		<Popover placement='top' isOpen={isBettingEnabled ? undefined : false}>
			{({ isOpen, onClose }) => (
				<>
					<PopoverTrigger>
						<Box position='relative'>
							<Button
								w={'100%'}
								size={'lg'}
								p={2}
								colorScheme='primary'
								disabled={!isBettingEnabled}
							>
								{team.teamName}
							</Button>
							{alreadyBet > 0 &&
								<Text
									background={`url(${coin})`}
									backgroundSize={'cover'}
									backgroundRepeat={'no-repeat'}
									backgroundPosition={'center'}
									position='absolute'
									top='0'
									right='0'
									border-radius='50%'
									width={10}
									height={10}
									textAlign='center'
									lineHeight={10}
									fontWeight={'bold'}>
									{alreadyBet}
								</Text>}
              {winner === team.teamName &&
                <>
                  <Text
                    position='absolute'
                    top='-50%'
                    left='0'
                    fontSize={50}
                    >
                    🎉
                  </Text>
                  <Text
                    position='absolute'
                    top='-50%'
                    right='0'
                    fontSize={50}
                    transform='scale(-1, 1)'
                    >
                    🎉
                  </Text>
                </>
              }
						</Box>
					</PopoverTrigger>
					<PopoverContent>
						<PopoverArrow />
						<PopoverCloseButton />
						<PopoverHeader>Place your bets!</PopoverHeader>
						<PopoverBody
              display='flex'
              flexDir='column'
              >
							<Flex justifyContent='center'>                
								<Text
									align='center'
									fontWeight={'bold'}
									fontSize={20}
									color='accent.600'
								>{`${bettingValue}`}</Text>
								<Img alignSelf='flex-start' src={enteloot} boxSize={'5'}/>
							</Flex>
							<Flex alignItems='center'>
								<Text px={1}>0</Text>
								<IconButton id='decrement' aria-label='Increase bet' variant='outline' colorScheme='primary' onClick={() => updateBettingValue(-1)} icon={<GoTriangleLeft />} />
								<Slider size='lg' min={0} max={alreadyBet + remaining} mx={5} value={bettingValue} onChange={(val) => setBettingValue(val)}>
									<SliderTrack>
										<SliderFilledTrack />
									</SliderTrack>
									<SliderThumb />
								</Slider>
								<IconButton aria-label='Increase bet' variant='outline' colorScheme='primary' onClick={() => updateBettingValue(1)} icon={<GoTriangleRight />} />
								<Text px={1}>{alreadyBet + remaining}</Text>
							</Flex>
							<Button
								size='sm'
								colorScheme='accent'
								margin='auto'
                isLoading={loading}
								onClick={() => { 
                  setLoading(true); 
                  placeBet(team.hackathonTeamId, bettingType, bettingValue, onClose, setLoading);
                }}
							>
								Bet
					</Button>
						</PopoverBody>
					</PopoverContent>
				</>
			)}
		</Popover>
	);
}