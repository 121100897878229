const gallaryImages ={
    2012_1:require('./2012_1.jpg').default,
    2012_2:require('./2012_2.jpg').default,
    2012_3:require('./2012_3.jpg').default,
    2012_4:require('./2012_4.jpg').default,
    2012_5:require('./2012_5.jpg').default,
    2012_6:require('./2012_6.jpg').default,
    2012_7:require('./2012_7.jpg').default,
    2012_8:require('./2012_8.jpg').default,
    2012_9:require('./2012_9.jpg').default,
    2012_10:require('./2012_10.jpg').default,
    2012_11:require('./2012_11.jpg').default,
    2012_12:require('./2012_12.jpg').default,
    2012_13:require('./2012_13.jpg').default,
    2012_14:require('./2012_14.jpg').default,
    2012_15:require('./2012_15.jpg').default,
    2012_16:require('./2012_16.jpg').default,
    2012_17:require('./2012_17.jpg').default,
    2012_18:require('./2012_18.jpg').default,
    2012_19:require('./2012_19.jpg').default,
    2012_20:require('./2012_20.jpg').default,
}
export default gallaryImages;