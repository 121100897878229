import {Alert, AlertIcon, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import React, {FunctionComponent, useMemo, useState} from "react";
import {LeaderboardItemModel} from "../../../models/leaderboard-item.model";
import {Pagination} from "../../../shared/Pagination";

interface ITournamentLeaderboard {
    leaderBoardItems: LeaderboardItemModel[]
}

export const TournamentLeaderboard: FunctionComponent<ITournamentLeaderboard> = props => {
    const [currentPage, setCurrentPage] = useState<number>(1);

    const pageSize: number = 10;
    const leaderboardResults = props.leaderBoardItems;

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;

        return leaderboardResults.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, leaderboardResults]);

    // TODO: Update lambda to get the position as part of request
    const getLeaderboardItemResultPosition = (index: number): number => {
        const currentPageToIndexValue = currentPage - 1 < 0 ? 0 : currentPage - 1;
        const bufferPosition = currentPageToIndexValue * pageSize; // Handle pagination
        const indexToPositionValue = ++index; // Indexes start at 0. We need the 1st value to be 1

        return bufferPosition + indexToPositionValue;
    }

    const handleRender = () => {
        if (props.leaderBoardItems.length > 0) {
            return <>
                <TableContainer>
                    <Table variant='simple' colorScheme='teal'>
                        <Thead>
                            <Tr>
                                <Th>Position</Th>
                                <Th>Player Name</Th>
                                <Th>Total Score</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentTableData.map((item, index) => (
                                <Tr key={index}>
                                    <Td>{getLeaderboardItemResultPosition(index)}</Td>
                                    <Td>{item.playerDisplayName}</Td>
                                    <Td>{item.playerTotalMatchPoints}</Td>
                                </Tr>
                            ))}

                        </Tbody>

                    </Table>
                </TableContainer>

                <Pagination items={leaderboardResults} pageSize={pageSize} currentPage={currentPage} onPageChange={(page: number) => setCurrentPage(page)}/>
            </>
        } else {
            return <>
                <Alert status={'warning'} variant="left-accent" mb={5}>
                    <AlertIcon/>
                    No results found for this tournament
                </Alert>
            </>
        }
    }

    return (
        <>
            {
                handleRender()
            }
        </>
    )
}
