import * as React from 'react';
import {
    Box, Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
} from "@chakra-ui/react";
import {Field, FieldAttributes, Form, Formik} from "formik";
import * as Yup from "yup";
import {getCurrentYear} from "../../../utils/hooks/general-utils";
import RegistrationInfoIitpsaModel from '../../../models/registration-info-iitpsa.model';

interface IHackathonRegisterFormIITPSA {
    registrationInfoIitpsaModel: RegistrationInfoIitpsaModel | null;
    register: Function;
    loading: boolean;
}

export const HackathonRegisterFormIITPSA: React.FunctionComponent<IHackathonRegisterFormIITPSA> = props => {

    const initValues = props.registrationInfoIitpsaModel ? props.registrationInfoIitpsaModel : {
        playerId: '', // -- DB attribute
        playerIdNumber: '',
        playerIdType: 'MemberNumber', // -- Only value
        yearEntered: getCurrentYear() // current year
    } as RegistrationInfoIitpsaModel;

    const registrationInfoValidation = Yup.object().shape({
        playerIdNumber: Yup.string()
            .required('Required'),
    });

    return (
        <Box>
            <Formik
                initialValues={initValues}
                onSubmit={(values) => props.register(values)}
                validationSchema={registrationInfoValidation}
            >
                {({ values }) => (
                    <Form>
                        <Field name={'playerIdNumber'}>
                            {({ field, form }: FieldAttributes<any>) => (
                                <Box mb={4}>
                                    <FormControl id="lastName" isRequired isInvalid={form.errors.playerMembershipNumber && form.touched.playerIdNumber}>
                                        <FormLabel>Membership Number</FormLabel>
                                        <Input colorScheme={'primary'} {...field} id={'playerIdNumber'} type="text" size="md" variant="filled" placeholder={'Your IITPSA Membership Number'} />
                                        <FormErrorMessage>{form.errors.playerIdNumber}</FormErrorMessage>
                                    </FormControl>
                                </Box>
                            )}
                        </Field>
                        <Button w={'100%'} type={'submit'} colorScheme={'primary'}
                                isLoading={props.loading} loadingText={'Registering you for the hackathon'}
                                mt={2}>Register</Button>
                    </Form>
                )}

            </Formik>
        </Box>
    )
};
