import {ChakraProvider, ColorModeScript} from "@chakra-ui/react"
import * as React from "react"
import ReactDOM from "react-dom"
import {App} from "./App"
import reportWebVitals from "./reportWebVitals"
import {theme} from "./utils/theme";
import {RecoilRoot} from "recoil";
import {BrowserRouter} from "react-router-dom";
import "@font/nunito/light.css"
import './fonts.css'
import {HelmetProvider} from 'react-helmet-async';

ReactDOM.render(
    <React.StrictMode>
        <ColorModeScript/>
        <RecoilRoot>
            <ChakraProvider theme={theme}>
                <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
                    <HelmetProvider>
                        <App/>
                    </HelmetProvider>
                </BrowserRouter>
            </ChakraProvider>
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
