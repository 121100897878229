import * as Yup from 'yup';
import { RegisterFormModel } from '../../models/register.model';
import { emailRegexPattern, hasEntelectEmailDomain, personNameRegexPattern, validPasswordRegexPattern } from '../../utils/constants';

export const initialValues: RegisterFormModel = {
	email: '',
	firstName: '',
	lastName: '',
	displayName: '',
	password: '',
	passwordConfirmation: ''
};

export const validationSchema = (userType: string) => Yup.object().shape({
	email: Yup.string().required('Email is required')
	          .matches(emailRegexPattern, 'Invalid email address')
	          .test('emp',
		          'Must be an Entelect email address',
			          val => val && userType === 'employee'
			                 ? hasEntelectEmailDomain(val)
			                 : true),
	password: Yup.string()
		.required('Password is required')
		.min(8, 'Password is too short - must be at least 8 characters')
		.matches(validPasswordRegexPattern, 'Password complexity is incorrect. Please make sure you have conformed to all requirements'),
	passwordConfirmation: Yup.string()
		.required('Password confirmation is required')
		.min(8, 'Password confirmation is too short - must be at least 8 characters')
		.oneOf([Yup.ref('password'), null], 'Passwords must match'),
	firstName: Yup.string().required('First name is required').min(2, 'First Name is too short - must be at least 2 characters').matches(personNameRegexPattern, 'Invalid first name'),
	lastName: Yup.string().required('Last name is required').min(2, 'Last Name is too short - must be at least 2 characters').matches(personNameRegexPattern, 'Invalid last name')
});
