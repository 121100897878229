import * as React from 'react';
import {ChangeEvent, useState} from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Flex,
    HStack,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Switch,
    Text,
    useDisclosure
} from "@chakra-ui/react";
import {Link as RouterLink} from 'react-router-dom';
import {useRecoilState} from "recoil";
import {cookieAtom, ICookiePreferences} from "../../atoms/cookie.atom";

interface ICookieBanner {}

export const CookieBanner: React.FunctionComponent<ICookieBanner> = props => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [enabled, setEnabled] = useState(true);

    const [cookiePreferences, setCookiePreferences] = useRecoilState<ICookiePreferences>(cookieAtom);

    const acceptCookies = () => {
        setCookiePreferences({
            hasViewed: true,
            necessary: true,
            nonNecessary: enabled,
        });
    };

    const onEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEnabled(e.target.checked);
    }

    if (cookiePreferences.hasViewed) {
        return (<></>);
    }

    return (
        <Box>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Privacy Overview</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <Text color={'gray.700'} fontSize={'sm'}>
                                This website uses cookies to improve your experience while you navigate through the website. Out of these, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may affect your browsing experience.
                            </Text>
                            <Accordion mt={8} allowToggle>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <AccordionIcon mr={2} />
                                            <Box flex="1" textAlign="left">
                                                Necessary
                                            </Box>
                                            <Box flex={1} textAlign={'right'} fontSize={'sm'} color={'gray.700'}>
                                                Always Enabled
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text fontSize={'sm'} color={'gray.700'}>
                                            Necessary cookies are absolutely essential for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Flex>
                                                <AccordionIcon mr={2} />
                                                <Box flex="1" textAlign="left">
                                                    Non-Necessary
                                                </Box>
                                            </Flex>
                                            <Spacer />
                                            <Box >
                                                <HStack flex="1" align={'flex-end'}>
                                                    <Text color={'gray.700'} fontSize={'sm'}>Enabled</Text>
                                                    <Switch colorScheme={'primary'} isChecked={enabled} onChange={onEnabledChange} id="non-essential" />
                                                </HStack>
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4} >
                                        <Text fontSize={'sm'} color={'gray.700'}>
                                            Any cookies that may not be particularly necessary for the website to function and is used specifically to collect user personal data via analytics, ads, other embedded contents are termed as non-necessary cookies. It is mandatory to procure user consent prior to running these cookies on your website.
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme={'primary'} onClick={() => acceptCookies()} variant="solid">SAVE & ACCEPT</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Box backgroundColor={'navigation.900'} position={'fixed'} zIndex={999} bottom={0} left={0} right={0}>
                <Stack alignItems={'center'} direction={['column', 'column', 'row', 'row']} spacing={2} p={4}>
                    <Text color={'whiteAlpha.900'} fontSize={'sm'}>
                        We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the <Link as={RouterLink} isExternal to={'/wiki/cookie-policy'} textDecoration={'underline'}>cookies</Link>.
                    </Text>
                    <Spacer />
                    <Box>
                        <Button
                            color={'whiteAlpha.900'}
                            variant={'link'}
                            size={'sm'}
                            onClick={onOpen}
                        >Cookie Settings</Button>
                    </Box>
                    <Box>
                        <Button
                            color={'navigation.900'}
                            backgroundColor={'whiteAlpha.900'}
                            variant={'solid'}
                            size={'sm'}
                            borderRadius={'sm'}
                            onClick={() => acceptCookies()}
                        >ACCEPT</Button>
                    </Box>
                </Stack>
            </Box>
        </Box>

    );
};
