import _ from "lodash";
import moment from "moment";
import {EntryType} from "../../models/entry.model";
import {HackathonLeaderboardModel} from "../../models/hackathon-leaderboard.model";

export const getAsBoolean = (value: string | boolean | number | undefined): boolean => {

    if (value === undefined) {
        return false;
    }

    switch (value) {
        case '':
            return false;
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default:
            return false;
    }
}

export const isEventOpen = (openDate: Date | string, closeDate: Date | string): boolean => {
    const now = moment(Date.now());
    const startDate = moment(openDate);
    const endDate = moment(closeDate);

    return now.isBetween(startDate, endDate);

}

export interface ValueForTypeParameters {
    university?: boolean | string | number | Date | JSX.Element,
    company?: boolean | string | number | Date | JSX.Element,
    main?: boolean | string | number | Date | JSX.Element,
    iitpsa?: boolean | string | number | Date | JSX.Element,
}

export const getValueForEntryType = (type: string, { university, company, main, iitpsa }: ValueForTypeParameters): undefined | boolean | string | number | Date | JSX.Element => {
    switch (type) {
        case EntryType.UNIVERSITY :
            return university;
        case EntryType.COMPANY :
            return company;
        case EntryType.MAIN:
            return main;
        case EntryType.IITPSA:
            return iitpsa;
    }

    return '';
}

export const getCurrentYear = (): number => {
    return new Date().getFullYear();
}

export const goToWiki = (wikiLink: string): void => {
    window.open(`${process.env.PUBLIC_URL}/wiki/${wikiLink}`, "_blank");
}

export const groupQuestions = (items: Array<HackathonLeaderboardModel>): Array<HackathonLeaderboardModel> => {
    const groupedTeams = _.groupBy<HackathonLeaderboardModel>(items, (item: HackathonLeaderboardModel) => item.hackathonTeam.teamName);

    let teams: Array<HackathonLeaderboardModel> = [];
    _.forOwn(groupedTeams, (items: Array<HackathonLeaderboardModel>, teamName: string) => {
        const sumScore = items.reduce((a, b) => {
            return { hackathonSubmission: { score: a.hackathonSubmission.score + b.hackathonSubmission.score } } as HackathonLeaderboardModel
        }, { hackathonSubmission: { score: 0 } } as HackathonLeaderboardModel);
        teams.push({
            hackathonTeam: { teamName, occupationName: items[0].hackathonTeam.occupationName },
            hackathonSubmission: { questionNumber: 99, score: sumScore.hackathonSubmission.score }
        } as HackathonLeaderboardModel);
    });

    return teams;
}
