const images2013 ={
    "GJ":require('./GJ.jpg').default,
    "HS":require('./HS.jpg').default,
    "jaco":require('./jaco.jpg').default,
    "jan":require('./jan.jpg').default,
    "lucas":require('./lucas.jpg').default,
    "marius":require('./marius.jpg').default,
    "pierre":require('./pierre.jpg').default,
    "werner":require('./werner.jpg').default,
}
export default images2013;