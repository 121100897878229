import { Box, Button, Checkbox, FormControl, FormErrorMessage, FormLabel, Image, Input, Radio, RadioGroup, Select, Stack } from "@chakra-ui/react";
import { Field, FieldAttributes, Form, Formik } from "formik";
import * as React from "react";
import { useState } from "react";
import { CompanyCupRegistrationModel } from "../../../models/registration-info-main.model";
import { registrationInfoValidation } from "../../../models/validation/registration-info-validation";
import { getCurrentYear } from "../../../utils/hooks/general-utils";
import bug_hunt_company_cup_registration from "../../../assets/images/hackathon/definitely_not_a_bug.png";

interface IHackathonRegisterFormCompany {
    companyCupRegistrationModel: CompanyCupRegistrationModel | null;
    register: Function;
    loading: boolean;
}

export const HackathonRegisterFormCompany: React.FunctionComponent<IHackathonRegisterFormCompany> = props => {

    const defaultIdType = 'ID';
    const defaultIdNumberPlaceholder = `Your ${defaultIdType} number`;

    const [selectedIdType, setSelectedIdType] = useState<string>(defaultIdType);
    const [idNumberPlaceHolder, setIdNumberPlaceHolder] = useState<string>(defaultIdNumberPlaceholder);

    const handleIdTypeChange = (value: string) => {
        setSelectedIdType(value);
        const placeholder = `Your ${value} number`;

        setIdNumberPlaceHolder(placeholder)
    }

    const handleRegistrationSubmission = (values: any) => {
        props.register(values);
    }

    const initValues = props.companyCupRegistrationModel ? props.companyCupRegistrationModel : {
        playerId: '',
        companyRegistrationInfoId: '',
        playerIdType: 'ID',
        playerIdNumber: '',
        contactNumber: '',
        occupation: '',
        experienceLevel: '',
        challengeFoundSource: 'google',
        hadCompetedPreviously: false,
        yearEntered: getCurrentYear(),
    } as CompanyCupRegistrationModel;

    return (
        <Box>
            <Formik
                initialValues={initValues}
                onSubmit={handleRegistrationSubmission}
                isInitialValid={false}
                validationSchema={registrationInfoValidation}
                validateOnBlur={true}
                validateOnChange={true}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <Box mb={4}>
                            <FormControl component={'fieldset'} isRequired>
                                <FormLabel>ID Type</FormLabel>

                                <RadioGroup name={'playerIdType'}
                                            value={values.playerIdType}
                                            onChange={(selectedValue) => {
                                                setFieldValue('playerIdType', selectedValue);
                                                handleIdTypeChange(selectedValue);
                                            }}>

                                    <Stack spacing={5} direction={'row'}>
                                        <Radio colorScheme={'primary'} value={'ID'}>RSA ID</Radio>
                                        <Radio colorScheme={'primary'} value={'Passport'}>Passport</Radio>
                                    </Stack>

                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Field name={'playerIdNumber'}>
                            {({ field, form }: FieldAttributes<any>) => (
                                <Box mb={4}>
                                    <FormControl id="playerIdNumber" isRequired isInvalid={form.errors.playerIdNumber && form.touched.playerIdNumber}>
                                        <FormLabel>
                                            {selectedIdType} Number
                                        </FormLabel>
                                        <Input
                                            colorScheme={'primary'}
                                            {...field}
                                            id={'playerIdNumber'}
                                            type="text"
                                            size="md"
                                            variant="filled"
                                            placeholder={idNumberPlaceHolder}/>
                                        <FormErrorMessage>{form.errors.playerIdNumber}</FormErrorMessage>
                                    </FormControl>
                                </Box>
                            )}
                        </Field>
                        <Field name={'contactNumber'}>
                            {({ field, form }: FieldAttributes<any>) => (
                                <Box mb={4}>
                                    <FormControl id="contactNumber" isRequired isInvalid={form.errors.contactNumber && form.touched.contactNumber}>
                                        <FormLabel>Contact Number</FormLabel>
                                        <Input colorScheme={'primary'} {...field} id={'contactNumber'} type="text" size="md" variant="filled" placeholder={'Your contact number'}/>
                                        <FormErrorMessage>{form.errors.contactNumber}</FormErrorMessage>
                                    </FormControl>
                                </Box>
                            )}
                        </Field>
                        <Field name={'occupation'}>
                            {({ field, form }: FieldAttributes<any>) => (
                                <Box mb={4}>
                                    <FormControl id="occupation" isRequired isInvalid={form.errors.occupation && form.touched.occupation}>
                                        <FormLabel>Occupation</FormLabel>
                                        <Select variant={'filled'} colorScheme={'primary'} {...field} placeholder="Select Occupation" id={'occupation'} value={values.occupation}>
                                            <option value={'software_engineer'}>Software Engineer</option>
                                            <option value={'user_experience_engineer'}>User Experience Engineer</option>
                                            <option value={'business_analyst'}>Business Analyst</option>
                                            <option value={'test_analyst'}>Test analyst</option>
                                            <option value={'data_analyst'}>Data analyst</option>
                                            <option value={'team_project_lead'}>Team/Project lead</option>
                                            <option value={'tech_lead'}>Tech lead</option>
                                            <option value={'recruitment'}>Recruitment</option>
                                            <option value={'administration'}>Administration</option>
                                            <option value={'delivery_manager'}>Delivery manager</option>
                                            <option value={'account_manager'}>Account manager</option>
                                            <option value={'general_manager'}>General manager</option>
                                        </Select>
                                        <FormErrorMessage>{form.errors.occupation}</FormErrorMessage>
                                    </FormControl>
                                </Box>
                            )}
                        </Field>
                        <Field name={'experienceLevel'}>
                            {({ field, form }: FieldAttributes<any>) => (
                                <Box mb={4}>
                                    <FormControl id="occupation" isRequired isInvalid={form.errors.experienceLevel && form.touched.experienceLevel}>
                                        <FormLabel>Experience Level</FormLabel>
                                        <Select variant={'filled'} colorScheme={'primary'} {...field} placeholder="Select Experience Level" id={'experienceLevel'}
                                                value={values.experienceLevel}>
                                            <option value={'graduate'}>Graduate</option>
                                            <option value={'standard'}>Standard</option>
                                            <option value={'intermediate'}>Intermediate</option>
                                            <option value={'senior'}>Senior</option>
                                        </Select>
                                        <FormErrorMessage>{form.errors.experienceLevel}</FormErrorMessage>
                                    </FormControl>
                                </Box>
                            )}
                        </Field>
                        <Field name={'challengeFoundSource'}>
                            {({ field, form }: FieldAttributes<any>) => (
                                <Box mb={4}>
                                    <FormControl id="challengeFoundSource" isRequired isInvalid={form.errors.challengeFoundSource && form.touched.challengeFoundSource}>
                                        <FormLabel>How did you find us? </FormLabel>
                                        <Select variant={'filled'} colorScheme={'primary'} {...field} placeholder="Select origin" id={'challengeFoundSource'}
                                                value={values.challengeFoundSource}>
                                            <option value={'friends'}>Friends</option>
                                            <option value={'google'}>Google</option>
                                            <option value={'google'}>Email</option>
                                            <option value={'other'}>Other</option>
                                        </Select>
                                        <FormErrorMessage>{form.errors.challengeFoundSource}</FormErrorMessage>
                                    </FormControl>
                                </Box>
                            )}
                        </Field>
                        <Field name={'hadCompetedPreviously'}>
                            {({ field }: FieldAttributes<any>) => (
                                <Box mb={4}>
                                    <FormControl id="hadCompetedPreviously">
                                        <Checkbox
                                            colorScheme={'primary'}
                                            {...field}
                                            isChecked={values.hadCompetedPreviously}
                                            name={'hadCompetedPreviously'}
                                            id={'hadCompetedPreviously'}>
                                            <text fontWeight={'medium'}>Did you compete previously?</text>
                                        </Checkbox>
                                    </FormControl>
                                </Box>
                            )}
                        </Field>
                        <Button
                            w={'100%'}
                            type={'submit'}
                            colorScheme={'primary'}
                            isLoading={props.loading}
                            loadingText={'Registering you for the Company Cup'}
                            mt={2}
                        >
                            Register
                        </Button>
                    </Form>
                )}

            </Formik>

            <Image
                src={bug_hunt_company_cup_registration}
                alt="Hackathon register company cup"
                position="absolute"
                bottom="0"
                right="0"
                h={["5em", "5em", "10em", "15em"]}
            />
        </Box>
    )
};
