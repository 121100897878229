import {Box, Button, HStack, Progress, Spacer, Spinner, Stack, Tag, Text, VStack} from "@chakra-ui/react";
import axios from "axios";
import * as React from 'react';
import {useState} from 'react';
import {HackathonApi} from "../../../api/hackathon.api";
import HackathonSubmissionModel, {HackathonSubmissionStatusType} from "../../../models/hackathon-submission.model";

interface IHackathonSolutionQuestion {
    questionNumber: number;
    uploadFunction: Function;
    loading: boolean;
    submission: HackathonSubmissionModel | undefined;
    hackathonType: string;
    questionPrefix: string;
}

const downloadHeaders = {
    'Content-Disposition': 'attachment; filename="grid-file.txt"',
    'Content-Type': 'text/plain; charset=utf-8'
};

export const HackathonSolutionQuestion: React.FunctionComponent<IHackathonSolutionQuestion> = props => {

    const [preparingFileDownload, setPreparingFileDownload] = useState(false);

    const downloadGridFile = () => {
        setPreparingFileDownload(true);
        HackathonApi
            .getDownloadLinkForFile(props.hackathonType, `${props.questionNumber.toString()}.txt`)
            .then((response) => {

                axios.get(response.data, { headers: downloadHeaders, responseType: 'text' as 'json' })
                    .then(downloadFileResult => {
                        const blob = new Blob([downloadFileResult.data], { type: 'text/plain;charset=utf-8' })
                        saveAs(blob, `${props.questionNumber}.txt`);

                        setPreparingFileDownload(false);
                    })
                    .catch(() => {
                        setPreparingFileDownload(false);
                    });
            })
            .catch(() => {
                setPreparingFileDownload(false);
            });
    }

    const getStatusColor = () => {
        if (props.submission) {
            switch (props.submission.submissionStatus) {
                case HackathonSubmissionStatusType.UPLOAD_STARTED:
                    return 'yellow';
                case HackathonSubmissionStatusType.UPLOAD_SUCCESS:
                    return 'yellow';
                case HackathonSubmissionStatusType.SCORING_STARTED:
                    return 'blue';
                case HackathonSubmissionStatusType.SCORING_SUCCESS:
                    return 'green';
                case HackathonSubmissionStatusType.SCORING_FAILED:
                    return 'red';
            }
        } else {
            return 'yellow'
        }
    }

    const getStatusElement = () => {
        if (props.loading) {
            return (
                <Tag colorScheme={'yellow'} variant={'outline'} py={1.5} px={5}>
                    <Spinner size={'sm'}/>
                </Tag>
            );
        }
        if (props.submission) {
            if (props.submission.submissionStatus === HackathonSubmissionStatusType.UPLOAD_STARTED || props.submission.submissionStatus === HackathonSubmissionStatusType.UPLOAD_SUCCESS) {
                return (
                    <Progress size="xs" isIndeterminate w={'100%'} my={1.5} mx={5}/>
                );
            } else if (props.submission.submissionStatus === HackathonSubmissionStatusType.SCORING_STARTED) {
                return (
                    <Spinner color={'blue'} my={1.5} mx={5}/>
                );
            } else {
                return (
                    <Tag py={2} variant={'outline'} colorScheme={getStatusColor()} w={'100%'}>
                        <Text mx={3} fontWeight={'bold'} letterSpacing={1.2} w={'100%'} textAlign={'center'}>
                            {props.submission.score}
                        </Text>
                    </Tag>
                );
            }
        } else {
            return (
                <Tag py={2} variant={'outline'} colorScheme={getStatusColor()} w={'100%'}>
                    <Text mx={3} fontWeight={'bold'} letterSpacing={1.2} w={'100%'} textAlign={'center'}>
                        NO SUBMISSION YET
                    </Text>
                </Tag>
            );
        }

    };

    return (
        <Box w={'100%'}>
            <Text color={'gray.900'} fontSize={'lg'} fontWeight={'bold'}>
                {props.questionPrefix} {props.questionNumber}
            </Text>
            <Stack direction={['column', 'column', 'row', 'row']}>
                <Box mx={5} my={2}>
                    {
                        props.children
                    }
                </Box>
                <Spacer/>
                <VStack spacing={3}>
                    {
                        getStatusElement()
                    }
                    <Box>
                        <HStack spacing={3}>
                            <Button
                                colorScheme={'accent'}
                                onClick={() => props.uploadFunction(props.questionNumber)}>
                                Upload solution
                            </Button>
                            <Button
                                colorScheme={'primary'}
                                isLoading={preparingFileDownload}
                                loadingText={'Preparing'}
                                onClick={() => downloadGridFile()}>
                                Download {props.questionPrefix} {props.questionNumber}
                            </Button>
                        </HStack>
                    </Box>
                </VStack>
            </Stack>
        </Box>
    )
};
