export const finalists2012 = [
    {
        id:1,
        finalist: 'Jaco Cronje',
        image: 'JacoCronje'
    },
    {
        id:2,
        finalist: 'Gustav Mauer',
        image: 'gustavmauer'
    },
    {
        id:3,
        finalist: 'Henri Wiechers',
        image: 'henri'
    },
    {
        id:4,
        finalist: 'Amani Mbara',
        image: 'amani'
    },
    {
        id:5,
        finalist: 'Andrew Tweddle',
        image: 'andrew'
    },
    {
        id:6,
        finalist: 'Keegan Carruthers-Smith',
        image: 'keegan'
    },
    {
        id:7,
        finalist: 'Pieter Bezuidenhout',
        image: 'pieter'
    },
    {
        id:8,
        finalist: 'Marcus Tomlinson',
        image: 'marcus'
    }
]