export const finalists2013 = [
    {
        id:1,
        finalist: 'G-J Van Rooyen',
        image: 'GJ'
    },
    {
        id:2,
        finalist: 'HS Coetzee',
        image: 'HS'
    },
    {
        id:3,
        finalist: 'Pierre Pretorius',
        image: 'pierre'
    },
    {
        id:4,
        finalist: 'Werner Stoop',
        image: 'werner'
    },
    {
        id:5,
        finalist: 'Jan Gutter',
        image: 'jan'
    },
    {
        id:6,
        finalist: 'Jaco Cronje',
        image: 'jaco'
    },
    {
        id:7,
        finalist: 'Marius Potgieter',
        image: 'marius'
    },
    {
        id:8,
        finalist: 'Lucas Dreyer',
        image: 'lucas'
    }
]