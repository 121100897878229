import * as React from 'react';
import {Box, Button, Center, Grid, GridItem, Progress, Spinner, Tag} from "@chakra-ui/react";
import HackathonSubmissionModel, {HackathonSubmissionStatusType} from "../../../models/hackathon-submission.model";
import {Text} from "@chakra-ui/layout";
import moment from "moment";
import {DownloadIcon} from "@chakra-ui/icons";
import {HackathonApi} from "../../../api/hackathon.api";
import {HackathonProfileResponseModel} from "../../../models/hackathon-profile.model";
import {useState} from "react";
import {saveAs} from "file-saver";
import axios from 'axios';

interface IHackathonSolutionSubmission {
    submission: HackathonSubmissionModel;
    hackathonProfile: HackathonProfileResponseModel;
    hackathonType: string;
}

export const HackathonSolutionSubmission: React.FunctionComponent<IHackathonSolutionSubmission> = props => {

    const [logsPreparing, setLogsPreparing] = useState(false);

    const getStatusFriendlyName = () => {
        switch (props.submission.submissionStatus) {
            case HackathonSubmissionStatusType.UPLOAD_STARTED: return 'Submission Uploading';
            case HackathonSubmissionStatusType.UPLOAD_SUCCESS: return 'Submission Uploaded';
            case HackathonSubmissionStatusType.SCORING_STARTED: return 'Submission Scoring Started';
            case HackathonSubmissionStatusType.SCORING_SUCCESS: return 'Submission Scoring Finished';
            case HackathonSubmissionStatusType.SCORING_FAILED: return 'Submission Scoring Failed';
        }
    }

    const getStatusColor = () => {
        switch (props.submission.submissionStatus) {
            case HackathonSubmissionStatusType.UPLOAD_STARTED: return 'yellow';
            case HackathonSubmissionStatusType.UPLOAD_SUCCESS: return 'yellow';
            case HackathonSubmissionStatusType.SCORING_STARTED: return 'blue';
            case HackathonSubmissionStatusType.SCORING_SUCCESS: return 'green';
            case HackathonSubmissionStatusType.SCORING_FAILED: return 'red';
        }
    }

    const getStatusElement = () => {
        if (props.submission.submissionStatus === HackathonSubmissionStatusType.UPLOAD_STARTED || props.submission.submissionStatus === HackathonSubmissionStatusType.UPLOAD_SUCCESS) {
            return (
                <Progress size="xs" isIndeterminate w={'100%'} />
            );
        } else if (props.submission.submissionStatus === HackathonSubmissionStatusType.SCORING_STARTED) {
            return (
                <Center w={'100%'}><Spinner color={'blue'} /></Center>
            );
        } else {
            return (
                <Tag  py={2} variant={'outline'} colorScheme={getStatusColor()} w={'100%'}>
                    <Text mx={5} fontWeight={'bold'} letterSpacing={1.2} w={'100%'} textAlign={'center'}>
                        {props.submission.score === -1 ? 'Processing' : props.submission.score }
                    </Text>
                </Tag>
            );
        }
    };

    const downloadLogs = () => {
        setLogsPreparing(true);
        HackathonApi.getDownloadLinkForLogs(props.hackathonType, props.hackathonProfile.hackathonTeam.hackathonTeamId, props.submission.hackathonSubmissionId)
            .then((response) => {
              axios.get(response.data)
              .then((downloadResult) => {
                const blob = new Blob([downloadResult.data]);
                const fileName = `submission_log_question_${props.submission.questionNumber}.txt`
                setLogsPreparing(false);
                saveAs(blob, fileName)
              });
            })
            .catch(() => {
                setLogsPreparing(false);
                // TODO Make toast about it failing
            });
    }

    return (
        <Box w={'100%'} p={2} m={1}>
            <Grid templateColumns={'repeat(24, 1fr)'} gap={2} alignItems={'center'}>
                <GridItem colSpan={[24,24,4,4]}>
                    <Text fontSize={'lg'} color={'gray.700'} fontWeight={'semibold'} mb={1} textAlign={'center'}>
                        {moment(props.submission.uploadDate).format('YYYY-MM-DD HH:mm')}

                    </Text>
                    <Text fontSize={'md'} color={'gray.600'} mb={1} textAlign={'center'}>
                        {moment(props.submission.uploadDate).fromNow()}
                    </Text>
                </GridItem>
                <GridItem colSpan={[24,24,4,4]}>
                    <Text fontSize={'md'} color={'gray.700'} fontWeight={'semibold'} mb={1} textAlign={'center'}>
                        Level {props.submission.questionNumber}</Text>
                </GridItem>
                <GridItem colSpan={[24,24,9,9]}>
                    <Tag variant={'solid'} colorScheme={getStatusColor()} w={'100%'} py={2}>
                        <Text mx={5} fontWeight={'semibold'} letterSpacing={1.2} w={'100%'} textAlign={'center'}>
                            {getStatusFriendlyName()}
                        </Text>
                    </Tag>
                </GridItem>
                <GridItem colSpan={[24,24,4,4]}>
                    {
                        getStatusElement()
                    }
                </GridItem>
                <GridItem colSpan={[24,24,3,3]}>
                    {
                        (props.submission.submissionStatus === HackathonSubmissionStatusType.SCORING_SUCCESS || props.submission.submissionStatus === HackathonSubmissionStatusType.SCORING_FAILED) &&
                        <Box>
                            <Button onClick={() => downloadLogs()} isLoading={logsPreparing} loadingText={'Preparing'} w={'100%'} size={'md'} rightIcon={<DownloadIcon />} color={getStatusColor()} variant="ghost">
                                Logs
                            </Button>
                        </Box>
                    }
                </GridItem>

            </Grid>
        </Box>
    )
};
