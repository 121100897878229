const images2020 = {
    "JPdaConceicao":require('./JPdaConceicao.jpg').default,
    "GawieJooste":require('./GawieJooste.jpg').default,
    "WillieTheron":require('./WillieTheron.jpg').default,
    "KobusvanSchoor":require('./KobusvanSchoor.jpg').default,
    "RiaanNel":require('./RiaanNel.jpg').default,
    "LouisLotter":require('./LouisLotter.jpg').default,
    "MallinMoolman":require('./MallinMoolman.jpg').default,
    "EdwardSteere":require('./EdwardSteere.jpg').default,
}

export default images2020