import * as Yup from 'yup';
import { ResetPasswordConfirmationFormModel } from "../../models/reset-password-confirmation.model";
import { resetCodeRegexPattern, validPasswordRegexPattern } from "../../utils/constants";

export const initialValues: ResetPasswordConfirmationFormModel = {
    resetCode: '',
    password: '',
    passwordConfirmation: ''
};

export const validationSchema = () => Yup.object().shape({
    resetCode: Yup.string().required('Reset code is required')
        .length(6, 'Reset code is 6 digits')
        .matches(resetCodeRegexPattern, 'Invalid reset code'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password is too short - must be at least 8 characters')
        .matches(validPasswordRegexPattern, 'Password complexity is incorrect. Please make sure you have conformed to all requirements'),
    passwordConfirmation: Yup.string()
        .required('Password confirmation is required')
        .min(8, 'Password confirmation is too short - must be at least 8 characters')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
});
