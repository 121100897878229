const images2018 ={
    "1_Louis_Lotter":require('./1_Louis_Lotter.jpg').default,
    "2_Edward_Steere":require('./2_Edward_Steere.jpg').default,
    "3_Justin_Worthe":require('./3_Justin_Worthe.jpg').default,
    "4_Andre_Nel":require('./4_Andre_Nel.jpg').default,
    "5_Leon_Wessels":require('./5_Leon_Wessels.jpg').default,
    "6_Riaan_Nel":require('./6_Riaan_Nel.jpg').default,
    "7_Ralf_Kistner":require('./7_Ralf_Kistner.jpg').default,
    "8_Willie_Theron":require('./8_Willie_Theron.jpg').default,
}
export default images2018;