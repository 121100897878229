import gallery from '../gallery/gallaryIndex';

export const photos2012 = [
    {
      src: gallery[20121],
      width: 4,
      height: 3
    },
    {
      src: gallery[20122],
      width: 1,
      height: 1
    },
    {
      src: gallery[20123],
      width: 4,
      height: 3
    },
    {
      src: gallery[20124],
      width: 4,
      height: 3
    },
    {
      src: gallery[20125],
      width: 4,
      height: 3
    },
    {
      src: gallery[20126],
      width: 4,
      height: 3
    },
    {
      src: gallery[20127],
      width: 4,
      height: 3
    },
    {
      src: gallery[20128],
      width: 4,
      height: 3
    },
    {
      src: gallery[20129],
      width: 4,
      height: 3
    },
    {
      src: gallery[201210],
      width: 4,
      height: 3
    },
    {
      src: gallery[201211],
      width: 4,
      height: 3
    },
    {
      src: gallery[201212],
      width: 4,
      height: 3
    },
    {
      src: gallery[201213],
      width: 4,
      height: 3
    },
    {
      src: gallery[201214],
      width: 4,
      height: 3
    },
    {
      src: gallery[201215],
      width: 4,
      height: 3
    },
    {
      src: gallery[201216],
      width: 4,
      height: 3
    },
    {
      src: gallery[201217],
      width: 4,
      height: 3
    },
    {
      src: gallery[201218],
      width: 4,
      height: 3
    },
    {
      src: gallery[201219],
      width: 4,
      height: 3
    },
    {
      src: gallery[201220],
      width: 4,
      height: 3
    }
  ];