export const emailRegex = new RegExp(`(?:[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])`);
export const southAfricanIdNumberPattern = new RegExp(`(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))`);
export const contactNumber = new RegExp(`^\\+?(9[976]|8[987530]|6[987]|5[90]|42|3[875]|2[98654321]|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)[1-9]\\d{1,14}$|^0\\d{1,14}$`);

export const passwordUpperCaseCharacter = new RegExp(`(?=.*?[A-Z])`);
export const passwordLoweCaseCharacter = new RegExp(`(?=.*?[a-z])`);
export const passwordSpecialCaseCharacter = new RegExp(`(?=.*?[\`!@#$%^&*()_+\\-=\\]{};':"\\\\|,.<>?~])`);
export const passwordNumberCharacter = new RegExp(`(?=.*?[0-9])`);

export const emailRegexPattern = new RegExp(
    `(?:[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])`
);

export const validPasswordRegexPattern = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^a-zA-Z0-9]).{8,}$`);
export const personNameRegexPattern = new RegExp(`^[a-zA-Z]+(?:[-' ][a-zA-Z]+)*$`);

export const numericalRegexPattern = new RegExp('^[0-9]+$');
export const resetCodeRegexPattern = new RegExp(`^[0-9]{6}$`);

export type CSSPosition = "-webkit-sticky" | "absolute" | "fixed" | "relative" | "static" | "sticky";

export function hasEntelectEmailDomain(valueToCheck: string): boolean {
    return valueToCheck.endsWith('@entelect.co.za')
        || valueToCheck.endsWith("@entelect.co.nz")
        || valueToCheck.endsWith('@entelect.nl')
        || valueToCheck.endsWith("@entelect.co.uk")
        || valueToCheck.endsWith("@entelect.com.au");
}
