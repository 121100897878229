import { Box, Grid, GridItem, useBreakpointValue, VStack } from '@chakra-ui/react';
import * as React from 'react';
import entelectChallengeSideImage from '../assets/images/shared/side_bar_background.png';
import sideBarCharacter from '../assets/images/shared/side_bar_character.png';
import { useScaleRationBoolean } from '../utils/hooks/use-scale-ratio';

interface ISideBar {
}

interface backgroundSvgSizes {
    position: string;
    size: string;
    hidden: boolean;
}

export const SideBarComponent: React.FunctionComponent<ISideBar> = props => {

    const isZoomed = useScaleRationBoolean([false, true]);

    const svgSize: backgroundSvgSizes | undefined = useBreakpointValue<backgroundSvgSizes>({
        base: {position: 'center', size: '0', hidden: true},
        sm: {position: 'center', size: '0', hidden: true},
        md: {position: 'right 0px bottom 0px', size: '300', hidden: true},
        lg: {position: 'right -10px bottom 0px', size: '240', hidden: false}
    });

    if (svgSize?.hidden) {
        return (
            <Box
                h={'100%'}
                backgroundImage={`url(${sideBarCharacter})`}
                backgroundRepeat={'no-repeat'}
                backgroundPosition={svgSize?.position}
                backgroundSize={`${svgSize?.size}px ${svgSize?.size}px`}
            >
                <Grid templateColumns={'repeat(24, 1fr)'} h={'100%'}>
                    <GridItem colSpan={isZoomed ? [2, 2, 5, 8] : [2, 2, 5, 6]}/>
                    <GridItem colSpan={isZoomed ? [20, 20, 14, 8] : [20, 20, 14, 6]}>
                        {
                            props.children
                        }
                    </GridItem>
                </Grid>
            </Box>
        );
    }

    return (
        <Box
            h={'100%'}
            backgroundImage={`url(${sideBarCharacter})`}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={svgSize?.position}
            backgroundSize={`${svgSize?.size}px ${svgSize?.size}px`}
        >
            <Grid templateColumns={'repeat(24, 1fr)'} h={'100%'}>
                <GridItem colSpan={isZoomed ? 6 : [0, 0, 0, 6]} h={'100%'}>
                    <VStack h={'100%'} backgroundColor={'primary.300'}>
                        <Box
                            w={'100%'}
                            h={'100%'}
                            backgroundSize={'100% 100%'}
                            backgroundPosition={'center'}
                            backgroundRepeat={'no-repeat'}
                            backgroundImage={`url(${entelectChallengeSideImage})`}
                            boxShadow={'0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'}
                        />
                    </VStack>
                </GridItem>
                <GridItem colSpan={isZoomed ? 5 : [2, 2, 5, 6]}/>
                <GridItem colSpan={isZoomed ? 8 : [20, 20, 14, 6]}>
                    {
                        props.children
                    }
                </GridItem>
            </Grid>
        </Box>
    );
};
