export default interface NotificationModel {
    notificationId: string;
    creationDate: Date;
    notificationName: string;
    notificationDescription: string;
    severityType: NotificationSeverityType;
    priority: NotificationPriorityType;
}

export enum NotificationPriorityType {
    LOW = "low",
    MEDIUM = "medium",
    HIGH = "high",
}

export enum NotificationSeverityType {
    INFO = "info",
    WARNING = "warning",
    DANGER = "danger"
}