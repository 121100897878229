import {Box, Text, Square, Flex, useBreakpointValue, Alert, AlertIcon, AlertTitle, AlertDescription} from "@chakra-ui/react";
import * as React from 'react';
import {useState, useEffect} from 'react';
import background from '../../assets/images/fun/fun_background.png';
import {useHistory} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {playerIsEntelectEmployee} from '../../atoms/player.atom';
import {SETTING_NAMES_HACKATHON, getSetting} from "../../atoms/settings.atom";
import {Betting} from "./Betting";
import {Voting} from "./Voting";
import moment from 'moment';
import {hackathonTournamentAtom} from "../../atoms/hackathon-tournament.atom";

interface IHackathonFun {
}

export const HackathonFun: React.FunctionComponent<IHackathonFun> = _ => {
	const history = useHistory();
	const isEmployee = useRecoilValue(playerIsEntelectEmployee);
	if (!isEmployee) {
		history.push('/portal');
	}

	const tournament = useRecoilValue(hackathonTournamentAtom);

	const [isVotingEnabled, setIsVotingEnabled] = useState(false);
	const [isBettingEnabled, setIsBettingEnabled] = useState(false);

	const startingCoins = Number(useRecoilValue(getSetting(SETTING_NAMES_HACKATHON.VOTING_PAGE_STARTING_VALUE)).value);

	let textColumnMaxWidth = '96em';
	let titleFontSize = 45;
	let minViewH = '50vh';
	let otherTitleFontColor = 'accent.600';
	let mainFontColor = 'navigation.700';
	let otherTextFontSize = 'calc(1.2em + 1vmin)';
	const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});

	useEffect(() => {
		const tourneyDate = moment(tournament!.hackathonDate);
    if (tourneyDate.isSame(moment(), 'day')) {
      setIsBettingEnabled(true);
   }
    if (moment().isSameOrAfter(tourneyDate.add(1, 'days'), 'day')) {
      setIsVotingEnabled(true);
   }
	}, [tournament])

	const squareMargins = isMobile ? 1 : 10;

	return (
		<Box
			background={`url(${background})`}
			backgroundSize={'cover'}
            backgroundAttachment={'fixed'}
            backgroundRepeat={'no-repeat'}
			backgroundPosition={'center'}
            as="main">
			<Flex w={'100%'} flexDirection={'column'}>
				<Square p={5} shadow="md" borderWidth="0.1em"
					background={'whiteAlpha.900'}
					borderRadius={'0.5em'}
					m={squareMargins}
					width={'100%'}
					maxWidth={textColumnMaxWidth}
					minH={minViewH}
					alignSelf={'center'}
					flexDirection={'column'}>

					<Text
						fontWeight={'bold'}
						fontSize={titleFontSize}
						color={otherTitleFontColor}
						align={'center'}
						pb={5}
						width={'80%'}
						maxWidth={textColumnMaxWidth}>
						Fun Stuff
					</Text>

					<Text
						fontSize={otherTextFontSize}
						color={mainFontColor}
						py={5}
						width={'100%'}
						maxWidth={textColumnMaxWidth}
						align={'center'}>
            Get ready to have a blast at the Entelect Challenge Company Cup!
            It's all about fun and excitement as we cheer on the teams! Want them to win those fabulous prizes?
            Well, they gotta play to win! So, for the team to be eligible for a spot prize, they need to be on the leaderboard by the end of the event.
            <br/>
            A littel hint, you say? Any valid submission will get you on the leaderboard, not matter the resulting score.
            <br/><br/>
            Ready to cast your votes? Just hold those horses for a second! The voting opens the day after the event so that every team has a fair shot at climbing up the leaderboard.
            <br/><br/>
            Oh, and speaking of more fun, let's talk betting! You've got a whopping <b>{startingCoins} virtual Enteloots</b> to play around with for today's event. Let the games begin! 🎉
					</Text>

				</Square>
				{tournament && moment().isSameOrAfter(moment(tournament.hackathonDate), 'day')
					?
					<>
						<Voting isVotingEnabled={isVotingEnabled} tournament={tournament!} />
						<Betting isBettingEnabled={isBettingEnabled} tournament={tournament!} />
					</>
					:
					<Box m={squareMargins}>
						<Alert status="warning">
								<AlertIcon/>
								<Box flexGrow={1}>
										<AlertTitle>This page is not open yet!</AlertTitle>
										<AlertDescription display="block">
												The fun will start on <b>{moment(tournament?.hackathonDate).format('dddd, MMMM Do')}</b>
										</AlertDescription>
								</Box>
						</Alert>
					</Box>
				}
			</Flex>
		</Box>
	);
}
