const images2015 ={
    "BernhardH":require('./BernhardH.jpg').default,
    "EricL":require('./EricL.jpg').default,
    "JackW":require('./JackW.jpg').default,
    "JacoVN":require('./JacoVN.jpg').default,
    "LeonardS":require('./LeonardS.jpg').default,
    "MatthewM":require('./MatthewM.jpg').default,
    "MikeY":require('./MikeY.jpg').default,
    "PierreP":require('./PierreP.jpg').default,
}
export default images2015;