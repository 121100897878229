import {ArrowForwardIcon, EditIcon, InfoOutlineIcon, NotAllowedIcon} from "@chakra-ui/icons";
import { Box, Button, Center, Flex, Image, SimpleGrid, Spacer, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import moment from "moment";
import * as React from "react";
import {useHistory} from "react-router-dom";
import {useRecoilValue} from "recoil";
import logo from "../../assets/images/entelect/EntelectChallenge_Logo_BW.svg";
import companyCupLogo from "../../assets/images/hackathon/Entelect_Company_Cup.svg";
import iitpsaLogo from "../../assets/images/hackathon/iitpsa_logo.png";
import universityCupLogo from "../../assets/images/hackathon/Entelect_Challenge_University_Cup.svg";
import bug_hunt_home from "../../assets/images/hackathon/hobbit_hole_in_code.png";

import {playerHaveEntry, playerIsEntelectEmployee} from "../../atoms/player.atom";
import {getSetting, getSettingAsBoolean, getSettingAsDate, getSettingAsHackathonData, SETTING_NAMES_ENTELECT_CHALLENGE, SETTING_NAMES_HACKATHON} from "../../atoms/settings.atom";
import {EntryType} from "../../models/entry.model";
import HackathonTournamentModel from "../../models/hackathon-tournament.model";
import {FancyHeading} from "../../shared/FancyHeading";
import {getCurrentYear, goToWiki} from "../../utils/hooks/general-utils";
import {useScaleRatio} from "../../utils/hooks/use-scale-ratio";
import {CountDown} from "./CountDown";
import {LogoHackathonHomeCard} from "./HackathonHomeCard";
import background from "../../assets/images/main/main_background.png";

interface IHome {
}

export const Home: React.FunctionComponent<IHome> = (props) => {
    const isEmployee = useRecoilValue(playerIsEntelectEmployee);

    const history = useHistory();

    const haveMainEntry = useRecoilValue(playerHaveEntry(EntryType.MAIN));
    const haveUniversityEntry = useRecoilValue(playerHaveEntry(EntryType.UNIVERSITY));

    const haveIITPSAEntry = useRecoilValue(playerHaveEntry(EntryType.IITPSA));
    const haveCompanyEntry = useRecoilValue(playerHaveEntry(EntryType.COMPANY));
    const enableIITPSA = useRecoilValue(getSettingAsBoolean(SETTING_NAMES_ENTELECT_CHALLENGE.IITPSA_ENABLE_SUBMISSION));

    const enabledEntelectChallenge = useRecoilValue(getSettingAsBoolean(SETTING_NAMES_ENTELECT_CHALLENGE.ENABLED));

    const hackathonCompanyTournament: HackathonTournamentModel = useRecoilValue(getSettingAsHackathonData(SETTING_NAMES_HACKATHON.COMPANY_TOURNAMENT_DATA));
    const hackathonUniversityTournament: HackathonTournamentModel = useRecoilValue(
        getSettingAsHackathonData(SETTING_NAMES_HACKATHON.UNIVERSITY_TOURNAMENT_DATA)
    );
    const hackathonIITPSATournament: HackathonTournamentModel = useRecoilValue(getSettingAsHackathonData(SETTING_NAMES_HACKATHON.IITPSA_TOURNAMENT_DATA));

    const entelectChallengeTournamentDate = useRecoilValue(getSettingAsDate(SETTING_NAMES_ENTELECT_CHALLENGE.OFFICIAL_TOURNAMENT_START_DATE));
    const entelectChallengeTournamentName = useRecoilValue(getSetting(SETTING_NAMES_ENTELECT_CHALLENGE.OFFICIAL_TOURNAMENT_NAME)).value;

    const columns = useBreakpointValue({ base: 1, sm: 1, md: 1, lg: 2, xl: 3 });
    const spacing = useBreakpointValue({ base: 4, sm: 4, md: 6, lg: 8, xl: 10 });

    // const swapPasswordRequirements = useBreakpointValue<StarmanSizes>({base: true, sm: true, md: true, lg: false});

    const goToEntelectChallenge = () => {
        if (haveMainEntry) {
            history.push(`/portal/entelect-challenge`);
        } else {
            history.push(`/portal/entelect-challenge/register`);
        }
    };

    const goToIITPSA = (hasEntry: boolean, entryType: string) => {
        if (hasEntry) {
            if (enableIITPSA) {
                history.push(`/portal/hackathon/${entryType}/solutions`);
            } else {
                history.push(`/portal/hackathon/${entryType}/team`);
            }
        } else {
            history.push(`/portal/hackathon/${entryType}/register`);
        }
    };
    const goToHackathon = (hasEntry: boolean, entryType: string) => {
        if (hasEntry) {
            history.push(`/portal/hackathon/${entryType}/solutions`);
        } else {
            history.push(`/portal/hackathon/${entryType}/register`);
        }
    };

    const getEntelectChallengeButton = () => {
        if (isEmployee) {
            return (
                <Button
                    px={20}
                    disabled={true}
                    colorScheme={"primary"}
                    leftIcon={<NotAllowedIcon/>}>
                    ACCESS DENIED
                </Button>
            );
        }

        if (enabledEntelectChallenge) {
            return (
                <Button
                    px={20}
                    colorScheme={"navigation"}
                    leftIcon={haveMainEntry ? <ArrowForwardIcon/> : <EditIcon/>}
                    onClick={() => goToEntelectChallenge()}>
                    {haveMainEntry ? "ENTER" : "REGISTER"}
                </Button>
            );
        } else {
            return (
                <Button
                    px={20}
                    disabled={true}
                    colorScheme={"primary"}
                    leftIcon={<NotAllowedIcon/>}>
                    CLOSED
                </Button>
            );
        }
    };
    let columnMinWidth = "15em";

    function shouldPassDate(hackathonDate: Date | null | undefined) {
        return hackathonDate && moment(hackathonDate)
            .toDate()
            .getFullYear() >= getCurrentYear();
    }

    return (
        <>
            <Box
                as="main"
                background={`url(${background})`}
                backgroundSize={'cover'}
                backgroundRepeat={'no-repeat'}
                minH={"100%"}
                backgroundPosition={"center"}>
                <Flex flexDirection={"column"} h={"100%"}>

                    <Box mt={[10, 10]}>
                        <Center>
                            <Image
                                alignSelf={"center"}
                                width={"30%"}
                                minWidth={columnMinWidth}
                                objectFit="cover"
                                src={logo}
                                alt="Entelect challenge logo"
                            />
                        </Center>
                    </Box>
                    <VStack spacing={8}>
                        <Box>
                            <Center>
                                <Text
                                    color={"whiteAlpha.900"}
                                    css={{"-webkit-text-stroke": '1.5px #125045'}}
                                    fontFamily={"Momcake-bold"} fontWeight={"bold"}
                                    fontSize={"calc(1em + 3vmin)"}>{moment(entelectChallengeTournamentDate)
                                    .format("YYYY.MM.DD")}</Text>
                            </Center>
                            <Center>
                                <Text
                                    color={"whiteAlpha.900"}
                                    css={{"-webkit-text-stroke": '1.5px #125045'}}
                                    fontFamily={"Momcake-bold"} fontWeight={"bold"} fontSize={"calc(1em + 3vmin)"}
                                    letterSpacing={1.2} textAlign={"center"}>
                                    SPROUTOPIA - R200K PRIZE POOL
                                </Text>
                            </Center>
                        </Box>
                        <Box>
                            <Center>
                                <Box
                                    background={"navigation.700"}
                                    borderRadius={"md"}
                                    px={5}
                                    py={2}
                                    mb={5}>
                                    <Text
                                        color={"whiteAlpha.900"} fontSize={"calc(1em + 1vmin)"}
                                        fontWeight={"semibold"}
                                        letterSpacing={1.2}>
                                        {entelectChallengeTournamentName}
                                    </Text>
                                </Box>
                            </Center>
                            <Center>
                                <CountDown
                                    date={moment(entelectChallengeTournamentDate)
                                        .toDate()}
                                    alternativeProps={{color: "whiteAlpha.900" }}/>
                            </Center>
                        </Box>
                        <Stack direction={["column", "column", "row", "row"]} spacing={4}>
                            {
                                getEntelectChallengeButton()
                            }
                            <Button
                                px={20}
                                colorScheme={"primary"}
                                onClick={() => history.push("/game")}
                                leftIcon={<InfoOutlineIcon/>}>
                                RULES
                            </Button>
                        </Stack>
                    </VStack>
                    <Spacer/>
                    <Box m={useScaleRatio([7, 5])}>
                        <Stack direction={"row"}>
                            <Box mb={useScaleRatio([7, 2])}>
                                <FancyHeading
                                    heading={"Hackathons"}
                                    headingSize={"4xl"}
                                    headingColor={"navigation.800"}
                                    cProps={{
                                        background: '#ffffff40', borderRadius: '5px', padding: '10px'
                                    }}/>
                            </Box>

                            <Image
                                h={"2em"}
                                objectFit={"contain"}
                                pos="relative"
                                fit={"contain"}
                                src={bug_hunt_home}
                            />
                        </Stack>

                        {/* on small screens (1st value) & medium screens (2nd value), there will be one column, on large screens (3rd value) & extra-large screens (4th value) there will be two columns */}
                        <SimpleGrid columns={columns} spacing={spacing}>
                            <LogoHackathonHomeCard
                                name={"IITPSA Cup"}
                                mode={"Single v. Single"}
                                date={shouldPassDate(hackathonIITPSATournament.hackathonDate) ? moment(hackathonIITPSATournament.hackathonDate)
                                    .toDate() : undefined}
                                maxCount={500}
                                type={EntryType.IITPSA}
                                onRegister={() => goToIITPSA(haveIITPSAEntry, EntryType.IITPSA)}
                                onRules={() => goToWiki("hackathon-rules")}
                                isAuthorized={!isEmployee}
                                haveEntry={haveIITPSAEntry}
                                isOpen={hackathonIITPSATournament.enabled}
                                logo={iitpsaLogo}
                            />
                            {
                                !isEmployee &&
                                <LogoHackathonHomeCard
                                    name={"University Cup"}
                                    mode={"Team v. Team"}
                                    date={shouldPassDate(hackathonUniversityTournament.hackathonDate) ? moment(hackathonUniversityTournament.hackathonDate)
                                        .toDate() : undefined}
                                    maxCount={500}
                                    type={EntryType.UNIVERSITY}
                                    onRegister={() => goToHackathon(haveUniversityEntry, EntryType.UNIVERSITY)}
                                    onRules={() => goToWiki("hackathon-rules")}
                                    isAuthorized={!isEmployee}
                                    haveEntry={haveUniversityEntry}
                                    isOpen={hackathonUniversityTournament.enabled}
                                    logo={universityCupLogo}
                                />
                            }
                            {
                                isEmployee &&
                                <LogoHackathonHomeCard
                                    name={"Company Cup"}
                                    mode={"Team v. Team"}
                                    date={shouldPassDate(hackathonCompanyTournament.hackathonDate) ? moment(hackathonCompanyTournament.hackathonDate)
                                        .toDate() : undefined}
                                    maxCount={60}
                                    type={EntryType.COMPANY}
                                    onRegister={() => goToHackathon(haveCompanyEntry, EntryType.COMPANY)}
                                    onRules={() => goToWiki("hackathon-rules")}
                                    isAuthorized={isEmployee}
                                    haveEntry={haveCompanyEntry}
                                    isOpen={hackathonCompanyTournament.enabled}
                                    logo={companyCupLogo}
                                />
                            }
                        </SimpleGrid>
                    </Box>
                </Flex>
            </Box>
        </>
    );
};
