import {EntryType} from "./entry.model";

export default interface HackathonSubmissionModel {
    hackathonSubmissionId: string;
    teamId: string;
    tournamentId: string;
    uploadDate: Date;
    score: number;
    scoreReason: string;
    questionNumber: number;
    submissionStatus: HackathonSubmissionStatusType;
    entryType: EntryType;
}

export enum HackathonSubmissionStatusType {
    UPLOAD_STARTED = 'upload_started',
    UPLOAD_SUCCESS = 'upload_success',

    SCORING_STARTED = 'scoring_started',
    SCORING_SUCCESS = 'scoring_success',
    SCORING_FAILED = 'scoring_failed',

    INTERNAL_EXCEPTION = 'internal_exception',
    EMPTY = '',
}