const gallaryImages ={
    2016_1:require('./2016_1.jpg').default,
    2016_2:require('./2016_2.jpg').default,
    2016_3:require('./2016_3.jpg').default,
    2016_4:require('./2016_4.jpg').default,
    2016_5:require('./2016_5.jpg').default,
    2016_6:require('./2016_6.jpg').default,
    2016_7:require('./2016_7.jpg').default,
    2016_8:require('./2016_8.jpg').default,
    2016_9:require('./2016_9.jpg').default,
    2016_10:require('./2016_10.jpg').default,
    2016_11:require('./2016_11.jpg').default,
    2016_12:require('./2016_12.jpg').default,
    2016_13:require('./2016_13.jpg').default,
    2016_14:require('./2016_14.jpg').default,
    2016_15:require('./2016_15.jpg').default,
    2016_16:require('./2016_16.jpg').default,
    2016_17:require('./2016_17.jpg').default,
    2016_18:require('./2016_18.jpg').default,
	2016_19:require('./2016_19.jpg').default,
    2016_20:require('./2016_20.jpg').default,
    2016_21:require('./2016_21.jpg').default,
    2016_22:require('./2016_22.jpg').default,
    2016_23:require('./2016_23.jpg').default,
    2016_24:require('./2016_24.jpg').default,
    2016_25:require('./2016_25.jpg').default,
    2016_26:require('./2016_26.jpg').default,
    2016_27:require('./2016_27.jpg').default,
	2016_28:require('./2016_28.jpg').default,
    2016_29:require('./2016_29.jpg').default,
    2016_30:require('./2016_30.jpg').default,
    2016_31:require('./2016_31.jpg').default,
	2016_32:require('./2016_32.jpg').default,
}
export default gallaryImages;