import {
    Box,
    Button,
    Grid,
    GridItem,
    StackDivider,
    VStack
} from '@chakra-ui/react';
import React, {useCallback, useEffect, useState} from 'react'
import {FancyHeading} from '../../../shared/FancyHeading';
import {HackathonTeamPlayerItem} from './HackathonTeamPlayerItem';
import {HackathonApi} from '../../../api/hackathon.api';
import ErrorModel from '../../../models/error.model';
import {AxiosError} from 'axios';
import {HackathonProfileResponseModel} from '../../../models/hackathon-profile.model';
import {useRecoilValue} from 'recoil';
import {IntermediateMessageLoader} from "../../../shared/loading/IntermediateMessageLoader";
import {hackathonProfileAtom} from "../../../atoms/hackathon-profile.atom";
import {useParams} from "react-router-dom";
import {HackathonTeamMemberDtoModel} from "../../../models/hackathon-team-member.model";
import {HackathonProfileStatusEnum} from "../../../models/hackathon-profile-status.enum";
import {useScaleRationBoolean} from "../../../utils/hooks/use-scale-ratio";
import {HackathonTeamHeader} from "./HackathonTeamHeader";
import { EntryType } from '../../../models/entry.model';

interface IHackathonTeam {
}

export const HackathonTeam: React.FunctionComponent<IHackathonTeam> = props => {

    const {type} = useParams();
    const hackathonProfile = useRecoilValue<HackathonProfileResponseModel>(hackathonProfileAtom);

    const isZoomed = useScaleRationBoolean([false, true]);

    const [refreshMemberLoading, setRefreshMemberLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const [teamMembers, setTeamMembers] = useState<Array<HackathonTeamMemberDtoModel>>([]);
    const [pendingMembers, setPendingMembers] = useState<Array<HackathonTeamMemberDtoModel>>([]);


    const [memberLoading, setMemberLoading] = useState('');

    const loadTeamMembers = useCallback(() => {
        setRefreshMemberLoading(true);
        HackathonApi.getTeamMembers(type)
            .then((result) => {
                setRefreshMemberLoading(false);
                setLoadingText('');
                setTeamMembers(result.data.filter(member => member.hackathonTeamMember.joinStatus === 'accepted'));
                setPendingMembers(result.data.filter(member => member.hackathonTeamMember.joinStatus === 'pending'));
            })
            .catch((error: AxiosError<ErrorModel>) => {
                setLoadingText('');
                // if (error.response?.data.code.includes('TeamMemberNotFoundException')) setplayerState(false)
                // else if (error.response?.data.code.includes('PendingTeamMemberAccessException')) setplayerState(false)
                //
                // console.log(error.response?.data.code + ' - ' + error.response?.data.message);
            });
    }, [type, setTeamMembers]);

    useEffect(() => {
        if (type === EntryType.IITPSA){
            setLoadingText('Fetching your IITPSA data...');
        }else{
            setLoadingText('Fetching team members');
        }
        loadTeamMembers();
    }, [loadTeamMembers, type]);

    const managePlayer = (teamMemberId: string, hackathonProfileStatus: HackathonProfileStatusEnum) => {
        setMemberLoading(teamMemberId);
        HackathonApi.manageTeamMember(type, {hackathonTeamMemberId: teamMemberId, joinStatus: hackathonProfileStatus }).then(() => {
            setMemberLoading('');
            if (hackathonProfileStatus === HackathonProfileStatusEnum.ACCEPTED) {
                const member: HackathonTeamMemberDtoModel | undefined = pendingMembers.find(mem => mem.hackathonTeamMember.hackathonTeamMemberId === teamMemberId);
                if (member) {
                    setPendingMembers(pendingMembers.filter(mem => mem.hackathonTeamMember.hackathonTeamMemberId !== teamMemberId));
                    member.hackathonTeamMember.joinStatus = HackathonProfileStatusEnum.ACCEPTED;
                    setTeamMembers(prevState => [...prevState, member]);
                }
            } else if (hackathonProfileStatus === HackathonProfileStatusEnum.REJECTED) {
                setPendingMembers(pendingMembers.filter(mem => mem.hackathonTeamMember.hackathonTeamMemberId !== teamMemberId));
            } else if (hackathonProfileStatus === HackathonProfileStatusEnum.KICKED) {
                setTeamMembers(teamMembers.filter(mem => mem.hackathonTeamMember.hackathonTeamMemberId !== teamMemberId));
            }
        })
    }

    return (
        
        <Grid templateColumns={'repeat(13, 1fr)'} h={'100%'}>
            <GridItem colSpan={isZoomed ? [1,1,2,3] : [1,1,2,3]}/>
            <GridItem colSpan={isZoomed ? [11,11,8,7] : [11,11,8,7]}>
                <Box mt={[4,4,6,14]} mb={[4,4,6,7]}>
                    <HackathonTeamHeader hackathonTeam={hackathonProfile.hackathonTeam} />
                </Box>
                {type !== EntryType.IITPSA &&
                <Box>
                    <FancyHeading heading={'Team members'} headingSize={'3xl'} lines={[true, false]} action={
                        <Button
                            colorScheme={'primary'}
                            variant={"ghost"}
                            isLoading={refreshMemberLoading}
                            loadingText={'Refreshing Members'}
                            onClick={() => loadTeamMembers()}
                        >Refresh Members</Button>
                    }/>
                </Box>
                }
                <Box>
                    {
                        loadingText !== ''
                            ? <IntermediateMessageLoader loadingMessage={loadingText}/>
                            :
                            <VStack
                                divider={<StackDivider/>}
                                spacing={4}
                                align="stretch"
                                mt={isZoomed ? 2 : 5}
                            >
                                {
                                    teamMembers.map((memberInList, index) =>
                                        <HackathonTeamPlayerItem
                                            key={index}
                                            loadingMemberId={memberLoading}
                                            colorType='green'
                                            playerType='Active'
                                            member={memberInList}
                                            kickFunction={teamMembers.length > 1 ? () => managePlayer(memberInList.hackathonTeamMember.hackathonTeamMemberId, HackathonProfileStatusEnum.KICKED) : null}
                                            acceptFunction={() => {}}
                                            rejectFunction={() => {}}
                                        />
                                    )
                                }
                                {
                                    pendingMembers.map((memberInList, index) =>
                                        <HackathonTeamPlayerItem
                                            key={index}
                                            loadingMemberId={memberLoading}
                                            colorType='yellow'
                                            playerType='Pending'
                                            member={memberInList}
                                            kickFunction={() => {}}
                                            acceptFunction={() => managePlayer(memberInList.hackathonTeamMember.hackathonTeamMemberId, HackathonProfileStatusEnum.ACCEPTED)}
                                            rejectFunction={() => managePlayer(memberInList.hackathonTeamMember.hackathonTeamMemberId, HackathonProfileStatusEnum.REJECTED)}
                                        />
                                    )
                                }
                            </VStack>
                    }
                </Box>
            </GridItem>
        </Grid>
    );
}


