import * as React from 'react';
import {useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightElement
} from "@chakra-ui/react";
import {SettingItemSection} from "../SettingItemSection";
import {useRecoilState} from "recoil";
import {githubAccessKeysAtom} from "../../../atoms/github-access-keys.atom";
import {AccessKeysApi} from "../../../api/access-keys.api";
import {AxiosError} from "axios";
import ErrorModel from "../../../models/error.model";

interface IAccessKeys {
}

export const AccessKeys: React.FunctionComponent<IAccessKeys> = props => {
    const [idCopied, setIdCopied] = useState(false);
    const [keyCopied, setKeyCopied] = useState(false);

    const [gitHubAccessKey, setGithubAccessKey] = useRecoilState(githubAccessKeysAtom);

    const [isAccessKeyLoading, setIsAccessKeyLoading] = useState<boolean>(false);

    const handleGenerateAccessKeys = () => {
        setIsAccessKeyLoading(true);

        AccessKeysApi.generateAccessKeys()
            .then((result) => {
                setGithubAccessKey(result.data);
                setIsAccessKeyLoading(false);
            })
            .catch((error: AxiosError<ErrorModel>) => {
                //console.error(error);
                setIsAccessKeyLoading(false);
            })
    }

    const copyToClipBoard = (value: string, key: string) => {
        navigator.clipboard.writeText(value).then(() => {
            if ('id' === key) {
                setIdCopied(true);
                setTimeout(() => setIdCopied(false), 3000);
            } else if ('key' === key) {
                setKeyCopied(true);
                setTimeout(() => setKeyCopied(false), 3000);
            }
        });
    }

    return (
        <Box>
            <SettingItemSection
                heading={"Entelect Challenge Access Keys"}
                subTexts={[
                    "These keys are important, as they grant you access to push your bot to our servers. ",
                    "NOTE: Re-generating these keys will invalidate your existing keys."
                ]}
            >
                <Box my={4}>
                    <FormControl id="displayName">
                        <FormLabel>Access Key ID</FormLabel>
                        <InputGroup>
                            <InputLeftAddon children="ACCESS_KEY_ID"/>
                            <Input colorScheme={'primary'} readOnly type="text" size="md" value={gitHubAccessKey.accessKeyId}
                                   placeholder={`Click 'Generate access keys' to generate credentials`}/>
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" onClick={() => copyToClipBoard(gitHubAccessKey.accessKeyId, 'id')}
                                        disabled={gitHubAccessKey.accessKeyId.length === 0}>
                                    {idCopied ? 'COPIED' : 'COPY'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <FormHelperText>Please add this to your Github Project &gt; Settings &gt; Secrets &gt; New
                            repository secret</FormHelperText>
                    </FormControl>
                </Box>
                <Box my={4}>
                    <FormControl id="displayName">
                        <FormLabel>Secret Access Key</FormLabel>
                        <InputGroup colorScheme={'primary'}>
                            <InputLeftAddon children="SECRET_ACCESS_KEY"/>
                            <Input readOnly type="text" size="md" value={gitHubAccessKey.secretAccessKey}
                                   placeholder={`Click 'Generate access keys' to generate credentials`}/>
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm"
                                        onClick={() => copyToClipBoard(gitHubAccessKey.secretAccessKey, 'key')}
                                        disabled={gitHubAccessKey.accessKeyId.length === 0}>
                                    {keyCopied ? 'COPIED' : 'COPY'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <FormHelperText>Please add this to your Github Project &gt; Settings &gt; Secrets &gt; New
                            repository secret</FormHelperText>
                    </FormControl>
                </Box>
                <Button colorScheme={'primary'} isLoading={isAccessKeyLoading} loadingText={'Generating access keys'}
                        onClick={() => handleGenerateAccessKeys()}>Generate access keys</Button>
            </SettingItemSection>
        </Box>
    )
};
