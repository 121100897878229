import {atom, RecoilState, selector} from "recoil";
import AccessKeysModel from "../models/access-keys.model";

export const githubAccessKeysAtom: RecoilState<AccessKeysModel> = atom<AccessKeysModel>({
    key: 'access_key_atom',
    default: {
        accessKeyId: '',
        secretAccessKey: ''
    } as AccessKeysModel
});

export const hasGitHubAccessKeyAtom = selector<boolean>({
    key: 'player_has_access_key_generated',
    get: ({ get }) => {
        const accessKey = get(githubAccessKeysAtom);
        return accessKey.accessKeyId.length > 0 && accessKey.secretAccessKey.length > 0;
    }
})
