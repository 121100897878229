import * as React from 'react';
import {Alert, AlertIcon, Box, HStack} from "@chakra-ui/react";
import {SettingItemSection} from "../SettingItemSection";

interface IAppearance {
}

export const Appearance: React.FunctionComponent<IAppearance> = props => {
    return (
        <Box>
            <SettingItemSection
                heading={'Day vs Night'}
                subTexts={['Choose how Entelect Challenge looks to you.']}
            >
                <HStack>
                    <Box>

                    </Box>
                </HStack>
                <Alert status="info" >
                    <AlertIcon/>
                    Coming soon...
                </Alert>
            </SettingItemSection>
        </Box>
    )
};