import {atom, DefaultValue, RecoilState, selector} from "recoil";

const LOCAL_STATE_LOCAL_STORAGE_KEY = "local_state";

export interface ILocalState {
    notificationsLastOpenedDate: Date
}

const reviver = (key: any, value: string | number | Date) => {
    if (typeof value === "string" && !isNaN(Date.parse(value))) {
        return new Date(value);
    } else {
        return value;
    }
}

export const localState = selector<ILocalState>({
    key: 'local_state_selector',
    get: (): ILocalState => {
        const localStorageData = localStorage.getItem(LOCAL_STATE_LOCAL_STORAGE_KEY);

        if (localStorageData) {
            return JSON.parse(localStorageData, reviver) as ILocalState;
        } else {
            return {
                notificationsLastOpenedDate: new Date(2000, 0, 0, 0, 0, 0, 0),
            };
        }

    }
});

export const localStateAtom: RecoilState<ILocalState> = atom<ILocalState>({
    key: 'local_state_atom',
    default: localState,
    effects_UNSTABLE: [
        ({ onSet }) => {
            onSet((localState: ILocalState | DefaultValue) => {
                if (!(localState instanceof DefaultValue)) {
                    localStorage.setItem(LOCAL_STATE_LOCAL_STORAGE_KEY, JSON.stringify(localState));
                }
            });
        }
    ]
});
