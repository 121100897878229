import { FunctionComponent, ReactNode } from "react";
import { Box, Text, useToken } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface IWikiCategoryCard {
    name: string;
    path: string;
    onCustomClick?: () => void;
}

export const WikiCategoryCard: FunctionComponent<IWikiCategoryCard> = props => {

    const [primary50] = useToken("colors", ["primary.50"]);

    const hoverStyling = {
        boxShadow: `${primary50} 0px 3px 10px 0px`,
        transform: "translate3d(0px, -2px, 0px)",
        cursor: "pointer"
    };

    const displayCardWithCustomClick = (): ReactNode => {
        return (
            <Box
                borderColor={"primary.200"}
                borderWidth={1}
                borderRadius={"md"}
                py={5}
                px={6}
                transition={"0.10s ease-in-out"}
                _hover={hoverStyling}
                onClick={props.onCustomClick}>
                <Text color={"primary.400"} fontWeight={"bold"}>
                    {
                        props.name
                    }
                </Text>
            </Box>
        );
    };

    const displayNormalCard = (): ReactNode => {
        return (
            <Link to={props.path}>
                <Box
                    borderColor={"primary.200"}
                    borderWidth={1}
                    borderRadius={"md"}
                    py={5}
                    px={6}
                    transition={"0.10s ease-in-out"}
                    _hover={hoverStyling}>
                    <Text color={"primary.400"} fontWeight={"bold"}>
                        {
                            props.name
                        }
                    </Text>
                </Box>
            </Link>
        );
    };

    return (
        <>{props.onCustomClick ? displayCardWithCustomClick() : displayNormalCard()}</>
    );
};
