import {AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Link, ListItem, OrderedList} from "@chakra-ui/react";
import * as React from "react";
import {FunctionComponent} from "react";

export const DownloadStarterPackAccordionItem: FunctionComponent = _ => {

    return (
        <AccordionItem>
            <h2>
                <AccordionButton _expanded={{ bg: 'navigation.800', color: 'white' }}>
                    <Box flex='1' textAlign='left'>
                        Download Starter Pack
                    </Box>
                    <AccordionIcon/>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                You are now going to download the starter pack.

                <br/>
                <br/>

                <OrderedList>
                    <ListItem>
                        You can now download the latest starter pack:
                        &nbsp;
                        <Link
                            href="https://github.com/EntelectChallenge/2024-Sproutopia/releases/latest"
                            isExternal
                        >
                            <Button
                                size={'sm'}
                                backgroundColor={'navigation.800'}
                                colorScheme={'navigation'}
                                color={'whiteAlpha.900'}
                            >
                                Download Starter Pack
                            </Button>
                        </Link>
                    </ListItem>
                    <ListItem>
                        After the .zip is downloaded. Open the <b>starter-pack.zip</b> and go to the <em>starter-bots</em> folder.
                    </ListItem>
                    <ListItem>
                        Choose your preferred language, and go into that folder.
                    </ListItem>
                    <ListItem>
                        Copy the contents/files of that folder, to the folder you just cloned from Github.
                    </ListItem>
                </OrderedList>

                <br/>

                Now you have the required files for you to start building your bot.

                <br/>

                Next you are going to download the CI/CD file for the language you just chose, to enable the new automatic submission process.
            </AccordionPanel>
        </AccordionItem>
    );
}
