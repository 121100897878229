import { AxiosResponse } from 'axios';
import PasswordResetConfirmationRequestModel from '../models/password-reset-confirmation-request.model';
import PasswordResetRequestModel from '../models/password-reset-request.model';
import RegisterResultModel from '../models/register-result.model';
import { RegisterModel } from '../models/register.model';
import ResendVerificationModel from '../models/resend-verification.model';
import SignInResultModel from '../models/sign-in-result.model';
import SignInModel from '../models/sign-in.model';
import SocialSignInResultModel from '../models/social-sign-in-result.model';
import SocialSignInModel from '../models/social-sign-in.model';
import { VerifyEmailModel, VerifyEmailResultModel } from '../models/verify-email.model';
import axiosAuthorizationInstance from './axios-authorization-instance.api';
import axiosInstance from './axios-instance.api';

export const AuthenticationApi = {

    signIn: (signIn: SignInModel): Promise<AxiosResponse<SignInResultModel>> => axiosInstance.post('/authentication/signin', signIn),

    register: (register: RegisterModel): Promise<AxiosResponse<RegisterResultModel>> => axiosInstance.post('/authentication/register', register),

    requestPasswordReset: (passwordResetRequest: PasswordResetRequestModel): Promise<AxiosResponse<any>> => axiosInstance.post('/authentication/reset-password-request', passwordResetRequest),

    requestPasswordResetConfirmation: (passwordResetConfirmationRequest: PasswordResetConfirmationRequestModel): Promise<AxiosResponse<any>> => axiosInstance.post('/authentication/reset-password', passwordResetConfirmationRequest),

    verifyEmail: (verifyEmail: VerifyEmailModel): Promise<AxiosResponse<VerifyEmailResultModel>> => axiosInstance.post('/authentication/verify', verifyEmail),

    resendVerificationEmail: (resendVerification: ResendVerificationModel): Promise<AxiosResponse<any>> => axiosInstance.post(`/authentication/resend-verification`, resendVerification),

    socialSignIn: (socialSignIn: SocialSignInModel): Promise<AxiosResponse<SocialSignInResultModel>> => axiosAuthorizationInstance.post('/token', socialSignIn)
}

export const baseAuthUrl = `${process.env.REACT_APP_AUTHORIZATION_URL}/authorize?identity_provider=`;
export const redirectUrl = `&redirect_uri=${process.env.REACT_APP_AUTHORIZATION_REDIRECT_URI}&response_type=CODE&client_id=${process.env.REACT_APP_AUTHORIZATION_CLIENT_ID}&scope=email openid profile`;

export const FacebookSignInUrl = `${baseAuthUrl}Facebook${redirectUrl}`;

export const GoogleSignInUrl = `${baseAuthUrl}Google${redirectUrl}`;

export const AmazonSignInUrl = `${baseAuthUrl}LoginWithAmazon${redirectUrl}`;
