import jwt_decode from "jwt-decode";
import {atom, DefaultValue, RecoilState, selector} from "recoil";
import CustomJwtPayload from "../models/custom-jwt-payload";

const storedToken = localStorage.getItem("token");

export const tokenAtom: RecoilState<string> = atom<string>({
    key: 'token_atom',
    default: storedToken ? storedToken : '',
    effects_UNSTABLE: [
        ({ onSet }) => {
            onSet(newToken => {
                if (!(newToken instanceof DefaultValue) && newToken) {
                    localStorage.setItem("token", newToken);
                    const decodedToken = jwt_decode<CustomJwtPayload>(newToken);

                    if (decodedToken.exp) {
                        localStorage.setItem("exp", decodedToken.exp.toString());
                    }
                }
            })
        },
    ]
});

export const decodedTokenState = selector<CustomJwtPayload | null>({
    key: 'decoded_token_selector',
    get: ({ get }) => {
        const token = get(tokenAtom);

        if (token === '') {
            return null;
        }

        return jwt_decode<CustomJwtPayload>(token);
    }
});

export const isTokenValid = selector<boolean>({
    key: 'token_valid_selector',
    get: ({ get }) => {
        const token = get(tokenAtom);

        if (token === '') {
            return false;
        }

        const decodedToken = get(decodedTokenState);

        if (decodedToken) {
            if (decodedToken.exp) {
                return decodedToken.exp > (Date.now() / 1000);
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
});
