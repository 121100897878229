import {Flex, Square, Text, useBreakpointValue} from "@chakra-ui/react";
import {IntermediateMessageLoader} from "../../shared/loading/IntermediateMessageLoader";
import * as React from 'react';
import {useEffect, useState} from 'react';
import {HackathonTeamModelWithVotes} from "../../models/hackathon-team.model";
import {VotingComponent} from "./VotingComponent";
import HackathonTournamentModel from "../../models/hackathon-tournament.model";
import {AxiosError} from "axios";
import ErrorModel from "../../models/error.model";
import HackathonFunModel from "../../models/hackathon-fun.model";
import {useRecoilValue} from "recoil";
import {HackathonApi} from "../../api/hackathon.api";
import {getSettingAsMap, SETTING_NAMES_HACKATHON} from "../../atoms/settings.atom";

interface IVoting {
    isVotingEnabled: boolean;
    tournament: HackathonTournamentModel;
}

interface VotingCategory {
    id: string;
    title: string;
    description: string;
}

export const Voting: React.FunctionComponent<IVoting> = ({isVotingEnabled, tournament}) => {
    const categoryData = useRecoilValue(getSettingAsMap(SETTING_NAMES_HACKATHON.VOTING_PAGE_VOTING_DATA));

    const [loading, setLoading] = useState(true);
    const [votes, setVotes] = useState<Array<HackathonFunModel> | undefined>(undefined);
    const [validTeams, setValidTeams] = useState<Array<HackathonTeamModelWithVotes> | undefined>(undefined);

    const votingCategories = Array.from(categoryData.values()) as Array<Object> as Array<VotingCategory>;

    useEffect(() => {
        const getValidTeamsPromise = HackathonApi.getVotableTeams(tournament!.hackathonTournamentId);
        const allVotesPromise = HackathonApi.getAllVotes(tournament!.hackathonTournamentId);

        Promise.all([getValidTeamsPromise, allVotesPromise])
            .then(([validTeamsResponse, allVotesResponse]) => {
                const allVotesData = allVotesResponse.data;
                setVotes(allVotesData);

                const validTeamsData = validTeamsResponse.data.sort((a, b) => {
                    if (a.teamName > b.teamName) return 1
                    else if (a.teamName < b.teamName) return -1
                    else return 0
                }) as Array<HackathonTeamModelWithVotes>;

                validTeamsData?.forEach(team => {
                    team.votes = allVotesData?.filter(vote => vote.hackathonTeamId === team.hackathonTeamId).length || 0
                })

                setValidTeams(validTeamsData);

                setLoading(false);
            })
            .catch((error: AxiosError<ErrorModel>) => {
                //console.error(error)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValidTeams, setVotes]);

    let textColumnMaxWidth = '96em';
    let titleFontSize = 45;
    let minViewH = '50vh';
    let otherTitleFontColor = 'accent.600';
    const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});

    const squareMargins = isMobile ? 1 : 10;

    return (
        <Square p={5} shadow="md" borderWidth="0.1em"
                background={'whiteAlpha.900'}
                borderRadius={'0.5em'}
                m={squareMargins}
                width={'100%'}
                maxWidth={textColumnMaxWidth}
                minH={minViewH}
                alignSelf={'center'}
                flexDirection={'column'}
        >
            <Text
                fontWeight={'bold'}
                fontSize={titleFontSize}
                color={otherTitleFontColor}
                align={'center'}
                pb={5}
                width={'80%'}
                maxWidth={textColumnMaxWidth}
                colorScheme="primary">
                Voting Categories
            </Text>

            <Text
                fontSize='calc(1.2em + 1vmin)'
                color='navigation.700'
                align='center'
                pb={5}
                width={'100%'}
                maxWidth={textColumnMaxWidth}>
                Voting will only be opened after the hackathon. <br/>
                <b>You can only vote once. Votes cannot be changed afterwards.</b>
            </Text>

            <Flex
                w={'100%'}
                flexDirection='row'
                flexWrap='wrap'
                gap={5}
                justifyContent='space-evenly'
                position='relative'
            >
                {!isVotingEnabled &&
                    <Square
                        background={'whiteAlpha.800'}
                        borderRadius={'0.5em'}
                        width='100%'
                        height='100%'
                        alignSelf={'center'}
                        flexDirection={'column'}
                        position='absolute'
                        zIndex={1}
                    >
                        <Text
                            fontWeight={'bold'}
                            fontSize={titleFontSize}
                            color='primary'
                            align={'center'}
                            pb={5}
                            width={'100%'}
                            maxWidth={textColumnMaxWidth}>
                            Voting is not enabled at this time
                        </Text>
                    </Square>
                }
                {loading
                    ? <IntermediateMessageLoader loadingMessage={'Fetching all the fun things!'}/>
                    : votes && votingCategories.map(category => (
                    <VotingComponent
                        key={category.id}
                        isVotingEnabled={isVotingEnabled}
                        tournament={tournament}
                        category={category}
                        validTeams={validTeams}
                        votes={votes}
                        setVotes={setVotes}
                    />
                ))
                }
            </Flex>
        </Square>
    );
}
