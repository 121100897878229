export enum StatusType {
    fail = 0,
    busy = 1,
    success = 2,
    warning = 3,
    pending = 4,
    queue = 5
}

export enum StatusTypeDescription {
    Pending = 'Pending',
    CodeBuildFailed = 'Cannot Be Run',
    MatchStarted = 'In Progress',
    MatchFailed= 'Failed',
    MatchSuccess = 'Success',
    InQueue = 'In Queue'
}
