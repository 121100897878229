import * as React from 'react';
import {
    Box,
    Button,
    Center,
    Flex, FormControl, HStack, IconButton, Input,
    Spacer,
    Stack,
    Tooltip,
    useBreakpointValue,
    useClipboard,
    VStack
} from "@chakra-ui/react";
import { Text } from "@chakra-ui/layout";
import { HackathonTeamModel } from "../../../models/hackathon-team.model";
import { CheckIcon, CloseIcon, CopyIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { FancyHeading } from "../../../shared/FancyHeading";
import { HackathonApi } from "../../../api/hackathon.api";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { hackathonProfileAtom } from "../../../atoms/hackathon-profile.atom";
import { getValueForEntryType, goToWiki } from "../../../utils/hooks/general-utils";
import { Field, FieldAttributes, Form, Formik } from 'formik';
import { useParams } from "react-router-dom";
import { getSettingAsBoolean, getSettingAsHackathonData, SETTING_NAMES_ENTELECT_CHALLENGE, SETTING_NAMES_HACKATHON } from "../../../atoms/settings.atom";
import HackathonTournamentModel from "../../../models/hackathon-tournament.model";
import { EntryType } from '../../../models/entry.model';

interface IHackathonTeamHeader {
    hackathonTeam: HackathonTeamModel;
}

export const HackathonTeamHeader: React.FunctionComponent<IHackathonTeamHeader> = props => {

    const { type } = useParams();
    const isMobile = useBreakpointValue({ base: true, sm: true, md: false, lg: false });
    const { hasCopied, onCopy } = useClipboard(props.hackathonTeam.teamCode);
    const enableIITPSA = useRecoilValue(getSettingAsBoolean(SETTING_NAMES_ENTELECT_CHALLENGE.IITPSA_ENABLE_SUBMISSION));
    const [teamNameUpdateLoading, setTeamNameUpdateLoading] = useState(false);
    const [teamAcceptUpdateLoading, setTeamAcceptUpdateLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [hackathonProfile, setHackathonProfile] = useRecoilState(hackathonProfileAtom);

    const hackathonCompanyTournament: HackathonTournamentModel = useRecoilValue(getSettingAsHackathonData(SETTING_NAMES_HACKATHON.COMPANY_TOURNAMENT_DATA));
    const hackathonUniversityTournament: HackathonTournamentModel = useRecoilValue(getSettingAsHackathonData(SETTING_NAMES_HACKATHON.UNIVERSITY_TOURNAMENT_DATA));
    const hackathonIitpsaTournament: HackathonTournamentModel = useRecoilValue(getSettingAsHackathonData(SETTING_NAMES_HACKATHON.IITPSA_TOURNAMENT_DATA));

    const goToDiscord = () => {
        window.open(getValueForEntryType(type, {
            university: hackathonUniversityTournament.communicationsLink,
            company: hackathonCompanyTournament.communicationsLink,
            iitpsa: hackathonIitpsaTournament.communicationsLink
        }) as string, '_blank')
    }

    const toggleTeam = () => {
        setTeamAcceptUpdateLoading(true);
        HackathonApi
            .updateTeam({ ...props.hackathonTeam, canAcceptMembers: !props.hackathonTeam.canAcceptMembers })
            .then((response) => {
                setTeamAcceptUpdateLoading(false);
                setHackathonProfile({
                    hackathonTeamMember: hackathonProfile.hackathonTeamMember,
                    hackathonTeam: response.data
                });
            })
            .catch(() => setTeamAcceptUpdateLoading(false));
    }

    const updateTeamName = (team: HackathonTeamModel) => {
        setTeamNameUpdateLoading(true);
        HackathonApi
            .updateTeam({ ...props.hackathonTeam, teamName: team.teamName })
            .then((response) => {
                setTeamNameUpdateLoading(false);
                setHackathonProfile({
                    hackathonTeamMember: hackathonProfile.hackathonTeamMember,
                    hackathonTeam: response.data
                });
                setIsEditing(false);
            })
            .catch(() => setTeamNameUpdateLoading(false));
    }

    return (
        <Center w={'100%'} >
            <Box w={['95%', '95%', '70%', '60%']}>
                <Stack direction={['column', 'column', 'row', 'row']} spacing={5}>
                    <Box>
                        <Box px={10} py={3} bg={'primary.500'} borderRadius={'md'}>
                            <Text color={'whiteAlpha.900'} fontSize={'6xl'} textAlign={'center'}>
                                {props.hackathonTeam.teamName.substr(0, 1).toUpperCase()}
                            </Text>
                        </Box>
                    </Box>
                    <Stack direction={'column'} w={'100%'}>
                        {type === EntryType.IITPSA ?
                            <FancyHeading heading={props.hackathonTeam.teamName} headingSize={'3xl'} lines={[true, false]} />
                            :
                            <Box>

                                {
                                    isEditing
                                        ? <Formik
                                            initialValues={props.hackathonTeam}
                                            onSubmit={(values) => updateTeamName(values)}>
                                            {({ values }) => (
                                                <Form>
                                                    <Field name={'teamName'}>
                                                        {({ field, form }: FieldAttributes<any>) => (
                                                            <HStack>
                                                                <FormControl isRequired>
                                                                    <Input colorScheme={'primary'} type="text" size="md"
                                                                        variant="filled" placeholder={'Team Name'}
                                                                        id={'teamName'} {...field} />
                                                                </FormControl>
                                                                <IconButton
                                                                    p={0}
                                                                    isLoading={teamNameUpdateLoading}
                                                                    aria-label={'submit new team name'}
                                                                    type={'submit'}
                                                                    _hover={{
                                                                        bg: 'primary.50'
                                                                    }}
                                                                    icon={
                                                                        <CheckIcon
                                                                            color={'primary.500'}
                                                                            fontSize={'md'}
                                                                        />
                                                                    }
                                                                />
                                                                <IconButton
                                                                    p={0}
                                                                    onClick={() => setIsEditing(false)}
                                                                    disabled={teamNameUpdateLoading}
                                                                    aria-label={'cancel new team name'}
                                                                    type={'button'}
                                                                    _hover={{
                                                                        bg: 'primary.50'
                                                                    }}
                                                                    icon={
                                                                        <CloseIcon
                                                                            color={'primary.500'}
                                                                            fontSize={'md'}
                                                                        />
                                                                    }
                                                                />
                                                            </HStack>
                                                        )}
                                                    </Field>
                                                </Form>
                                            )}
                                        </Formik>
                                        : <FancyHeading heading={props.hackathonTeam.teamName} headingSize={'3xl'} lines={[true, false]} action={
                                            <Button colorScheme={'primary'} size={'sm'} variant={'ghost'} onClick={() => setIsEditing(true)}>Edit</Button>
                                        } />
                                }

                            </Box>
                        }

                        <Spacer />
                        {type !== EntryType.IITPSA &&
                            <Box>
                                <Tooltip hasArrow label="Click to copy Team Code!" placement={isMobile ? 'bottom' : 'right'} defaultIsOpen>
                                    <Flex
                                        mb={[8, 8, 2, 2]}
                                        mt={1}
                                        w={'100%'}
                                        borderColor={'primary.300'}
                                        borderWidth={1}
                                        borderRadius={'lg'}
                                        bg={'primaryAlpha.50'}
                                        py={1}
                                        px={4}
                                        alignItems={'center'}
                                        cursor={'pointer'}
                                        transition={'all 0.2s'}
                                        _hover={{
                                            bg: 'primaryAlpha.100',
                                            borderColor: 'primary.400'
                                        }}
                                        onClick={onCopy}
                                    >
                                        <Text fontSize={'xl'}>
                                            <Text as={'span'} color={'primary.700'} fontWeight={'semibold'}
                                                letterSpacing={1.25}>{props.hackathonTeam.teamCode}</Text>
                                        </Text>
                                        <Spacer />
                                        {
                                            hasCopied
                                                ? <Text color={'primary.500'} fontWeight={'semibold'}>Copied</Text>
                                                : <CopyIcon color={'primary.500'} boxSize={5} />
                                        }

                                    </Flex>
                                </Tooltip>
                            </Box>
                        }
                        {(type === EntryType.IITPSA && !enableIITPSA) &&
                        <Box>
                            <Text fontSize={'xl'}>
                                            <Text as={'span'} color={'primary.700'} fontWeight={'semibold'}
                                                letterSpacing={1.25}>Solution Coming Soon</Text>
                                        </Text>
                        </Box>
                        }
                        <Box w={'100%'}>
                            <Stack direction={['column', 'column', 'row', 'row']} w={'100%'} spacing={4}>
                                <Box w={'100%'}>
                                    <Button
                                        variant={'solid'}
                                        size={'sm'}
                                        colorScheme={'accent'}
                                        w={'100%'}
                                        onClick={() => goToDiscord()}
                                    >Discord Server</Button>
                                </Box>
                                {type !== EntryType.IITPSA &&
                                    <HStack w={'100%'} spacing={3}>
                                        <Button
                                            variant={'outline'}
                                            size={'sm'}
                                            colorScheme={'primary'}
                                            w={'100%'}
                                            isLoading={teamAcceptUpdateLoading}
                                            loadingText={props.hackathonTeam.canAcceptMembers ? 'Disabling orphan members' : 'Enabling orphan members'}
                                            onClick={() => toggleTeam()}
                                        >{props.hackathonTeam.canAcceptMembers ? 'Disable' : 'Enable'} orphan members</Button>
                                        <QuestionOutlineIcon fontSize={'xl'} color={'primary.500'} cursor={'pointer'} onClick={() => goToWiki('no-hackathon-team')} />
                                    </HStack>
                                }
                            </Stack>
                        </Box>

                    </Stack>
                </Stack>
                {type !== EntryType.IITPSA &&
                    <Box mt={6} w={'100%'}>
                        <VStack alignItems={'start'} spacing={1}>
                            <Text fontSize={15} color={'gray.600'} textAlign={['center', 'center', 'center', 'left']}>
                                You can open your team to accept players that don't have a team but want to participate with a team.
                            </Text>
                            <Text fontSize={15} color={'gray.600'} textAlign={['center', 'center', 'center', 'left']}>
                                You can also interact with your team members online via our Discord server (see button, above).
                            </Text>
                            <Text fontSize={15} color={'gray.600'} textAlign={['center', 'center', 'center', 'left']}>
                                Or you can manage your team right here! You can accept or reject team invites here, as well as kick those bad behaving team members.
                            </Text>
                        </VStack>
                    </Box>
                }
            </Box>

        </Center>
    )
};
