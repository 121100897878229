import * as React from 'react';
import {CountdownRenderProps} from "react-countdown/dist/Countdown";
import Countdown from "react-countdown";

interface IHackathonComponentSwitch {
    switchDate: Date;
    busyComponent: JSX.Element;
    completedComponent: JSX.Element;
}

export const HackathonComponentSwitch: React.FunctionComponent<IHackathonComponentSwitch> = props => {

    const renderer = (countdownRenderProps: CountdownRenderProps): React.ReactNode => {
        if (countdownRenderProps.completed) {
            return props.completedComponent;
        } else {
            return props.busyComponent;
        }
    }

    return (
        <Countdown
            date={props.switchDate}
            renderer={renderer}
        />
    )
};