export const finalists2018 = [
    {
        id:1,
        finalist: 'Louis Lotter',
        image: '1_Louis_Lotter'
    },
    {
        id:2,
        finalist: 'Edward Steere',
        image: '2_Edward_Steere'
    },
    {
        id:3,
        finalist: 'Justin Worthe',
        image: '3_Justin_Worthe'
    },
    {
        id:4,
        finalist: 'Andre Nel',
        image: '4_Andre_Nel'
    },
    {
        id:5,
        finalist: 'Leon Wessels',
        image: '5_Leon_Wessels'
    },
    {
        id:6,
        finalist: 'Riaan Nel',
        image: '6_Riaan_Nel'
    },
    {
        id:7,
        finalist: 'Ralf Kistner',
        image: '7_Ralf_Kistner'
    },
    {
        id:8,
        finalist: 'Willie Theron',
        image: '8_Willie_Theron'
    }
]