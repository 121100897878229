import { SquadTypeEnum } from "./squad-type.enum";
import teamImageIndex from "./teamImageIndex";

export type TeamMemberDetails = {
    displayName: string;
    name: string;
    teamSquad: SquadTypeEnum;
    description: string;
    imageFile: any;
}

const teamAssets: TeamMemberDetails[] =
    [
        {
            displayName: "Amantle Mashele",
            name: "Amantle_Mashele",
            teamSquad: SquadTypeEnum.CLOUD,
            description: "desc",
            imageFile: teamImageIndex.Amantle_Mashele
        },
        {
            displayName: "Bianca McFadyen",
            name: "Bianca_McFadyen",
            teamSquad: SquadTypeEnum.PROJECT_MANAGEMENT,
            description: "desc",
            imageFile: teamImageIndex.Bianca_McFadyen
        },
        {
            displayName: "Creolin Gopal",
            name: "Creolin_Gopal",
            teamSquad: SquadTypeEnum.GAME_ENGINE,
            description: "desc",
            imageFile: teamImageIndex.Creolin_Gopal
        },
        {
            displayName: "Dewald Swanepoel",
            name: "Dewald_Swanepoel",
            teamSquad: SquadTypeEnum.GAME_ENGINE,
            description: "desc",
            imageFile: teamImageIndex.Dewald_Swanepoel
        },
        {
            displayName: "Gabi Joubert",
            name: "Gabi_Joubert",
            teamSquad: SquadTypeEnum.MARKETING,
            description: "desc",
            imageFile: teamImageIndex.Gabi_Joubert
        },
        {
            displayName: "Izak Joubert",
            name: "Izak_Joubert",
            teamSquad: SquadTypeEnum.HACKATHON,
            description: "desc",
            imageFile: teamImageIndex.Izak_Joubert
        },
        {
            displayName: "Jenique Peters",
            name: "Jenique_Peters",
            teamSquad: SquadTypeEnum.GAME_ENGINE,
            description: "desc",
            imageFile: teamImageIndex.Jenique_Peters
        },
        {
            displayName: "Joshua Willmot",
            name: "Joshua_Willmot",
            teamSquad: SquadTypeEnum.WEBSITE,
            description: "desc",
            imageFile: teamImageIndex.Joshua_Willmot
        },
        {
            displayName: "JP Roussel",
            name: "JP_Roussel",
            teamSquad: SquadTypeEnum.WEBSITE,
            description: "desc",
            imageFile: teamImageIndex.JP_Roussel
        },
        {
            displayName: "Laaiqah Seedat",
            name: "Laaiqah_Seedat",
            teamSquad: SquadTypeEnum.GAME_ENGINE,
            description: "desc",
            imageFile: teamImageIndex.Laaiqah_Seedat
        },
        {
            displayName: "Lijani Van Wyk de Vries",
            name: "Lijani_Van_Wyk_de_Vries",
            teamSquad: SquadTypeEnum.MARKETING,
            description: "desc",
            imageFile: teamImageIndex.Lijani_Van_Wyk_de_Vries
        },
        {
            displayName: "Minal Pramlall",
            name: "Minal_Pramlall",
            teamSquad: SquadTypeEnum.WEBSITE,
            description: "desc",
            imageFile: teamImageIndex.Minal_Pramlall
        },
        {
            displayName: "Natasha Draper",
            name: "Natasha_Draper",
            teamSquad: SquadTypeEnum.GAME_ENGINE,
            description: "desc",
            imageFile: teamImageIndex.Natasha_Draper
        },
        {
            displayName: "Neil Foxcroft",
            name: "Neil_Foxcroft",
            teamSquad: SquadTypeEnum.CLOUD,
            description: "desc",
            imageFile: teamImageIndex.Neil_Foxcroft
        },
        {
            displayName: "Rene du Plooy",
            name: "Rene_du_Plooy",
            teamSquad: SquadTypeEnum.WEBSITE,
            description: "desc",
            imageFile: teamImageIndex.Rene_du_Plooy
        },
        {
            displayName: "Simeon Boshoff",
            name: "Simeon_Boshoff",
            teamSquad: SquadTypeEnum.HACKATHON,
            description: "desc",
            imageFile: teamImageIndex.Simeon_Boshoff
        },
        {
            displayName: "Thobelani Makeleni",
            name: "Thobelani_Makeleni",
            teamSquad: SquadTypeEnum.GAME_ENGINE,
            description: "desc",
            imageFile: teamImageIndex.Thobelani_Makeleni
        },
        {
            displayName: "Wiehan van Niekerk",
            name: "Wiehan_van_Niekerk",
            teamSquad: SquadTypeEnum.WEBSITE,
            description: "desc",
            imageFile: teamImageIndex.Wiehan_van_Niekerk
        }
    ];
export default teamAssets;
