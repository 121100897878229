import { Icon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Heading,
    HStack,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Spacer,
    Spinner,
    Text,
    useBreakpointValue,
    useDisclosure,
    VStack
} from '@chakra-ui/react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FiDownload, FiRefreshCw } from 'react-icons/fi';
import { SubmissionsApi } from '../../../api/submissions.api';
import AwsSubmissionModel from '../../../models/aws-submissions.model';
import { EntryType } from '../../../models/entry.model';
import ErrorModel from '../../../models/error.model';
import { StatusTypeDescription } from '../../../models/status.type.enum';
import { defaultSubmissionModel, mapToSubmissionsModel, SubmissionsModel } from '../../../models/submissions.model';
import { IntermediateMessageLoader } from '../../../shared/loading/IntermediateMessageLoader';
import { AiOutlineEye, AiOutlineWarning } from "react-icons/ai";
import { FaChevronLeft, FaChevronRight, FaRobot } from "react-icons/fa6";
import { ImCheckmark, ImCloudCheck } from "react-icons/im";
import { SiGithub } from "react-icons/si";
import { CgDetailsMore } from "react-icons/cg";

interface IEntelectChallengeSubmissions {

}

export const EntelectChallengeSubmissions: FunctionComponent<IEntelectChallengeSubmissions> = props => {
	const [isSubmissionsLoading, setIsSubmissionsLoading] = useState<boolean>(false);
	const [isMatchLogDownloading, setIsMatchLogDownloading] = useState<boolean>(false);
	const [isBotLogDownloading, setIsBotLogDownloading] = useState<boolean>(false);
	const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
	const [currentSubmissionIndex, setCurrentSubmissionIndex] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [submissions, setSubmissions] = useState<SubmissionsModel[]>([]);
	const isMobile = useBreakpointValue({ base: true, sm: true, md: true, lg: false });
	const [displayPageNumber, setDisplayPageNumber] = useState<number>(1);
	const submissionsPerPage = 10;

	const totalPages = Math.ceil(submissions.length / submissionsPerPage) || 1;

	useEffect(() => {
		const currentDisplayPageNumber = currentPage + 1;
		setDisplayPageNumber(currentDisplayPageNumber);
	}, [currentPage]);

	const [submissionToDownload, setSubmissionToDownload] = useState<SubmissionsModel>(defaultSubmissionModel);

	const handleRefreshSubmissions = useCallback(() => {
		setIsSubmissionsLoading(true);

		SubmissionsApi.getSubmissions(EntryType.MAIN)
		              .then((result: AxiosResponse<AwsSubmissionModel[]>) => {
			              const awsSubmissionsModel = result.data as AwsSubmissionModel[];
			              const submissionsModel = mapToSubmissionsModel(awsSubmissionsModel);

			              setSubmissions(submissionsModel);
			              setIsSubmissionsLoading(false);
			              setIsInitialLoad(false);
		              })
		              .catch((error: AxiosError<ErrorModel>) => {
			              //console.error(error);
			              setIsSubmissionsLoading(false);
			              setIsInitialLoad(false);
		              });
	}, []);

	function getModalFooterButtons(submissionModel: SubmissionsModel) {
		return submissionModel && <>
            <Button
                variant="solid"
                colorScheme="navigation"
                px={5}
                mx={1}
                textAlign={'center'}
                onClick={() => handleMatchLogDownload(submissionModel)}
                leftIcon={submissionModel.matchDate !== null ? <FiDownload/> : <AiOutlineWarning/>}
                disabled={isMatchLogDownloading || submissionModel.matchDate === null || isPendingStatus(submissionModel.matchStatus)}
                isLoading={isMatchLogDownloading && submissionModel.botId === submissionToDownload?.botId}
                loadingText={'Downloading'}>
                Match Log
            </Button>
            <Spacer/>
            <Button
                variant="solid"
                colorScheme="navigation"
                px={5}
                mx={1}
                textAlign={'center'}
                onClick={() => handleBotLogDownload(submissionModel)}
                leftIcon={<FaRobot/>}
                disabled={isBotLogDownloading || isPendingStatus(submissionModel.matchStatus)}
                isLoading={isBotLogDownloading && submissionModel.botId === submissionToDownload?.botId}
                loadingText={'Downloading'}>
                Bot Log
            </Button>
            <Spacer/>
            <Button
                leftIcon={<AiOutlineEye/>}
                px={5}
                mx={1}
                textAlign={'center'}
                variant="outline"
                colorScheme="navigation"
                disabled={true}>
                Visualize
            </Button>
        </>;
	}

	const getSubmissionHistory = (onOpen: () => void, isOpen: boolean, onClose: () => void) => {
		let titleFontSize = 'calc(0.8em + 1vmin)';
		let titleFontColor = 'accent.600';
		let gridMinWidth = '60em';
		if (submissions?.length > 0) {
			return (
				<>
					<SimpleGrid
						columns={5}
						gridTemplateColumns={'1fr 1fr 1fr 1fr 3fr'}
						minW={gridMinWidth}
						my={5}>

						<Text
							align={'center'}
							fontWeight={'semibold'}
							fontSize={titleFontSize}
							color={titleFontColor}
							colorScheme="primary">
							Submission #
						</Text>

						<Text
							align={'center'}
							fontWeight={'semibold'}
							fontSize={titleFontSize}
							color={titleFontColor}
							colorScheme="primary">
							Upload
						</Text>

						<Text
							align={'center'}
							fontWeight={'semibold'}
							fontSize={titleFontSize}
							color={titleFontColor}
							colorScheme="primary">
							Submission
						</Text>

						<Text
							align={'center'}
							fontWeight={'semibold'}
							fontSize={titleFontSize}
							color={titleFontColor}
							colorScheme="primary">
							Match
						</Text>

						<Text
							align={'center'}
							fontWeight={'semibold'}
							fontSize={titleFontSize}
							color={titleFontColor}
							colorScheme="primary">
							Available Actions
						</Text>

						{submissions.map((item, index) => (
							index >= currentPage * submissionsPerPage && index < (currentPage + 1) * submissionsPerPage && item &&
                            <>
                                <Divider gridColumn={'1 / -1'} my={2} borderWidth={index <= 1 ? '2px' : ''}/>
								{SubmissionComponent(item, index === 0, submissions.length - index, onOpen)}
                            </>
						))}
					</SimpleGrid>

					<Modal isOpen={isOpen} onClose={onClose} size={isMobile ? 'full' : 'xl'}>
						<ModalOverlay/>
						<ModalContent>
							<ModalHeader>Submission Details</ModalHeader>
							<ModalCloseButton/>
							<ModalBody>
								{submissions && SubmissionDetailItem(submissions[submissions.length - currentSubmissionIndex])}
							</ModalBody>

							<ModalFooter justifyContent={'center'}>
								{getModalFooterButtons(submissions[submissions.length - currentSubmissionIndex])}
							</ModalFooter>
						</ModalContent>
					</Modal>
				</>
			);
		} else {
			return (
				<>
					<Text
						fontSize={'2em'}
						color={'navigation.800'}
						p={5}
						fontWeight={'semibold'}>
						No submissions found, yet. You can do it!
					</Text>
				</>
			);
		}
	};

	const downloadHeaders = {
		'Content-Disposition': 'attachment; filename="zip.gz"',
		'Content-Type': 'application/gzip'
	};

	function downLoadLog(logUrl: string, matchDate: Date | null, fileNamePrefix: string, handleCleanup: (() => void)) {
		SubmissionsApi.getLog(logUrl)
		              .then((result) => {
			              if (result.status !== 200) {
				              handleCleanup();
				              //console.error('result status is ' + result.status + ', logUrl is ' + logUrl);
				              return;
			              }
			              axios.get(result.data, { headers: downloadHeaders, responseType: 'arraybuffer' as 'json' })
			                   .then((downloadResult) => {
				                   const blob = new Blob([downloadResult.data]);
				                   const matchFormattedDate = matchDate !== null ? moment(matchDate)
				                   .format('YYYY-MM-DD HH:mm') : '';
				                   const fileName = `${fileNamePrefix}_${matchFormattedDate}.json`;
				                   saveAs(blob, fileName);

				                   handleCleanup();
			                   });
		              })
		              .catch((error: AxiosError<ErrorModel>) => {
			              //console.log(error);
			              handleCleanup();
		              });
	}

	const handleMatchLogDownload = (submissionModel: SubmissionsModel) => {
		setIsMatchLogDownloading(true);
		setSubmissionToDownload(submissionModel);

		downLoadLog(submissionModel.matchLogsUrl, submissionModel.matchDate, `${submissionModel.botId}_match`, () => {
			setIsMatchLogDownloading(false);
			setSubmissionToDownload(defaultSubmissionModel);
		});
	};

	const handleBotLogDownload = (submissionModel: SubmissionsModel) => {
		setIsBotLogDownloading(true);
		setSubmissionToDownload(submissionModel);

		downLoadLog(submissionModel.botLogsUrl, submissionModel.matchDate, `${submissionModel.botId}_bot`, () => {
			setIsBotLogDownloading(false);
			setSubmissionToDownload(defaultSubmissionModel);
		});
	};

	function isFailedStatus(status: string): boolean {
		return status === StatusTypeDescription.MatchFailed ||
		       status === StatusTypeDescription.CodeBuildFailed;
	}

	function isSuccessStatus(status: string): boolean {
		return status === StatusTypeDescription.MatchSuccess;
	}

	function isPendingStatus(status: string): boolean {
		return status === StatusTypeDescription.MatchStarted ||
		       status === StatusTypeDescription.Pending ||
		       status === StatusTypeDescription.InQueue;
	}

	function handleMoreDetailsClicked(index: number, onOpen: () => void) {
		setCurrentSubmissionIndex(index);
		onOpen();
	}

	const SubmissionDetailItem = (submissionsModel: SubmissionsModel) => {
		let attributeFontSize = '1em';
		let attributeFontColor = 'accent.600';

		return (
			<>
				<VStack>
					{submissionsModel && Object.entries(submissionsModel)
					                           .map((item) =>
					 !item[0].includes('Url') && !item[0].includes('Type') && item[0] !== 'isReported' &&
                     <>
                         <Flex w={'100%'}>
                             <Text
                                 align={'center'}
                                 fontWeight={'semibold'}
                                 fontSize={attributeFontSize}
                                 color={attributeFontColor}>
								 {item[0].replace(/([A-Z])/g, ' $1')
								         .charAt(0)
								         .toUpperCase() + item[0].replace(/([A-Z])/g, ' $1')
								                                 .slice(1)}:
                             </Text>
                             <Spacer/>
                             <Text
                                 align={'center'}
                                 fontWeight={'semibold'}
                                 color={getColorBasedOnStatus(item[1])}
                                 fontSize={attributeFontSize}>
								 {isFailedStatus(item[1]) &&
                                  <Icon as={AiOutlineWarning} boxSize={4}/>
								 }
								 {isSuccessStatus(item[1]) &&
                                  <Icon as={ImCheckmark} boxSize={4}/>
								 }
								 {isPendingStatus(item[1]) &&
                                  <Spinner/>
								 }
								 {' '}
								 {item[0].includes('Date') ? (item[1] ? moment(item[1])
								 .format('YYYY-MM-DD HH:mm:ss') : 'Unknown') : item[1]}
                             </Text>
                         </Flex>
                         <Divider/>
                     </>
					                           )}
				</VStack>
			</>
		);
	};

	function getColorBasedOnStatus(status: string) {
		if (isSuccessStatus(status)) {
			return 'green';
		} else if (isFailedStatus(status)) {
			return 'red';
		}
		return '';
	}

	const SubmissionComponent = (submissionModel: SubmissionsModel, isFirst: boolean, index: number, onOpen: () => void) => {

		return (
			<>
				<Text
					align={'center'}
					fontWeight={isFirst ? 'bold' : ''}
					m={'auto'}>
					{index}
				</Text>

				<Flex
					flexDirection={'column'}
					alignItems={'center'}
					m={'auto'}
					fontWeight={isFirst ? 'bold' : ''}
					color={getColorBasedOnStatus(submissionModel.uploadStatus)}>

					<HStack alignItems="center" align={'center'} margin={'auto'}>
						{submissionModel.uploadStatus === 'Failed' &&
                         <Icon as={AiOutlineWarning} boxSize={5}/>
						}
						{submissionModel.uploadStatus !== 'Failed' &&
                         <>
                             <Icon as={SiGithub} boxSize={5}/>
                             <Icon as={ImCloudCheck} boxSize={5}/>
                         </>
						}
					</HStack>
				</Flex>

				<Flex
					flexDirection={'column'}
					alignItems="center"
					m={'auto'}
					fontWeight={isFirst ? 'bold' : ''}
					color={getColorBasedOnStatus(submissionModel.buildStatus)}>

					<HStack alignItems="center" align={'center'} margin={'auto'}>
						{submissionModel.buildStatus === 'Failed' &&
                         <Icon as={AiOutlineWarning} boxSize={5}/>
						}
						{submissionModel.buildStatus !== 'Failed' &&
                         <>
                             <Icon as={SiGithub} boxSize={5}/>
                             <Icon as={ImCloudCheck} boxSize={5}/>
                         </>
						}
					</HStack>
				</Flex>

				<Flex
					flexDirection={'column'}
					alignItems="center"
					m={'auto'}
					fontWeight={isFirst ? 'bold' : ''}
					color={getColorBasedOnStatus(submissionModel.matchStatus)}>

					<HStack alignItems="center" align={'center'} margin={'auto'}>
						{isFailedStatus(submissionModel.matchStatus) &&
                         <Icon as={AiOutlineWarning} boxSize={5}/>
						}
						{isSuccessStatus(submissionModel.matchStatus) &&
                         <Icon as={ImCheckmark} boxSize={5}/>
						}
						{isPendingStatus(submissionModel.matchStatus) &&
                         <Spinner/>
						}
						<Text align={'center'}>
							{submissionModel.matchStatus}
						</Text>
					</HStack>
				</Flex>
				<Flex flexDirection={'row'} align={'center'} margin={'auto'}>
					<Button
						variant="solid"
						colorScheme="navigation"
						px={5}
						textAlign={'center'}
						onClick={() => handleMatchLogDownload(submissionModel)}
						leftIcon={submissionModel.matchDate !== null ? <FiDownload/> : <AiOutlineWarning/>}
						disabled={submissionModel.matchDate === null || isPendingStatus(submissionModel.matchStatus)}
						isLoading={isMatchLogDownloading && submissionModel.botId === submissionToDownload?.botId}
						loadingText={'Downloading'}>
						Match Log
					</Button>
					&nbsp;
					<Button
						variant="solid"
						colorScheme="navigation"
						px={5}
						textAlign={'center'}
						onClick={() => handleBotLogDownload(submissionModel)}
						leftIcon={<FaRobot/>}
						disabled={submissionModel.matchId === null || isPendingStatus(submissionModel.matchStatus)}
						isLoading={isBotLogDownloading && submissionModel.botId === submissionToDownload?.botId}
						loadingText={'Downloading'}>
						Bot Log
					</Button>
					&nbsp;
					<Button
						leftIcon={<AiOutlineEye/>}
						textAlign={'center'}
						variant="outline"
						colorScheme="navigation"
						disabled={true}>
						Visualize
					</Button>
					&nbsp;
					<IconButton
						icon={<CgDetailsMore/>}
						textAlign={'center'}
						variant="outline"
						colorScheme="navigation"
						onClick={() => handleMoreDetailsClicked(index, onOpen)}
						aria-label={'More details'}/>
				</Flex>
			</>
		);
	};

	useEffect(() => {
		handleRefreshSubmissions();
	}, [handleRefreshSubmissions]);

	const { isOpen, onOpen, onClose } = useDisclosure();

	if (isSubmissionsLoading && isInitialLoad) {
		return (
			<IntermediateMessageLoader loadingMessage={'Fetching your submissions'} />
		);
	}

	return (
		<>
			<SimpleGrid columns={2}>
				<Box>
					<Heading
						fontSize={'2xl'}
						color={'navigation.800'}
						colorScheme="accent">
						Submission History
					</Heading>
				</Box>

				<Box>
					<IconButton
						float={'right'}
						mx={5}
						colorScheme={'navigation'}
						aria-label={'Refresh submissions'}
						icon={<FiRefreshCw/>}
						onClick={handleRefreshSubmissions}
						isLoading={isSubmissionsLoading}
					/>
				</Box>
			</SimpleGrid>

			<Box overflowX={isMobile ? 'scroll' : 'hidden'}>
				{getSubmissionHistory(onOpen, isOpen, onClose)}
			</Box>

			<Spacer/>
			<Divider mb={5}/>
			<Center position={'absolute'} bottom={0} mb={5} w={'100%'}>
				<IconButton
					variant="solid"
					colorScheme="navigation"
					textAlign={'center'}
					onClick={() => setCurrentPage(currentPage - 1)}
					icon={<FaChevronLeft/>}
					disabled={currentPage === 0}
					aria-label={'previous page'}/>
				<Text mx={5} fontSize={'1em'} fontWeight={'semibold'}>
					Page: {displayPageNumber} of {totalPages}
				</Text>
				<IconButton
					variant="solid"
					colorScheme="navigation"
					textAlign={'center'}
					icon={<FaChevronRight/>}
					onClick={() => setCurrentPage(currentPage + 1)}
					disabled={submissions.length <= (currentPage + 1) * submissionsPerPage}
					aria-label={'next page'}/>
			</Center>
		</>

	);
};
