import { Badge, Box, Container, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {playerHaveEntry, playerIsEntelectEmployee} from "../../atoms/player.atom";
import {getSettingAsBoolean, SETTING_NAMES_ENTELECT_CHALLENGE} from "../../atoms/settings.atom";
import {EntryType} from "../../models/entry.model";
import {EntelectChallengeLeaderboards} from "./leaderboard/EntelectChallengeLeaderboards";
import {EntelectChallengeSubmissions} from "./submissions/EntelectChallengeSubmissions";
import {useLocation} from "react-router-dom";
import {EntelectChallengeMatches} from "./matches/EntelectChallengeMatches";

interface IEntelectChallengeHome {
}

export const EntelectChallengeHome: React.FunctionComponent<IEntelectChallengeHome> = props => {
    const history = useHistory();

    const enabledEntelectChallenge = useRecoilValue(getSettingAsBoolean(SETTING_NAMES_ENTELECT_CHALLENGE.ENABLED));
    const haveMainEntry = useRecoilValue(playerHaveEntry(EntryType.MAIN));
    const isEmployee = useRecoilValue(playerIsEntelectEmployee);
    const location = useLocation();

    const [tabIndex, setTabIndex] = React.useState(0);
    let query = useQuery();

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    useEffect(() => {
        if (enabledEntelectChallenge && !isEmployee) {
            if (!haveMainEntry) {
                history.push(`/portal/entelect-challenge/register`)
            }
        } else {
            history.push(`/portal`);
        }
        const route = location.pathname.split("/").pop();
        if(query.get("tournament") || route === "leaderboards") {
            handleTabsChange(1)
        }
    }, [enabledEntelectChallenge, haveMainEntry, history, isEmployee, query, location])

    const handleTabsChange = (index: number) => {
        setTabIndex(index);
    }

    return (
        <>
            {/*{countdownDate < new Date() &&*/}
            <Box pt={'3em'}>
                <Container maxWidth={'container.xl'}>

                    <Tabs
                        index={tabIndex}
                        isFitted
                        isLazy
                        variant='line'
                        onChange={handleTabsChange}>

                        <TabList mb='1em'>
                            <Tab>
                                Submissions
                            </Tab>
                            <Tab>
                                Leaderboards
                            </Tab>
                            <Tab>
                                Friendlies
                                <Box display="inline-block" position="relative" left="1em" top="-1em">
                                    <Badge colorScheme="green">NEW</Badge>
                                </Box>
                            </Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel minH={"60vh"} position={"relative"} pb={20}>
                                <EntelectChallengeSubmissions/>
                            </TabPanel>
                            <TabPanel>
                                <EntelectChallengeLeaderboards/>
                            </TabPanel>
                            <TabPanel>
                                <EntelectChallengeMatches/>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Container>
            </Box>
        </>
    )
};
