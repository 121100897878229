import {
    Alert,
    AlertIcon,
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Radio,
    RadioGroup,
    Select,
    Stack,
    useToast
} from "@chakra-ui/react";
import {Field, Form, Formik} from "formik";
import {FieldAttributes} from "formik/dist/Field";
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from "recoil";
import * as Yup from "yup";
import {PlayerApi} from "../../../api/player.api";
import {playerHaveEntry, playerIsEntelectEmployee} from "../../../atoms/player.atom";
import {registrationInfoMainAtom} from "../../../atoms/registration-info.atom";
import {EntryType} from "../../../models/entry.model";
import RegistrationInfoMainModel from "../../../models/registration-info-main.model";
import {IntermediateGlobalLoading} from "../../../shared/loading/IntermediateGlobalLoading";
import {southAfricanIdNumberPattern} from "../../../utils/constants";
import {SettingItemSection} from "../SettingItemSection";

interface IRegistrationInfo {
}

export const RegistrationInfo: React.FunctionComponent<IRegistrationInfo> = _ => {
    const [registrationInfoMainLoading, setRegistrationInfoMainLoading] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

    const toast = useToast();

    const isEmployee = useRecoilValue(playerIsEntelectEmployee);
    const haveEntelectChallengeEntry = useRecoilValue(playerHaveEntry(EntryType.MAIN));
    const haveUniversityEntry = useRecoilValue(playerHaveEntry(EntryType.UNIVERSITY));
    const haveIITPSAEntry = useRecoilValue(playerHaveEntry(EntryType.IITPSA));
    const haveCompanyEntry = useRecoilValue(playerHaveEntry(EntryType.COMPANY));
    const [mainRegistrationInfo, setMainRegistrationInfo] = useRecoilState(registrationInfoMainAtom);

    const defaultIdType = 'ID';
    const defaultIdNumberPlaceholder = `Your ${defaultIdType} number`;

    const [selectedIdType, setSelectedIdType] = useState<string>(defaultIdType);
    const [idNumberPlaceHolder, setIdNumberPlaceHolder] = useState<string>(defaultIdNumberPlaceholder);

    const registrationInfoValidation = Yup.object().shape({
        selectedIdType: Yup.string(),

        playerIdType: Yup.string()
            .required('Required'),
        playerIdNumber: Yup.string()
            .when('playerIdType', {
                is: 'ID',
                then: Yup.string()
                    .required()
                    .length(13, 'Incorrect ID length')
                    .matches(southAfricanIdNumberPattern, 'Invalid SA ID number'),
                otherwise: Yup.string()
                    .required()
                    .min(5, 'Passport number must be greater than 5 characters')
            }),
        contactNumber: Yup.string()
            .length(10, 'Incorrect number length')
            .required('Required'),
        occupation: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        experienceLevel: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        challengeFoundSource: Yup.string()
            .required('Required'),
    });

    const handleIdTypeChange = (value: string) => {
        setSelectedIdType(value);
        const placeholder = `Your ${value} number`;

        setIdNumberPlaceHolder(placeholder)
    }

    const updateMainInfo = (model: RegistrationInfoMainModel) => {
        model.playerId = mainRegistrationInfo.playerId;
        model.mainRegistrationInfoId = mainRegistrationInfo.mainRegistrationInfoId;

        setRegistrationInfoMainLoading(true);

        PlayerApi.updatePlayerRegistrationInfo(model)
            .then(result => {
                setRegistrationInfoMainLoading(false);
                toast({
                    title: `Updated your registration info.`,
                    position: 'top-right',
                    status: 'success',
                });
            })
            .catch(reason => {
                //console.error(reason);
                setRegistrationInfoMainLoading(false);
                toast({
                    title: `Registration info update failed`,
                    position: 'top-right',
                    status: 'error',
                });
            });
    };

    useEffect(() => {
        PlayerApi.getPlayerMainRegistrationInfo()
            .then(result => {
                let data = result.data;
                let mappedResult = {
                    ...data
                } as any
                let key: keyof typeof data
                for (key in data) {
                    if (data[key] == null) {
                        mappedResult[key] = ""
                    }
                }
                setMainRegistrationInfo(mappedResult as RegistrationInfoMainModel)
                setIsInitialLoad(false);
            })
            .catch(reason => {
                //console.error(reason);
                setIsInitialLoad(false);
            });
    }, [setMainRegistrationInfo, setIsInitialLoad]);

    if (isInitialLoad) {
        return (
            <IntermediateGlobalLoading displayWithoutText={true} isNotFull={true}/>
        );
    }

    const hasNoCupEntries = (): boolean => {
        return !haveEntelectChallengeEntry && !haveUniversityEntry && !haveCompanyEntry && !haveIITPSAEntry;
    }

    return (
        <Box>
            {hasNoCupEntries() &&
                <SettingItemSection heading={'Registration info'} colSpan={10}>
                    <Alert status="info">
                        <AlertIcon/>
                        You currently don't have any registration information to edit. Please register for a cup to edit
                        this information.
                    </Alert>
                </SettingItemSection>
            }
            {
                (haveEntelectChallengeEntry && !isEmployee) &&
                <SettingItemSection
                    heading={'Entelect Challenge cup info'}
                    subTexts={['You can update your information here regarding the Entelect Challenge cup.']}
                >
                    <Formik
                        initialValues={mainRegistrationInfo}
                        onSubmit={(values) => updateMainInfo(values)}
                        isInitialValid={false}
                        validationSchema={registrationInfoValidation}
                        validateOnBlur={true}
                        validateOnChange={true}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <Box mb={4}>
                                    <FormControl isRequired>
                                        <FormLabel>ID Type</FormLabel>

                                        <RadioGroup name={'playerIdType'}
                                                    value={values.playerIdType}
                                                    onChange={(selectedValue) => {
                                                        setFieldValue('playerIdType', selectedValue);
                                                        handleIdTypeChange(selectedValue);
                                                    }}>

                                            <Stack spacing={5} direction={'row'}>
                                                <Radio colorScheme={'primary'} value={'ID'}>RSA ID</Radio>
                                                <Radio colorScheme={'primary'} value={'Passport'}>Passport</Radio>
                                            </Stack>

                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                <Field name={'playerIdNumber'}>
                                    {({ field, form }: FieldAttributes<any>) => (
                                        <Box mb={4}>
                                            <FormControl id="playerIdNumber" isRequired isInvalid={form.errors.playerIdNumber && form.touched.playerIdNumber}>
                                                <FormLabel>
                                                    {selectedIdType} Number
                                                </FormLabel>
                                                <Input
                                                    colorScheme={'primary'}
                                                    {...field}
                                                    id={'playerIdNumber'}
                                                    type="text"
                                                    size="md"
                                                    variant="filled"
                                                    placeholder={idNumberPlaceHolder}/>
                                                <FormErrorMessage>{form.errors.playerIdNumber}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                    )}
                                </Field>
                                <Field name={'contactNumber'}>
                                    {({ field, form }: FieldAttributes<any>) => (
                                        <Box mb={4}>
                                            <FormControl id="contactNumber" isRequired isInvalid={form.errors.contactNumber && form.touched.contactNumber}>
                                                <FormLabel>Contact Number</FormLabel>
                                                <Input
                                                    colorScheme={'primary'}
                                                    {...field}
                                                    id={'contactNumber'}
                                                    type="text"
                                                    size="md"
                                                    variant="filled"
                                                    placeholder={'Your contact number'}/>
                                                <FormErrorMessage>{form.errors.contactNumber}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                    )}
                                </Field>
                                <Field name={'occupation'}>
                                    {({ field, form }: FieldAttributes<any>) => (
                                        <Box mb={4}>
                                            <FormControl id="occupation" isRequired isInvalid={form.errors.occupation && form.touched.occupation}>
                                                <FormLabel>Occupation</FormLabel>
                                                <Select variant={'filled'} colorScheme={'primary'} {...field} placeholder="Select Occupation" id={'occupation'}
                                                        value={values.occupation}>
                                                    <option value={'student'}>Student</option>
                                                    <option value={'software_engineer'}>Software Engineer</option>
                                                    <option value={'user_experience_engineer'}>User Experience Engineer</option>
                                                    <option value={'business_analyst'}>Business Analyst</option>
                                                    <option value={'test_analyst'}>Test analyst</option>
                                                    <option value={'data_analyst'}>Data analyst</option>
                                                    <option value={'team_project_lead'}>Team/Project lead</option>
                                                    <option value={'tech_lead'}>Tech lead</option>
                                                    <option value={'recruitment'}>Recruitment</option>
                                                    <option value={'administration'}>Administration</option>
                                                    <option value={'delivery_manager'}>Delivery manager</option>
                                                    <option value={'account_manager'}>Account manager</option>
                                                    <option value={'general_manager'}>General manager</option>
                                                </Select>
                                                <FormErrorMessage>{form.errors.occupation}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                    )}
                                </Field>
                                <Field name={'experienceLevel'}>
                                    {({ field, form }: FieldAttributes<any>) => (
                                        <Box my={4}>
                                            <FormControl
                                                id="occupation"
                                                isRequired
                                                isInvalid={form.errors.experienceLevel && form.touched.experienceLevel}>
                                                <FormLabel>Experience Level</FormLabel>
                                                <Select variant={'filled'} colorScheme={'primary'} {...field} placeholder="Select Experience Level" id={'experienceLevel'}
                                                        value={values.experienceLevel}>
                                                    <option value={'graduate'}>Graduate</option>
                                                    <option value={'standard'}>Standard</option>
                                                    <option value={'intermediate'}>Intermediate</option>
                                                    <option value={'senior'}>Senior</option>
                                                </Select>
                                                <FormErrorMessage>{form.errors.experienceLevel}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                    )}
                                </Field>
                                <Field name={'challengeFoundSource'}>
                                    {({ field, form }: FieldAttributes<any>) => (
                                        <Box mb={4}>
                                            <FormControl id="challengeFoundSource" isRequired isInvalid={form.errors.challengeFoundSource && form.touched.challengeFoundSource}>
                                                <FormLabel>How did you find us? </FormLabel>
                                                <Select variant={'filled'} colorScheme={'primary'} {...field} placeholder="Select option" id={'challengeFoundSource'}
                                                        value={values.challengeFoundSource}>
                                                    <option value={'friends'}>Friends</option>
                                                    <option value={'google'}>Google</option>
                                                    <option value={'google'}>Email</option>
                                                    <option value={'other'}>Other</option>
                                                </Select>
                                                <FormErrorMessage>{form.errors.challengeFoundSource}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                    )}
                                </Field>
                                <Field name={'hadCompetedPreviously'}>
                                    {({ field }: FieldAttributes<any>) => (
                                        <Box mb={4}>
                                            <FormControl id="hadCompetedPreviously">
                                                <FormLabel fontWeight={'medium'}>
                                                    Have you competed in previous years?
                                                </FormLabel>
                                                <Select variant={'filled'} colorScheme={'primary'} {...field} id={'hadCompetedPreviously'}
                                                        value={values.hadCompetedPreviously}>
                                                    <option value={'true'}>Competed Previously</option>
                                                    <option value={'false'}>Have Not Competed Previously</option>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    )}
                                </Field>
                                <Button
                                    type={'submit'}
                                    colorScheme={'primary'}
                                    isLoading={registrationInfoMainLoading}
                                    loadingText={'Updating registration info'}>
                                    Update registration info
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </SettingItemSection>
            }
            {
                (haveUniversityEntry && !isEmployee) &&
                <SettingItemSection heading={'University cup info'} colSpan={10}>
                    <Alert status="info">
                        <AlertIcon/>
                        Update registration information coming soon
                    </Alert>
                </SettingItemSection>
            }
            {
                (haveIITPSAEntry && !isEmployee) &&
                <SettingItemSection heading={'IITPSA cup info'} colSpan={10}>
                    <Alert status="info">
                        <AlertIcon/>
                        Update registration information coming soon
                    </Alert>
                </SettingItemSection>
            }
            {
                (haveCompanyEntry && isEmployee) &&
                <SettingItemSection heading={'Company cup info'} colSpan={10}>
                    <Alert status="info">
                        <AlertIcon/>
                        Update registration information coming soon
                    </Alert>
                </SettingItemSection>
            }
        </Box>
    )
};
