import {useMediaQuery} from "@chakra-ui/react";

export function useScaleRatio(values: number[]): number {
    const [is125] = useMediaQuery("(-webkit-min-device-pixel-ratio: 1.25)");

    return is125 ? values[1] : values[0];
}

export function useScaleRationBoolean(values: boolean[]): boolean {
    const [is125] = useMediaQuery("(-webkit-min-device-pixel-ratio: 1.25)");

    return is125 ? values[1] : values[0];
}