import * as React from 'react';
import { Box } from "@chakra-ui/react";
import {WikiSection} from "../WikiSection";
import {WikiSubSection} from "../WikiSubSection";
import {Text} from "@chakra-ui/layout";

interface IWikiSectionNoHackathonTeam {}

export const WikiSectionNoHackathonTeam: React.FunctionComponent<IWikiSectionNoHackathonTeam> = props => {
    return (
        <Box mb={10}>
            <WikiSection heading={'No hackathon team'} >
                <WikiSubSection heading={'Enable orphan members'}>
                    <Text color={'primary.800'}>When this is enabled, you allow your team to accept players assigned to your team via the Entelect Challenge hackathon admins. Players that will be assigned to your team are players that don't have a team, but really want to compete in a team.</Text>
                </WikiSubSection>
                <WikiSubSection heading={'Place me in a team'}>
                    <Text color={'primary.800'}>
                        You can choose this option at the hackathon team creation page. This will allow the Entelect Challenge hackathon admins to assign you to a random team that are willing to accept random players. You can still create or join a team after you have selected this option. This will let us know you have found a team.
                    </Text>
                </WikiSubSection>
            </WikiSection>
        </Box>
    )
};
