import { Alert, AlertIcon, Box, Button, Center, Divider, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightAddon, PopoverAnchor, useBreakpointValue, VStack } from "@chakra-ui/react";
import { Field, Form, Formik, useFormik } from "formik";
import { FieldAttributes } from "formik/dist/Field";
import React, { FunctionComponent, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { AuthenticationApi } from "../../api/authentication.api";
import PasswordResetConfirmationRequestModel from "../../models/password-reset-confirmation-request.model";
import PasswordResetConfirmationModel from "../../models/password-reset-confirmation.model";
import PasswordResetRequestModel from "../../models/password-reset-request.model";
import { FancyHeading } from "../../shared/FancyHeading";
import { SideBarComponent } from "../../shared/SideBarComponent";
import * as Yup from "yup";
import { emailRegex, numericalRegexPattern } from "../../utils/constants";
import { PasswordRequirementsPopover } from "./PasswordRequirementsPopover";
import { usePasswordRequirements } from "../../utils/hooks/use-password-validation";
import { initialValues, validationSchema } from "./reset-password-confirmation-form.functions";

interface IPasswordReset {

}

export const PasswordReset: FunctionComponent<IPasswordReset> = props => {
    const history = useHistory();

    const [hasAskedToBeReset, setHasAskedToBeReset] = useState<boolean>(false);
    const [isPasswordRequestLoading, setIsPasswordRequestLoading] = useState<boolean>(false);
    const [isPasswordConfirmationRequestLoading, setIsPasswordConfirmationRequestLoading] = useState<boolean>(false);
    const [username, setUsername] = useState<string>('');
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);
    const [successfulResetConfirmationText, setSuccessfulResetConfirmationText] = useState<string>('');
    const [errorText, setErrorText] = useState<string>('');

    const popoverParentRef = useRef(null);
    const {passwordRequirements, handlePasswordRequirementsChange} = usePasswordRequirements();
    const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});

    const passwordResetRequestInitialValues = {
        username: ''
    } as PasswordResetRequestModel

    const passwordResetRequestValidationSchema = Yup.object().shape({
        username: Yup.string()
            .required('Required')
            .matches(emailRegex, 'Invalid email address'),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema(),
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: (model: PasswordResetConfirmationModel) => handleResetPasswordConfirmation(model)
    });

    const handleResetPasswordRequest = (values: PasswordResetRequestModel) => {
        setIsPasswordRequestLoading(true);
        setUsername(values.username);
        setErrorText('');

        AuthenticationApi.requestPasswordReset({username: values.username})
            .then(result => {
                //console.log('Password request result: ', result.data);
                setIsPasswordRequestLoading(false);
                setHasAskedToBeReset(true);
            })
            .catch(reason => {
                setIsPasswordRequestLoading(false);
                setErrorText('An error occurred when trying to request a reset code. Please verify your email is correct');
            })
    }

    const handleResetCodeKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!numericalRegexPattern.test(event.key)) {
            event.preventDefault();
        }
    }

    const onPasswordChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
        handlePasswordRequirementsChange(changeEvent);
        formik.handleChange(changeEvent);
    };

    const handleResetPasswordConfirmation = (values: PasswordResetConfirmationModel) => {
        setIsPasswordConfirmationRequestLoading(true);
        setSuccessfulResetConfirmationText('');
        setErrorText('');

        const passwordResetConfirmationRequestModel = {
            username,
            password: values.password,
            confirmationCode: values.resetCode
        } as PasswordResetConfirmationRequestModel

        AuthenticationApi.requestPasswordResetConfirmation(passwordResetConfirmationRequestModel)
            .then(result => {
                //console.log('Password confirmation result: ', result.data);
                setIsPasswordConfirmationRequestLoading(false);
                setSuccessfulResetConfirmationText('Password has successfully been reset. You will be redirected soon');

                setTimeout(() => {
                    history.push(`/signin`);
                }, 3000);
            })
            .catch(reason => {
                setIsPasswordConfirmationRequestLoading(false);
                setErrorText('An error occurred when trying to reset your password. Please verify your reset code & password are correct');
            })
    }

    const showPasswordResetRequestForm = () => {
        return (
            <>
                <Box>
                    <Formik
                        initialValues={passwordResetRequestInitialValues}
                        onSubmit={(values) => handleResetPasswordRequest(values)}
                        validationSchema={passwordResetRequestValidationSchema}>

                        {({values}) => (
                            <Form>
                                <Field name={'username'}>
                                    {({field, form}: FieldAttributes<any>) => (
                                        <Box mb={4}>
                                            <FormControl
                                                id="username"
                                                isRequired
                                                isInvalid={form.errors.username && form.touched.username}>

                                                <FormLabel>Email Address</FormLabel>

                                                <Input
                                                    {...field}
                                                    colorScheme={'primary'}
                                                    id={'username'}
                                                    type="text"
                                                    size="md"
                                                    variant="filled"
                                                    placeholder={'Email address'}
                                                />

                                                <FormErrorMessage>{form.errors.username}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                    )}
                                </Field>

                                <Button
                                    w={'100%'}
                                    type={'submit'}
                                    colorScheme={'primary'}
                                    mt={2}
                                    isLoading={isPasswordRequestLoading}
                                    loadingText={'Requesting password reset code...'}
                                >
                                    Request Password Reset
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Box>
                {
                    errorText.length !== 0 &&
                    <Box>
                        <Alert status="error" variant="left-accent" borderRadius={'md'}>
                            <AlertIcon/>
                            {errorText}
                        </Alert>
                    </Box>
                }
            </>
        )
    }

    const showPasswordConfirmationForm = () => {
        return (
            <>
                <Box>
                    <form>

                        <Box mb={4}>
                            <Box mb={2}>
                                <Alert status="info" variant="left-accent" borderRadius={'md'}>
                                    <AlertIcon/>
                                    Please check your email ({username}) for the password reset code
                                </Alert>
                            </Box>

                            <FormControl
                                id="resetCode"
                                isRequired
                                isInvalid={formik.touched.resetCode && Boolean(formik.errors.resetCode)}>

                                <FormLabel>Reset Code</FormLabel>

                                <Input
                                    colorScheme={'primary'}
                                    id={'resetCode'}
                                    type="text"
                                    value={formik.values.resetCode}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    size="md"
                                    variant="filled"
                                    placeholder={'Reset code'}
                                    autoComplete={"reset-code"}
                                    onKeyPress={handleResetCodeKeyPress}
                                />

                                <FormErrorMessage>{formik.errors.resetCode}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb={4}>
                            <FormControl
                                id="password"
                                isRequired
                                isInvalid={formik.touched.password && Boolean(formik.errors.password)}>

                                <FormLabel>New Password</FormLabel>

                                <PasswordRequirementsPopover
                                    passwordRequirements={passwordRequirements}
                                    placement={isMobile ? 'bottom' : 'right'}
                                    children={
                                        <>
                                            <PopoverAnchor>
                                                <InputGroup colorScheme={'primary'}>
                                                    <Input
                                                        colorScheme={'primary'}
                                                        id={'password'}
                                                        name={'password'}
                                                        type={showNewPassword ? 'text' : 'password'}
                                                        value={formik.values.password}
                                                        onBlur={formik.handleBlur}
                                                        onChange={onPasswordChange}
                                                        size="md"
                                                        variant="filled"
                                                        placeholder={'Password'}
                                                        autoComplete={"new-password"}
                                                        ref={popoverParentRef}
                                                    />

                                                    <InputRightAddon borderWidth={0} paddingX={1} children={
                                                        <Button
                                                            colorScheme={'primary'}
                                                            variant={'ghost'}
                                                            size="sm"
                                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                                        >
                                                            {showNewPassword ? 'HIDE' : 'SHOW'}
                                                        </Button>
                                                    }/>
                                                </InputGroup>
                                            </PopoverAnchor>
                                        </>
                                    }
                                />

                                <FormErrorMessage>{formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box mb={4}>
                            <FormControl
                                id={'passwordConfirmation'}
                                isRequired
                                isInvalid={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                            >
                                <FormLabel>Confirm New Password</FormLabel>

                                <InputGroup colorScheme={'primary'}>
                                    <Input
                                        colorScheme={'primary'}
                                        id={'passwordConfirmation'}
                                        name={'passwordConfirmation'}
                                        pr='4.5rem'
                                        type={showPasswordConfirmation ? 'text' : 'password'}
                                        value={formik.values.passwordConfirmation}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        size="md"
                                        variant="filled"
                                        placeholder={'Confirm Password'}
                                        autoComplete={"new-confirmation-password"}
                                    />

                                    <InputRightAddon borderWidth={0} paddingX={1} children={
                                        <Button
                                            colorScheme={'primary'}
                                            variant={'ghost'}
                                            size="sm"
                                            onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                                        >
                                            {showPasswordConfirmation ? 'HIDE' : 'SHOW'}
                                        </Button>
                                    }/>
                                </InputGroup>

                                <FormErrorMessage>{formik.errors.passwordConfirmation}</FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Button
                            w={'100%'}
                            type={'submit'}
                            colorScheme={'primary'}
                            mt={2}
                            isLoading={isPasswordConfirmationRequestLoading}
                            loadingText={'Resetting password...'}
                            disabled={!formik.isValid || isPasswordConfirmationRequestLoading}
                            onClick={formik.submitForm}
                        >
                            Reset Password
                        </Button>
                    </form>
                </Box>
                {
                    successfulResetConfirmationText.length !== 0 &&
                    <Box>
                        <Alert status="success" variant="left-accent" borderRadius={'md'}>
                            <AlertIcon/>
                            {successfulResetConfirmationText}
                        </Alert>
                    </Box>
                }
                {
                    successfulResetConfirmationText.length === 0 && errorText.length !== 0 &&
                    <Box>
                        <Alert status="error" variant="left-accent" borderRadius={'md'}>
                            <AlertIcon/>
                            {errorText}
                        </Alert>
                    </Box>
                }
            </>
        )
    }

    return (
        <SideBarComponent>
            <Center h={'100%'}>
                <VStack w={'100%'} spacing={5} align={'stretch'}>
                    <FancyHeading heading={'Reset Password'} headingSize={'3xl'}/>

                    <Box>
                        <Divider/>
                    </Box>

                    {
                        !hasAskedToBeReset
                            ? showPasswordResetRequestForm()
                            : showPasswordConfirmationForm()
                    }

                </VStack>
            </Center>
        </SideBarComponent>
    )
}
