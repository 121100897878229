import {atom, RecoilState} from "recoil";
import HackathonTournamentModel from "../models/hackathon-tournament.model";
import { EntryType } from "../models/entry.model";

export const hackathonTournamentAtom: RecoilState<HackathonTournamentModel> = atom<HackathonTournamentModel>({
    key: 'tournament_atom',
    default: {
      hackathonTournamentId: '',
      enabled: false,
      enabledStreaming: false,
      scorerIdentifier: '',
      communicationsLink: '',
      friendlyName: '',
      entryType: EntryType.COMPANY,
      hackathonDate: new Date(),
      solutionOpenDate: new Date(),
      solutionCloseDate: new Date(),
      leaderboardUnfreeze: false,
      leaderboardOpenDate: new Date(),
      leaderboardCloseDate: new Date(),
      leaderboardFreezeDate: new Date(),
    } as HackathonTournamentModel
});
