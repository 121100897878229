import * as React from 'react';
import {Box, Grid, GridItem, useColorMode} from "@chakra-ui/react";
import {SettingItem} from "./SettingItem";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import {Profile} from "./settings-sections/Profile";
import {AccessKeys} from "./settings-sections/AccessKeys";
import {Account} from "./settings-sections/Account";
import {RegistrationInfo} from "./settings-sections/RegistrationInfo";
import {Notifications} from "./settings-sections/Notifications";
import {Appearance} from "./settings-sections/Appearance";
import {Tournaments} from "./settings-sections/Tournaments";
import {CICDFile} from "./settings-sections/CicdFile";
import {useRecoilValue} from "recoil";
import {playerIsEntelectEmployee} from "../../atoms/player.atom";
import {GladosContainer} from "../../shared/GladosContainer";

interface ISettings {
}

export const Settings: React.FunctionComponent<ISettings> = props => {
    const {colorMode} = useColorMode();
    const {path, url} = useRouteMatch();

    const isEmployee = useRecoilValue(playerIsEntelectEmployee);

    return (
        <GladosContainer>
            <Grid templateColumns={'repeat(12, 1fr)'} h={'100%'}>
                <GridItem colSpan={2}/>
                <GridItem colSpan={8}>
                    <Box mt={50}>
                        <Grid templateColumns="repeat(24, 1fr)" gap={10}>
                            <GridItem colSpan={5}>
                                <Box borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'} borderRadius={'md'}
                                     borderWidth={'1px'}>
                                    <Box
                                        px={4}
                                        py={2}
                                        borderBottomColor={colorMode === 'light' ? 'gray.200' : 'gray.600'}
                                        borderBottomWidth={'1px'}
                                        fontWeight={'bold'}
                                        color={colorMode === 'light' ? 'gray.800' : 'gray.400'}
                                    >Account Settings</Box>
                                    <SettingItem name={'Profile'} path={`${url}/profile`}/>
                                    <SettingItem name={'Registration Info'} path={`${url}/registration-info`}/>
                                    {/*<SettingItem name={'Account'} path={`${url}/account`}/>*/}
                                    {
                                        !isEmployee &&
                                        <SettingItem name={'Tournaments'} path={`${url}/tournaments`}/>
                                    }
                                    <SettingItem name={'Notifications'} path={`${url}/notifications`} new={true}/>
                                    {
                                        !isEmployee &&
                                        <SettingItem name={'Access Keys'} path={`${url}/access-keys`}/>
                                    }
                                    {
                                        !isEmployee &&
                                        <SettingItem name={'CI/CD Template'} path={`${url}/cicd-template`}/>
                                    }
                                    <SettingItem name={'Appearance'} path={`${url}/appearance`} last={true} new={true}/>
                                </Box>
                            </GridItem>
                            <GridItem colSpan={19}>
                                <Switch>
                                    <Route path={`${path}/profile`}>
                                        <Profile/>
                                    </Route>
                                    <Route path={`${path}/account`}>
                                        <Account/>
                                    </Route>
                                    <Route path={`${path}/access-keys`}>
                                        <AccessKeys/>
                                    </Route>
                                    <Route path={`${path}/registration-info`}>
                                        <RegistrationInfo/>
                                    </Route>
                                    <Route path={`${path}/notifications`}>
                                        <Notifications/>
                                    </Route>
                                    <Route path={`${path}/appearance`}>
                                        <Appearance/>
                                    </Route>
                                    <Route path={`${path}/tournaments`}>
                                        <Tournaments/>
                                    </Route>
                                    <Route path={`${path}/cicd-template`}>
                                        <CICDFile/>
                                    </Route>
                                </Switch>
                            </GridItem>
                        </Grid>

                    </Box>

                </GridItem>
            </Grid>
        </GladosContainer>
    );
};
