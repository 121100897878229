export const finalists2014 = [
    {
        id:1,
        finalist: 'Pierre Pretorius',
        image: 'pierre'
    },
    {
        id:2,
        finalist: 'Bernhard Häussermann',
        image: 'bernhard_haussermann'
    },
    {
        id:3,
        finalist: 'Rethabile Mokoena',
        image: 'rethabile_mokoena'
    },
    {
        id:4,
        finalist: 'Gregory Doukas',
        image: 'gregory_doukas'
    },
    {
        id:5,
        finalist: 'Nicholas Scheltema',
        image: 'nicholas_scheltema'
    },
    {
        id:6,
        finalist: 'Ruan Liebenberg',
        image: 'ruan_liebenberg'
    },
    {
        id:7,
        finalist: 'Raymond Claassen',
        image: 'raymond_claasen'
    },
    {
        id:8,
        finalist: 'Lindsay Hans',
        image: 'lindsay_hans'
    }
]