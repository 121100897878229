import { AspectRatio, Box, Button, Divider, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Square, Text, Tooltip, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import bottom_image from "../../../assets/images/main/main_bottom_image.png";
import logo from "../../../assets/images/entelect/EntelectChallenge_Logo_With_Flair.svg";
import banner_left_image from "../../../assets/images/main/banner-left.png";
import banner_right_image from "../../../assets/images/main/banner-right.png";
import prizes from "../../../assets/images/main/prizes.svg";
import step_1_image from "../../../assets/images/main/step-1.png";
import step_2_image from "../../../assets/images/main/step-2.png";
import step_3_image from "../../../assets/images/main/step-3.png";
import background from "../../../assets/images/main/main_background.png";
import { getSettingAsMap, SETTING_NAME_UNAUTHENTICATED } from "../../../atoms/settings.atom";
import { gettingStartedAtom } from "../../../atoms/getting-started.atom";
import { GettingStartedAccordionItems } from "../../../models/accordion-items.enum";
import { getCurrentYear } from "../../../utils/hooks/general-utils";
import bug_hunt_image from "../../../assets/images/home/why_are_you_using_the_dev_tools.png";

interface IUnauthenticatedHome {
}

export const UnauthenticatedHome: React.FunctionComponent<IUnauthenticatedHome> = props => {
    let imageMaxWidth = "40em";
    let textColumnMaxWidth = "92em";
    let titleFontSize = 50;
    let subTitleFontSize = 27;
    let minViewH = "50vh";
    let otherTextFontSize = "calc(1.5em + 1vmin)";
    let titleFontColor = "navigation.700";
    let subTitleFontColor = "gray.400";
    let mainFontColor = "navigation.700";
    //let linkColor = 'navigation.500';
    let buttonColor = "navigation.800";
    let secondaryButtonColor = "primary.600";

    const history = useHistory();

    const {isOpen, onOpen, onClose} = useDisclosure();
    const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});
    const entelectChallengeForumLink = process.env.REACT_APP_ENTELECT_CHALLENGE_FORUM;

    const youtubeVideoMap = useRecoilValue(getSettingAsMap(SETTING_NAME_UNAUTHENTICATED.YOUTUBE_VIDEO_MAP));
    const setAccordionIndex = useSetRecoilState(gettingStartedAtom);

    const handleDownloadCiCdNavigation = () => {
        setAccordionIndex(GettingStartedAccordionItems.DownloadCiCdFile);
        history.push("/getting-started");
    };

    return (
        <Box
            my={"1em"}
            background={`url(${background})`}
            backgroundSize={'cover'}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={"center"}
            as="main">
            <Flex flexDirection={"column"}>
                <Square
                    my={"2em"}
                    width={"100%"}
                    maxWidth={textColumnMaxWidth}
                    minH={"42vh"}
                    alignSelf={"center"}
                    flexDirection={"column"}>

                    <Image
                        alignSelf={"center"}
                        htmlWidth={"80%"}
                        maxWidth={imageMaxWidth}
                        objectFit="cover"
                        src={logo}
                        alt={`Entelect Challenge ${getCurrentYear()} Logo`}
                    />

                    {youtubeVideoMap && youtubeVideoMap.get("hasVideo") && youtubeVideoMap.has("youtubeUrl") &&
                        <>
                            <Tooltip label="View last year's trailer video" placement="top">
                                <Button
                                    mt={10}
                                    size={"lg"}
                                    backgroundColor={buttonColor}
                                    colorScheme={"navigation"}
                                    color={"whiteAlpha.900"}
                                    onClick={() => onOpen()}>
                                    TRAILER VIDEO
                                </Button>
                            </Tooltip>
                        </>
                    }
                </Square>

                <Square p={5} shadow="md" borderWidth="0.1em"
                        background={"whiteAlpha.800"}
                        borderRadius={"0.5em"}
                        mb={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                    <Box
                        align={"center"}
                        py={5}
                        width={"80%"}>
                        <Text
                            fontWeight={"bold"}
                            fontSize={titleFontSize}
                            color={titleFontColor}
                            align={"center"}
                            maxWidth={textColumnMaxWidth}
                            colorScheme="primary"
                            as={"span"}>
                            Eager to
                        </Text>
                        <Text
                            fontWeight={"bold"}
                            fontSize={titleFontSize}
                            color={"accent.600"}
                            align={"center"}
                            maxWidth={textColumnMaxWidth}
                            colorScheme="primary"
                            as={"span"}>
                            {" "} get started?
                        </Text>
                    </Box>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        The Entelect Challenge is an annual coding competition where students,
                        professional developers, and enthusiasts develop an intelligent bot to play a game.
                    </Text>
                    <Divider/>
                    {/* <Text */}
                    {/*     fontSize={otherTextFontSize} */}
                    {/*     color={mainFontColor} */}
                    {/*     py={5} */}
                    {/*     width={"80%"} */}
                    {/*     maxWidth={textColumnMaxWidth} */}
                    {/*     align={"center"} */}
                    {/*     colorScheme="primary"> */}
                    {/*     The game for the Entelect Challenge 2024 will be announced soon. */}
                    {/*     <br/> */}
                    {/*     Keep a lookout for the announcement on our social media platforms. */}
                    {/* </Text> */}
                    <Text
                    	fontSize={otherTextFontSize}
                    	color={'accent.600'}
                    	fontWeight={'bold'}
                    	py={5}
                    	width={'80%'}
                    	maxWidth={textColumnMaxWidth}
                    	align={'center'}
                    	colorScheme="primary">
                    	Sproutopia
                    </Text>
                    <Divider/>
                    <Text
                    	fontSize={otherTextFontSize}
                    	color={mainFontColor}
                    	py={5}
                    	width={'80%'}
                    	maxWidth={textColumnMaxWidth}
                    	align={'center'}
                    	colorScheme="primary">
                        The game for the 2024 Entelect Challenge requires players to
                        outsmart and outmaneuver their rivals to cultivate the most land,
                        whilst overcoming the challenging weeds, and utilizing the power-ups to their advantage.
                    </Text>
                </Square>

                {!isMobile &&
                    <Square
                        sx={{width: "80%", height: "0vh"}}
                        maxWidth={textColumnMaxWidth}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                        <Image
                            h={"20em"}
                            objectFit={"contain"}
                            pos="relative" top="-10vw" left="-50%"
                            fit={"contain"}
                            src={banner_left_image}
                        />
                    </Square>
                }

                {!isMobile &&
                    <Square
                        sx={{width: "80%", height: "0vh"}}
                        maxWidth={textColumnMaxWidth}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                        <Image
                            h={"20em"}
                            objectFit={"contain"}
                            pos="relative" top="-10vw" left="50%"
                            fit={"contain"}
                            src={banner_right_image}
                        />
                    </Square>
                }

                <Square p={5} shadow="md" borderWidth="0.1em"
                        sx={{width: "80%"}}
                        background={"whiteAlpha.800"}
                        borderRadius={"0.5em"}
                        my={5}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>

                    <Text
                        fontWeight={"bold"}
                        fontSize={subTitleFontSize}
                        color={subTitleFontColor}
                        align={"center"}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        colorScheme="primary">
                        STEP 1
                    </Text>

                    <Text
                        fontWeight={"bold"}
                        fontSize={titleFontSize}
                        color={titleFontColor}
                        align={"center"}
                        pb={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        colorScheme="primary">
                        Register
                    </Text>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        You will need to register on the Player Portal in order to get started.
                    </Text>
                    <Button
                        m={5}
                        size={"lg"}
                        backgroundColor={buttonColor}
                        colorScheme={"navigation"}
                        color={"whiteAlpha.900"}
                        onClick={() => history.push("/portal")}>
                        PLAYER PORTAL
                    </Button>
                </Square>

                {!isMobile &&
                    <Square
                        sx={{width: "20%", height: "0vh"}}
                        maxWidth={textColumnMaxWidth}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                        <Image
                            w={"80vw"}
                            objectFit={"contain"}
                            pos="relative" top="0" left="-35vw"
                            fit={"contain"}
                            src={step_1_image}
                        />
                    </Square>
                }

                <Square p={5} shadow="md" borderWidth="0.1em"
                        background={"whiteAlpha.800"}
                        borderRadius={"0.5em"}
                        my={5}
                        sx={{width: "80%"}}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>

                    <Text
                        fontWeight={"bold"}
                        fontSize={subTitleFontSize}
                        color={subTitleFontColor}
                        align={"center"}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        colorScheme="primary">
                        STEP 2
                    </Text>

                    <Text
                        fontWeight={"bold"}
                        fontSize={titleFontSize}
                        color={titleFontColor}
                        align={"center"}
                        pb={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        colorScheme="primary">
                        Download the CI/CD file
                    </Text>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Follow the instructions in the getting started page to create a GitHub account.
                    </Text>
                    <Button
                        m={5}
                        size={"lg"}
                        backgroundColor={buttonColor}
                        colorScheme={"navigation"}
                        color={"whiteAlpha.900"}
                        onClick={handleDownloadCiCdNavigation}>
                        DOWNLOAD CI/CD
                    </Button>
                </Square>

                {!isMobile &&
                    <Square
                        sx={{width: "20%", height: "0vh"}}
                        maxWidth={textColumnMaxWidth}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                        <Image
                            w={"80vw"}
                            objectFit={"contain"}
                            pos="relative" top="0" left="35vw"
                            fit={"contain"}
                            src={step_2_image}
                        />
                    </Square>
                }

                <Square p={5} shadow="md" borderWidth="0.1em"
                        background={"whiteAlpha.800"}
                        borderRadius={"0.5em"}
                        my={5}
                        sx={{width: "80%"}}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                    <Text
                        fontWeight={"bold"}
                        fontSize={subTitleFontSize}
                        color={subTitleFontColor}
                        align={"center"}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        colorScheme="primary">
                        STEP 3
                    </Text>
                    <Text
                        fontWeight={"bold"}
                        fontSize={titleFontSize}
                        color={titleFontColor}
                        align={"center"}
                        pb={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        colorScheme="primary">
                        Improve
                    </Text>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"70%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Change some of the provided logic, or code your own into one of the given starter
                        bots and follow the streamlined GitHub automatic submission process.
                    </Text>
                    <Box
                        align={"center"}
                        flexDirection={"row"}>

                        <Button
                            href={"https://github.com/EntelectChallenge/2024-Sproutopia/releases/latest"}
                            m={5}
                            size={"lg"}
                            backgroundColor={buttonColor}
                            colorScheme={"navigation"}
                            color={"whiteAlpha.900"}
                            target={"_blank"}
                            as={"a"}>
                            STARTER PACK
                        </Button>
                        <Button
                            m={5}
                            href={entelectChallengeForumLink}
                            size={"lg"}
                            backgroundColor={secondaryButtonColor}
                            colorScheme={"navigation"}
                            color={"whiteAlpha.900"}
                            target={"_blank"}
                            as={"a"}>
                            JOIN THE COMMUNITY
                        </Button>
                    </Box>
                </Square>

                {!isMobile &&
                    <Square
                        sx={{width: "20%", height: "0vh"}}
                        maxWidth={textColumnMaxWidth}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                        <Image
                            w={"80vw"}
                            objectFit={"contain"}
                            pos="relative" top="0" left="-35vw"
                            fit={"contain"}
                            src={step_3_image}
                        />
                    </Square>
                }

                <Square p={5} shadow="md" borderWidth="0.1em"
                        background={"whiteAlpha.800"}
                        borderRadius={"0.5em"}
                        my={5}
                        sx={{width: "80%"}}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                    <Box
                        align={"center"}
                        py={5}
                        width={"80%"}>
                        <Text
                            fontWeight={"bold"}
                            fontSize={titleFontSize}
                            color={titleFontColor}
                            align={"center"}
                            maxWidth={textColumnMaxWidth}
                            colorScheme="primary"
                            as={"span"}>
                            Win
                        </Text>
                        <Text
                            fontWeight={"normal"}
                            fontSize={titleFontSize}
                            color={"accent.600"}
                            align={"center"}
                            maxWidth={textColumnMaxWidth}
                            colorScheme="primary"
                            as={"span"}>
                            {" "} Prizes
                        </Text>
                    </Box>
                    <Box>
                        <Image
                            h={"80vh"}
                            src={prizes}
                        />
                    </Box>
                </Square>

                <Square
                    width={"80%"}
                    maxWidth={textColumnMaxWidth}
                    h={"0vh"}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Image
                        h={"10em"}
                        objectFit={"contain"}
                        pos="relative" top="1vw" left="-50%"
                        fit={"contain"}
                        src={bug_hunt_image}
                    />
                </Square>

                <Square p={5} shadow="md" borderWidth="0.1em"
                        background={"whiteAlpha.800"}
                        borderRadius={"0.5em"}
                        my={5}
                        sx={{width: "80%"}}
                        maxWidth={textColumnMaxWidth}
                        minH={minViewH}
                        alignSelf={"center"}
                        flexDirection={"column"}>

                    <Button
                        href={"https://github.com/EntelectChallenge/2024-Sproutopia/"}
                        m={5}
                        size={"lg"}
                        backgroundColor={buttonColor}
                        colorScheme={"navigation"}
                        color={"whiteAlpha.900"}
                        target={"_blank"}
                        as={"a"}>
                        READ THE RULES
                    </Button>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"50%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        If you want to find out more about the previous challenges or
                        Entelect, check out the following links:
                    </Text>
                    <Box
                        align={"center"}
                        flexDirection={"row"}>

                        <Button
                            m={5}
                            size={"lg"}
                            backgroundColor={secondaryButtonColor}
                            colorScheme={"navigation"}
                            color={"whiteAlpha.900"}
                            onClick={() => history.push("/legacy")}>
                            CHALLENGE HISTORY
                        </Button>
                        <Button
                            m={5}
                            href={"https://culture.entelect.co.za/"}
                            size={"lg"}
                            backgroundColor={buttonColor}
                            colorScheme={"navigation"}
                            color={"whiteAlpha.900"}
                            target={"_blank"}
                            as={"a"}>
                            ABOUT ENTELECT
                        </Button>
                    </Box>
                </Square>

                <Square
                    mt={"5em"}
                    sx={{width: "100%"}}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Image
                        width={"100%"}
                        objectFit={"contain"}
                        fit={"contain"}
                        src={bottom_image}
                    />
                </Square>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader/>
                    <ModalCloseButton/>
                    <ModalBody>
                        <AspectRatio
                            mt={2}
                            borderRadius={"0.5em"}
                            transform={"translateZ(0px)"}
                            ratio={16 / 9}
                            zIndex={0}>
                            <iframe
                                src={youtubeVideoMap.get("youtubeUrl")}
                                style={{
                                    borderRadius: "0.5em",
                                    border: "0"
                                }}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </AspectRatio>
                    </ModalBody>
                    <ModalFooter/>
                </ModalContent>
            </Modal>
        </Box>
    );
};
