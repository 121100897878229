const images2012 ={
    "amani":require('./amani.jpg').default,
    "andrew":require('./andrew.jpg').default,
    "gustavmauer":require('./gustavmauer.jpg').default,
    "henri":require('./henri.jpg').default,
    "JacoCronje":require('./JacoCronje.jpg').default,
    "keegan":require('./keegan.jpg').default,
    "marcus":require('./marcus.jpg').default,
    "pieter":require('./pieter.jpg').default,
}
export default images2012;