import gallery from '../gallery/gallaryIndex';

export const photos2016 = [
    {
      src: gallery[20161],
      width: 4,
      height: 3
    },
    {
      src: gallery[20162],
      width: 4,
      height: 3
    },
    {
      src: gallery[20163],
      width: 4,
      height: 3
    },
    {
      src: gallery[20164],
      width: 4,
      height: 3
    },
    {
      src: gallery[20165],
      width: 4,
      height: 3
    },
    {
      src: gallery[20166],
      width: 4,
      height: 3
    },
    {
      src: gallery[20167],
      width: 4,
      height: 3
    },
    {
      src: gallery[20168],
      width: 4,
      height: 3
    },
    {
      src: gallery[20169],
      width: 4,
      height: 3
    },
    {
      src: gallery[201610],
      width: 4,
      height: 3
    },
    {
      src: gallery[201611],
      width: 4,
      height: 3
    },
    {
      src: gallery[201612],
      width: 4,
      height: 3
    },
    {
      src: gallery[201613],
      width: 4,
      height: 3
    },
    {
      src: gallery[201614],
      width: 4,
      height: 3
    },
    {
      src: gallery[201615],
      width: 4,
      height: 3
    },
    {
      src: gallery[201616],
      width: 4,
      height: 3
    },
    {
      src: gallery[201617],
      width: 4,
      height: 3
    },
    {
      src: gallery[201618],
      width: 4,
      height: 3
    },
    {
      src: gallery[201619],
      width: 4,
      height: 3
    },
    {
      src: gallery[201620],
      width: 4,
      height: 3
    },
    {
      src: gallery[201621],
      width: 4,
      height: 3
    },
    {
      src: gallery[201622],
      width: 4,
      height: 3
    },
    {
      src: gallery[201623],
      width: 4,
      height: 3
    },
    {
      src: gallery[201624],
      width: 4,
      height: 3
    },
    {
      src: gallery[201625],
      width: 4,
      height: 3
    },
    {
      src: gallery[201626],
      width: 4,
      height: 3
    },
    {
      src: gallery[201627],
      width: 4,
      height: 3
    },
    {
      src: gallery[201628],
      width: 4,
      height: 3
    },
    {
      src: gallery[201629],
      width: 4,
      height: 3
    },
    {
      src: gallery[201630],
      width: 4,
      height: 3
    },
    {
      src: gallery[201631],
      width: 4,
      height: 3
    },
    {
      src: gallery[201632],
      width: 4,
      height: 3
    }
  ];