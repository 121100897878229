import { Box, Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, HStack, Input, InputGroup, InputRightAddon, PopoverAnchor, useBreakpointValue } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { FC, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getSettingAsBoolean, SETTING_NAME_OPERATIONAL } from '../../atoms/settings.atom';
import { RegisterFormModel, RegisterModel } from '../../models/register.model';
import { usePasswordRequirements } from '../../utils/hooks/use-password-validation';
import { PasswordRequirementsPopover } from './PasswordRequirementsPopover';
import { initialValues, validationSchema } from './register-form.functions';

interface IRegisterForm {
	userType: string;
	isLoading: boolean;
	onRegisterRequest: (model: RegisterModel) => void;
}

export const RegisterForm: FC<IRegisterForm> = (props) => {
	const enabledRegister = useRecoilValue(getSettingAsBoolean(SETTING_NAME_OPERATIONAL.ENABLED_REGISTER));

	const popoverParentRef = useRef(null);

	const [showPassword, setShowPassword] = useState(false);

	const { passwordRequirements, handlePasswordRequirementsChange } = usePasswordRequirements();
	const isMobile = useBreakpointValue({ base: true, sm: true, md: true, lg: false });

	const buttonColor = 'navigation.800';

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema(props.userType),
		validateOnBlur: true,
		validateOnChange: true,
		onSubmit: (model: RegisterFormModel) => handleRegistrationSubmit(model)
	});

	const onPasswordChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
		handlePasswordRequirementsChange(changeEvent);
		formik.handleChange(changeEvent);
	};

	const handleRegistrationSubmit = (model: RegisterFormModel): void => {
		const registerModel: RegisterModel = {
			email: model.email,
			firstName: model.firstName,
			lastName: model.lastName,
			password: model.password,
			displayName: `${model.firstName} ${model.lastName}`
		};

		props.onRegisterRequest(registerModel);
	}

	return (
		<>
			<Box>
				<form>
					<Box mb={4}>
						<FormControl
							id={'email'}
							isRequired
							isInvalid={formik.touched.email && Boolean(formik.errors.email)}
						>
							<FormLabel>Email Address</FormLabel>

							<Input
								colorScheme={'primary'}
								id={'email'}
								name={'email'}
								type="text"
								value={formik.values.email}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								size="md"
								variant="filled"
								placeholder={'Email address'}
								isDisabled={!enabledRegister}
							/>

							<FormErrorMessage>{formik.errors.email}</FormErrorMessage>
							{
								props.userType === 'employee' &&
                                <FormHelperText>Please use your @entelect email address</FormHelperText>
							}
						</FormControl>
					</Box>

					{isMobile ? (
						<>
							<Box mb={4}>
								<FormControl
									id={'firstName'}
									isRequired
									isInvalid={formik.touched.firstName && Boolean(formik.errors.firstName)}
								>
									<FormLabel>First Name</FormLabel>

									<Input
										colorScheme={'primary'}
										id={'firstName'}
										name={'firstName'}
										type="text"
										value={formik.values.firstName}
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										size="md"
										variant="filled"
										placeholder={'First name'}
										isDisabled={!enabledRegister}
									/>

									<FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
								</FormControl>
							</Box>

							<Box mb={4}>
								<FormControl
									id={'lastName'}
									isRequired
									isInvalid={formik.touched.lastName && Boolean(formik.errors.lastName)}
								>
									<FormLabel>Last Name</FormLabel>

									<Input
										colorScheme={'primary'}
										id={'lastName'}
										name={'lastName'}
										type="text"
										value={formik.values.lastName}
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
										size="md"
										variant="filled"
										placeholder={'Last name'}
										isDisabled={!enabledRegister}
									/>

									<FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
								</FormControl>
							</Box>
						</>
					) : (
						 <>
							 <HStack direction={['column', 'row']} spacing={4} align={'stretch'}>
								 <Box w={'100%'}>
									 <FormControl
										 id={'firstName'}
										 isRequired
										 isInvalid={formik.touched.firstName && Boolean(formik.errors.firstName)}
									 >
										 <FormLabel>First Name</FormLabel>

										 <Input
											 colorScheme={'primary'}
											 id={'firstName'}
											 name={'firstName'}
											 type="text"
											 value={formik.values.firstName}
											 onBlur={formik.handleBlur}
											 onChange={formik.handleChange}
											 size="md"
											 variant="filled"
											 placeholder={'First name'}
											 isDisabled={!enabledRegister}
										 />

										 <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
									 </FormControl>
								 </Box>

								 <Box w={'100%'}>
									 <FormControl
										 id={'lastName'}
										 isRequired
										 isInvalid={formik.touched.lastName && Boolean(formik.errors.lastName)}
									 >
										 <FormLabel>Last Name</FormLabel>

										 <Input
											 colorScheme={'primary'}
											 id={'lastName'}
											 name={'lastName'}
											 type="text"
											 value={formik.values.lastName}
											 onBlur={formik.handleBlur}
											 onChange={formik.handleChange}
											 size="md"
											 variant="filled"
											 placeholder={'Last name'}
											 isDisabled={!enabledRegister}
										 />

										 <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
									 </FormControl>
								 </Box>
							 </HStack>
						 </>
					 )}

					<Box mb={4}>
						<FormControl
							id={'password'}
							isRequired
							isInvalid={formik.touched.password && Boolean(formik.errors.password)}
						>
							<FormLabel>Password</FormLabel>

							{isMobile && (
								<FormErrorMessage my={4}>{formik.errors.password}</FormErrorMessage>
							)}

							<PasswordRequirementsPopover
								passwordRequirements={passwordRequirements}
								placement={isMobile ? 'bottom' : 'right'}
								children={
									<>
										<PopoverAnchor>
										<InputGroup colorScheme={'primary'}>
											<Input
												colorScheme={'primary'}
												id={'password'}
												name={'password'}
												type={showPassword ? 'text' : 'password'}
												value={formik.values.password}
												onBlur={formik.handleBlur}
												onChange={onPasswordChange}
												size="md"
												variant="filled"
												placeholder={'Password'}
												isDisabled={!enabledRegister}
												ref={popoverParentRef}
											/>

											<InputRightAddon borderWidth={0} paddingX={1} children={
												<Button
													colorScheme={'primary'}
													variant={'ghost'}
													size="sm"
													onClick={() => setShowPassword(!showPassword)}
													isDisabled={!enabledRegister}
												>
													{showPassword ? 'HIDE' : 'SHOW'}
												</Button>
											}/>
										</InputGroup>
										</PopoverAnchor>
									</>
								}
							/>

							{!isMobile && (
								<FormErrorMessage>{formik.errors.password}</FormErrorMessage>
							)}
						</FormControl>
					</Box>

					<Box
						mb={4}
						mt={isMobile ? '60' : '0'}
					>
						<FormControl
							id={'passwordConfirmation'}
							isRequired
							isInvalid={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
						>
							<FormLabel>Confirm Password</FormLabel>

							<InputGroup colorScheme={'primary'}>
								<Input
									colorScheme={'primary'}
									id={'passwordConfirmation'}
									name={'passwordConfirmation'}
									type={showPassword ? 'text' : 'password'}
									value={formik.values.passwordConfirmation}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									size="md"
									variant="filled"
									placeholder={'Confirm Password'}
									isDisabled={!enabledRegister}
								/>

								<InputRightAddon borderWidth={0} paddingX={1} children={
									<Button
										colorScheme={'primary'}
										variant={'ghost'}
										size="sm"
										onClick={() => setShowPassword(!showPassword)}
										isDisabled={!enabledRegister}
									>
										{showPassword ? 'HIDE' : 'SHOW'}
									</Button>
								}/>
							</InputGroup>

							<FormErrorMessage>{formik.errors.passwordConfirmation}</FormErrorMessage>
						</FormControl>
					</Box>

					<Button
						w={'100%'}
						type={'button'}
						colorScheme={'navigation'}
						isLoading={props.isLoading}
						loadingText={'Registering'}
						backgroundColor={buttonColor}
						mt={2}
						disabled={!enabledRegister || props.isLoading}
						onClick={formik.submitForm}
					>
						Create Account
					</Button>
				</form>

			</Box>
		</>
	);
};
