import {Center, Grid, GridItem, VStack} from "@chakra-ui/react";
import React, {FunctionComponent, useState} from "react";
import {useHistory} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {PlayerApi} from "../../api/player.api";
import {playerAtom} from "../../atoms/player.atom";
import {EntryType} from "../../models/entry.model";
import RegistrationInfoMainModel from "../../models/registration-info-main.model";
import {FancyHeading} from "../../shared/FancyHeading";
import {getCurrentYear, getValueForEntryType} from "../../utils/hooks/general-utils";
import {EntelectChallengeRegisterForm} from "./EntelectChallengeRegisterForm";

interface IEntelectChallengeRegister {
}

export const EntelectChallengeRegister: FunctionComponent<IEntelectChallengeRegister> = _ => {

    const history = useHistory();

    const setPlayer = useSetRecoilState(playerAtom);

    const [loading, setLoading] = useState(false);

    const entryType = EntryType.MAIN;

    const register = (registrationModel: RegistrationInfoMainModel) => {
        setLoading(true);

        PlayerApi.register(registrationModel, entryType)
            .then(_ => {
                setPlayer((prevState) => {
                    return {
                        ...prevState,
                        playerEntries: [
                            ...prevState.playerEntries,
                            {
                                entryType: entryType,
                                entryId: '',
                                playerId: prevState.playerId,
                                entryYear: getCurrentYear()
                            }
                        ]
                    }
                });

                history.push(`/portal/entelect-challenge/`);
            })
            .catch(_ => {
                setLoading(false);
            });
    }

    return (
        <Grid templateColumns={'repeat(11, 1fr)'} h={'100%'} py={10}>
            <GridItem colSpan={[1, 1, 2, 4]}/>
            <GridItem colSpan={[9, 9, 7, 3]} mb={4}>
                <Center h={'100%'}>
                    <VStack align={'-moz-initial'} w={'100%'}>
                        <FancyHeading heading={`Registration for the Entelect Challenge`} headingSize={'3xl'}/>

                        {
                            getValueForEntryType(EntryType.MAIN, {
                                main: <EntelectChallengeRegisterForm
                                    entelectChallengeRegistrationModel={null}
                                    register={register}
                                    loading={loading}
                                />
                            })
                        }
                    </VStack>
                </Center>
            </GridItem>
        </Grid>
    )
}
