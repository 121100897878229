import * as React from 'react';
import {CountdownRenderProps} from "react-countdown/dist/Countdown";
import {Text} from "@chakra-ui/layout";
import Countdown from "react-countdown";

interface IHackathonCountdown {
    date: Date;
}

export const HackathonCountdown: React.FunctionComponent<IHackathonCountdown> = props => {

    const renderer = (props: CountdownRenderProps): React.ReactNode => {
        if (props.completed) {
            // Render a completed state
            return <Text
                color={'gray.500'}
                fontSize={'sm'}
                w={['100%', '100%', 'auto', 'auto']}
                textAlign={['center', 'center', 'end', 'end']}
            >Time's up. Pencils down!</Text>
        } else {

            if (props.days === 0 && props.hours === 0 && props.minutes === 0) {
                return (
                    <Text
                        color={'gray.500'}
                        fontSize={'sm'}
                        w={['100%', '100%', 'auto', 'auto']}
                        textAlign={['center', 'center', 'end', 'end']}
                    >{props.seconds.toString()} seconds remaining</Text>
                );
            }

            if (props.days === 0 && props.hours === 0) {
                return (
                    <Text
                        color={'gray.500'}
                        fontSize={'sm'}
                        w={['100%', '100%', 'auto', 'auto']}
                        textAlign={['center', 'center', 'end', 'end']}
                    >{props.minutes.toString()} minutes and {props.seconds.toString()} seconds remaining</Text>
                );
            }

            if (props.days === 0) {
                return (
                    <Text
                        color={'gray.500'}
                        fontSize={'sm'}
                        w={['100%', '100%', 'auto', 'auto']}
                        textAlign={['center', 'center', 'end', 'end']}
                    >{props.hours.toString()} hours and {props.minutes.toString()} minutes remaining</Text>
                );
            }
            // Render a countdown
            return (
                <Text
                    color={'gray.500'}
                    fontSize={'sm'}
                    w={['100%', '100%', 'auto', 'auto']}
                    textAlign={['center', 'center', 'end', 'end']}
                >{props.days.toString()} days, {props.hours.toString()} hours and {props.minutes.toString()} minutes remaining</Text>
            );
        }
    };

    return (
        <Countdown
            date={props.date}
            renderer={renderer}
        />
    )
};