import * as React from 'react';
import {Box, BoxProps, Spacer, Stack, StackProps, Text} from "@chakra-ui/react";
import {FancyHorizontalLine} from "./FancyHorizontalLine";

interface IFancyHeading {
    heading: string;
    headingSize: string;
    headingColor?: string;
    cProps?: BoxProps;
    stackProps?: StackProps;
    lines?: Array<boolean>;
    action?: React.ReactNode;
}

export const FancyHeading: React.FunctionComponent<IFancyHeading> = props => {
    const headingColor = props.headingColor ? props.headingColor : 'gray.800';

    return (
        <Box {...props.cProps}>
            <Stack direction={['column', 'column', 'row', 'row']} alignItems={'center'} spacing={1} {...props.stackProps} >
                <Text color={headingColor} fontWeight={'bold'} w={['100%', '100%', 'auto', 'auto']} textAlign={['center', 'center', 'left', 'left']} fontSize={props.headingSize}>
                    {props.heading}
                </Text>
                <Spacer />
                {
                    props.action &&
                        props.action
                }
            </Stack>
            <FancyHorizontalLine lines={props.lines} />
        </Box>
    )
};
