import * as React from 'react';
import {Box, Button} from "@chakra-ui/react";
import {FancyHeading} from "../../shared/FancyHeading";
import {ArrowBackIcon} from "@chakra-ui/icons";
import {useHistory} from "react-router-dom";

interface IWikiSection {
    heading: string;
}

export const WikiSection: React.FunctionComponent<IWikiSection> = props => {
    const history = useHistory();

    return (
        <Box mt={10}>
            <FancyHeading heading={props.heading} headingColor={'primary.700'} headingSize={'4xl'} lines={[true, true]} action={
                <Button mr={2} leftIcon={<ArrowBackIcon />} size={'md'} colorScheme={'primary'} variant={'outline'} onClick={() => history.push('/wiki')}>
                    Entelect Challenge Wiki Home
                </Button>
            } />
            <Box mt={2}>
                {
                    props.children
                }
            </Box>
        </Box>
    )
};
