import gallery from '../gallery/gallaryIndex';

export const photos2018 = [
    {
      src: gallery[20181],
      width: 4,
      height: 3
    },
    {
      src: gallery[20182],
      width: 4,
      height: 3
    },
    {
      src: gallery[20183],
      width: 4,
      height: 3
    },
    {
      src: gallery[20184],
      width: 4,
      height: 3
    },
    {
      src: gallery[20185],
      width: 4,
      height: 3
    },
    {
      src: gallery[20186],
      width: 4,
      height: 3
    },
    {
      src: gallery[20187],
      width: 4,
      height: 3
    },
    {
      src: gallery[20188],
      width: 4,
      height: 3
    },
    {
      src: gallery[20189],
      width: 4,
      height: 3
    },
    {
      src: gallery[201810],
      width: 4,
      height: 3
    },
    {
      src: gallery[201811],
      width: 4,
      height: 3
    },
    {
      src: gallery[201812],
      width: 4,
      height: 3
    },
    {
      src: gallery[201813],
      width: 4,
      height: 3
    },
    {
      src: gallery[201814],
      width: 4,
      height: 3
    },
    {
      src: gallery[201815],
      width: 4,
      height: 3
    },
    {
      src: gallery[201816],
      width: 4,
      height: 3
    },
    {
      src: gallery[201817],
      width: 4,
      height: 3
    },
    {
      src: gallery[201818],
      width: 4,
      height: 3
    },
    {
      src: gallery[201819],
      width: 4,
      height: 3
    },
    {
      src: gallery[201820],
      width: 4,
      height: 3
    },
    {
      src: gallery[201821],
      width: 4,
      height: 3
    },
    {
      src: gallery[201822],
      width: 4,
      height: 3
    },
    {
      src: gallery[201823],
      width: 4,
      height: 3
    },
    {
      src: gallery[201824],
      width: 4,
      height: 3
    },
    {
      src: gallery[201825],
      width: 4,
      height: 3
    },
    {
      src: gallery[201826],
      width: 4,
      height: 3
    },
    {
      src: gallery[201827],
      width: 4,
      height: 3
    },
    {
      src: gallery[201828],
      width: 4,
      height: 3
    },
    {
      src: gallery[201829],
      width: 4,
      height: 3
    },
    {
      src: gallery[201830],
      width: 4,
      height: 3
    },
    {
      src: gallery[201831],
      width: 4,
      height: 3
    },
    {
      src: gallery[201832],
      width: 4,
      height: 3
    },
    {
      src: gallery[201833],
      width: 4,
      height: 3
    },
    {
      src: gallery[201834],
      width: 4,
      height: 3
    },
    {
      src: gallery[201835],
      width: 4,
      height: 3
    },
    {
      src: gallery[201836],
      width: 4,
      height: 3
    },
    {
      src: gallery[201837],
      width: 4,
      height: 3
    }
  ];