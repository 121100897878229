import { Text } from "@chakra-ui/layout";
import { Box, Center, Flex, Image, Link, SimpleGrid, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import logo from "../../../assets/images/entelect/EntelectChallenge_Footer.svg";
import entelect_slogan from "../../../assets/images/entelect/Entelect_Slogan_Transparent-white.png";
import { SocialMediaLinks } from "../../footer/SocialMediaLinks";
import bug_hunt_footer from "../../../assets/images/shared/footer_period.png";

interface IFooter {
}

const main_bg: string = "navigation.800";
const entelectChallengeForumLink = process.env.REACT_APP_ENTELECT_CHALLENGE_FORUM;

export const UnauthenticatedFooter: React.FunctionComponent<IFooter> = (props) => {
    return (
        <Box
            bg={main_bg}
            as="footer"
            w="100%"
            zIndex={2}
            padding={1}
            alignItems={"center"}>
            <SimpleGrid
                columns={[1, null, 3]}
                spacing={4}
                alignItems={"center"}
                padding={4}>
                <VStack direction={"column"}>
                    <SocialMediaLinks
                        social_icon_size={"20px"}
                        color={"whiteAlpha.900"}
                        button_props={{_hover: {bg: "navigation.700"}}}
                    />
                </VStack>
                <Center>
                    <Image
                        h={"6em"}
                        objectFit={"contain"}
                        fit={"contain"}
                        src={logo}
                    />
                </Center>
                <Stack>
                    <Link
                        textAlign={["center"]}
                        color={"whiteAlpha.900"}
                        fontSize={"lg"}
                        textDecoration={"underline"}
                        href={entelectChallengeForumLink}
                        isExternal>
                        Entelect Challenge Forum
                    </Link>

                    <Image
                        h={"5em"}
                        objectFit={"contain"}
                        fit={"contain"}
                        src={entelect_slogan}
                    />
                </Stack>
            </SimpleGrid>

            <Center position={"relative"}>
                <Text
                    fontSize={"md"}
                    color={"whiteAlpha.900"}
                    textAlign={"center"}
                    padding={4}>
                    <Link
                        href={"/wiki/privacy-policy"}
                        isExternal>
                        Privacy Policy
                    </Link>
                    <span> | </span>
                    <Link
                        href={"/wiki/cookie-policy"}
                        isExternal>
                        Cookie Policy
                    </Link>
                    <span> | </span>
                    <Box display="inline-block">
                        <Flex alignItems="center" direction={"row"}>
                            &copy; Copyright {new Date().getFullYear()} by Entelect. All Rights Reserved
                            <Image
                                src={bug_hunt_footer}
                                alt="Footer period image"
                                h={"1em"}
                            />
                        </Flex>
                    </Box>
                </Text>
            </Center>
        </Box>
    );
};
