const gallaryImages ={
    2018_1:require('./2018_1.jpg').default,
    2018_2:require('./2018_2.jpg').default,
    2018_3:require('./2018_3.jpg').default,
    2018_4:require('./2018_4.jpg').default,
    2018_5:require('./2018_5.jpg').default,
    2018_6:require('./2018_6.jpg').default,
    2018_7:require('./2018_7.jpg').default,
    2018_8:require('./2018_8.jpg').default,
    2018_9:require('./2018_9.jpg').default,
    2018_10:require('./2018_10.jpg').default,
    2018_11:require('./2018_11.jpg').default,
    2018_12:require('./2018_12.jpg').default,
    2018_13:require('./2018_13.jpg').default,
    2018_14:require('./2018_14.jpg').default,
    2018_15:require('./2018_15.jpg').default,
    2018_16:require('./2018_16.jpg').default,
    2018_17:require('./2018_17.jpg').default,
    2018_18:require('./2018_18.jpg').default,
	2018_19:require('./2018_19.jpg').default,
    2018_20:require('./2018_20.jpg').default,
    2018_21:require('./2018_21.jpg').default,
    2018_22:require('./2018_22.jpg').default,
    2018_23:require('./2018_23.jpg').default,
    2018_24:require('./2018_24.jpg').default,
    2018_25:require('./2018_25.jpg').default,
    2018_26:require('./2018_26.jpg').default,
    2018_27:require('./2018_27.jpg').default,
	2018_28:require('./2018_28.jpg').default,
    2018_29:require('./2018_29.jpg').default,
    2018_30:require('./2018_30.jpg').default,
    2018_31:require('./2018_31.jpg').default,
	2018_32:require('./2018_32.jpg').default,
    2018_33:require('./2018_33.jpg').default,
    2018_34:require('./2018_34.jpg').default,
    2018_35:require('./2018_35.jpg').default,
    2018_36:require('./2018_36.jpg').default,
    2018_37:require('./2018_37.jpg').default,
}
export default gallaryImages;