import {atom, RecoilState, selector, selectorFamily} from "recoil";
import {EntryType} from "../models/entry.model";
import PlayerModel from "../models/player.model";
import {getCurrentYear} from "../utils/hooks/general-utils";

export const playerAtom: RecoilState<PlayerModel> = atom<PlayerModel>({
    key: 'player_atom',
    default: {
        email: '',
        firstName: '',
        lastName: '',
        displayName: '',
        playerId: '',
        botNickname: '',
        imageUrl: '',
        languageType: '',
        legacySubmissionFlow: false,
        playerEntries: []
    } as PlayerModel
});

export const playerIsEntelectEmployee = selector<boolean>({
    key: 'player_is_entelect_selector',
    get: ({ get }) => {
        const player = get(playerAtom);

        if (player) {
            return player.email.endsWith('@entelect.co.za')
                || player.email.endsWith("@entelect.co.nz")
                || player.email.endsWith('@entelect.nl')
                || player.email.endsWith("@entelect.co.uk")
                || player.email.endsWith("@entelect.com.au");
        }
        return false;
    }
});

export const playerHaveEntry = selectorFamily<boolean, EntryType>({
    key: 'player_have_entry_selector',
    get: (entryToFind) => ({ get }) => {
        const playerEntries = get(playerAtom).playerEntries;
        return playerEntries.find(({ entryType, entryYear }) => entryType === entryToFind && +entryYear === getCurrentYear()) !== undefined;
    }
});
