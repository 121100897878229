export const finalists2021 = [
    {
        id:1,
        finalist: 'Riaan Nel',
        image: 'RiaanNel'
    },
    {
        id:2,
        finalist: 'Gawie Jooste',
        image: 'GawieJooste'
    },
    {
        id:3,
        finalist: 'Aman Yasin',
        image: 'AmanYasin'
    }
]