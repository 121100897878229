import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Checkbox,
    FormControl, Text,
    useColorMode,
    useToast
} from "@chakra-ui/react";
import {SettingItemSection} from "../SettingItemSection";
import {useRecoilState, useRecoilValue} from "recoil";
import {playerHaveEntry} from "../../../atoms/player.atom";
import {Field, Form, Formik} from "formik";
import {EntryType} from "../../../models/entry.model";
import {registrationInfoMainAtom} from "../../../atoms/registration-info.atom";
import {PlayerApi} from "../../../api/player.api";
import RegistrationInfoMainModel from "../../../models/registration-info-main.model";
import {IntermediateGlobalLoading} from "../../../shared/loading/IntermediateGlobalLoading";
import * as Yup from "yup";
import {FieldAttributes} from "formik/dist/Field";

interface ITournaments {
}

export const Tournaments: React.FunctionComponent<ITournaments> = _ => {
    const [tournamentsAvailabilityLoading, setTournamentsAvailabilityLoading] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

    const toast = useToast();
    const {colorMode} = useColorMode();

    const haveEntelectChallengeEntry = useRecoilValue(playerHaveEntry(EntryType.MAIN));
    const [mainRegistrationInfo, setMainRegistrationInfo] = useRecoilState(registrationInfoMainAtom);

    const hasNoCupEntries = (): boolean => {
        return !haveEntelectChallengeEntry;
    }
    const registrationInfoValidation = Yup.object().shape({
        optInTournament: Yup.boolean(),
        optInFriendly: Yup.boolean(),
    });

    const updateMainInfo = (model: RegistrationInfoMainModel) => {
        model.playerId = mainRegistrationInfo.playerId;
        model.mainRegistrationInfoId = mainRegistrationInfo.mainRegistrationInfoId;
        model.optInFriendly = model.optInFriendly === true; //Required to get rid of occasional undefined
        model.optInTournament = model.optInTournament === true; //Required to get rid of occasional undefined

        setTournamentsAvailabilityLoading(true);

        PlayerApi.updatePlayerRegistrationInfo(model)
            .then(result => {
                setTournamentsAvailabilityLoading(false);
                toast({
                    title: `Updated your tournament availability.`,
                    position: 'top-right',
                    status: 'success',
                });
            })
            .catch(reason => {
                //console.error(reason);
                setTournamentsAvailabilityLoading(false);
                toast({
                    title: `Tournament availability update failed`,
                    position: 'top-right',
                    status: 'error',
                });
            });
    };

    useEffect(() => {
        PlayerApi.getPlayerMainRegistrationInfo()
            .then(result => {
                setMainRegistrationInfo(result.data)
                setIsInitialLoad(false);
            })
            .catch(reason => {
                //console.error(reason);
                setIsInitialLoad(false);
            });
    }, [setMainRegistrationInfo, setIsInitialLoad]);


    if (isInitialLoad) {
        return (
            <IntermediateGlobalLoading displayWithoutText={true} isNotFull={true}/>
        );
    }

    return (
        <Box>
            {hasNoCupEntries() &&
                <SettingItemSection heading={'Tournaments availability'} colSpan={10}>
                    <Alert status="info">
                        <AlertIcon/>
                        You currently don't have any Tournament information to edit. Please register for the Entelect
                        Challenge cup to edit
                        this information.
                    </Alert>
                </SettingItemSection>
            }
            {haveEntelectChallengeEntry &&
                <SettingItemSection
                    heading={'Entelect Challenge cup Tournaments availability'}
                    subTexts={['You choose which tournaments you want to participate in for the Entelect Challenge Cup.']}
                >
                    <Formik
                        initialValues={mainRegistrationInfo}
                        onSubmit={(values) => updateMainInfo(values)}
                        isInitialValid={false}
                        validationSchema={registrationInfoValidation}
                        validateOnBlur={true}
                        validateOnChange={true}
                    >
                        {({values}) => (
                            <Form>
                                <Field name={'optInTournament'}>
                                    {({field, _}: FieldAttributes<any>) => (
                                        <Box mb={4}>
                                            <FormControl>
                                                <Checkbox colorScheme={'primary'}
                                                          {...field}
                                                          id={'optInTournament'}
                                                          name={'optInTournament'}
                                                          isChecked={values.optInTournament === true}>
                                                    Participate in official tournaments.
                                                </Checkbox>
                                                <Text ml={6} fontSize={'sm'}
                                                      color={colorMode === 'light' ? 'gray.500' : 'whiteAlpha.600'}>
                                                    Whether or not your bot will be added to the official tournament
                                                    lineup.
                                                </Text>
                                            </FormControl>
                                        </Box>
                                    )}
                                </Field>

                                <Field name={'optInFriendly'}>
                                    {({field, _}: FieldAttributes<any>) => (
                                        <Box mb={4}>
                                            <FormControl>
                                                <Checkbox colorScheme={'primary'}
                                                          {...field}
                                                          id={'optInFriendly'}
                                                          name={'optInFriendly'}
                                                          isChecked={values.optInFriendly === true}>
                                                    Participate in friendly tournaments.
                                                </Checkbox>
                                                <Text ml={6} fontSize={'sm'}
                                                      color={colorMode === 'light' ? 'gray.500' : 'whiteAlpha.600'}>
                                                    Whether or not your bot will be added to the friendly tournament
                                                    lineup.
                                                </Text>
                                            </FormControl>
                                        </Box>
                                    )}
                                </Field>
                                <Button colorScheme={'primary'} isLoading={tournamentsAvailabilityLoading}
                                        loadingText={'Updating tournament availability'}
                                        type={'submit'}>Update tournament availability</Button>
                            </Form>
                        )}
                    </Formik>
                </SettingItemSection>
            }
        </Box>
    )
};
