import * as React from 'react';
import {Badge, Box, useColorMode} from "@chakra-ui/react";
import {Link, useRouteMatch} from 'react-router-dom';

interface ISettingItem {
    name: string;
    path: string;
    last?: boolean;
    new?: boolean;
}

export const SettingItem: React.FunctionComponent<ISettingItem> = props => {
    const isActive = useRouteMatch(props.path);
    const {colorMode} = useColorMode();

    const isLast = props.last;
    return (
        <Link to={props.path}>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                px={isActive ? '14px' : '16px'}
                py={2}
                borderBottomColor={isLast ? '' : colorMode === 'light' ? 'gray.200' : 'gray.600'}
                borderBottomWidth={isLast ? '' : '1px'}
                borderBottomRadius={isLast ? 'md' : ''}
                borderLeftColor={isActive ? colorMode === 'light' ? 'primary.500' : 'primary.200' : ''}
                borderLeftWidth={isActive ? '2px' : ''}
                cursor={'pointer'}
                _hover={{background: colorMode === 'light' ? 'gray.50' : 'gray.700'}}
            >

                <Box>{props.name}</Box>
                {
                    props.new && <Box><Badge colorScheme="green">NEW</Badge></Box>
                }
            </Box>
        </Link>
    )
};