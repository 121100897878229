import { Text } from '@chakra-ui/layout';
import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Center,
	Divider,
	Grid,
	GridItem,
	List,
	ListIcon,
	ListItem,
	Spacer,
	Square,
	Stack,
	Tooltip,
	useBreakpointValue,
	useDisclosure,
	VStack
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import axios from 'axios';
import { saveAs } from 'file-saver';
import moment from 'moment';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { HackathonApi } from '../../../api/hackathon.api';
import solution_closed_background from '../../../assets/images/hackathon/solution_closed_backgroun.svg';
import solution_not_open_background from '../../../assets/images/hackathon/solution_not_open_background.svg';
import { hackathonProfileAtom } from '../../../atoms/hackathon-profile.atom';
import { getSettingAsHackathonData, SETTING_NAMES_HACKATHON } from '../../../atoms/settings.atom';
import HackathonSubmissionModel from '../../../models/hackathon-submission.model';
import HackathonTournamentModel from '../../../models/hackathon-tournament.model';
import { FancyHeading } from '../../../shared/FancyHeading';
import { IntermediateMessageLoader } from '../../../shared/loading/IntermediateMessageLoader';
import { getCurrentYear, getValueForEntryType, isEventOpen } from '../../../utils/hooks/general-utils';
import { HackathonComponentSwitch } from '../HackathonComponentSwitch';
import { HackathonQuestionUploadModal } from './HackathonQuestionUploadModal';
import { HackathonSolutionQuestion } from './HackathonSolutionQuestion';
import { HackathonSolutionSubmission } from './HackathonSolutionSubmission';
import { BiHive, BiPlanet, BiShapePolygon } from "react-icons/bi";
import { RiPlantFill } from "react-icons/ri";
import { GiWaterSplash } from "react-icons/gi";

interface IHackathonSolutions {
}

const downloadHeaders = {
	'Content-Disposition': 'attachment; filename="specification.pdf"'
};

export const HackathonSolutions: React.FunctionComponent<IHackathonSolutions> = props => {

	const { type } = useParams();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [questionNumberSelected, setQuestionNumberSelected] = useState(0);

	const isMobile = useBreakpointValue({ base: true, sm: true, md: false, lg: false });

	const [highestSolutionLoading, setHighestSolutionLoading] = useState(false);
	const [submissionsLoading, setSubmissionsLoading] = useState(false);
	const [preparingFileDownload, setPreparingFileDownload] = useState(false);

	const [highestScores, setHighestScores] = useState<Array<HackathonSubmissionModel>>([]);
	const [submissions, setSubmissions] = useState<Array<HackathonSubmissionModel>>([]);

	const hackathonProfile = useRecoilValue(hackathonProfileAtom);

	const hackathonCompanyTournament: HackathonTournamentModel = useRecoilValue(getSettingAsHackathonData(SETTING_NAMES_HACKATHON.COMPANY_TOURNAMENT_DATA));
	const hackathonUniversityTournament: HackathonTournamentModel = useRecoilValue(getSettingAsHackathonData(SETTING_NAMES_HACKATHON.UNIVERSITY_TOURNAMENT_DATA));
	const hackathonIitpsaTournament: HackathonTournamentModel = useRecoilValue(getSettingAsHackathonData(SETTING_NAMES_HACKATHON.IITPSA_TOURNAMENT_DATA));

	const universityPractiseTournamentName = `UNIVERSITY_PRACTISE_${getCurrentYear()}`;
	const companyPractiseTournamentName = 'COMPANY_PRACTISE';
	const iitpsaPractiseTournamentName = 'IITPSA_PRACTISE';

	const practiseMode = getValueForEntryType(
		type,
		{
			university: hackathonUniversityTournament.friendlyName.toUpperCase() === universityPractiseTournamentName,
			company: hackathonCompanyTournament.friendlyName.toUpperCase() === companyPractiseTournamentName,
			iitpsa: hackathonIitpsaTournament.friendlyName.toUpperCase() === iitpsaPractiseTournamentName
		}
	) as boolean;

	const [isSolutionsOpen, setIsSolutionsOpen] = useState(
		getValueForEntryType(
			type,
			{
				university: isEventOpen(hackathonUniversityTournament.solutionOpenDate, hackathonUniversityTournament.solutionCloseDate),
				company: isEventOpen(hackathonCompanyTournament.solutionOpenDate, hackathonCompanyTournament.solutionCloseDate),
				iitpsa: isEventOpen(hackathonIitpsaTournament.solutionOpenDate, hackathonIitpsaTournament.solutionCloseDate)
			}
		)
	);

	const loadHighestSubmissions = useCallback(() => {
		setHighestSolutionLoading(true);
		HackathonApi.getHighestScores(type, hackathonProfile.hackathonTeam.hackathonTeamId)
			.then((response) => {
				setHighestSolutionLoading(false);
				setHighestScores(response.data);
			})
			.catch(() => setHighestSolutionLoading(false));
	},
		[hackathonProfile.hackathonTeam.hackathonTeamId, type]
	);

	const loadSubmissions = useCallback(() => {
		setSubmissionsLoading(true);
		HackathonApi.getHackathonSubmissions(type, hackathonProfile.hackathonTeam.hackathonTeamId)
			.then((response) => {
				setSubmissionsLoading(false);
				setSubmissions(response.data);
			})
			.catch(() => setSubmissionsLoading(false));
	}, [hackathonProfile.hackathonTeam.hackathonTeamId, type]);

	const checkIfSolutionsShouldBeOpen = useCallback(() => {
		setIsSolutionsOpen(
			getValueForEntryType(
				type,
				{
					university: isEventOpen(hackathonUniversityTournament.solutionOpenDate, hackathonUniversityTournament.solutionCloseDate),
					company: isEventOpen(hackathonCompanyTournament.solutionOpenDate, hackathonCompanyTournament.solutionCloseDate),
					iitpsa: isEventOpen(hackathonIitpsaTournament.solutionOpenDate, hackathonIitpsaTournament.solutionCloseDate)
				}
			)
		);
	}, [hackathonCompanyTournament.solutionOpenDate, hackathonCompanyTournament.solutionCloseDate, hackathonUniversityTournament.solutionOpenDate, hackathonUniversityTournament.solutionCloseDate, hackathonIitpsaTournament.solutionOpenDate, hackathonIitpsaTournament.solutionCloseDate, type]);

	useEffect(() => {
		checkIfSolutionsShouldBeOpen();
		const interval = setInterval(() => {
			checkIfSolutionsShouldBeOpen();
		}, 1000);

		// This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
		return () => clearInterval(interval);
	}, [checkIfSolutionsShouldBeOpen]);

	useEffect(() => {
		if (isSolutionsOpen) {
			loadHighestSubmissions();
			loadSubmissions();
		}
	}, [loadHighestSubmissions, loadSubmissions, isSolutionsOpen]);

	const getHighestSubmissionForQuestion = (questionNumber: number) => {
		return highestScores.find((submission) => submission.questionNumber === questionNumber);
	};

	const downloadSpecificationFile = () => {
		setPreparingFileDownload(true);
		HackathonApi
			.getDownloadLinkForFile(type, 'specification.pdf')
			.then((response) => {

				axios.get(response.data, { headers: downloadHeaders, responseType: 'arraybuffer' })
					.then(downloadFileResult => {
						const blob = new Blob([downloadFileResult.data], { type: 'application/pdf;charset=utf-8' });
						saveAs(blob, `specification.pdf`);

						setPreparingFileDownload(false);
					})
					.catch(() => {
						setPreparingFileDownload(false);
					});
			})
			.catch(() => {
				setPreparingFileDownload(false);
			});
	};

	const openUploadModal = (questionNumber: number) => {
		setQuestionNumberSelected(questionNumber);
		onOpen();
	};

	const closeUploadModal = () => {
		onClose();
		loadHighestSubmissions();
		loadSubmissions();
	};

	const AnimatedBox = styled(Box)`
        background: linear-gradient(-45deg, #FFDDC2, #E8A2AA, #A2D4E8, #B0E8A2);
        background-size: 400% 400%;
        animation: gradient 60s ease infinite;

        @keyframes gradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
    `;

	const solutionLines: Record<number, Record<string, JSX.Element | null>> = {
		1: {
			'company':
				<HackathonSolutionQuestion
					questionNumber={1}
					loading={highestSolutionLoading}
					uploadFunction={openUploadModal}
					submission={getHighestSubmissionForQuestion(1)}
					hackathonType={type}
					questionPrefix={'Level'}
				>
					<List spacing={0.5}>
						<ListItem><ListIcon as={BiPlanet} color={'primary.900'} />Planet Radius: <strong>10</strong></ListItem>
						<ListItem><ListIcon as={BiHive} color={'primary.900'} />Number of Biomes: <strong>3</strong></ListItem>
					</List>
				</HackathonSolutionQuestion>
			,
			'university':
				<HackathonSolutionQuestion
					questionNumber={1}
					loading={highestSolutionLoading}
					uploadFunction={openUploadModal}
					submission={getHighestSubmissionForQuestion(1)}
					hackathonType={type}
					questionPrefix={'Level'}
				>
					<List spacing={0.5}>
						<ListItem><ListIcon as={BiPlanet} color={'primary.900'} />Planet Radius: <strong>10</strong></ListItem>
						<ListItem><ListIcon as={BiHive} color={'primary.900'} />Number of Biomes: <strong>3</strong></ListItem>
					</List>
				</HackathonSolutionQuestion>
			,
			'iitpsa':
				<HackathonSolutionQuestion
					questionNumber={1}
					loading={highestSolutionLoading}
					uploadFunction={openUploadModal}
					submission={getHighestSubmissionForQuestion(1)}
					hackathonType={type}
					questionPrefix={'Level'}
				>
					<List spacing={0.5}>
						<ListItem><ListIcon as={BiShapePolygon} color={'primary.900'} />Garden Shape: Triangle</ListItem>
						<ListItem><ListIcon as={RiPlantFill} color={'primary.900'} />Herbs: 15</ListItem>
						<ListItem><ListIcon as={GiWaterSplash} color={'primary.900'} />Sprinklers: 3</ListItem>
					</List>
				</HackathonSolutionQuestion>
		},
		2: {
			'company':
				<HackathonSolutionQuestion
					questionNumber={2}
					loading={highestSolutionLoading}
					uploadFunction={openUploadModal}
					submission={getHighestSubmissionForQuestion(2)}
					hackathonType={type}
					questionPrefix={'Level'}
				>
					<List spacing={0.5}>
						<ListItem><ListIcon as={BiPlanet} color={'primary.900'} />Planet Radius: <strong>30</strong></ListItem>
						<ListItem><ListIcon as={BiHive} color={'primary.900'} />Number of Biomes: <strong>4</strong></ListItem>
					</List>
				</HackathonSolutionQuestion>
			,
			'university':
				<HackathonSolutionQuestion
					questionNumber={2}
					loading={highestSolutionLoading}
					uploadFunction={openUploadModal}
					submission={getHighestSubmissionForQuestion(2)}
					hackathonType={type}
					questionPrefix={'Level'}
				>
					<List spacing={0.5}>
						<ListItem><ListIcon as={BiPlanet} color={'primary.900'} />Planet Radius: <strong>30</strong></ListItem>
						<ListItem><ListIcon as={BiHive} color={'primary.900'} />Number of Biomes: <strong>4</strong></ListItem>
					</List>
				</HackathonSolutionQuestion>
			,
			'iitpsa':
				<HackathonSolutionQuestion
					questionNumber={2}
					loading={highestSolutionLoading}
					uploadFunction={openUploadModal}
					submission={getHighestSubmissionForQuestion(2)}
					hackathonType={type}
					questionPrefix={'Level'}
				>
					<List spacing={0.5}>
						<ListItem><ListIcon as={BiShapePolygon} color={'primary.900'} />Garden Shape: Square</ListItem>
						<ListItem><ListIcon as={RiPlantFill} color={'primary.900'} />Herbs: 30</ListItem>
						<ListItem><ListIcon as={GiWaterSplash} color={'primary.900'} />Sprinklers: 6</ListItem>
					</List>
				</HackathonSolutionQuestion>
		},
		3: {
			'company':
				<HackathonSolutionQuestion
					questionNumber={3}
					loading={highestSolutionLoading}
					uploadFunction={openUploadModal}
					submission={getHighestSubmissionForQuestion(3)}
					hackathonType={type}
					questionPrefix={'Level'}
				>
					<List spacing={0.5}>
						<ListItem><ListIcon as={BiPlanet} color={'primary.900'} />Planet Radius: <strong>100</strong></ListItem>
						<ListItem><ListIcon as={BiHive} color={'primary.900'} />Number of Biomes: <strong>6</strong></ListItem>
					</List>
				</HackathonSolutionQuestion>
			,
			'university':
				<HackathonSolutionQuestion
					questionNumber={3}
					loading={highestSolutionLoading}
					uploadFunction={openUploadModal}
					submission={getHighestSubmissionForQuestion(3)}
					hackathonType={type}
					questionPrefix={'Level'}
				>
					<List spacing={0.5}>
						<ListItem><ListIcon as={BiPlanet} color={'primary.900'} />Planet Radius: <strong>100</strong></ListItem>
						<ListItem><ListIcon as={BiHive} color={'primary.900'} />Number of Biomes: <strong>6</strong></ListItem>
					</List>
				</HackathonSolutionQuestion>
			,
			'iitpsa':
				<HackathonSolutionQuestion
					questionNumber={3}
					loading={highestSolutionLoading}
					uploadFunction={openUploadModal}
					submission={getHighestSubmissionForQuestion(3)}
					hackathonType={type}
					questionPrefix={'Level'}
				>
					<List spacing={0.5}>
						<ListItem><ListIcon as={BiShapePolygon} color={'primary.900'} />Garden Shape: Diamond</ListItem>
						<ListItem><ListIcon as={RiPlantFill} color={'primary.900'} />Herbs: 30</ListItem>
						<ListItem><ListIcon as={GiWaterSplash} color={'primary.900'} />Sprinklers: 6</ListItem>
					</List>
				</HackathonSolutionQuestion>
		},
		4: {
			'company':
				<HackathonSolutionQuestion
					questionNumber={4}
					loading={highestSolutionLoading}
					uploadFunction={openUploadModal}
					submission={getHighestSubmissionForQuestion(4)}
					hackathonType={type}
					questionPrefix={'Level'}
				>
					<List spacing={0.5}>
						<ListItem><ListIcon as={BiPlanet} color={'primary.900'} />Planet Radius: <strong>210</strong></ListItem>
						<ListItem><ListIcon as={BiHive} color={'primary.900'} />Number of Biomes: <strong>8</strong></ListItem>
					</List>
				</HackathonSolutionQuestion>
			,
			'university':
				<HackathonSolutionQuestion
					questionNumber={4}
					loading={highestSolutionLoading}
					uploadFunction={openUploadModal}
					submission={getHighestSubmissionForQuestion(4)}
					hackathonType={type}
					questionPrefix={'Level'}
				>
					<List spacing={0.5}>
						<ListItem><ListIcon as={BiPlanet} color={'primary.900'} />Planet Radius: <strong>210</strong></ListItem>
						<ListItem><ListIcon as={BiHive} color={'primary.900'} />Number of Biomes: <strong>8</strong></ListItem>
					</List>
				</HackathonSolutionQuestion>
			,
			'iitpsa':
				<HackathonSolutionQuestion
					questionNumber={4}
					loading={highestSolutionLoading}
					uploadFunction={openUploadModal}
					submission={getHighestSubmissionForQuestion(4)}
					hackathonType={type}
					questionPrefix={'Level'}
				>
					<List spacing={0.5}>
						<ListItem><ListIcon as={BiShapePolygon} color={'primary.900'} />Garden Shape: Circle</ListItem>
						<ListItem><ListIcon as={RiPlantFill} color={'primary.900'} />Herbs: 80</ListItem>
						<ListItem><ListIcon as={GiWaterSplash} color={'primary.900'} />Sprinklers: 24</ListItem>
					</List>
				</HackathonSolutionQuestion>
		},
		5: {
			// 'company':
			//     <HackathonSolutionQuestion
			//         questionNumber={5}
			//         loading={highestSolutionLoading}
			//         uploadFunction={openUploadModal}
			//         submission={getHighestSubmissionForQuestion(5)}
			//         hackathonType={type}
			//         questionPrefix={'Map'}
			//     >
			//         <List spacing={0.5}>
			//             <ListItem><ListIcon as={ImMap} color={'primary.900'}/>Max size [X, Y]: [<strong>100, 100</strong>]</ListItem>
			//             <ListItem><ListIcon as={ImDroplet} color={'primary.900'}/>Number of locations: <strong>5</strong></ListItem>
			//         </List>
			//     </HackathonSolutionQuestion>

			// "iitpsa":
			//     <HackathonSolutionQuestion
			//         questionNumber={5}
			//         loading={highestSolutionLoading}
			//         uploadFunction={openUploadModal}
			//         submission={getHighestSubmissionForQuestion(5)}
			//         hackathonType={type}
			//         questionPrefix={'Galaxy'}
			//     >
			//         <List spacing={0.5}>
			//             <ListItem><ListIcon as={ImStack} color={'primary.900'}/>Number of Unique Resources: 10</ListItem>
			//             <ListItem><ListIcon as={ImRocket} color={'primary.900'}/>Number of Ships: 100</ListItem>
			//             <ListItem><ListIcon as={ImMeter} color={'primary.900'}/>Ship Capacity: 5000</ListItem>
			//             <ListItem><ListIcon as={ImLab} color={'primary.900'}/>Number of Labs: 20</ListItem>
			//             <ListItem><ListIcon as={ImWarning} color={'primary.900'}/>Outpost Threshold: 6000000</ListItem>
			//             <ListItem><ListIcon as={ImListNumbered} color={'primary.900'}/>Number of Quotas: 10</ListItem>
			//         </List>
			//     </HackathonSolutionQuestion>
		}
	};

	return (
		<>
			{!isSolutionsOpen ? (<>
				<Box height={'100%'}>
					<HackathonComponentSwitch
						switchDate={
							getValueForEntryType(
								type,
								{
									university: hackathonUniversityTournament.solutionOpenDate,
									company: hackathonCompanyTournament.solutionOpenDate,
									iitpsa: hackathonIitpsaTournament.solutionOpenDate
								}
							) as Date
						}
						busyComponent={
							<Box
								height={'100%'}
								background={`url(${solution_not_open_background})`}
								backgroundSize={'cover'}
								backgroundAttachment={'fixed'}
								backgroundRepeat={'no-repeat'}
								backgroundPosition={'center'}
								as="main"
							>
								<Grid templateColumns={'repeat(12, 1fr)'} h={'100%'}>
									<GridItem colSpan={[1, 1, 1, 3]} />
									<GridItem colSpan={[10, 10, 10, 6]}>
										<Box mt={10}>
											<Alert status="warning">
												<AlertIcon />
												<Box flexGrow={1}>
													<AlertTitle>Uploading solutions closed!</AlertTitle>
													<AlertDescription display="block">
														Uploading solutions will be open
														between <b>{moment(getValueForEntryType(type, {
															university: hackathonUniversityTournament.solutionOpenDate,
															company: hackathonCompanyTournament.solutionOpenDate,
															iitpsa: hackathonIitpsaTournament.solutionOpenDate
														}) as Date)
															.format('YYYY-MM-DD HH:mm')}</b> and <b>{moment(getValueForEntryType(type, {
																university: hackathonUniversityTournament.solutionCloseDate,
																company: hackathonCompanyTournament.solutionCloseDate,
																iitpsa: hackathonIitpsaTournament.solutionCloseDate
															}) as Date)
																.format('YYYY-MM-DD HH:mm')}</b>
														<br />
														The solutions will automatically open when the time is right.
													</AlertDescription>
												</Box>
											</Alert>
										</Box>
									</GridItem>
								</Grid>
							</Box>
						}
						completedComponent={
							<HackathonComponentSwitch
								switchDate={
									getValueForEntryType(
										type,
										{
											university: hackathonUniversityTournament.solutionCloseDate,
											company: hackathonCompanyTournament.solutionCloseDate,
											iitpsa: hackathonIitpsaTournament.solutionCloseDate
										}
									) as Date
								}
								busyComponent={
									<></>
								}
								completedComponent={
									<Box
										height={'100%'}
										background={`url(${solution_closed_background})`}
										backgroundSize={'contain'}
										backgroundAttachment={'fixed'}
										backgroundRepeat={'no-repeat'}
										backgroundPosition={'center'}
										as="main"
									>
										<Grid templateColumns={'repeat(12, 1fr)'} h={'100%'}>
											<GridItem colSpan={[1, 1, 1, 3]} />
											<GridItem colSpan={[10, 10, 10, 6]}>
												<Box mt={10}>
													<Alert status="warning">
														<AlertIcon />
														<Box flex="1">
															<AlertTitle>Uploading solutions closed!</AlertTitle>
															<AlertDescription display="block">
																Uploading solutions is now closed. The Entelect Challenge Admins
																are now verifying the results. <br />
																The leaderboard will unfreeze once the verification is complete.
															</AlertDescription>
														</Box>
													</Alert>
												</Box>
											</GridItem>
										</Grid>
									</Box>
								}
							/>
						}
					/>
				</Box>
			</>) : (<>
				<AnimatedBox
					w="100%"
					h="100%"
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<Square p={5} shadow="md" borderWidth="0.1em"
						background={'whiteAlpha.800'}
						borderRadius={'0.5em'}
						m={'10'}
						maxWidth={'96em'}
						minH={'50vh'}
						alignSelf={'center'}
						flexDirection={'column'}>

						<Box height={'100%'}>
							<HackathonComponentSwitch
								switchDate={
									getValueForEntryType(
										type,
										{
											university: hackathonUniversityTournament.solutionOpenDate,
											company: hackathonCompanyTournament.solutionOpenDate,
											iitpsa: hackathonIitpsaTournament.solutionOpenDate
										}
									) as Date
								}
								busyComponent={
									<Box
										height={'100%'}
										background={`url(${solution_not_open_background})`}
										backgroundSize={'cover'}
										backgroundAttachment={'fixed'}
										backgroundRepeat={'no-repeat'}
										backgroundPosition={'center'}
										as="main"
									>
										<Grid templateColumns={'repeat(12, 1fr)'} h={'100%'}>
											<GridItem colSpan={[1, 1, 1, 3]} />
											<GridItem colSpan={[10, 10, 10, 6]}>
												<Box mt={10}>
													<Alert status="warning">
														<AlertIcon />
														<Box flexGrow={1}>
															<AlertTitle>Uploading solutions closed!</AlertTitle>
															<AlertDescription display="block">
																Uploading solutions will be open
																between <b>{moment(getValueForEntryType(type, {
																	university: hackathonUniversityTournament.solutionOpenDate,
																	company: hackathonCompanyTournament.solutionOpenDate,
																	iitpsa: hackathonIitpsaTournament.solutionOpenDate
																}) as Date)
																	.format('YYYY-MM-DD HH:mm')}</b> and <b>{moment(getValueForEntryType(type, {
																		university: hackathonUniversityTournament.solutionCloseDate,
																		company: hackathonCompanyTournament.solutionCloseDate,
																		iitpsa: hackathonIitpsaTournament.solutionCloseDate
																	}) as Date)
																		.format('YYYY-MM-DD HH:mm')}</b>
																<br />
																The solutions will automatically open when the time is right.
															</AlertDescription>
														</Box>
													</Alert>
												</Box>
											</GridItem>
										</Grid>
									</Box>
								}
								completedComponent={
									<HackathonComponentSwitch
										switchDate={
											getValueForEntryType(
												type,
												{
													university: hackathonUniversityTournament.solutionCloseDate,
													company: hackathonCompanyTournament.solutionCloseDate,
													iitpsa: hackathonIitpsaTournament.solutionCloseDate
												}
											) as Date
										}
										busyComponent={
											<Box>
												<HackathonQuestionUploadModal
													questionNumberSelected={questionNumberSelected}
													isOpen={isOpen}
													onClose={() => closeUploadModal()} entryType={type}
													teamId={hackathonProfile.hackathonTeam.hackathonTeamId} />

												<Grid templateColumns={'repeat(1, 6fr)'} h={'100%'}>
													<GridItem colSpan={[1, 1, 1, 3]} />
													<GridItem colSpan={[12, 12, 12, 6]}>
														<Box mt={10}>
															{
																practiseMode ?
																	<Tooltip
																		gutter={isMobile ? 8 : 12} hasArrow
																		placement={isMobile ? 'top' : 'left'} isOpen
																		label="PRACTISE MODE ENABLED" fontSize="md"
																		bg={'orange.300'}>
																		<Box>
																			<FancyHeading
																				heading={'Problem Description'}
																				headingSize={'3xl'} />
																		</Box>
																	</Tooltip>
																	: <Box>
																		<FancyHeading heading={'Problem Description'} headingSize={'3xl'} />
																	</Box>
															}

															<Stack
																alignItems={'flex-end'} mt={4}
																direction={['column', 'column', 'row', 'row']}>
																{
																	getValueForEntryType(
																		type,
																		{
																			iitpsa:
																				<VStack spacing={2} alignItems={'start'}>
																					<Text pr={[0, 0, 8, 16]} color={'gray.600'}
																						textAlign={['center', 'center', 'start', 'start']}>
																						Good morning Greenthumb, I hope you’ve put on sunscreen and brought your hat. Today we’re spending the whole day in the garden!
																					</Text>
																					<br />
																					<Text pr={[0, 0, 8, 16]} color={'gray.600'}
																						textAlign={['center', 'center', 'start', 'start']}>
																						There will be four different gardens to optimize today. Each garden has a maximum number of herbs and sprinklers you can place.
																					</Text>
																					<Text pr={[0, 0, 8, 16]} color={'gray.600'}
																						textAlign={['center', 'center', 'start', 'start']}>
																						The goal of today’s hackathon is to <b>optimise the growth rate of your entire garden</b> to rake in the most profits and walk away the <b>winner!</b>
																					</Text>
																					<Text pr={[0, 0, 8, 16]} color={'gray.600'}
																						textAlign={['center', 'center', 'start', 'start']}>
																						There are ten herbs at your disposal today namely <b>Rosemary</b>, <b>Basil</b>, <b>Mint</b>, <b>Lavender</b>, <b>Cilantro</b>, <b>Ginseng</b>, <b>Angelica</b>, <b>Patchouli</b>, <b>Valerian</b>, and <b>Rue</b>.
																					</Text>
																					<Text pr={[0, 0, 8, 16]} color={'gray.600'}
																						textAlign={['center', 'center', 'start', 'start']}>
																						Each herb has its own set of requirements to optimize growth. Some herbs are also more valuable than others.
																					</Text>
																					<Text pr={[0, 0, 8, 16]} color={'gray.600'}
																						textAlign={['center', 'center', 'start', 'start']}>
																						Herbs require unobstructed sunlight as well as water coverage to optimise growth.
																					</Text>
																					<Text pr={[0, 0, 8, 16]} color={'gray.600'}
																						textAlign={['center', 'center', 'start', 'start']}>
																						You must determine the optimum placement of herbs and water sprinklers to maximise the profits you gain from selling your herbs.
																					</Text>
																					<Text pr={[0, 0, 8, 16]} color={'gray.600'}
																						textAlign={['center', 'center', 'start', 'start']}>
																						There will be four different gardens to optimize today. Each garden has a maximum number of herbs and sprinklers you can place.
																					</Text>
																				</VStack>,
																			university:
																				<VStack spacing={2} alignItems={'start'}>
																					<Text pr={[0, 0, 8, 16]} color={'gray.600'}
																						textAlign={['center', 'center', 'start', 'start']}>
																						The year is 2894. Your mission, should you choose to accept it,
																						is to undertake the monumental task of terraforming four
																						currently uninhabitable planets for the benefit of the United Alliance.
																						These planets, once transformed into life-supporting environments,
																						will serve a crucial role in sustaining our civilization
																						by being utilized in growing crops for our colonies.
																					</Text>
																					<Text pr={[0, 0, 8, 16]} color={'gray.600'}
																						textAlign={['center', 'center', 'start', 'start']}>
																						There are <b>eight</b> different biome types: Sizzling Sahara,
																						Violent Volcanoes, Shocking Storms, Curious Caves,
																						Blazing Badlands, Perilous Peaks, Radiant Ravines, and Windy Wallows.
																					</Text>
																				</VStack>,
																			company:
																				<VStack spacing={2} alignItems={'start'}>
																					<Text pr={[0, 0, 8, 16]} color={'gray.600'}
																						textAlign={['center', 'center', 'start', 'start']}>
																						The year is 2894. Your mission, should you choose to accept it,
																						is to undertake the monumental task of terraforming four
																						currently uninhabitable planets for the benefit of the United Alliance.
																						These planets, once transformed into life-supporting environments,
																						will serve a crucial role in sustaining our civilization
																						by being utilized in growing crops for our colonies.
																					</Text>
																					<Text pr={[0, 0, 8, 16]} color={'gray.600'}
																						textAlign={['center', 'center', 'start', 'start']}>
																						There are <b>eight</b> different biome types: Sizzling Sahara,
																						Violent Volcanoes, Shocking Storms, Curious Caves,
																						Blazing Badlands, Perilous Peaks, Radiant Ravines, and Windy Wallows.
																					</Text>
																				</VStack>
																		}
																	)
																}

																<Spacer />
																<Box w={['100%', '100%', 'auto', 'auto']}>
																	<Center w={'100%'}>
																		<Button colorScheme={'primary'}
																			isLoading={preparingFileDownload}
																			loadingText={'Preparing'}
																			onClick={() => downloadSpecificationFile()}>
																			Download specification
																		</Button>
																	</Center>
																</Box>
															</Stack>
														</Box>
														<Divider my={5} />
														<Box>
															<FancyHeading
																heading={'Levels to optimize'}
																headingSize={'2xl'}
																lines={[true, false]}
																action={
																	<Button
																		colorScheme={'primary'}
																		variant={'ghost'}
																		isLoading={highestSolutionLoading}
																		loadingText={'Refreshing Scores'}
																		onClick={() => loadHighestSubmissions()}
																	>
																		Refresh Scores
																	</Button>
																} />
															<VStack spacing={2} divider={<Divider />} mt={4}>
																{
																	solutionLines[1][type] && solutionLines[1][type]
																}
																{
																	solutionLines[2][type] && solutionLines[2][type]
																}
																{
																	solutionLines[3][type] && solutionLines[3][type]
																}
																{
																	solutionLines[4][type] && solutionLines[4][type]
																}
																{
																	solutionLines[5][type] && solutionLines[5][type]
																}
															</VStack>
														</Box>
														<Divider my={5} />
														<Box>
															<FancyHeading
																heading={'Uploads'}
																headingSize={'2xl'}
																lines={[true, false]}
																action={
																	<Button
																		colorScheme={'primary'}
																		variant={'ghost'}
																		isLoading={submissionsLoading}
																		loadingText={'Refreshing Submissions'}
																		onClick={() => loadSubmissions()}
																	>
																		Refresh Submissions
																	</Button>
																} />
															{
																submissionsLoading
																	? <IntermediateMessageLoader
																		loadingMessage={'Fetching your submissions'} />
																	: <VStack spacing={1} divider={<Divider />} mt={2} mb={10}>
																		{
																			submissions.length === 0
																				?
																				<Text
																					fontSize={'lg'}
																					fontWeight={'semibold'}
																					color={'gray.600'}>
																					No uploaded solutions yet
																				</Text>
																				: submissions.map(submission => (
																					<HackathonSolutionSubmission
																						key={submission.hackathonSubmissionId}
																						submission={submission}
																						hackathonProfile={hackathonProfile}
																						hackathonType={type}
																					/>))
																		}
																	</VStack>
															}
														</Box>
													</GridItem>
												</Grid>
											</Box>
										}
										completedComponent={
											<Box
												height={'100%'}
												background={`url(${solution_closed_background})`}
												backgroundSize={'cover'}
												backgroundAttachment={'fixed'}
												backgroundRepeat={'no-repeat'}
												backgroundPosition={'center'}
												as="main"
											>
												<Grid templateColumns={'repeat(12, 1fr)'} h={'100%'}>
													<GridItem colSpan={[1, 1, 1, 3]} />
													<GridItem colSpan={[10, 10, 10, 6]}>
														<Box mt={10}>
															<Alert status="warning">
																<AlertIcon />
																<Box flex="1">
																	<AlertTitle>Uploading solutions closed!</AlertTitle>
																	<AlertDescription display="block">
																		Uploading solutions is now closed. The Entelect Challenge Admins
																		are now verifying the results. <br />
																		The leaderboard will unfreeze once the verification is complete.
																	</AlertDescription>
																</Box>
															</Alert>
														</Box>
													</GridItem>
												</Grid>
											</Box>
										}
									/>
								}
							/>

						</Box>

					</Square>
				</AnimatedBox>
			</>)}
		</>
	);
};
