import {extendTheme} from "@chakra-ui/react";

// USE http://mcg.mbitson.com/#!?primary=%234b5fd3&navigation=%23122547&accent=%23e27693 for color generation based on theme color
export const theme = extendTheme({
    components: {
        Alert: {
            baseStyle: {
                container: {
                    borderRadius: '0.375rem',
                }
            },
        },
    },
    fonts: {
        heading: `'Nunito', sans-serif`,
        body: `'Nunito', sans-serif`,
    },
    colors: {
        primary: {
            "50": "#f5f9f0",
            "100": "#d7e5c2",
            "200": "#bbcca1",
            "300": "#9dab87",
            "400": "#8c9979",
            "500": "#768166",
            "600": "#646d56",
            "700": "#505745",
            "800": "#43493a",
            "900": "#31352a"
        },
        primaryAlpha: {
            "50": "#f5f9f0",
            "100": "#d7e5c2",
            "200": "#bbcca1",
            "300": "#9dab87",
            "400": "#8c9979",
            "500": "#768166",
            "600": "#646d56",
            "700": "#505745",
            "800": "#43493a",
            "900": "#31352a"
        },
        accent: {
            "50": "#fdf6f4",
            "100": "#f6dad3",
            "200": "#eebaad",
            "300": "#e4907c",
            "400": "#de775f",
            "500": "#d55233",
            "600": "#b94124",
            "700": "#95341d",
            "800": "#7e2c19",
            "900": "#5c2012"
        },
        accentAlpha: {
            "50": "#fdf6f4",
            "100": "#f6dad3",
            "200": "#eebaad",
            "300": "#e4907c",
            "400": "#de775f",
            "500": "#d55233",
            "600": "#b94124",
            "700": "#95341d",
            "800": "#7e2c19",
            "900": "#5c2012"
        },
        navigation: {
            "50": "#f4f8f7",
            "100": "#d5e3e1",
            "200": "#b1cbc6",
            "300": "#85aea6",
            "400": "#6d9d95",
            "500": "#4c877d",
            "600": "#307468",
            "700": "#165f52",
            "800": "#125045",
            "900": "#0d3a32"
        }
    }
});
