import * as React from 'react';
import { Box } from "@chakra-ui/react";
import WidgetBot from "@widgetbot/react-embed";
import {StreamChatConfigModel} from "../../models/stream-config.model";

interface IStreamChatPortal {
    config: StreamChatConfigModel;
    width?: string | number;
    height?: string | number;
}

export const StreamChatPortal: React.FunctionComponent<IStreamChatPortal> = props => {

    const width = props.width ? props.width : 300;
    const height = props.height ? props.height : 600;

    const getChatForType = () => {
        switch (props.config.type) {
            case "twitch": {
                return (
                    <iframe title={'twitch'} src={`https://www.twitch.tv/embed/${props.config.sources[0]}/chat?parent=https://challenge.entelect.co.za`} height={height} width={width} />
                )
            }
            case "youtube": {
                return (
                    <iframe title={'yt'} src={`https://www.youtube.com/live_chat?v=${props.config.sources[0]}&embed_domain=https://challenge.entelect.co.za/`} height={height} width={width} />
                );
            }
            case "discord": {
                return (
                    <WidgetBot
                        width={+width}
                        height={+height}
                        server={props.config.sources[0]}
                        channel={props.config.sources[1]}
                    />
                );
            }
            case "none": {
                return  (<></>);
            }
        }
    }

    return (
        <Box h={'100%'}>
            {
                getChatForType()
            }
        </Box>
    )
};