import {AxiosError} from "axios";
import * as H from "history";
import axiosInstance from "./axios-instance.api";

export const Interceptors = {
  setupTokenInterceptor: () => {
      axiosInstance.interceptors.request.use((config) => {
          const key = localStorage.getItem("token");
          config.headers['Authorization'] = `Bearer ${key}`
          return config
      });
  },

  setupUnauthorizedInterceptor: (history: H.History) => {
      axiosInstance.interceptors.response.use(response => response, (error) => {
          const response = (error as AxiosError).response;
          if (
            !history.location.pathname.includes("signin") && 
            response && 
            response.status === 401
          ) {
            history.push({
              pathname: "/signin",
              state: { from: history.location.pathname }
            });
          }

          return Promise.reject(error);
      });
  },
}
