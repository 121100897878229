import { Box, Flex, Image, Spacer, Square } from "@chakra-ui/react";
import React from "react";
import { SquadTypeEnum } from "../../../assets/images/team/squad-type.enum";
import teamAssets, { TeamMemberDetails } from "../../../assets/images/team/teamAssets";
import bug_hunt_team from "../../../assets/images/team/why_did_the_chicken_NOT_cross_the_road_gandalf_said_you_shall_not_pass.png";
import { TeamSquadMembers } from "./TeamSquadMembers";
import background from "../../../assets/images/main/main_background.png";

interface ITeamPage {
}

export const TeamPage: React.FunctionComponent<ITeamPage> = () => {
    const textColumnMaxWidth = "92em";
    const teamMemberDetails: TeamMemberDetails[] = teamAssets;

    const cloudSquad = teamMemberDetails.filter(t => t.teamSquad === SquadTypeEnum.CLOUD);
    const gameEngineSquad = teamMemberDetails.filter(t => t.teamSquad === SquadTypeEnum.GAME_ENGINE);
    const hackathonSquad = teamMemberDetails.filter(t => t.teamSquad === SquadTypeEnum.HACKATHON);
    const marketingSquad = teamMemberDetails.filter(t => t.teamSquad === SquadTypeEnum.MARKETING);
    const projectManagementSquad = teamMemberDetails.filter(t => t.teamSquad === SquadTypeEnum.PROJECT_MANAGEMENT);
    const websiteSquad = teamMemberDetails.filter(t => t.teamSquad === SquadTypeEnum.WEBSITE);

    return (
        <Box
            py={5}
            background={`url(${background})`}
            backgroundSize={'cover'}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={"center"}
            as="main"
            w={"100%"}
        >
            <Flex flexDirection={"column"}>

                <TeamSquadMembers teamMembers={cloudSquad} squadName={SquadTypeEnum.CLOUD}/>

                <TeamSquadMembers teamMembers={gameEngineSquad} squadName={SquadTypeEnum.GAME_ENGINE}/>

                <TeamSquadMembers teamMembers={hackathonSquad} squadName={SquadTypeEnum.HACKATHON}/>

                <Square
                    width={"90%"}
                    maxWidth={textColumnMaxWidth}
                    h={"0vh"}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Image
                        h={"5em"}
                        objectFit={"contain"}
                        pos="relative" top="1vw" left="50%"
                        fit={"contain"}
                        src={bug_hunt_team}
                    />
                </Square>

                <TeamSquadMembers teamMembers={marketingSquad} squadName={SquadTypeEnum.MARKETING}/>

                <TeamSquadMembers teamMembers={projectManagementSquad} squadName={SquadTypeEnum.PROJECT_MANAGEMENT}/>

                <TeamSquadMembers teamMembers={websiteSquad} squadName={SquadTypeEnum.WEBSITE}/>

                <Spacer pb={5}/>
            </Flex>
        </Box>
    );
};
