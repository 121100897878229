const gallaryImages ={
    2021_1:require('./2021_1.jpeg').default,
    2021_2:require('./2021_2.jpeg').default,
    2021_3:require('./2021_3.jpeg').default,
    2021_4:require('./2021_4.jpeg').default,
    2021_5:require('./2021_5.jpeg').default,
    2021_6:require('./2021_6.jpeg').default,
    2021_7:require('./2021_7.jpeg').default,
    2021_8:require('./2021_8.jpeg').default,
    2021_9:require('./2021_9.jpeg').default,
    2021_10:require('./2021_10.jpeg').default,
    2021_11:require('./2021_11.jpeg').default

}
export default gallaryImages;