import { Button, Flex, Spacer, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import TournamentItemModel from '../../../models/tournament-item.model';

interface ITournamentDetails {
	currentTournamentId: string;
	tournamentItem: TournamentItemModel;
	onLeaderboardNavigation: (tournamentId: string) => void;
}

export const TournamentDetails: FC<ITournamentDetails> = (props) => {
	let textColumnMaxWidth = '96em';
	let titleFontSize = 45;
	let minViewH = '50vh';
	let otherTextFontSize = 'calc(1.2em + 1vmin)';
	let mainFontColor = 'navigation.700';
	let buttonColor = 'navigation.800';

	return (
		<>
			<Flex
				direction={'column'}
				minH={minViewH}
				align={'center'}
				width={'80%'}>
				<Text
					fontWeight={'bold'}
					fontSize={titleFontSize}
					color={mainFontColor}
					align={'center'}
					maxWidth={textColumnMaxWidth}
					colorScheme="primary">
					{props.tournamentItem.name}
				</Text>
				<Spacer/>
				<Text
					fontSize={otherTextFontSize}
					fontWeight={'bold'}
					color={'accent.600'}
					align={'center'}
					maxWidth={textColumnMaxWidth}
				>
					Deadline:
				</Text>
				<Text
					fontSize={otherTextFontSize}
					color={mainFontColor}
					align={'center'}
					maxWidth={textColumnMaxWidth}
					colorScheme="primary">
					{props.tournamentItem.deadline}
				</Text>
				<Spacer/>
				<Text
					fontSize={otherTextFontSize}
					color={'accent.600'}
					align={'center'}
					fontWeight={'bold'}
					maxWidth={textColumnMaxWidth}>
					Prizes:
				</Text>

				{props.tournamentItem.prizeList.map((prize, index) => (
					<Text
						key={index}
					>
						{prize}
					</Text>
				))}
				<Button
					m={5}
					size={'lg'}
					backgroundColor={buttonColor}
					colorScheme={'navigation'}
					color={'whiteAlpha.900'}
					disabled={props.tournamentItem.tournamentId.length <= 0 || props.tournamentItem.tournamentId !== props.currentTournamentId}
					onClick={() => props.onLeaderboardNavigation(props.tournamentItem.tournamentId)}>
					LEADERBOARD
				</Button>
			</Flex>
		</>
	);
};
