import { Alert, AlertIcon, Box, Center, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import MatchModel from '../../../models/match.model';
import { Pagination } from '../../../shared/Pagination';

interface ITournamentMatches {
  tournamentMatchItems: MatchModel[];
}

export const TournamentMatches: FunctionComponent<ITournamentMatches> = props => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentSorts, setCurrentSorts] = useState<MatchSortModel>({
    scoreSort: 0,
    placementSort: 0
  });
  const [currentMatchItems, setCurrentMatchItems] = useState<MatchModel[]>(props.tournamentMatchItems);

  const pageSize: number = 10;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;

    if (currentMatchItems?.length > 0) {
      return currentMatchItems.slice(firstPageIndex, lastPageIndex);
    } else {
      return [];
    }
  }, [currentPage, currentMatchItems]);

  function sortMatches(sort: MatchSortModel, isPlacementSort: boolean) {
    if ((sort.scoreSort === 2 && !isPlacementSort) || (sort.placementSort === 2 && isPlacementSort)) {
      setCurrentSorts({ scoreSort: 0, placementSort: 0 });
      setCurrentMatchItems(props.tournamentMatchItems);
    } else {
      let reversed = false;
      if (isPlacementSort) {
        reversed = sort.placementSort === 1;
        setCurrentSorts({ scoreSort: 0, placementSort: sort.placementSort + 1 });
        const sortedMatchItems = [...props.tournamentMatchItems].sort((a, b) => {
          return reversed ? b.placement - a.placement : a.placement - b.placement;
        });
        setCurrentMatchItems(sortedMatchItems);
      } else {
        reversed = sort.scoreSort === 1;
        setCurrentSorts({ scoreSort: sort.scoreSort + 1, placementSort: 0 });
        const sortedMatchItems = [...props.tournamentMatchItems].sort((a, b) => {
          return reversed ? b.finalScore - a.finalScore : a.finalScore - b.finalScore;
        });
        setCurrentMatchItems(sortedMatchItems);
      }
    }
  }

  const handleRender = () => {
    let titleFontSize = 'calc(0.8em + 1vmin)';
    let titleFontColor = 'accent.600';
    function determineCurrentSortingCharacter(sort: MatchSortModel, isPlacementSort: boolean) {
      let currentSort = isPlacementSort ? sort.placementSort : sort.scoreSort;
      if (currentSort === 1) {
        return <FaChevronUp display='inline' />; // Up arrow
      }
      if (currentSort === 2) {
        return <FaChevronDown display='inline' />; // Down arrow
      }
      return "";
    }

    function getColorBasedOnStatus(status: string) {
      if (status.toLocaleLowerCase().includes('completed')) {
        return 'green';
      } else if (status.toLocaleLowerCase().includes('failed')) {
        return 'red';
      } else if (status.toLocaleLowerCase().includes('terminated')) {
        return 'gray'
      }
      return 'blue';
    }

    if (props.tournamentMatchItems.length > 0) {
      return <>
        <TableContainer>
          <Table variant="simple" colorScheme="teal">
            <Thead>
              <Tr>
                <Th
                  textAlign="center"
                  fontWeight={'semibold'}
                  fontSize={titleFontSize}
                  color={titleFontColor}
                  style={{ cursor: 'pointer' }}
                  onClick={_ => {
                    sortMatches(currentSorts, true);
                  }}>
                  <Center>
                    Placement<Box px={2}>{determineCurrentSortingCharacter(currentSorts, true)}</Box>
                  </Center>
                </Th>
                <Th
                  textAlign="center"
                  fontWeight={'semibold'}
                  fontSize={titleFontSize}
                  color={titleFontColor}
                  style={{ cursor: 'pointer' }}
                  onClick={_ => {
                    sortMatches(currentSorts, false);
                  }}>
                  <Center>
                    Score<Box px={2}>{determineCurrentSortingCharacter(currentSorts, false)}</Box>
                  </Center>
                </Th>
                <Th textAlign="center"
                  fontWeight={'semibold'}
                  fontSize={titleFontSize}
                  color={titleFontColor}>
                  <Center>
                    Seed
									</Center>
                </Th>
                <Th textAlign="center"
                  fontWeight={'semibold'}
                  fontSize={titleFontSize}
                  color={titleFontColor}>
                  Match Id
								</Th>
                <Th textAlign="center"
                  fontWeight={'semibold'}
                  fontSize={titleFontSize}
                  color={titleFontColor}>
                  Status
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentTableData.map((item, index) => (
                <Tr key={index}>
                  <Td textAlign="center">{item.botState.toLocaleLowerCase().includes('completed') ? item.placement : '-'}</Td>
                  <Td textAlign="center">{item.botState.toLocaleLowerCase().includes('completed') ? item.finalScore : '-'}</Td>
                  <Td textAlign="center">{item.botState.toLocaleLowerCase().includes('completed') ? item.seed : '-'}</Td>
                  <Td textAlign="center">{item.matchDetailsId}</Td>
                  <Td
                    textAlign="center"
                    fontWeight="bold"
                    color={getColorBasedOnStatus(item.botState)}
                  >
                    {item.botState.toLocaleUpperCase()}
                  </Td>
                </Tr>
              ))}

            </Tbody>

          </Table>
        </TableContainer>

        <Pagination items={currentMatchItems} pageSize={pageSize} currentPage={currentPage}
          onPageChange={(page: number) => setCurrentPage(page)} />
      </>;
    } else {
      return <>
        <Alert status={'warning'} variant="left-accent" mb={5}>
          <AlertIcon />
					No matches found for this tournament
				</Alert>
      </>;
    }
  };

  return (
    <>
      {
        handleRender()
      }
    </>
  );
};

export default interface MatchSortModel {
  scoreSort: number,
  placementSort
  :
  number
}
