import gallery from '../gallery/gallaryIndex';

export const photos2017 = [
    {
      src: gallery[20171],
      width: 4,
      height: 3
    },
    {
      src: gallery[20172],
      width: 4,
      height: 3
    },
    {
      src: gallery[20173],
      width: 4,
      height: 3
    },
    {
      src: gallery[20174],
      width: 4,
      height: 3
    },
    {
      src: gallery[20175],
      width: 4,
      height: 3
    }
  ];