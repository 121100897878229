import axios from "axios";

const apiKey = '123456789abcdefghijklmnopq';

const headers = {
    'X-Api-Key': apiKey
};

export const axiosTournamentInstance = axios.create({
    baseURL: process.env.REACT_APP_TOURNAMENT_BASE_URL,
    headers
})

export default axiosTournamentInstance;
