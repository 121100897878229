import {atom, RecoilState, selector} from "recoil";

export const ciCdLanguageAtom: RecoilState<string> = atom<string>({
    key: 'ci_cd_selected_language_atom',
    default: ''
});

export const hasCiCdLanguageAtom = selector<boolean>({
    key: 'player_has_selected_ci_cd_language_file',
    get: ({ get }) => {
        const selectedCiCdLanguage = get(ciCdLanguageAtom);
        return selectedCiCdLanguage.length > 0
    }
})
