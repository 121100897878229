import gallery from '../gallery/gallaryIndex';

export const photos2013 = [
    {
      src: gallery[20131],
      width: 4,
      height: 3
    },
    {
      src: gallery[20132],
      width: 4,
      height: 3
    },
    {
      src: gallery[20133],
      width: 4,
      height: 3
    },
    {
      src: gallery[20134],
      width: 4,
      height: 3
    },
    {
      src: gallery[20135],
      width: 4,
      height: 3
    },
    {
      src: gallery[20136],
      width: 4,
      height: 3
    },
    {
      src: gallery[20137],
      width: 4,
      height: 3
    },
    {
      src: gallery[20138],
      width: 4,
      height: 3
    },
    {
      src: gallery[20139],
      width: 4,
      height: 3
    },
    {
      src: gallery[201310],
      width: 4,
      height: 3
    },
    {
      src: gallery[201311],
      width: 4,
      height: 3
    },
    {
      src: gallery[201312],
      width: 4,
      height: 3
    },
    {
      src: gallery[201313],
      width: 4,
      height: 3
    },
    {
      src: gallery[201314],
      width: 4,
      height: 3
    },
    {
      src: gallery[201315],
      width: 4,
      height: 3
    },
    {
      src: gallery[201316],
      width: 4,
      height: 3
    },
    {
      src: gallery[201317],
      width: 4,
      height: 3
    },
    {
      src: gallery[201318],
      width: 4,
      height: 3
    },
    {
      src: gallery[201319],
      width: 4,
      height: 3
    },
    {
      src: gallery[201320],
      width: 4,
      height: 3
    }
  ];