import React from "react";
import { Box, Center, Flex, Heading, Image, VStack } from "@chakra-ui/react";
import background from "../../../assets/images/shared/coming_soon_background.svg";
import logo from "../../../assets/images/entelect/EntelectChallenge_Logo_BW.svg";
import { useScaleRatio } from "../../../utils/hooks/use-scale-ratio";
import { CountDown } from "../../home/CountDown";
import { useRecoilValue } from "recoil";
import { getSettingAsDate, SETTING_NAME_UNAUTHENTICATED } from "../../../atoms/settings.atom";

interface IUnauthenticatedComingSoon {
}

export const ComingSoon: React.FunctionComponent<IUnauthenticatedComingSoon> = (props) => {
    let columnMinWidth = "15em";
    const countdownDate = useRecoilValue(getSettingAsDate(SETTING_NAME_UNAUTHENTICATED.COMING_SOON_COUNTDOWN_DATE));

    return (
        <Box
            background={`linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) ), url(${background})`}
            backgroundSize={"cover"}
            backgroundAttachment={"fixed"}
            backgroundRepeat={"no-repeat"}
            backgroundPosition={"center"}
            as="main"
            h={"100vh"}>
            <Flex
                flexDirection={"column"}
                h={"100%"}>
                <VStack mt={useScaleRatio([20, 5])} spacing={8}>
                    <Box>
                        <Center>
                            <Image
                                alignSelf={"center"}
                                width={"30%"}
                                minWidth={columnMinWidth}
                                objectFit="cover"
                                src={logo}
                                alt="Entelect challenge logo"
                            />
                        </Center>
                    </Box>

                    <Box>
                        <Center>
                            <Heading
                                textAlign={"center"}
                                fontWeight={"bold"}
                                fontFamily={"Momcake-bold"}
                                fontSize={75}
                                color={"navigation.800"}
                                py={5}
                                colorScheme="accent">
                                Coming Soon
                            </Heading>
                        </Center>
                    </Box>

                    <Box mt={4}>
                        <Center>
                            <CountDown
                                date={countdownDate}
                                alternativeProps={{color: "navigation.700"}}
                            />
                        </Center>
                    </Box>
                </VStack>
            </Flex>
        </Box>
    );
};
