export const finalists2019 = [
    {
        id:1,
        finalist: 'Willie Theron',
        image: 'WillieTheron'
    },
    {
        id:2,
        finalist: 'Marvin Thobejane',
        image: 'MarvinThobejane'
    },
    {
        id:3,
        finalist: 'Jean Paul Da Conceicao',
        image: 'JeanPaulDaConceicao'
    },
    {
        id:4,
        finalist: 'Andre Makaal',
        image: 'AndreMakaal'
    },
    {
        id:5,
        finalist: 'Mallin Moolman',
        image: 'MallinMoolman'
    },
    {
        id:6,
        finalist: 'Gawie Jooste',
        image: 'GawieJooste'
    },
    {
        id:7,
        finalist: 'Leon Wessels',
        image: 'LeonWessels'
    },
    {
        id:8,
        finalist: 'Riaan Nel',
        image: 'RiaanNel'
    }
]