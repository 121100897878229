import * as React from 'react';
import {Flex, Grid, GridItem} from "@chakra-ui/react";

interface IGladosContainer {
}

export const GladosContainer: React.FunctionComponent<IGladosContainer> = props => {
    return (
        <Flex direction={'column'} minH={'100%'} as={"main"}>
            <Grid flexGrow={1}>
                <GridItem>
                    {props.children}
                </GridItem>
            </Grid>
        </Flex>
    )
};
