import { Box, Flex, Image, Square, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { TeamMemberDetails } from "../../../assets/images/team/teamAssets";

interface ITeamSquadMembers {
    teamMembers: Array<TeamMemberDetails>;
    squadName: string;
}

export const TeamSquadMembers: FC<ITeamSquadMembers> = (props) => {
    const [rows, setRows] = useState<TeamMemberDetails[][]>([]);

    const textColumnMaxWidth = "96em";
    const titleFontSize = 45;

    const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});
    const mainFontColor = "navigation.700";
    const otherTextFontSize = "calc(1.2em + 1vmin)";

    useEffect(() => {
        const displayRows = [];

        for (let i = 0; i < props.teamMembers.length; i += 4) {
            const displayRow = props.teamMembers.slice(i, i + 4);
            displayRows.push(displayRow);
        }

        setRows(displayRows);
    }, [props.teamMembers]);

    const handleDisplayForDifferentScreenSize = (member: TeamMemberDetails, containerWidth: string): ReactNode => {
        return (
            <>
                <Stack width={containerWidth} key={member.name} py={1}>
                    <Box align={"center"}>
                        <Image
                            borderRadius="full"
                            src={member.imageFile}
                            width={"16em"}
                            height={"16em"}
                            fit={"cover"}
                            alt={member.displayName}/>
                    </Box>
                    <Text
                        fontWeight={"bold"}
                        textAlign={"center"}
                        fontSize={otherTextFontSize}
                        color={mainFontColor}>{member.displayName}</Text>
                </Stack>
            </>
        );
    };

    return (
        <>
            <Square
                p={5} shadow="md" borderWidth="0.1em"
                background={"whiteAlpha.800"}
                borderRadius={"0.5em"}
                mt={5}
                width={"80%"}
                pb={5}
                maxWidth={textColumnMaxWidth}
                alignSelf={"center"}
                flexDirection={"column"}>

                <Text
                    fontWeight={"bold"}
                    fontSize={titleFontSize}
                    color={"accent.600"}
                    align={"center"}
                    maxWidth={textColumnMaxWidth}
                    colorScheme="primary"
                    as={"span"}>
                    {props.squadName}
                </Text>

                {
                    rows.map((row, index) => (
                            <Flex
                                key={index}
                                width={"100%"}
                                flexDirection={isMobile ? "column" : "row"}
                                align={"center"}
                                justifyContent={"center"}
                                marginTop={"24px"}>
                                {
                                    row.map((member: TeamMemberDetails, memberIndex: number) => (
                                        <>
                                            {isMobile ? (
                                                handleDisplayForDifferentScreenSize(member, "100%")
                                            ) : (
                                                handleDisplayForDifferentScreenSize(member, "25%")
                                            )}
                                        </>
                                    ))
                                }
                            </Flex>
                        )
                    )
                }
            </Square>
        </>
    );
};
