import * as React from 'react';
import {
    Box,
    Button,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spacer,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import NotificationModel from "../../models/notification.model";
import {NavigationNotificationBarItem} from "./NavigationNotificationBarItem";
import {Text} from "@chakra-ui/layout";
import {ExternalLinkIcon} from "@chakra-ui/icons";

interface INavigationNotificationsBar {
    isOpen: boolean;
    onClose: Function;
    notifications: Array<NotificationModel>;
    isLoading: boolean;
    isMobile: boolean | undefined;
}

const entelectChallengeForumLink = process.env.REACT_APP_ENTELECT_CHALLENGE_FORUM;

export const NavigationNotificationsBar: React.FunctionComponent<INavigationNotificationsBar> = props => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Box>
            <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Report a Bug</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text textAlign={'center'} px={10} color={'gray.700'}>
                            Please go to the Entelect Challenge forum and create a new post describing the bug you found.
                        </Text>
                        <Link href={entelectChallengeForumLink} isExternal textAlign={'center'} w={'100%'} fontSize={'lg'} display={'block'} mt={4} color={'primary.400'} mb={2} >
                            Entelect Challenge Forum <ExternalLinkIcon mx={1} mb={1} />
                        </Link>
                    </ModalBody>

                </ModalContent>
            </Modal>
            <Drawer
                isOpen={props.isOpen}
                placement="right"
                onClose={() => props.onClose()}
                size={props.isMobile ? "full" : "xs"}
            >
                <DrawerOverlay/>
                <DrawerContent>
                    <DrawerCloseButton/>
                    <DrawerHeader>Notifications</DrawerHeader>

                    <DrawerBody mb={2}>
                        <VStack h={'100%'}>
                            {
                                props.isLoading ?
                                    <>loading</> :
                                    props.notifications.length === 0 ?
                                        <>empty</> :
                                        <VStack alignItems={'start'} spacing={4} divider={<Divider/>}>
                                            {
                                                props.notifications.map((notification, index) => (
                                                    <NavigationNotificationBarItem key={index} notification={notification}/>
                                                ))
                                            }
                                        </VStack>
                            }
                            <Spacer />
                            <Box mt={2} w={'100%'}>
                                <Button w={'100%'} colorScheme={'primary'} variant={'outline'} onClick={onOpen}>Report a bug</Button>
                            </Box>
                        </VStack>
                    </DrawerBody>

                </DrawerContent>
            </Drawer>
        </Box>

    )
};
