import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Radio, RadioGroup, Select, Stack} from "@chakra-ui/react";
import {Field, FieldAttributes, Form, Formik} from "formik";
import * as React from 'react';
import {useState} from 'react';
import RegistrationInfoUniversityModel from "../../../models/registration-info-university.model";
import {universityRegistrationInfoValidation} from '../../../models/validation/university-registration-info-validation';
import {getCurrentYear} from "../../../utils/hooks/general-utils";

interface IHackathonRegisterFormUniversity {
    registrationInfoUniversityModel: RegistrationInfoUniversityModel | null;
    register: Function;
    loading: boolean;
}

export const HackathonRegisterFormUniversity: React.FunctionComponent<IHackathonRegisterFormUniversity> = props => {

    const defaultIdType = 'ID';
    const defaultIdNumberPlaceholder = `Your ${defaultIdType} number`;

    const [selectedIdType, setSelectedIdType] = useState<string>(defaultIdType);
    const [idNumberPlaceHolder, setIdNumberPlaceHolder] = useState<string>(defaultIdNumberPlaceholder);

    const handleIdTypeChange = (value: string) => {
        setSelectedIdType(value);
        const placeholder = `Your ${value} number`;

        setIdNumberPlaceHolder(placeholder)
    }

    const handleRegistrationSubmission = (values: any) => {
        props.register(values);
    }

    const initValues = props.registrationInfoUniversityModel ? props.registrationInfoUniversityModel : {
        playerId: '', // -- DB attribute
        universityRegistrationInfoId: '', // -- DB attribute
        playerIdType: 'ID', // -- Only value
        playerIdNumber: '', // RSA ID
        studentNumber: '', // University ID
        university: '', // University - Dropdown
        currentYearOfStudy: '', // Study Year - Dropdown
        fieldOfStudy: '', // -- Free text
        yearEntered: getCurrentYear() // current year
    } as RegistrationInfoUniversityModel;

    return (
        <Box>
            <Formik
                initialValues={initValues}
                onSubmit={handleRegistrationSubmission}
                isInitialValid={false}
                validationSchema={universityRegistrationInfoValidation}
                validateOnBlur={true}
                validateOnChange={true}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <Box mb={4}>
                            <FormControl component={'fieldset'} isRequired>
                                <FormLabel>ID Type</FormLabel>

                                <RadioGroup name={'playerIdType'}
                                            value={values.playerIdType}
                                            onChange={(selectedValue) => {
                                                setFieldValue('playerIdType', selectedValue);
                                                handleIdTypeChange(selectedValue);
                                            }}>

                                    <Stack spacing={5} direction={'row'}>
                                        <Radio colorScheme={'primary'} value={'ID'}>RSA ID</Radio>
                                        <Radio colorScheme={'primary'} value={'Passport'}>Passport</Radio>
                                    </Stack>

                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Field name={'playerIdNumber'}>
                            {({ field, form }: FieldAttributes<any>) => (
                                <Box mb={4}>
                                    <FormControl id="playerIdNumber" isRequired isInvalid={form.errors.playerIdNumber && form.touched.playerIdNumber}>
                                        <FormLabel>
                                            {selectedIdType} Number
                                        </FormLabel>
                                        <Input
                                            colorScheme={'primary'}
                                            {...field}
                                            id={'playerIdNumber'}
                                            type="text"
                                            size="md"
                                            variant="filled"
                                            placeholder={idNumberPlaceHolder}/>
                                        <FormErrorMessage>{form.errors.playerIdNumber}</FormErrorMessage>
                                    </FormControl>
                                </Box>
                            )}
                        </Field>
                        <Field name={'studentNumber'}>
                            {({ field, form }: FieldAttributes<any>) => (
                                <Box mb={4}>
                                    <FormControl id="studentNumber" isRequired isInvalid={form.errors.studentNumber && form.touched.studentNumber}>
                                        <FormLabel>Student Number</FormLabel>
                                        <Input colorScheme={'primary'} {...field} id={'studentNumber'} type="text" size="md" variant="filled" placeholder={'Your student number'}/>
                                        <FormErrorMessage>{form.errors.studentNumber}</FormErrorMessage>
                                    </FormControl>
                                </Box>
                            )}
                        </Field>
                        <Field name={'university'}>
                            {({ field, form }: FieldAttributes<any>) => (
                                <Box mb={4}>
                                    <FormControl id="university" isRequired isInvalid={form.errors.university && form.touched.university}>
                                        <FormLabel>University</FormLabel>
                                        <Select variant={'filled'} colorScheme={'primary'} {...field} placeholder="Select University" id={'university'} value={values.university}>
											<option value={'Durban University of Technology'}>Durban University of Technology</option>
                                            <option value={'Belgium University'}>Belgium University</option>
                                            <option value={'Cape Peninsula University of Technology'}>Cape Peninsula University of Technology</option>
                                            <option value={'Eduvos'}>Eduvos</option>
                                            <option value={'IIE MSA'}>IIE MSA</option>
                                            <option value={'IIE Varsity College'}>IIE Varsity College</option>
                                            <option value={'Nelson Mandela University'}>Nelson Mandela University</option>
                                            <option value={'North-West University'}>North-West University</option>
                                            <option value={'Rhodes University'}>Rhodes University</option>
                                            <option value={'Stellenbosch University'}>Stellenbosch University</option>
                                            <option value={'Tshwane University of Technology'}>Tshwane University of Technology</option>
                                            <option value={'University of Cape Town'}>University of Cape Town</option>
                                            <option value={'University of Johannesburg'}>University of Johannesburg</option>
                                            <option value={'University of KwaZulu Natal'}>University of KwaZulu Natal</option>
                                            <option value={'University of Pretoria'}>University of Pretoria</option>
                                            <option value={'University of South Africa'}>University of South Africa</option>
                                            <option value={'University of the Free State'}>University of the Free State</option>
                                            <option value={'University of the Western Cape'}>University of the Western Cape</option>
                                            <option value={'University of Witwatersrand'}>University of Witswatersrand</option>
                                        </Select>
                                        <FormErrorMessage>{form.errors.university}</FormErrorMessage>
                                    </FormControl>
                                </Box>
                            )}
                        </Field>
                        <Field name={'fieldOfStudy'}>
                            {({ field, form }: FieldAttributes<any>) => (
                                <Box mb={4}>
                                    <FormControl id="fieldOfStudy" isRequired isInvalid={form.errors.fieldOfStudy && form.touched.fieldOfStudy}>
                                        <FormLabel>Field of Study</FormLabel>
                                        <Input colorScheme={'primary'} {...field} id={'fieldOfStudy'} type="text" size="md" variant="filled" placeholder={'Field of study'}/>
                                        <FormErrorMessage>{form.errors.fieldOfStudy}</FormErrorMessage>
                                    </FormControl>
                                </Box>
                            )}
                        </Field>
                        <Field name={'currentYearOfStudy'}>
                            {({ field, form }: FieldAttributes<any>) => (
                                <Box mb={4}>
                                    <FormControl id="currentYearOfStudy" isRequired isInvalid={form.errors.currentYearOfStudy && form.touched.currentYearOfStudy}>
                                        <FormLabel>Current Year of Study</FormLabel>
                                        <Select variant={'filled'} colorScheme={'primary'} {...field} placeholder="Current year of study" id={'currentYearOfStudy'}
                                                value={values.currentYearOfStudy}>
                                            <option value={'1'}>1</option>
                                            <option value={'2'}>2</option>
                                            <option value={'3'}>3</option>
                                            <option value={'4'}>4</option>
                                            <option value={'5'}>5</option>
                                            <option value={'6'}>6</option>
                                            <option value={'7'}>7</option>
                                            <option value={'8'}>8</option>
                                            <option value={'9'}>9</option>
                                            <option value={'10'}>10</option>
                                        </Select>
                                        <FormErrorMessage>{form.errors.currentYearOfStudy}</FormErrorMessage>
                                    </FormControl>
                                </Box>
                            )}
                        </Field>
                        <Button
                            w={'100%'}
                            type={'submit'}
                            colorScheme={'primary'}
                            isLoading={props.loading}
                            loadingText={'Registering you for the University Cup'}
                            mt={2}>Register</Button>
                    </Form>
                )}

            </Formik>
        </Box>
    )
};
