import axios, {AxiosResponse} from "axios";
import {CreateTeam, JoinTeam} from "../models/company.model";
import {HackathonProfileResponseModel} from "../models/hackathon-profile.model";
import axiosInstance from "./axios-instance.api";
import {HackathonTeamMemberDtoModel, HackathonTeamMemberModel} from "../models/hackathon-team-member.model";
import HackathonTeamMemberManagementRequestModel from "../models/hackathon-team-member-management-request.model";
import HackathonUploadRequestModel from "../models/hackathon-upload-request.model";
import HackathonUploadResponseModel from "../models/hackathon-upload-response.model";
import HackathonSubmissionModel from "../models/hackathon-submission.model";
import {HackathonLeaderboardModel} from "../models/hackathon-leaderboard.model";
import {HackathonTeamModel} from "../models/hackathon-team.model";
import HackathonTournamentModel from "../models/hackathon-tournament.model";
import HackathonFunModel from "../models/hackathon-fun.model";
import HackathonFunVoteModel from "../models/hackathon-fun-vote.model";
import HackathonFunBetModel from "../models/hackathon-fun-bet.model";

export const HackathonApi = {
		getHackathonProfile: (entryType: string): Promise<AxiosResponse<HackathonProfileResponseModel>> => axiosInstance.get(`/hackathon/profile/${entryType}`),

		createTeam: (createTeamRequest: CreateTeam): Promise<AxiosResponse<HackathonProfileResponseModel>> => axiosInstance.post('/hackathon/team/create', createTeamRequest),

		joinTeam: (joinTeamRequest: JoinTeam): Promise<AxiosResponse<HackathonProfileResponseModel>> => axiosInstance.post('/v2/hackathon/team/join', joinTeamRequest),

		updateTeam: (hackathonTeam: HackathonTeamModel): Promise<AxiosResponse<HackathonTeamModel>> => axiosInstance.post('/hackathon/team/update', hackathonTeam),

		getTeamMembers: (entryType: string): Promise<AxiosResponse<Array<HackathonTeamMemberDtoModel>>> => axiosInstance.get(`/hackathon/team/members/${entryType}`),

		manageTeamMember: (entryType: string, hackathonTeamMemberManagementRequestModel: HackathonTeamMemberManagementRequestModel): Promise<AxiosResponse<HackathonTeamMemberModel>> => axiosInstance.post(`/hackathon/team/manage/${entryType}`, hackathonTeamMemberManagementRequestModel),

		getTeamCount: (entryType: string): Promise<AxiosResponse<number>> => axiosInstance.get(`/hackathon/team/count/${entryType}`),

		getUploadSolutionUrls: (entryType: string, hackathonUploadRequestModel: HackathonUploadRequestModel): Promise<AxiosResponse<HackathonUploadResponseModel>> => axiosInstance.post(`/hackathon/upload/${entryType}`, hackathonUploadRequestModel),

		uploadFile: (url: string, file: File | null): Promise<AxiosResponse<null>> => axios.put(url, file),

		getHighestScores: (entryType: string, teamId: string): Promise<AxiosResponse<Array<HackathonSubmissionModel>>> => axiosInstance.get(`/hackathon/scores/${entryType}/${teamId}`),

		getHackathonSubmissions: (entryType: string, teamId: string): Promise<AxiosResponse<Array<HackathonSubmissionModel>>> => axiosInstance.get(`hackathon/submissions/${entryType}/${teamId}`),

		getHackathonLeaderboard: (entryType: string): Promise<AxiosResponse<Array<HackathonLeaderboardModel>>> => axios.get(`${process.env.REACT_APP_API_URL}/hackathon/leaderboard/${entryType}`),

		placeMeInTeam: (entryType: string): Promise<AxiosResponse<HackathonProfileResponseModel>> => axiosInstance.post(`/hackathon/team/place-me/${entryType}`),

		getDownloadLinkForLogs: (entryType: string, teamId: string, submissionId: string): Promise<AxiosResponse<string>> => axiosInstance.get(`/hackathon/log/${entryType}/${teamId}/${submissionId}`),

		getDownloadLinkForFile: (entryType: string, hackathonFile: string): Promise<AxiosResponse<string>> => axiosInstance.get(`/hackathon/file/${entryType}/${hackathonFile}`),

		getAllTeams: (entryType: string) : Promise<AxiosResponse<Array<HackathonTeamModel>>> => axiosInstance.get(`/hackathon/team/all/${entryType}`),

    getVotableTeams: (tournamentId: string) : Promise<AxiosResponse<Array<HackathonTeamModel>>> => axiosInstance.get(`/hackathon/fun/teams/${tournamentId}`),

		getFunTournament: () : Promise<AxiosResponse<HackathonTournamentModel>> => axiosInstance.get(`/hackathon/fun/tournament`),

		getAllVotes: (tournamentId: string) : Promise<AxiosResponse<Array<HackathonFunModel>>> => axiosInstance.get(`/hackathon/fun/votes/${tournamentId}`),

		getPlayerBets: (tournamentId: string, playerId: string) : Promise<AxiosResponse<Array<HackathonFunModel>>> => axiosInstance.get(`/hackathon/fun/bets/${tournamentId}/${playerId}`),

		castVote: (hackathonFunVote: HackathonFunVoteModel) : Promise<AxiosResponse<Array<HackathonFunModel>>> => axiosInstance.post(`/hackathon/fun/votes`, hackathonFunVote),

		placeBet: (hackathonFunBet: HackathonFunBetModel) : Promise<AxiosResponse<Array<HackathonFunModel>>> => axiosInstance.post(`/hackathon/fun/bets`, hackathonFunBet)
}
