import {AxiosResponse} from "axios";
import axiosInstance from "./axios-instance.api";

const headers = {
    'Content-Type': 'text/plain; charset=utf-8'
}

const responseType = 'text' as 'json';

// TODO: Find a way to return a blob
// const downloadHeaders = {
//     'Content-Disposition': 'attachment; filename="cicd-build.yml"',
//     'Content-Type': 'text/plain; charset=utf-8'
// }

export const CiCdApi = {
    getCiCdFile: (selectedLanguage: string): Promise<AxiosResponse<string>> => axiosInstance.post(`/player/cicd`, { language: selectedLanguage }, { headers, responseType }),
    // TODO: Find a way to return a blob
    //downloadCiCdFile: (downloadUrl: string): Promise<AxiosResponse<string>> => axiosInstance.get(downloadUrl, { headers: downloadHeaders, responseType: 'text' as 'json' })
}
