import gallery from '../gallery/gallaryIndex';

export const photos2019 = [
    {
      src: gallery[20191],
      width: 4,
      height: 3
    },
    {
      src: gallery[20192],
      width: 4,
      height: 3
    },
    {
      src: gallery[20193],
      width: 4,
      height: 3
    },
    {
      src: gallery[20194],
      width: 4,
      height: 3
    },
    {
      src: gallery[20195],
      width: 4,
      height: 3
    },
    {
      src: gallery[20196],
      width: 4,
      height: 3
    },
    {
      src: gallery[20197],
      width: 4,
      height: 3
    },
    {
      src: gallery[20198],
      width: 4,
      height: 3
    },
    {
      src: gallery[20199],
      width: 4,
      height: 3
    },
    {
      src: gallery[201910],
      width: 4,
      height: 3
    },
    {
      src: gallery[201911],
      width: 4,
      height: 3
    },
    {
      src: gallery[201912],
      width: 4,
      height: 3
    },
    {
      src: gallery[201913],
      width: 4,
      height: 3
    },
    {
      src: gallery[201914],
      width: 4,
      height: 3
    },
    {
      src: gallery[201915],
      width: 4,
      height: 3
    },
    {
      src: gallery[201916],
      width: 4,
      height: 3
    },
    {
      src: gallery[201917],
      width: 4,
      height: 3
    },
    {
      src: gallery[201918],
      width: 4,
      height: 3
    },
    {
      src: gallery[201919],
      width: 4,
      height: 3
    },
    {
      src: gallery[201920],
      width: 4,
      height: 3
    },
    {
      src: gallery[201921],
      width: 4,
      height: 3
    },
    {
      src: gallery[201922],
      width: 4,
      height: 3
    },
    {
      src: gallery[201923],
      width: 4,
      height: 3
    },
    {
      src: gallery[201924],
      width: 4,
      height: 3
    },
    {
      src: gallery[201925],
      width: 4,
      height: 3
    },
    {
      src: gallery[201926],
      width: 4,
      height: 3
    },
    {
      src: gallery[201927],
      width: 4,
      height: 3
    },
    {
      src: gallery[201928],
      width: 4,
      height: 3
    },
    {
      src: gallery[201929],
      width: 4,
      height: 3
    },
    {
      src: gallery[201930],
      width: 4,
      height: 3
    },
    {
      src: gallery[201931],
      width: 4,
      height: 3
    },
    {
      src: gallery[201932],
      width: 4,
      height: 3
    },
    {
      src: gallery[201933],
      width: 4,
      height: 3
    },
    {
      src: gallery[201934],
      width: 4,
      height: 3
    },
    {
      src: gallery[201935],
      width: 4,
      height: 3
    },
    {
      src: gallery[201936],
      width: 4,
      height: 3
    },
    {
      src: gallery[201937],
      width: 4,
      height: 3
    },
    {
      src: gallery[201938],
      width: 4,
      height: 3
    },
    {
      src: gallery[201939],
      width: 4,
      height: 3
    },
    {
      src: gallery[201940],
      width: 4,
      height: 3
    },
    {
      src: gallery[201941],
      width: 4,
      height: 3
    },
    {
      src: gallery[201942],
      width: 4,
      height: 3
    },
    {
      src: gallery[201943],
      width: 4,
      height: 3
    },
    {
      src: gallery[201944],
      width: 4,
      height: 3
    },
    {
      src: gallery[201945],
      width: 4,
      height: 3
    },
    {
      src: gallery[201946],
      width: 4,
      height: 3
    },
    {
      src: gallery[201947],
      width: 4,
      height: 3
    }
  ];