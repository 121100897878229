const gallaryImages ={
    2013_1:require('./2013_1.jpg').default,
    2013_2:require('./2013_2.jpg').default,
    2013_3:require('./2013_3.jpg').default,
    2013_4:require('./2013_4.jpg').default,
    2013_5:require('./2013_5.jpg').default,
    2013_6:require('./2013_6.jpg').default,
    2013_7:require('./2013_7.jpg').default,
    2013_8:require('./2013_8.jpg').default,
    2013_9:require('./2013_9.jpg').default,
    2013_10:require('./2013_10.jpg').default,
    2013_11:require('./2013_11.jpg').default,
    2013_12:require('./2013_12.jpg').default,
    2013_13:require('./2013_13.jpg').default,
    2013_14:require('./2013_14.jpg').default,
    2013_15:require('./2013_15.jpg').default,
    2013_16:require('./2013_16.jpg').default,
    2013_17:require('./2013_17.jpg').default,
    2013_18:require('./2013_18.jpg').default,
    2013_19:require('./2013_19.jpg').default,
    2013_20:require('./2013_20.jpg').default,
}
export default gallaryImages;