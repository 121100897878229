import * as React from 'react';
import {Box} from "@chakra-ui/react";
import {SettingItemSection} from "../SettingItemSection";

interface IAccount {
}

export const Account: React.FunctionComponent<IAccount> = props => {
    return (
        <Box>
            <SettingItemSection
                heading={"Export account data"}
                subTexts={["Export all the data we have about you."]}
            />
            <SettingItemSection
                heading={"Delete your account"}
                subTexts={["Once you deleted your account, your data will be lost forever."]}
            />

        </Box>
    )
};