import {Avatar, Badge, Box, Button, HStack, Spacer, Stack, VStack} from '@chakra-ui/react'
import React, {useState} from 'react'
import {Text} from "@chakra-ui/layout";
import {CheckIcon, NotAllowedIcon} from "@chakra-ui/icons";
import {HackathonTeamMemberDtoModel} from "../../../models/hackathon-team-member.model";


interface ITeamPlayer {
    member: HackathonTeamMemberDtoModel;
    loadingMemberId: string;
    colorType: string;
    playerType: string;
    kickFunction: Function | null;
    acceptFunction: Function;
    rejectFunction: Function;
}

export const HackathonTeamPlayerItem: React.FunctionComponent<ITeamPlayer> = props => {

    const loading = props.loadingMemberId === props.member.hackathonTeamMember.hackathonTeamMemberId;
    const isActive = props.playerType === 'Active';

    const [loadingAction, setLoadingAction] = useState('');

    // const pictureArray = [TeamLead, TechLead, DevelopmentManager, softwareEngineer, theScout];
    // const randomIndex = Math.floor(Math.random() * pictureArray.length);
    // const selectedPicture = pictureArray[randomIndex];

    const doNothing = () => {};

    return (
        <Box >
            <Stack alignItems={'center'} direction={['column', 'column', 'row', 'row']}>
                <Avatar
                    borderColor={'white'}
                    // borderWidth={1}
                    size={'lg'}
                    name="Member Name"
                    src={props.member.player.imageUrl}
                    mr={4}
                />
                <VStack alignItems={['center', 'center', 'center', 'start']} w={'100%'}>
                    <Text mt="1" fontWeight="bold" color={'gray.700'} fontSize={'xl'}>
                        {props.member.player.displayName}
                    </Text>
                    <Badge mt={1} borderRadius="lg" px="4" py={0.5} colorScheme={props.colorType} >
                        <Text>{props.playerType}</Text>
                    </Badge>
                </VStack>
                <Spacer />
                <HStack px={4}>
                    {
                        isActive && props.kickFunction !== null &&
                        <Button
                            colorScheme="accent"
                            isLoading={loading && loadingAction === 'kicking'}
                            loadingText={'Kicking Member'}
                            leftIcon={<NotAllowedIcon />}
                            onClick={() => {
                                setLoadingAction('kicking');
                                props.kickFunction ? props.kickFunction() : doNothing();
                            }}
                            px={4}
                        >Kick member</Button>
                    }
                    {
                        !isActive &&
                        <Button
                            colorScheme="primary"
                            isDisabled={loading}
                            isLoading={loading && loadingAction === 'accept'}
                            loadingText={'Accepting Member'}
                            leftIcon={<CheckIcon />}
                            onClick={() => {
                                setLoadingAction('accept');
                                props.acceptFunction();
                            }}
                            px={4}
                        >Accept member</Button>
                    }
                    {
                        !isActive &&
                        <Button
                            colorScheme="accent"
                            isDisabled={loading}
                            isLoading={loading && loadingAction === 'reject'}
                            loadingText={'Rejecting Member'}
                            leftIcon={<NotAllowedIcon />}
                            onClick={() => {
                                setLoadingAction('reject');
                                props.rejectFunction();
                            }}
                            px={4}
                        >Reject member</Button>
                    }
                </HStack>
            </Stack>
        </Box>
    )
}
