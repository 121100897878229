import {atom, RecoilState, selector} from "recoil";
import {HackathonProfileResponseModel} from "../models/hackathon-profile.model";
import {HackathonProfileStatusEnum} from "../models/hackathon-profile-status.enum";

export const hackathonProfileAtom: RecoilState<HackathonProfileResponseModel> = atom<HackathonProfileResponseModel>({
    key: 'hackathon_player_atom',
    default: {
        hackathonTeam: {
            hackathonTeamId: '',
            hackathonType: '',
            teamCode: '',
            teamName: '',
            creationDate: 0,
            occupationName: '',
            canAcceptMembers: false,
        },
        hackathonTeamMember: {
            hackathonTeamMemberId: '',
            hackathonTeamId: '',
            joinStatus: HackathonProfileStatusEnum.UNASSIGNED,
            entryId: '',
            joinedDate: new Date()
        }
    },
});

export const doesHaveHackathonProfile = selector<boolean>({
    key: 'player_does_have_hackathon_profile_selector',
    get: ({get}) => {
        const hackathonProfile = get(hackathonProfileAtom);
        return !(hackathonProfile.hackathonTeam === null
            || hackathonProfile.hackathonTeamMember.hackathonTeamMemberId.length === 0
            || hackathonProfile.hackathonTeamMember.joinStatus === HackathonProfileStatusEnum.KICKED
            || hackathonProfile.hackathonTeamMember.joinStatus === HackathonProfileStatusEnum.PENDING
            || hackathonProfile.hackathonTeamMember.joinStatus === HackathonProfileStatusEnum.REJECTED
            || hackathonProfile.hackathonTeamMember.joinStatus === HackathonProfileStatusEnum.UNASSIGNED
            || hackathonProfile.hackathonTeamMember.joinStatus === HackathonProfileStatusEnum.PLACE);
    }
});