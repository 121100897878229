import { Box, Button, FormControl, FormLabel, Heading, Image, ListItem, Select, SimpleGrid, Stack, Text, UnorderedList } from "@chakra-ui/react";
import axios from "axios";
import * as React from "react";
import { ChangeEvent, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { CiCdApi } from "../../api/ci-cd.api";
import { ciCdLanguageAtom, hasCiCdLanguageAtom } from "../../atoms/ci-cd-language.atom";
import { enumSelector } from "../../helpers/typescriptHelper";
import { LanguageOption } from "../../models/language-option.enum";
import bug_hunt_ci_cd_download from "../../assets/images/ci-cd/shire_syntax_adventure.png";

interface IDownloadCiCdFile {

}

export const CICDFileDownload: React.FunctionComponent<IDownloadCiCdFile> = props => {
    const hasCiCdLanguageBeenSelected = useRecoilValue(hasCiCdLanguageAtom);
    const [selectedCiCdLanguage, setSelectedCiCdLanguage] = useRecoilState(ciCdLanguageAtom);

    const [isCiCdFileDownloading, setIsCiCdFileDownloading] = useState<boolean>(false);

    const languageOptions = enumSelector(LanguageOption);

    const downloadHeaders = {
        'Content-Disposition': 'attachment; filename="cicd-build.yml"',
        'Content-Type': 'text/plain; charset=utf-8'
    }

    const handleLanguageOptionChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.selectedOptions[0].value;
        setSelectedCiCdLanguage(value);
    }

    const handleDownloadCiCdFile = () => {
        setIsCiCdFileDownloading(true);

        CiCdApi.getCiCdFile(selectedCiCdLanguage)
            .then(result => {

                axios.get(result.data, {headers: downloadHeaders, responseType: 'text' as 'json'})
                    .then(ciCdFileResult => {
                        const blob = new Blob([ciCdFileResult.data], {type: 'text/plain;charset=utf-8'})
                        saveAs(blob, `${selectedCiCdLanguage}.yml`)

                        setIsCiCdFileDownloading(false);
                    })
            })
            .catch(error => {
                console.log(error);
                setIsCiCdFileDownloading(false);
            })
    }

    return (
        <>
            <SimpleGrid px={'2em'} pt={'4em'} pb={'2em'} columns={2} minChildWidth='30em'>
                <Box>
                    <Heading
                        textAlign={"center"}
                        fontSize={'2xl'}
                        fontWeight={'bold'}
                        color={'navigation.800'}
                        py={7}>
                        Download your CI/CD file
                    </Heading>

                    <Box my={4} ml={{lg: '3em'}} mr={{md: '3em'}}>
                        <FormControl id="language" alignContent={"center"}>
                            <FormLabel>CI/CD template language</FormLabel>
                            <Select
                                placeholder='Choose a language'
                                variant='filled'
                                value={selectedCiCdLanguage}
                                onChange={handleLanguageOptionChange}
                                width={'25em'}
                            >
                                {
                                    languageOptions.map((languageOption, index) => {
                                        return <option value={languageOption.value} id={'languageOption_' + index} key={index}>{languageOption.title}</option>
                                    })
                                }
                            </Select>
                        </FormControl>
                        <Stack direction={"row"} display={"flex"} align={"end"}>
                            <Button
                                colorScheme={'primary'}
                                isLoading={isCiCdFileDownloading}
                                mt={'1em'}
                                loadingText={'Downloading CI/CD template'}
                                onClick={() => handleDownloadCiCdFile()}
                                isDisabled={!hasCiCdLanguageBeenSelected}>
                                Download CI/CD Template
                            </Button>
                            <Image
                                h={"2em"}
                                objectFit={"contain"}
                                pos="relative"
                                fit={"contain"}
                                src={bug_hunt_ci_cd_download}
                            />
                        </Stack>
                    </Box>
                </Box>

                <Box mt={{base: 4, md: 0}} pl={{md: 6}}>
                    <Heading
                        textAlign={"center"}
                        fontSize={'2xl'}
                        fontWeight={'bold'}
                        color={'navigation.800'}
                        py={7}>
                        Instructions
                    </Heading>

                    <Text fontSize='lg' pb={'1em'}>
                        Your bot will be submitted via your Github CICD pipeline. Follow instructions below:
                    </Text>

                    <UnorderedList spacing={4} fontSize='lg'>
                        <ListItem>
                            Please download the CI/CD file for your desired language on the left.
                            Place that downloaded <b>{selectedCiCdLanguage}.yml</b> file in your bot root directory under .github/workflows/ to enable your bot to be uploaded via the
                            Github automatic submission process.
                        </ListItem>
                        <ListItem>
                            If your bot uses any third party plugins, it should contain those packages within the language's package manager config.
                        </ListItem>
                        <ListItem>
                            Do not use absolute paths to files or dependencies.
                        </ListItem>
                    </UnorderedList>

                </Box>
            </SimpleGrid>
        </>

    );
}
