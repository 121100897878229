import { Alert, AlertIcon, Box, Button, Center, Divider, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AuthenticationApi } from '../../api/authentication.api';
import EmailSent from '../../assets/svg/email-sent-2.svg';
import { getSettingAsBoolean, SETTING_NAME_OPERATIONAL } from '../../atoms/settings.atom';
import { RegisterModel } from '../../models/register.model';
import ResendVerificationModel from '../../models/resend-verification.model';
import { SideBarComponent } from '../../shared/SideBarComponent';
import { RegisterForm } from './RegisterForm';

interface IRegister {
}

interface AlertConfig {
	message: string;
	status: 'info' | 'warning' | 'success' | 'error' | undefined;
}

export const Register: React.FunctionComponent<IRegister> = props => {
	const params = new URLSearchParams(window.location.search);
	const userType = params.get('type') ? params.get('type') : ''; // 123

	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState('');
	const [email, setEmail] = useState('');
	const [loadingResend, setLoadingResend] = useState(false);
	const [resendVerificationEmailAlert, setResendVerificationEmailAlert] = useState<AlertConfig>({ message: '', status: undefined } as AlertConfig);

	const [errorText, setErrorText] = useState('');

	const enabledRegister = useRecoilValue(getSettingAsBoolean(SETTING_NAME_OPERATIONAL.ENABLED_REGISTER));

	let titleFontSize = 45;
	let mainFontColor = 'navigation.700';

	const register = (values: RegisterModel) => {
		setLoading(true);

		setEmail(values.email);

		AuthenticationApi
		.register(values)
		.then(result => {
			setLoading(false);

			if (result.data.registerReason.length === 0) {
				setModalOpen(values.email);
			} else if (result.data.registerReason === 'USERNAME_EXIST') {
				setErrorText('Username already exist.');
			} else if (result.data.registerReason === 'INVALID_PASSWORD') {
				setErrorText('Password does not conform.');
			}
		})
		.catch(reason => {
			setLoading(false);
		});
	};

	const closeModal = () => setModalOpen('');

	const goToSignIn = () => history.push('/signin');

	const handleResendVerificationEmail = (): void => {
		setLoadingResend(true);

		const resendVerification = {
			playerId: '',
			email: email
		} as ResendVerificationModel;

		AuthenticationApi
		.resendVerificationEmail(resendVerification)
		.then(result => {
			setLoadingResend(false);
			setResendVerificationEmailAlert({ message: `Successfully resent the email verification to ${email}`, status: 'info' });
		})
		.catch(reason => {
			setLoadingResend(false);
			setResendVerificationEmailAlert({ message: `There was an issue in resending the verification email`, status: 'error' });
		});
	};

	return (
		<SideBarComponent>
			<Modal size={'xl'} isOpen={modalOpen !== ''} onClose={closeModal}>
				<ModalOverlay/>
				<ModalContent>
					<ModalCloseButton/>
					<ModalBody>
						<Center>
							<VStack spacing={5}>
								<Img src={EmailSent} boxSize={'72'}/>
								<Box>
									<Text
										mt={-5}
										textAlign={'center'}
										fontWeight={'semibold'}
										fontSize={'3xl'}
										letterSpacing={0.9}
										color={'gray.700'}>
										Email Verification
									</Text>
									<Text
										textAlign={'center'}
										color={'gray.700'}
										px={10}
										mt={4}>
										We have sent an email to &nbsp;
										<Text
											as={'span'}
											color={'primary.500'}>
											{modalOpen}
										</Text>
										, to verify the validity of your email address.
										After receiving the email, please follow the link provided to complete your registration.
									</Text>
								</Box>
								<Divider w={'80%'}/>
								<Box pb={2}>
									<Text textAlign={'center'} fontWeight={'semibold'} color={'gray.700'} mb={1}>
										Haven't received an email?
									</Text>
									<Text textAlign={'center'} fontSize={'sm'} color={'gray.600'} mb={0}>
										Please check your spam folder for the email, or
									</Text>
									<Button
										w={'100%'}
										colorScheme={'accent'}
										variant={'ghost'}
										size={'sm'}
										textAlign={'center'}
										isLoading={loadingResend}
										loadingText={'Resending verification email...'}
										onClick={handleResendVerificationEmail}
									>
										Resend verification email
									</Button>
								</Box>

								{resendVerificationEmailAlert.message.length > 0
								 && resendVerificationEmailAlert.status !== undefined &&
                                 <Box mb={2}>
                                     <Alert
                                         status={resendVerificationEmailAlert.status}
                                         variant="left-accent"
                                         borderRadius={'md'}>
                                         <AlertIcon/>
										 {resendVerificationEmailAlert.message}
                                     </Alert>
                                 </Box>
								}
							</VStack>
						</Center>
					</ModalBody>
				</ModalContent>
			</Modal>
			<Center h={'100%'} pb={2}>
				<VStack w={'100%'} spacing={5} align={'stretch'}>
					<Text
						py={5}
						fontWeight={'bold'}
						fontSize={titleFontSize}
						color={mainFontColor}
						align={'center'}>
						Sign up to Entelect Challenge
					</Text>
					{
						!enabledRegister &&
                        <Alert status={'warning'} variant="left-accent" mb={5}>
                            <AlertIcon/>
                            Registration is currently disabled.
                        </Alert>
					}
					<Box>
						<RegisterForm
							userType={userType ?? ''}
							isLoading={loading}
							onRegisterRequest={(model) => register(model)}
						/>
					</Box>
					{
						errorText.length !== 0 &&
                        <Box>
                            <Alert status="error" variant="left-accent" borderRadius={'md'}>
                                <AlertIcon/>
								{errorText}
                            </Alert>
                        </Box>
					}
					<Divider/>
					<Box>
						<Text
							py={5}
							fontWeight={'bold'}
							color={mainFontColor}
							align={'left'}>
							Already  have an account?
						</Text>

						<Button
							w={'100%'}
							type={'submit'}
							colorScheme={'navigation'}
							variant={'outline'}
							onClick={goToSignIn}>
							SIGN IN
						</Button>
					</Box>
				</VStack>
			</Center>
		</SideBarComponent>
	);
};
