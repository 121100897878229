import {Box} from "@chakra-ui/react";
import axios, {AxiosResponse} from "axios";
import jwt_decode from "jwt-decode";
import * as React from 'react';
import {useEffect} from 'react';
import {Redirect, useLocation} from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";
import {playerAtom} from "../../atoms/player.atom";
import {decodedTokenState, tokenAtom} from "../../atoms/token.atom";
import CustomJwtPayload from "../../models/custom-jwt-payload";
import PlayerModel from "../../models/player.model";
import SocialSignInResultModel from "../../models/social-sign-in-result.model";
import {IntermediateGlobalLoading} from "../../shared/loading/IntermediateGlobalLoading";

interface IPostLogin {
}

export const PostLogin: React.FunctionComponent<IPostLogin> = props => {
    const location = useLocation();

    const [token, setToken] = useRecoilState(tokenAtom);
    const [player, setPlayer] = useRecoilState(playerAtom);
    const decodedToken = useRecoilValue(decodedTokenState);

    const params = new URLSearchParams(window.location.search);
    const code: string = params.get('code') ?? '';

    const base64EncodedAuthorization = btoa(`${process.env.REACT_APP_AUTHORIZATION_CLIENT_ID}:${process.env.REACT_APP_AUTHORIZATION_CLIENT_SECRET}`);
    const httpHeaders = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${base64EncodedAuthorization}`
    };

    const clientId: string = process.env.REACT_APP_AUTHORIZATION_CLIENT_ID ?? '';
    const redirectUri: string = process.env.REACT_APP_AUTHORIZATION_REDIRECT_URI ?? '';

    const body = new URLSearchParams();
    body.set('grant_type', 'authorization_code');
    body.set('client_id', clientId);
    body.set('code', code);
    body.set('redirect_uri', redirectUri);

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_AUTHORIZATION_URL}/token`, body.toString(), { headers: httpHeaders })
            .then((result: AxiosResponse<SocialSignInResultModel>) => {
                setToken(result.data.access_token); // TODO: Maybe keep the same as SignInResultModel (i.e., accessToken)

                if (token !== null || token !== '') {
                    const username = decodedToken ?? jwt_decode<CustomJwtPayload>(token);

                    axios.post(`${process.env.REACT_APP_API_URL}/post-authentication`, { username })
                        .then((result: AxiosResponse<PlayerModel>) => {
                            //console.log('Player: ', result);
                        })
                        .catch(reason => {
                            // TODO: Fix this after (2022-03-01)
                            //console.error('Error getting player: ', reason);
                        });
                }
            })
            .catch(reason => {
                //console.error(reason);
            });
    }, [setToken, setPlayer]); // eslint-disable-line react-hooks/exhaustive-deps

    const returnUrl = location.state.from && !location.state.from.startsWith("/signin") ? location.state.from : "/portal";

    return (
        <Box h={'100%'}>
            {
                token && player
                    ?
                    <Redirect to={returnUrl}/>
                    :
                    <IntermediateGlobalLoading/>
            }
        </Box>
    );
}
