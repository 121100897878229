const teamImages = {
    Amantle_Mashele: require("./Amantle_Mashele.jpg").default,
    Bianca_McFadyen: require("./Bianca_McFadyen.jpg").default,
    Creolin_Gopal: require("./Creolin_Gopal.jpg").default,
    Dewald_Swanepoel: require("./Dewald_Swanepoel.jpg").default,
    Gabi_Joubert: require("./Gabi_Joubert.jpg").default,
    Izak_Joubert: require("./Zack_Joubert.jpg").default,
    Jenique_Peters: require("./Jenique_Peters.jpg").default,
    Joshua_Willmot: require("./Joshua_Willmot.jpg").default,
    JP_Roussel: require("./JP_Roussel.jpg").default,
    Laaiqah_Seedat: require("./Laaiqah_Seedat.jpg").default,
    Lijani_Van_Wyk_de_Vries: require("./Lijani_van_Wyk_de_Vries.jpg").default,
    Minal_Pramlall: require("./Minal_Pramlall.jpg").default,
    Natasha_Draper: require("./Natasha_Draper.jpg").default,
    Neil_Foxcroft: require("./Neil_Foxcroft.jpg").default,
    Rene_du_Plooy: require("./Rene_du_Plooy.jpg").default,
    Simeon_Boshoff: require("./Simeon_Boshoff.jpg").default,
    Thobelani_Makeleni: require("./Thobelani_Makeleni.jpg").default,
    Wiehan_van_Niekerk: require("./Wiehan_van_Niekerk.jpg").default,
};
export default teamImages;
