import * as React from 'react';
import {Box, Text} from "@chakra-ui/react";
import {WikiSection} from "../WikiSection";
import {WikiSubSection} from "../WikiSubSection";

interface IWikiCookieSection {}

export const WikiCookieSection: React.FunctionComponent<IWikiCookieSection> = _ => {
    return (
        <Box>
            <WikiSection heading={'Cookie Policy'} >
                <WikiSubSection heading={'What are cookies?'}>
                    <Text color={'gray.600'}>
                        A cookie is a small text file that a website stores on your computer or mobile device when you visit the site.
                        <br /><br />
                        First party cookies are cookies set by the website you’re visiting. Only that website can read them. In addition, a website might potentially use external services, which also set their own cookies, known as third-party cookies.
                        <br /> <br />
                        Persistent cookies are cookies saved on your computer and that are not deleted automatically when you quit your browser, unlike a session cookie, which is deleted when you quit your browser. When you visit our site, you will be able to accept or decline cookies.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'How do we use cookies?'}>
                    <Text color={'gray.600'}>
                        Entelect challenge does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'Managing cookies'}>
                    <Text color={'gray.600'}>
                        We have a few methods to manage cookies on our site.
                        <br />
                        <b>Removing device cookies</b>
                        <br />
                        You can delete all cookies that are already on your device by clearing your browser history. This is found in the Settings section of your browser. Be aware that this may clear all your cookies for all sites you have visited, even those you have opted-in to. You may also lose saved login information and preferences for other websites.
                    </Text>
                </WikiSubSection>
            </WikiSection>
        </Box>
    )
};
