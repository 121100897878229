import {Square, Flex, Box, Grid, GridItem, Text, useBreakpointValue, useToast, Spinner} from "@chakra-ui/react";
import {IntermediateMessageLoader} from "../../shared/loading/IntermediateMessageLoader";
import {FancyHeading} from "../../shared/FancyHeading";
import * as React from 'react';
import {useState, useEffect} from 'react';
import {HackathonTeamModel} from "../../models/hackathon-team.model";
import {BettingPopover} from "./BettingPopover";
import {useRecoilState, useRecoilValue} from "recoil";
import {playerAtom} from "../../atoms/player.atom";
import HackathonTournamentModel from "../../models/hackathon-tournament.model";
import {getSetting, SETTING_NAMES_HACKATHON, getSettingAsMap} from "../../atoms/settings.atom";
import HackathonFunModel from "../../models/hackathon-fun.model";
import {HackathonApi} from "../../api/hackathon.api";
import {AxiosError} from "axios";
import ErrorModel from "../../models/error.model";
import HackathonFunBetModel from "../../models/hackathon-fun-bet.model";
import moment from "moment";

interface IBetting {
	isBettingEnabled: boolean;
	tournament: HackathonTournamentModel;
}

interface BettingCategory {
	id: string;
	title: string;
	description: string;
	open: boolean;
  winner: string;
}

export const Betting: React.FunctionComponent<IBetting> = ({isBettingEnabled, tournament}) => {
	const startingCoins = Number(useRecoilValue(getSetting(SETTING_NAMES_HACKATHON.VOTING_PAGE_STARTING_VALUE)).value);
  const categoryData = useRecoilValue(getSettingAsMap(SETTING_NAMES_HACKATHON.VOTING_PAGE_BETTING_DATA));

	const [remaining, setRemaining] = useState(startingCoins);
	const [player, ] = useRecoilState(playerAtom);
	
	const [allTeams, setAllTeams] = useState<Array<HackathonTeamModel> | undefined>(undefined);
	const [loadingPage, setLoadingPage] = useState(true);
	const [loadingSpinner, setLoadingSpinner] = useState(true);
	
	const [bets, setBets] = useState<Array<HackathonFunModel> | undefined>(undefined);
	const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});
	const toast = useToast();

	const bettingCategories = Array.from(categoryData.values()) as Array<Object> as Array<BettingCategory>;

	let textColumnMaxWidth = '96em';
	let titleFontSize = 45;
	let minViewH = '50vh';
	const textFontSize = 'calc(1em + 1vmin)';
	const descriptionMinHeight = 'calc(3em + 3vmin)';
	const squareMargins = isMobile ? 1 : 10;

	useEffect(() => {
		const getAllTeamsPromise = HackathonApi.getAllTeams('company');
		const allBetsPromise = HackathonApi.getPlayerBets(tournament!.hackathonTournamentId, player.playerId);

		Promise.all([getAllTeamsPromise, allBetsPromise])
			.then(([allTeamsResponse, allBetsResponse]) => {
				const allTeamsData = allTeamsResponse.data
        .filter(team => moment(team.creationDate).isSame(moment(), 'year'))
        .sort((a, b) => {
					if (a.teamName > b.teamName) return 1
					else if (a.teamName < b.teamName) return -1
					else return 0
				}).map(team => ({...team, bets: []}));
				setAllTeams(allTeamsData);

				const allBetsData = allBetsResponse.data;
				const alreadyBet = allBetsData?.reduce((part, a) => part + a.bettingValue, 0)
				setRemaining(Math.max(0, startingCoins - alreadyBet))
				setBets(allBetsData);

				setLoadingPage(false);
				setLoadingSpinner(false);
			})
			.catch((error: AxiosError<ErrorModel>) => {
				toast({
					title: error.message,
					position: 'top-right',
					status: 'error'
				});
			});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setAllTeams, setBets]);

  const checkExisting = (hackathonTeamId: string, bettingType: string, bettingValue: number) => {
    const existingBet = bets!.find(bet => bet.bettingType === bettingType && bet.playerId === player.playerId && bet.hackathonTeamId === hackathonTeamId);

		let existingValue = 0;
		if (existingBet) {
			existingValue = existingBet.bettingValue;
			existingBet.bettingValue = bettingValue;
			setBets(bets);
		}

    return existingValue
  }

	const placeBet = (hackathonTeamId: string, bettingType: string, bettingValue: number, onClose: () => void, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    setLoadingSpinner(true);
		const backupBets = JSON.parse(JSON.stringify(bets));
		
    const existingValue = checkExisting(hackathonTeamId, bettingType, bettingValue);

		const remainder = remaining + existingValue - bettingValue;
		if (remainder >= 0) {
			setRemaining(remainder);
			
			const bet = {
				hackathonTournamentId: tournament.hackathonTournamentId,
				hackathonTeamId: hackathonTeamId,
				playerId: player.playerId,
				bettingType: bettingType,
				bettingValue: bettingValue
			} as HackathonFunBetModel;

			HackathonApi.placeBet(bet)
			.then((response) => {
				setBets(response.data);
			})
			.catch((error) => {
				let errorToast = "Something went wrong. Please let us know."
				if (error.response) {
					if (error.response.data.code === 'MaximumAmountAlreadyBetException') {
						errorToast = "You don't have enough tokens left."
					}
          if (error.response.data.code === 'BetCategoryClosedException') {
            errorToast = "This category has been closed. Please refresh."
          }
				}
				toast({
					title: errorToast,
					position: 'top-right',
					status: 'error'
				});
				setBets(backupBets);
        setRemaining(remaining);
			})
      .finally(() => {
        setLoadingSpinner(false);
        setLoading(false);
        onClose();
      });
		} else {
			toast({
				title: "You don't have enough tokens left",
				position: 'top-right',
				status: 'error'
			});
			setBets(backupBets);
      setRemaining(remaining);
      setLoadingSpinner(false);
      setLoading(false);
      onClose();
		}
	}

	return (
		<Square p={5} shadow="md" borderWidth="0.1em"
			background={'whiteAlpha.900'}
			borderRadius={'0.5em'}
			m={squareMargins}
			width={'100%'}
			maxWidth={textColumnMaxWidth}
			minH={minViewH}
			alignSelf={'center'}
			flexDirection={'column'}
			position='relative'
		>
      <Text
        fontWeight='extrabold'
        fontFamily={'Momcake-bold'}
        fontSize={isMobile ? 35 : 45}
        color='accent.500'
        textShadow="-1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000"
        position={isMobile ? 'inherit' : 'absolute'}
        top={0}
        right={0}
        m={2}>
        {loadingSpinner ? <Spinner/> : remaining} {remaining > 1 ? 'Enteloots' : 'Enteloot'} left
      </Text>

			<Text
				fontWeight='bold'
				fontSize={45}
				color='accent.600'
				align='center'
				pb={5}
				width={'100%'}
				maxWidth={textColumnMaxWidth}>
				Betting Categories
			</Text>

			<Text
				fontSize='calc(1.2em + 1vmin)'
				color='navigation.700'
				align='center'
				pb={5}
				width={'100%'}
				maxWidth={textColumnMaxWidth}>
				Spend your virtual Enteloot below. Which team do you think is going to take the title? 
        You can change a bet you have made while the category is still open. Once a title has been claimed, the category will be closed.
			</Text>

			<Flex
				w={'100%'}
				flexDirection='row'
				flexWrap='wrap'
				gap={8}
				justifyContent='space-evenly'
			>
				{!isBettingEnabled && 
				<Square
					background={'whiteAlpha.800'}
					borderRadius={'0.5em'}
					width='100%'
					height='100%'
					alignSelf={'center'}
					flexDirection={'column'}
					position='absolute'
					zIndex={1}
				>
				 <Text
					fontWeight={'bold'}
					fontSize={titleFontSize}
					color='primary'
					align={'center'}
					pb={5}
					width={'80%'}
					maxWidth={textColumnMaxWidth}>
					Betting is not enabled at this time
					</Text>
				</Square>
				}
				{loadingPage
					? <IntermediateMessageLoader loadingMessage={'Fetching all the fun things!'} />
					: bettingCategories.map(category => (
						<Box key={category.id} flex={'1 1 45%'}>
							<FancyHeading heading={category.title} headingSize={'4xl'} headingColor={'primary.700'} />
							<Text
								mt={2}
								color={'navigation.500'}
								w='100%'
								fontSize={textFontSize}
								minHeight={descriptionMinHeight}
								textAlign='left'
							>
								{category.description}
							</Text>
							<Grid
								templateColumns={`repeat(${isMobile ? 1 : 2}, 1fr)`}
								gap={8}
							>
								{allTeams && allTeams.map((team) => (
									<GridItem key={team.teamName}>
										<BettingPopover 
                      isBettingEnabled={isBettingEnabled && category.open} 
                      team={team}
                      bets={bets?.filter(bet => bet.bettingType === category.id && bet.hackathonTeamId === team.hackathonTeamId)} 
                      remaining={remaining} 
                      bettingType={category.id} 
                      placeBet={placeBet}
                      winner={category.winner}
                    />
									</GridItem>
								))}
							</Grid>
						</Box>
					))
				}
			</Flex>
		</Square>
	);
}