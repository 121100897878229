export const finalists2020 = [
    {
        id:1,
        finalist: 'JP da Conceicao',
        image: 'JPdaConceicao'
    },
    {
        id:2,
        finalist: 'Gawie Jooste',
        image: 'GawieJooste'
    },
    {
        id:3,
        finalist: 'Willie Theron',
        image: 'WillieTheron'
    },
    {
        id:4,
        finalist: 'Kobus van Schoor',
        image: 'KobusvanSchoor'
    },
    {
        id:5,
        finalist: 'Riaan Nel',
        image: 'RiaanNel'
    },
    {
        id:6,
        finalist: 'Louis Lotter',
        image: 'LouisLotter'
    },
    {
        id:7,
        finalist: 'Mallin Moolman',
        image: 'MallinMoolman'
    },
    {
        id:8,
        finalist: 'Edward Steere',
        image: 'EdwardSteere'
    }
]