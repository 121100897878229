import { CheckCircleIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import { List, ListIcon, ListItem, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger } from '@chakra-ui/react';
import React, { FC, ReactNode, RefObject } from 'react';
import { PasswordRequirements } from '../../models/password-requirements-model';

interface IPasswordRequirementsPopover {
    passwordRequirements: PasswordRequirements;
    popoverParentRef?: RefObject<HTMLElement>;
    placement: 'bottom' | 'left' | 'right' | 'top';
    children: ReactNode;
}

export const PasswordRequirementsPopover: FC<IPasswordRequirementsPopover> = (props) => {
    return (
        <>
            <Popover
                initialFocusRef={props.popoverParentRef}
                placement={props.placement}
                autoFocus={false}
                isOpen={true}
            >
                <PopoverTrigger>
                    {props.children}
                </PopoverTrigger>

                <PopoverContent>
                    <PopoverArrow/>
                    <PopoverHeader>Password Requirements</PopoverHeader>
                    <PopoverBody>
                        <List spacing={3}>
                            <ListItem>
                                {
                                    props.passwordRequirements.upperCaseCharacter
                                        ? <ListIcon as={CheckCircleIcon}
                                                    color="green.500"/>
                                        : <ListIcon as={QuestionOutlineIcon}
                                                    color="blue.500"/>
                                }

                                At least one uppercase character.
                            </ListItem>
                            <ListItem>
                                {
                                    props.passwordRequirements.lowerCaseCharacter
                                        ? <ListIcon as={CheckCircleIcon}
                                                    color="green.500"/>
                                        : <ListIcon as={QuestionOutlineIcon}
                                                    color="blue.500"/>
                                }
                                At least one lowercase character.
                            </ListItem>
                            <ListItem>
                                {
                                    props.passwordRequirements.numberCharacter
                                        ? <ListIcon as={CheckCircleIcon}
                                                    color="green.500"/>
                                        : <ListIcon as={QuestionOutlineIcon}
                                                    color="blue.500"/>
                                }
                                At least one number.
                            </ListItem>
                            <ListItem>
                                {
                                    props.passwordRequirements.specialCharacter
                                        ? <ListIcon as={CheckCircleIcon}
                                                    color="green.500"/>
                                        : <ListIcon as={QuestionOutlineIcon}
                                                    color="blue.500"/>
                                }
                                At least one special character.
                            </ListItem>
                            <ListItem>
                                {
                                    props.passwordRequirements.lengthCharacter
                                        ? <ListIcon as={CheckCircleIcon}
                                                    color="green.500"/>
                                        : <ListIcon as={QuestionOutlineIcon}
                                                    color="blue.500"/>
                                }
                                At least eight characters.
                            </ListItem>
                        </List>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    );
};
