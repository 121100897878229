import * as React from 'react';
import {Box, Spacer, Stack} from "@chakra-ui/react";
import NotificationModel, {NotificationSeverityType} from "../../models/notification.model";
import {Text} from "@chakra-ui/layout";
import moment from "moment";
import {InfoIcon, WarningIcon, WarningTwoIcon} from "@chakra-ui/icons";

interface INavigationNotificationBarItem {
    notification: NotificationModel;
}

export const NavigationNotificationBarItem: React.FunctionComponent<INavigationNotificationBarItem> = props => {

    const getIconForNotificationSeverity = () => {
        switch (props.notification.severityType) {
            case NotificationSeverityType.INFO: return (<InfoIcon color={'blue.400'} fontSize={'xl'} />);
            case NotificationSeverityType.WARNING: return (<WarningTwoIcon color={'orange.400'} fontSize={'xl'} />);
            case NotificationSeverityType.DANGER: return (<WarningIcon color={'red.400'} fontSize={'xl'} />);
        }
        return (<></>);
    };

    return (
        <Box w={'100%'} >
            <Stack direction={'row'} spacing={3}>
                <Box h={'100%'}>
                    {
                        getIconForNotificationSeverity()
                    }
                </Box>
                <Box w={'100%'} >
                    <Stack direction={'row'}>
                        <Text fontWeight={'bold'} color={'gray.700'} >{props.notification.notificationName}</Text>
                        <Spacer />
                        <Text fontWeight={'thin'} color={'gray.600'} textAlign={'right'}>{moment(props.notification.creationDate).format('YYYY/MM/DD')}</Text>
                    </Stack>
                    <Text color={'gray.500'} pt={1} whiteSpace={'pre-wrap'}>
                        {props.notification.notificationDescription}
                    </Text>
                </Box>
            </Stack>
        </Box>
    )
};
