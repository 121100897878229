export const finalists2016 = [
    {
        id:1,
        finalist: 'Faruk Can Kaya',
        image: 'Faruk_Can_Kaya'
    },
    {
        id:2,
        finalist: 'Christian Reeve',
        image: 'Christian_Reeve'
    },
    {
        id:3,
        finalist: 'Rethabile Mokoena',
        image: 'Rethabile_Mokoena'
    },
    {
        id:4,
        finalist: 'Willie Theron',
        image: 'Willie_Theron'
    },
    {
        id:5,
        finalist: 'Charl van Niekerk',
        image: 'Charl_van_Niekerk'
    },
    {
        id:6,
        finalist: 'Ralf Kistner',
        image: 'Ralf_Kistner'
    },
    {
        id:7,
        finalist: 'Thinus Potgieter',
        image: 'Thinus_Potgieter'
    },
    {
        id:8,
        finalist: 'Riaan Nel',
        image: 'Riaan_Nel'
    }
]