import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Code, HStack, Image, Link, ListItem, Stack, UnorderedList, VStack } from "@chakra-ui/react";
import * as React from "react";
import { FunctionComponent } from "react";
import bug_hunt_getting_started from "../../../assets/images/getting-started/code_me_if_you_can_solo.png";

export const PrerequisitesAccordionItem: FunctionComponent = props => {
    const gitConfigSetName: string = `git config --global user.name "John Doe"`;
    const gitConfigSetEmail: string = `git config --global user.email johndoe@example.com`;

    return (
        <AccordionItem>
            <h2>
                <AccordionButton _expanded={{bg: 'navigation.800', color: 'white'}}>
                    <Box flex='1' textAlign='left'>
                        Prerequisites
                    </Box>
                    <AccordionIcon/>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                Our new easy and innovative submission process requires a few prerequisites to be installed, if you don't have
                it already.

                <br/>
                <br/>

                <UnorderedList>
                    <ListItem>
                        Install Git from <Link color={'navigation.400'} href="https://git-scm.com/downloads" isExternal>https://git-scm.com/downloads</Link>
                    </ListItem>
                    <ListItem>
                        After Git is installed, you need to add your Name and email to the git config. You can do so by running these commands in your terminal.

                        <br/>

                        <VStack align={'left'}>
                            <Box>
                                <HStack>
                                    <Box>
                                        <Code colorScheme='purple' children={gitConfigSetName}/>
                                    </Box>
                                    <Box>
                                        <Button size='xs' onClick={() => navigator.clipboard.writeText(gitConfigSetName)}>Copy</Button>
                                    </Box>
                                </HStack>
                            </Box>

                            <Box>
                                <HStack>
                                    <Box>
                                        <Code colorScheme='purple' children={gitConfigSetEmail}/>
                                    </Box>
                                    <Box>
                                        <Button size='xs' onClick={() => navigator.clipboard.writeText(gitConfigSetEmail)}>Copy</Button>
                                    </Box>
                                </HStack>
                            </Box>
                        </VStack>
                    </ListItem>
                </UnorderedList>

                <br/>

                <Stack direction={"row"}>
                    <p>You now should have Git installed. Next, we will show you how to create your own Github repository.</p>
                    <Image
                        h={"2em"}
                        objectFit={"contain"}
                        pos="relative"
                        fit={"contain"}
                        src={bug_hunt_getting_started}
                    />
                </Stack>
            </AccordionPanel>
        </AccordionItem>
    );
}
