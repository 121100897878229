import {Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Code, Image, ListItem, OrderedList} from "@chakra-ui/react";
import * as React from "react";
import {FunctionComponent} from "react";
import cloningRepoImage from "../../../assets/images/getting-started/cloningRepo.png";

export const CloneGithubRepositoryAccordionItem: FunctionComponent = _ => {
    return (
        <AccordionItem>
            <h2>
                <AccordionButton _expanded={{ bg: 'navigation.800', color: 'white' }}>
                    <Box flex='1' textAlign='left'>
                        Clone Github Repository
                    </Box>
                    <AccordionIcon/>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                It is time to clone your github repository to your computer now.
                This is necessary, because you can now make changes to your bot and push it to Git (Github).
                From there Github will build and submit your bot to The Entelect Challenge servers.

                <br/>
                <br/>

                <OrderedList>
                    <ListItem>
                        On Github, go to <b>Code</b> and select the HTTPS option.
                    </ListItem>
                    <ListItem>
                        Copy the url you see there. It should look similar to: <Code>https://github.com/your-username/your-repository-name.git</Code>
                    </ListItem>
                    <ListItem>
                        On your computer, go to my documents and open your preferred command prompt there.
                    </ListItem>
                    <ListItem>
                        Type the following, <Code>git clone [paste the link you copied, here]</Code> and press enter.
                    </ListItem>
                    <ListItem>
                        Wait for it to complete before closing the command prompt
                    </ListItem>
                </OrderedList>

                <br/>

                You may receive the following: "warning: You appear to have cloned an empty repository." You can go ahead and ignore this warning.

                <br/>

                You now have your repository checked out on your computer. Next you are going to download the starter pack.

                <Accordion
                    pt={3}
                    allowToggle
                >
                    <AccordionItem>
                        <h2>
                            <AccordionButton
                                _expanded={{ bg: 'navigation.800', color: 'white' }}
                            >
                                <Box flex='1' textAlign='left'>
                                    Click here for help cloning your repository
                                </Box>
                                <AccordionIcon/>
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Image src={cloningRepoImage} fallbackSrc={cloningRepoImage} alt='Cloning your repository'/>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </AccordionPanel>
        </AccordionItem>
    )
}
