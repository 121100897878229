import * as React from 'react';
import {useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input,
    Select,
    useBoolean,
    useToast
} from "@chakra-ui/react";
import {SettingItemSection} from "../SettingItemSection";
import {Field, Form, Formik} from 'formik';
import {ProfileModel, ProfileModelRequest, SubmissionRequest} from "../../../models/profile.model";
import {FieldAttributes} from "formik/dist/Field";
import * as Yup from 'yup';
import {useRecoilState} from 'recoil';
import {playerAtom} from '../../../atoms/player.atom';
import {PlayerApi} from '../../../api/player.api';

interface IProfile {
}

export const Profile: React.FunctionComponent<IProfile> = props => {
    const distanceBetweenFormControls: number = 4;

    const [player, setPlayer] = useRecoilState(playerAtom);
    const [loadingProfile, setLoadingProfile] = useBoolean(false);
    const [loadingSubmission, setLoadingSubmission] = useBoolean(false);
    const toast = useToast();

    const [languageType, setlanguageType] = useState('');

    const updateProfile = (values: ProfileModel) => {
        setLoadingProfile.on();

        const requestValues: ProfileModelRequest = {
            firstName: values.firstName,
            lastname: values.lastName,
            displayName: values.displayName
        }
        PlayerApi.updatePlayerProfile(requestValues)
            .then(result => {
                setLoadingProfile.off();

                setPlayer((prevState) => {
                    return {
                        ...prevState,
                        firstName: result.data.firstName,
                        lastName: result.data.lastName,
                        displayName: result.data.displayName
                    }
                });
                toast({
                    title: `Updated your profile.`,
                    position: 'top-right',
                    status: 'success',
                });
            })
            .catch(reason => {
                setLoadingProfile.off();
                toast({
                    title: `Failed to update your profile`,
                    position: 'top-right',
                    status: 'error',
                });
            });

    }


    const updateSubmission = () => {

        setLoadingSubmission.on();
        const value: SubmissionRequest = {
            legacySubmissionFlow: false,
            preferredLanguage: languageType || "NOOP"

        }
        PlayerApi.updateSubmissionFlow(value)
            .then(result => {
                setLoadingSubmission.off();
                toast({
                    title: `Updated your default submission language.`,
                    position: 'top-right',
                    status: 'success',
                });
            })
            .catch(reason => {
                setLoadingSubmission.off();
                toast({
                    title: `Failed to update default submission language.`,
                    position: 'top-right',
                    status: 'error',
                });
            });

    }

    const profileValidation = Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        lastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        displayName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
    });

    return (
        <Box>
            <SettingItemSection
                heading={"Edit your details"}
                subTexts={["This is the information we have about you. You can edit this information on our system."]}
            >
                <Formik
                    initialValues={{
                        firstName: player.firstName,
                        lastName: player.lastName,
                        displayName: player.displayName
                    } as ProfileModel}
                    onSubmit={(values) => updateProfile(values)}
                    validationSchema={profileValidation}
                >
                    {(props) => (
                        <Form>
                            <Field name={'firstName'}>
                                {({field, form}: FieldAttributes<any>) => (
                                    <Box my={distanceBetweenFormControls}>
                                        <FormControl id="firstName" isRequired
                                                     isInvalid={form.errors.firstName && form.touched.firstName}>
                                            <FormLabel htmlFor={'firstName'}>First Name</FormLabel>
                                            <Input colorScheme={'primary'} {...field} id={'firstName'} type="text"
                                                   size="md" variant="filled" placeholder={'Your First Name'}/>
                                            <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
                                        </FormControl>
                                    </Box>
                                )}
                            </Field>
                            <Field name={'lastName'}>
                                {({field, form}: FieldAttributes<any>) => (
                                    <Box my={distanceBetweenFormControls}>
                                        <FormControl id="lastName" isRequired
                                                     isInvalid={form.errors.lastName && form.touched.lastName}>
                                            <FormLabel>Last Name</FormLabel>
                                            <Input colorScheme={'primary'} {...field} id={'lastName'} type="text"
                                                   size="md" variant="filled" placeholder={'Your Last Name'}/>
                                            <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
                                        </FormControl>
                                    </Box>
                                )}
                            </Field>
                            <Field name={'displayName'}>
                                {({field, form}: FieldAttributes<any>) => (
                                    <Box my={distanceBetweenFormControls}>
                                        <FormControl id="displayName" isRequired
                                                     isInvalid={form.errors.displayName && form.touched.displayName}>
                                            <FormLabel>Display Name</FormLabel>
                                            <Input colorScheme={'primary'} {...field} id={'displayName'} type="text"
                                                   size="md" variant="filled" placeholder={'Your Display Name'}/>
                                            <FormErrorMessage>{form.errors.displayName}</FormErrorMessage>
                                            <FormHelperText>Your display name will appear on the
                                                leaderboards.</FormHelperText>
                                        </FormControl>
                                    </Box>
                                )}
                            </Field>
                            <Button type={'submit'} colorScheme={'primary'} isLoading={loadingProfile}
                                    loadingText={'Updating profile'}>Update profile</Button>
                        </Form>
                    )}
                </Formik>
            </SettingItemSection>

            <SettingItemSection heading={"Default submission language"}>
                <Box my={distanceBetweenFormControls}>
                    <FormControl id="language">
                        <Select colorScheme={'primary'} placeholder="Select language"
                                onChange={(e) => setlanguageType(e.target.value)}>
                            <option>C#</option>
                            <option>JavaScript</option>
                            <option>C++</option>
                            <option>Java</option>
                            <option>Python</option>
                            <option>Python - Pytorch</option>
                            <option>Python - Tensorflow</option>
                        </Select>
                    </FormControl>
                </Box>
                <Button colorScheme={'primary'} isLoading={loadingSubmission}
                        loadingText={'Updating submission settings'} onClick={() => updateSubmission()}>Update
                    submission settings</Button>
            </SettingItemSection>


        </Box>
    )
};

