import gallery from '../gallery/gallaryIndex';

export const photos2015 = [
    {
      src: gallery[20151],
      width: 4,
      height: 3
    },
    {
      src: gallery[20152],
      width: 4,
      height: 3
    },
    {
      src: gallery[20153],
      width: 4,
      height: 3
    },
    {
      src: gallery[20154],
      width: 4,
      height: 3
    },
    {
      src: gallery[20155],
      width: 4,
      height: 3
    },
    {
      src: gallery[20156],
      width: 4,
      height: 3
    },
    {
      src: gallery[20157],
      width: 4,
      height: 3
    },
    {
      src: gallery[20158],
      width: 4,
      height: 3
    },
    {
      src: gallery[20159],
      width: 4,
      height: 3
    },
    {
      src: gallery[201510],
      width: 4,
      height: 3
    },
    {
      src: gallery[201511],
      width: 4,
      height: 3
    },
    {
      src: gallery[201512],
      width: 4,
      height: 3
    },
    {
      src: gallery[201513],
      width: 4,
      height: 3
    },
    {
      src: gallery[201514],
      width: 4,
      height: 3
    },
    {
      src: gallery[201515],
      width: 4,
      height: 3
    },
    {
      src: gallery[201516],
      width: 4,
      height: 3
    },
    {
      src: gallery[201517],
      width: 4,
      height: 3
    },
    {
      src: gallery[201518],
      width: 4,
      height: 3
    },
    {
      src: gallery[201519],
      width: 4,
      height: 3
    },
    {
      src: gallery[201520],
      width: 4,
      height: 3
    },
    {
      src: gallery[201521],
      width: 4,
      height: 3
    },
    {
      src: gallery[201522],
      width: 4,
      height: 3
    },
    {
      src: gallery[201523],
      width: 4,
      height: 3
    },
    {
      src: gallery[201524],
      width: 4,
      height: 3
    },
    {
      src: gallery[201525],
      width: 4,
      height: 3
    },
    {
      src: gallery[201526],
      width: 4,
      height: 3
    },
    {
      src: gallery[201527],
      width: 4,
      height: 3
    },
    {
      src: gallery[201528],
      width: 4,
      height: 3
    },
    {
      src: gallery[201529],
      width: 4,
      height: 3
    },
    {
      src: gallery[201530],
      width: 4,
      height: 3
    },
    {
      src: gallery[201531],
      width: 4,
      height: 3
    },
    {
      src: gallery[201532],
      width: 4,
      height: 3
    }
  ];