import {Box, Button, FormControl, FormLabel, Select} from "@chakra-ui/react";
import axios from "axios";
import * as React from 'react';
import {ChangeEvent, useState} from 'react';
import {useRecoilState, useRecoilValue} from "recoil";
import {CiCdApi} from "../../../api/ci-cd.api";
import {ciCdLanguageAtom, hasCiCdLanguageAtom} from "../../../atoms/ci-cd-language.atom";
import {enumSelector} from "../../../helpers/typescriptHelper";
import {LanguageOption} from "../../../models/language-option.enum";
import {SettingItemSection} from "../SettingItemSection";

interface ICICDFile {
}

export const CICDFile: React.FunctionComponent<ICICDFile> = props => {
    const hasCiCdLanguageBeenSelected = useRecoilValue(hasCiCdLanguageAtom);
    const [selectedCiCdLanguage, setSelectedCiCdLanguage] = useRecoilState(ciCdLanguageAtom);

    const [isCiCdFileDownloading, setIsCiCdFileDownloading] = useState<boolean>(false);

    const languageOptions = enumSelector(LanguageOption);

    const downloadHeaders = {
        'Content-Disposition': 'attachment; filename="cicd-build.yml"',
        'Content-Type': 'text/plain; charset=utf-8'
    }

    const handleLanguageOptionChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.selectedOptions[0].value;
        setSelectedCiCdLanguage(value);
    }

    const handleDownloadCiCdFile = () => {
        setIsCiCdFileDownloading(true);

        CiCdApi.getCiCdFile(selectedCiCdLanguage)
            .then(result => {

                axios.get(result.data, { headers: downloadHeaders, responseType: 'text' as 'json' })
                    .then(ciCdFileResult => {
                        const blob = new Blob([ciCdFileResult.data], { type: 'text/plain;charset=utf-8' })
                        saveAs(blob, `${selectedCiCdLanguage}.yml`)

                        setIsCiCdFileDownloading(false);
                    })
            })
            .catch(error => {
                //console.log(error);
                setIsCiCdFileDownloading(false);
            })
    }

    return (
        <Box>
            <SettingItemSection
                heading={'Download CI/CD file'}
                subTexts={['Download your personal CI/CD template file for your preferred language.']}
            >
                <Box my={4}>
                    <FormControl id="language">
                        <FormLabel>CI/CD template language</FormLabel>
                        <Select
                            placeholder='Choose a language'
                            variant='filled'
                            value={selectedCiCdLanguage}
                            onChange={handleLanguageOptionChange}
                        >
                            {
                                languageOptions.map((languageOption, index) => {
                                    return <option value={languageOption.value} id={'languageOption_' + index} key={index}>{languageOption.title}</option>
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Button
                    colorScheme={'primary'}
                    isLoading={isCiCdFileDownloading}
                    loadingText={'Downloading CI/CD template'}
                    onClick={() => handleDownloadCiCdFile()}
                    isDisabled={!hasCiCdLanguageBeenSelected}>
                    Download CI/CD template
                </Button>
            </SettingItemSection>
        </Box>
    )
};
