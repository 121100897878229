import * as React from 'react';
import {Box} from "@chakra-ui/react";
import ReactPlayer from "react-player";
import {StreamVideoConfigModel} from "../../models/stream-config.model";

interface IStreamVideoPortal {
    config: StreamVideoConfigModel;
    width?: string | number;
    height?: string | number;
}

export const StreamVideoPortal: React.FunctionComponent<IStreamVideoPortal> = props => {

    const width = props.width ? props.width : 600;
    const height = props.height ? props.height : 300;

    return (
        <Box h={'100%'} w={'100%'} >
            <ReactPlayer width={width} height={height} playing url={props.config.source} config={{
                youtube: {
                    playerVars: { showinfo: 1 }
                },
            }} />
        </Box>

    );

};