import { Box, Center, Text } from "@chakra-ui/react";
import * as React from "react";
import { ReactNode, useEffect, useState } from "react";
import LoadingIcon from "../../assets/images/shared/Loading_Icon.gif";
import { LoadingMessages } from "./LoadingMessages";

interface IIntermediateGlobalLoading {
    isNotFull?: boolean;
    displayWithoutText?: boolean;
}

export const IntermediateGlobalLoading: React.FunctionComponent<IIntermediateGlobalLoading> = props => {
    const [text, setText] = useState("");

    const newMessage = () => {
        const rndInt = Math.floor(Math.random() * LoadingMessages.length);
        setText(LoadingMessages[rndInt]);
    };

    useEffect(() => {
        newMessage();
        const interval = setInterval(() => {
            newMessage();
        }, 5000);

        // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        return () => clearInterval(interval);
    }, []);

    const displayLoader = (): ReactNode => {
        return (
            <Center height="100vh">
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    // Adding background gradient style
                    bgGradient="linear(to-b, teal.300, blue.600)"
                    // Ensure the gradient covers the entire screen
                    w="100%"
                    h="100%"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                    >
                        {/* Loading Icon */}
                        <Box
                            as="img"
                            src={LoadingIcon}
                            alt="Loading Icon"
                            maxW={props.isNotFull ? "50%" : "100%"}
                        />

                        {/* Text below the loading icon */}
                        {(!props.displayWithoutText || text.length > 0) &&
                            <Text
                                textAlign={"center"}
                                color={"white"}
                                fontSize={"4xl"}
                                fontWeight={"extrabold"}>
                                {text}.
                            </Text>
                        }
                    </Box>
                </Box>
            </Center>
        );
    };

    return (
        displayLoader()
    );
};
