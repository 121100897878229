import * as React from 'react';
import {useEffect, useState} from 'react';
import {Redirect, Route, Switch, useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {HackathonTeam} from '../hackathon-team/HackathonTeam';
import {HackathonApi} from "../../../api/hackathon.api";
import {AxiosError} from "axios";
import ErrorModel from "../../../models/error.model";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {doesHaveHackathonProfile, hackathonProfileAtom} from "../../../atoms/hackathon-profile.atom";
import {IntermediateMessageLoader} from "../../../shared/loading/IntermediateMessageLoader";
import {HackathonTeamCreateJoin} from "../HackathonTeamCreateJoin";
import {HackathonSolutions} from "../hackathon-solutions/HackathonSolutions";
import {HackathonTournaments} from "../HackathonTournaments";
import {HackathonLeaderboards} from "../hackathon-leaderboard/HackathonLeaderboards";
import {GladosContainer} from "../../../shared/GladosContainer";
import {HackathonStream} from "../hackathon-stream/HackathonStream";

interface IHackathonHome {
}

export const HackathonHome: React.FunctionComponent<IHackathonHome> = props => {
        const {type} = useParams();
        const {url, path} = useRouteMatch();
        const history = useHistory();

        const [loadingText, setLoadingText] = useState('Fetching your hackathon profile');
        const setHackathonProfile = useSetRecoilState(hackathonProfileAtom);
        const doesPlayerHaveHackathonProfile = useRecoilValue(doesHaveHackathonProfile);

        // const routeType = type === 'company' ? RouteType.EMPLOYEE : RouteType.PLAYER;

        useEffect(() => {
            HackathonApi.getHackathonProfile(type)
                .then((result) => {
                    setHackathonProfile(result.data);
                    // if (result.data.hackathonTeamMember.joinStatus === HackathonProfileStatusEnum.ACCEPTED) {
                    //     history.push(`${url}/solutions`);
                    // } else {
                    //     history.push(`${url}`);
                    // }
                    setLoadingText('');
                })
                .catch((error: AxiosError<ErrorModel>) => {
                    setLoadingText('');
                    if (error.response?.data.code.includes('EntryNotFoundException')) {
                        history.push(`${url}/register`);
                    }
                });
        }, [type, url, history, setHackathonProfile]);

        if (loadingText.length !== 0) {
            return (
                <IntermediateMessageLoader loadingMessage={loadingText} />
            );
        }
        if (!doesPlayerHaveHackathonProfile) {
            return (
                <GladosContainer>
                    <Switch>
                        <Route path={`${path}/team`}>
                            <Redirect to={`${url}/create`} />
                        </Route>
                        <Route path={`${path}/solutions`}>
                            <Redirect to={`${url}/create`} />
                        </Route>
                        <Route path={`${path}/tournaments`}>
                            <Redirect to={`${url}/create`} />
                        </Route>
                        <Route path={`${path}/leaderboard`}>
                            <Redirect to={`${url}/create`} />
                        </Route>
                        <Route path={`${path}/stream`}>
                            <HackathonStream />
                        </Route>
                        <Route path={`${path}/create`}>
                            <HackathonTeamCreateJoin/>
                        </Route>
                    </Switch>
                </GladosContainer>
            );
        }

        return (
            <GladosContainer>
                <Switch>
                    {/*<ProtectedRoute*/}
                    {/*    component={HackathonTeam}*/}
                    {/*    path={`${path}/team`}*/}
                    {/*    routeType={routeType}*/}
                    {/*/>*/}
                    {/*<ProtectedRoute*/}
                    {/*    component={HackathonSolutions}*/}
                    {/*    path={`${path}/solutions`}*/}
                    {/*    routeType={routeType}*/}
                    {/*/>*/}
                    {/*<ProtectedRoute*/}
                    {/*    component={HackathonTournaments}*/}
                    {/*    path={`${path}/tournaments`}*/}
                    {/*    routeType={routeType}*/}
                    {/*/>*/}
                    {/*<ProtectedRoute*/}
                    {/*    component={HackathonLeaderboards}*/}
                    {/*    path={`${path}/leaderboards`}*/}
                    {/*    routeType={routeType}*/}
                    {/*/>*/}
                    <Route path={`${path}/team`}>
                        <HackathonTeam/>
                    </Route>
                    <Route path={`${path}/solutions`}>
                        <HackathonSolutions/>
                    </Route>
                    <Route path={`${path}/tournaments`}>
                        <HackathonTournaments/>
                    </Route>
                    <Route path={`${path}/leaderboard`}>
                        <HackathonLeaderboards isPublic={false}/>
                    </Route>
                    <Route path={`${path}/stream`}>
                        <HackathonStream />
                    </Route>
                    <Route path={`${path}/create`}>
                        <Redirect to={`${url}/team`} />
                    </Route>
                </Switch>

            </GladosContainer>
        );
    }
;
