import gallery from '../gallery/gallaryIndex';

export const photos2014 = [
    {
      src: gallery[20141],
      width: 4,
      height: 3
    },
    {
      src: gallery[20142],
      width: 4,
      height: 3
    },
    {
      src: gallery[20143],
      width: 4,
      height: 3
    },
    {
      src: gallery[20144],
      width: 4,
      height: 3
    },
    {
      src: gallery[20145],
      width: 4,
      height: 3
    },
    {
      src: gallery[20146],
      width: 4,
      height: 3
    },
    {
      src: gallery[20147],
      width: 4,
      height: 3
    },
    {
      src: gallery[20148],
      width: 4,
      height: 3
    },
    {
      src: gallery[20149],
      width: 4,
      height: 3
    },
    {
      src: gallery[201410],
      width: 4,
      height: 3
    },
    {
      src: gallery[201411],
      width: 4,
      height: 3
    },
    {
      src: gallery[201412],
      width: 4,
      height: 3
    },
    {
      src: gallery[201413],
      width: 4,
      height: 3
    },
    {
      src: gallery[201414],
      width: 4,
      height: 3
    },
    {
      src: gallery[201415],
      width: 4,
      height: 3
    },
    {
      src: gallery[201416],
      width: 4,
      height: 3
    },
    {
      src: gallery[201417],
      width: 4,
      height: 3
    },
    {
      src: gallery[201418],
      width: 4,
      height: 3
    }
  ];