import React, {useState} from 'react';
import {PasswordRequirements} from '../../models/password-requirements-model';
import {passwordLoweCaseCharacter, passwordNumberCharacter, passwordSpecialCaseCharacter, passwordUpperCaseCharacter} from '../constants';

export const usePasswordRequirements = () => {
    const [passwordRequirements, setPasswordRequirements] = useState<PasswordRequirements>({
        upperCaseCharacter: false,
        lowerCaseCharacter: false,
        specialCharacter: false,
        numberCharacter: false,
        lengthCharacter: false
    });

    const changeEventType = 'change';
    const changeEventTargetName = 'password'

    const handlePasswordRequirementsChange = (eventValue: React.ChangeEvent<HTMLFormElement | HTMLInputElement>) => {
        if (eventValue.type === changeEventType && eventValue.target.name === changeEventTargetName) {
            let tempPasswordRequirements = passwordRequirements;

            if (eventValue.target.value.length === 0) {
                setPasswordRequirements({
                    upperCaseCharacter: false,
                    lowerCaseCharacter: false,
                    specialCharacter: false,
                    numberCharacter: false,
                    lengthCharacter: false
                });
            } else {
                tempPasswordRequirements.upperCaseCharacter = passwordUpperCaseCharacter.test(eventValue.target.value);
                tempPasswordRequirements.lowerCaseCharacter = passwordLoweCaseCharacter.test(eventValue.target.value);
                tempPasswordRequirements.specialCharacter = passwordSpecialCaseCharacter.test(eventValue.target.value);
                tempPasswordRequirements.numberCharacter = passwordNumberCharacter.test(eventValue.target.value);
                tempPasswordRequirements.lengthCharacter = eventValue.target.value.length > 7;

                setPasswordRequirements(tempPasswordRequirements);
            }
        }
    }

    return {
        passwordRequirements,
        handlePasswordRequirementsChange
    }
}
