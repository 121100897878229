import {AxiosResponse} from "axios";
import TournamentModel from "../models/tournament.model";
import axiosInstance from "./axios-instance.api";
import axiosTournamentInstance from "./axios-tournament-instance.api";
import MatchModel from "../models/match.model";

export const TournamentApi = {
    getTournaments: (): Promise<AxiosResponse<TournamentModel[]>> => axiosInstance.get(`/tournament/tournaments/currentYear`),
    getTournamentResults: (tournamentId: string, tournamentDate: string): Promise<AxiosResponse<any>> => axiosTournamentInstance.get(`/tournament/${tournamentId}/${tournamentDate}`),

    getMatches: (tournamentId: string): Promise<AxiosResponse<MatchModel[]>> => axiosInstance.get(`/tournament/matches/${tournamentId}`)
}
