export enum LanguageOption {
    'C#' = 'dotnetcore',
    'C++' = 'cplusplus',
    'Java' = 'java',
    'Javascript' = 'javascript',
    'Kotlin' = 'kotlin',
    'Python - Pytorch' = 'python',
    'Python - Tensorflow' = 'python',
    'Python' = 'python',
    'Typescript' = 'typescript',
}
