export const finalists2015 = [
    {
        id:1,
        finalist: 'Bernhard HÃ¤ussermann',
        image: 'BernhardH'
    },
    {
        id:2,
        finalist: 'Mike Yudaken',
        image: 'MikeY'
    },
    {
        id:3,
        finalist: 'Leonard Seymore',
        image: 'LeonardS'
    },
    {
        id:4,
        finalist: 'Pierre Pretorius',
        image: 'PierreP'
    },
    {
        id:5,
        finalist: 'Matthew Mullin',
        image: 'MatthewM'
    },
    {
        id:6,
        finalist: 'Jaco van Niekerk',
        image: 'JacoVN'
    },
    {
        id:7,
        finalist: 'Jack Wright',
        image: 'JackW'
    },
    {
        id:8,
        finalist: 'Eric Louw',
        image: 'EricL'
    }
]