import * as yearDetailModel from '../../../../models/year-detail.model';


const _2012: yearDetailModel.YearDetails = {
    title: 'Tron (Snake)',
    video: 'iHlhMoPp0Wc',
    galleryImageCount: 20,
    categoryFinalists: {
        'finalists': [
            {
                name: 'Jaco Cronje',
                description: `I'm an Image Processing Researcher in the Optronic Sensor Systems group at the CSIR. I enjoy working on difficult programing challenges. My experience ranges from embedded software development to game programming,
3D graphics, virtual-reality simulator development and computer vision research.`,
                imageFile: 'JacoCronje.jpg',
            },
            {
                name: 'Gustav Mauer',
                description: `I have enjoyed programming since primary school and always like a challenge. My main expertise is in distributed and high throughput systems,
which made the Entelect Challenge even more interesting for me. I currently work at Amazon.com in Cape Town on their EC2 product range.`,
                imageFile: 'gustavmauer.jpg',
            },
            {
                name: 'Henri Wiechers',
                description: `Henri is a software developer in Johannesburg. He likes almost anything involving computers and learning new things.`,
                imageFile: 'henri.jpg',
            },
            {
                name: 'Amani Mbara',
                description: `I'm Amani, 25, a graduate electrical engineer from UCT. I'm currently a web developer, C++ and Java programmer, perfectionist, and an all-round bore.`,
                imageFile: 'amani.jpg',
            },
            {
                name: 'Andrew Tweddle',
                description: `Andrew is a senior technologist at Dariel Solutions. He is married with two young children. He is also a board game geek and owns a large collection of german-style board games.`,
                imageFile: 'andrew.jpg',
            },
            {
                name: 'Keegan Carruthers-Smith',
                description: `I'm Keegan Carruthers-Smith and I am born and mostly raised in Cape Town. I was doing my MSc in Computer Science at UCT, but I am off to go work for Facebook this month (October). (Congrats Keegan!)`,
                imageFile: 'keegan.jpg',
            },
            {
                name: 'Pieter Bezuidenhout',
                description: `I'm a software developer by trade. I spend my free time watching Sci-Fi and Anime and I have been known to play PC games like Starcraft 2 and The Elder Scrolls.`,
                imageFile: 'pieter.jpg',
            },
            {
                name: 'Marcus Tomlinson',
                description: `I am a senior software engineer at Thoroughtec, and co-founder of Adapt Audio. Game developer by day and audio specialist by night.`,
                imageFile: 'marcus.jpg',
            },
        ],
    },
    playOffVideoIds: [
        'https://www.youtube.com/embed/kKXzcS9iMSw',
        '5EhrFwLtQZk',
        'NGxDSaiYOkk',
        'fFzR6dqQYV4',
        'xlfRIVelhqM',
    ],
    content: `<p> The R100K Challenge is simple - in theory. Candidates have to write a artificially intelligent program that has the ability to play against a opponent in the game of light-bikes, as seen in the film Tron. Those willin t test their abilities will have to ensure, however, that they design the mos effective and intelligent opponent. NAG Magazine and Entelect Software wil the host a tournament with all of the successful entrants, with schedule play-offs playing each program against the other in an online environment. Thes sure-to-be captivating games will be available for viewing online, where th successes and failures can be monitored throughout. An interactive an adrenaline-inducing process, the question is which program will be lef standin at the end, giving the maker a cash injection and reputation that he or sh ca only dream of. </p>
<br><p> Entelect Software, a fast-growing company, has a reputation as being th 'best-of-breed' software house in the country with teams and projects in al o South Africa's biggest players in finance, healthy, insurance, military entertainment and more. CEO of Entelect Software, Charles Pritchard says "Thi competition is for anyone who has a keen interest in software developmen and i willing to sink their teeth into this creative challenge. We want to se jus what these innovative minds are capable of." </p>
<br><p> "This competition not only promises to be thrilling, but could chang someone' life. The fact that Entelect Software are investing in the local sector lik this is a huge opportunity." Says Michael James, Editor, NAG Magazine. NA Magazine also hosts rAge (Really Awesome Game Expo) each year where the 2012 rAge will see the final of the competition taking place. </p>
<br><p> The finals of the ground-breaking 100K Challenge took place at Africa' larges technology and video gaming exhibition, rAge at the Coco-Cola Dome i Johannesburg. James says, "By combining our really AWESOME expo with such fantastic competition, we aim to bring the industry something they haven' see before." </p>`,
};

const _2013: yearDetailModel.YearDetails = {
    title: 'Battle Tank',
    video: 'a3zDz9NZYjE',
    galleryImageCount: 20,
    categoryFinalists: {
        'finalists': [{
            name: 'G-J Van Rooyen',
            description: `G-J is an electronic engineer from Stellenbosch, and mostly works with communications systems and signal processing. He does way too little actual programming in his day job, so makes a point of taking on a pet project (like this Challenge) every now and again. He lives in Somerset West with his wife, who is a fashion designer, and their two children aged 4 and 2. G-J is deeply embarrassed that he didn't spend more time helping his bots overcome their emotional problems and irrational fear of straight lines.`,
            imageFile: 'GJ.jpg',
        }, {
            name: 'HS Coetzee',
            description: `Learned to code on the African Savannah using nothing but a bone abacus and a dead skunk named Charles. Arrested in 2025 in Brazil for smuggling raw coffee beans across the border. Once accused of writing efficient and well documented code - made the accuser eat his words as a Base64 encoded 64Kb binary array - through a SOAP WebService...hosted in China. Doctors say he will eventually make a full recovery. Monks on the 9th continent call him "Asha-uh Ka-tank", which roughly translates to "That-Guy-That-Writes-That-Code-That-Usualy-Works-Sorta-OK-Even-When-Babalaas". On a dare, almost convinced Linus to install Windows 8. Named "Hero-of-the-People" by the Just-left-of-Center Crab-People Republic in 2009. Currently working on World Domination Plan v54.345.42.001c - just not sure how to motivate the anteaters yet...`,
            imageFile: 'HS.jpg',
        }, {
            name: 'Pierre Pretorius',
            description: `I'm a 27 year old software developer working for EPI-USE Labs. I'm primarily a ruby on rails web developer with a few years experience in SAP add-on product development, as well as java enterprise web development. Software development is my passion, from coding to the business aspects of it. I have a long history in programming and gaming. I was introduced to both at a very young age and haven't stopped since.`,
            imageFile: 'pierre.jpg',
        }, {
            name: 'Werner Stoop',
            description: `I've graduated in Computer Engineering at the University of Pretoria in 2003. I finished my Masters degree in 2010. I'm currently working at MTN on the network management systems. When I was in school, teachers always told me that there is no future in computers.`,
            imageFile: 'werner.jpg',
        }, {
            name: 'Jan Gutter',
            description: `Jan Gutter is an Electronic Engineer hailing from behind the Boereworsgordyn in the Free State. His chequered past has led him through lecturing, working on minor weapons of destruction and trying like heck to keep the bad guys out. He's currently working at LucidView doing all sorts of cool stuff in networking. He's a complete and utter nerd and has been completely subjugated by the cat that owns him. He's living in Centurion, so the only way to be sure to get him is to nuke it from orbit.`,
            imageFile: 'jan.jpg',
        }, {
            name: 'Jaco Cronje',
            description: `I'm an Image Processing Researcher at the CSIR. I enjoy working on difficult programming challenges. My experience range from embedded software development, game programming, 3D graphics, data mining, artificial intelligence and computer vision. I'm studying towards a PhD in Computational Intelligence and Computer Vision at the University of Pretoria.`,
            imageFile: 'jaco.jpg',
        }, {
            name: 'Marius Potgieter',
            description: `Marius studied computer science at Tuks after school, but didn't finish. He then went on to work as a programmer and still doing it today.`,
            imageFile: 'marius.jpg',
        }, {
            name: 'Lucas Dreyer',
            description: `I studied Electronic Engineering with Computer Science at Stellenbosch university, and has since been working as a software developer. I love programming competitions, studying artificial intelligence, and being a science geek. I'll play computer games occasionally but usually I have bots that do that for me! When I'm not coding I like to watch movies and hanging out with my wife.`,
            imageFile: 'lucas.jpg',
        }],
    },
    playOffVideoIds: [
        `https://www.youtube.com/embed/kKXzcS9iMSw`,
        `5EhrFwLtQZk`,
        `NGxDSaiYOkk`,
        `fFzR6dqQYV4`,
        'xlfRIVelhqM',
    ],
    content: `<p>The 2013 Entelect R100K Challenge saw hopefuls battle it out with the Nintendo cult classic "Battle City" style game. The technical bar was raised for the challenge in 2013 which provided the opportunity for each entry to play off two tanks against their opponents. Strategies involved destroying the enemy base, or destroying the enemies.</p>
<br><p>2012 saw the launch of the inaugural Entelect 100K Challenge - and 2013 saw the lift-off of the annual contest pitting like mined individuals against one another in a blazing battle to win. Entelect Software and NAG Magazine - South Africa's authority on current trends in computer, console, online and mobile gaming and technology-related entertainment - presented an opportunity for contestants to change their life. The competition saw a thrilling play-off event on 16 September where the finalists were selected to head off to rAge (Really Awesome Gaming Expo) for an adrenaline filled final at the awesome event in October!</p>
<br><p>Candidates had to write an artificially intelligent program that can play against an opponent in the cult Nintendo classic, Battle City. Innovation, effectiveness and intelligence are all that were required. "We had to change things up a bit this year. Last year was quite a familiar A.I. problem for coders to deal with (Tron Lightcycles). This year we decided to take things down 8-bit memory lane and do something fun in the form of Battle City. The core problem that coders will have to solve this year is similar, but with a few more quirks and a lot more opportunity for unique strategy. As we saw with last year's challenge, the finalists were all individuals who went to great lengths for that extra 10% intelligence. We hope this year to see an even wider array of clever strategies as the tanks fight it out for superiority on the battlefield." - Tim Kroon, Entelect Software.</p>
<br><p>"This is a high-energy, adrenaline-infused opportunity that not only has a huge winning incentive but is a lot of fun. This is for every red-blooded individual who enjoys a bit of competition and could picture themselves securing the prestige of developing the winning code -a great career starter. We are thrilled to be able to host the finals at rAge, which further adds to the excitement of the Entelect 100K Challenge," says Michael James, Editor, NAG Magazine. The competition was eventually won by 27 year old Pierre Pretorius after many near shaves in an exciting competition.</p>`,
};

const _2014: yearDetailModel.YearDetails = {
    title: 'Pacman',
    video: 'NHArVk-4tIk',
    galleryImageCount: 18,
    categoryFinalists: {
        'Finalists - Pool A': [{
            name: 'Pierre Pretorius "Pierre"',
            description: `I'm a 28 year old software developer from Pretoria. I completed B.IT at the University of Pretoria and I've been working for EPI-USE Labs the last 7 years. I went to great lengths to ensure I have a good entry this year and spent even more time than I did on my winning bot last year. After seeing how many close games it had to get to the semi-finals, it is clear that the competition is really tough this year.`,
            imageFile: 'pierre.jpg',
        }, {
            name: 'Bernhard Häussermann "Gluttony"',
            description: `Bernhard graduated in Computer Science at the University of Pretoria in 2008 and finished his honours degree part-time in 2011. He is currently employed as a software developer at Digiata, where he is working on Linx 5 - a procedural-style process development tool. In his spare time he likes to solve programming problems or to fiddle with his Arduino. He also plays the piano, and enjoys playing Joplin-rags in particular.`,
            imageFile: 'bernhard_haussermann.jpg',
        }, {
            name: 'Rethabile Mokoena "Recks"',
            description: `A passionate individual who enjoys a challenge. I completed my Masters in Electrical Engineering from the Electrical &amp; Information Engineering Department of the University of Witwatersrand in 2012. It's my hobby to explore various technologies and concepts, so working on programming challenges (like this challenge) allows me the opportunity to achieve exactly that.`,
            imageFile: 'rethabile_mokoena.jpg',
        }, {
            name: 'Gregory Doukas "Duke"',
            description: `My name is Gregory Doukas and I currently work in IT development as a Technical consultant focusing on CRM products in Johannesburg. I studied at the University of Johannesburg and currently have my Masters in A.I. with a dissertation focusing on Machine Learning, Multi-agent systems and A-life. I entered the Entelect challenge as a first timer as I've always had an interest in A.I. projects and the idea of building a "clever" Packman really appealed to me initially. My bot, "Duke" uses a combination of a few strategies that in high-level can be described as using a MiniMax approach. Ultimately "Duke" was only good enough when he could beat me (and all my friends) at a round of PacMan!`,
            imageFile: 'gregory_doukas.jpg',
        }],
        'Finalists - Pool B': [{
            name: 'Nicholas Scheltema "Twazzock"',
            description: `I'm currently a senior software developer at a financial firm in East London, where I've worked for the last 9 years. I've also been a hobbyist games developer for the last 20 years. My entry tries to maximise point scoring rather than trying to mess with the opponent player (since it's points that decide the winner) through multiple A.I. phases which includes trying to guess future outcomes of moves.`,
            imageFile: 'nicholas_scheltema.jpg',
        }, {
            name: 'Ruan Liebenberg "Spartan"',
            description: `I started programming at a young age when a friend of my dad gave me some of his old programming textbooks, starting with Visual Basic and then Java. I have been programming ever since as I enjoy solving problems, puzzles and creating new things. I first heard of the Entelect Challenge in the NAG magazine, and having never coded anything remotely resembling an A.I. before, I thought it would be a interesting challenge.`,
            imageFile: 'ruan_liebenberg.jpg',
        }, {
            name: 'Raymond Claassen "RoTed"',
            description: `I am Senior Software Engineer, currently between jobs. Programming and gaming are two of the things that I am most passionate about. It is a lot of fun to have the chance to combine them through the competition.`,
            imageFile: 'raymond_claasen.jpg',
        }, {
            name: 'Lindsay Hans "Muncher"',
            description: `I received a BSc in Mathematics from UCT, since then I've been an analyst at Eighty20 for almost 3 years, mainly handling large data. I always love a challenge, when I found this one I just couldn't stop myself. Trying to come up with something, I basically stumbled upon alpha-beta pruning and added a heuristic based on Dijkstra with some fine tuning.`,
            imageFile: 'lindsay_hans.jpg',
        }],
    },
    playOffVideoIds: [
        'https://www.youtube.com/embed/AjdravWP54g',
        'haVn078jA5U',
    ],
    content: `<p>The R100K Challenge finals concluded to a packed-house at rAge in October last year. With R165 000 worth of cash prizes at stake, the audience watched in anticipation as once again Pierre Pretorius defeated the competition and took home the grand prize of R100 000 in the advanced pool. In pool B, the victor was Lindsay Hans from Cape Town who took home a cool cash prize of R50 000. Runner ups did not leave empty handed as they went home with vouchers for takealot.co.za and a Raspberry Pi.</p>
<br><p>2014 saw new, creative and interesting work come to the fore in the competition, including a variety of entries submitted to the Gooey Awards. The Gooey awards calls for developers and designers to attempt creating a GUI (Graphical User Interface) for the R100K Challenge game. Any technology and tools can be utilized, as long as they were web-browser or Windows compatible. "We had a variety of entries for the Gooey Award this year, ranging from skin-and-bones user-interfaces to fantastically sophisticated 3D entries with music, sound effects, special visual effects and dynamic camera movements," says Kroon.</p>
<br><p>"From the tournament side, we had a range of contestants - from school-goers to senior citizens," continues Kroon. "This is fantastic and reinforces the fact that the competition really does appeal to a broad audience."</p>
<br><p>With a lot more attention being placed on Artificial Intelligence (A.I.) and Machine Learning in computer sciences, comes increasing interest with the advent of big data, cloud computing and of course - video games. "The skills required to have a successful entry in the R100K Challenge are however broader than just that of A.I.," says Kroon. "Close attention to detail needs to be paid to both technical specifications and performance. There are a host of challenges involved in the competition and a successful contestant is someone who pays attention to all the categories of their entry and not only A.I. strategy."</p>
<br><p>"The PacMan concept for this year's challenge has been received exceptionally well. We have already been in talks about concepts for the game for next year's challenge that will push contestants to utilise a wider variety or hybrid or artificial intelligence algorithms. We encourage all potential coders, developers and those who have an interest in gaming to enter next year's challenge." concludes Kroon.</p>

<h3 class="color-title">Gooey Award!</h3>

<p>The inaugural Gooey Award went spectacularly well, with some really talented and impressive individuals submitting GUI's that blew our expectations apart. It ended up in a close battle between Werner Stoop and Christiaan Visser, with the final victory going to Christiaan for his excellent attention to detail, "feel" and an amazing overall impression. Have a look at the video below for a glimpse at the winning GUI as well as a word from Christiaan.</p>`,
};

const _2015: yearDetailModel.YearDetails = {
    title: 'Space Invaders',
    video: 'm9JGsCu7ohg',
    galleryImageCount: 32,
    categoryFinalists: {
        'Finalists - Pool A': [{
            name: 'Bernhard HÃ¤ussermann',
            description: `I graduated in Computer Science at the University of Pretoria in 2008 and finished my honours degree part-time in 2011. I'm employed as a software developer at Digiata, where I work on Linx 5 - a drag-and-drop process development tool. In my spare time I like to solve programming problems and I also play the piano. During this year's contest I enjoyed the alternative dynamic of the game compared to previous years with the added strategic element.`,
            imageFile: 'BernhardH.jpg',
        }, {
            name: 'Mike Yudaken',
            description: `I'm an embedded system developer currently doing contract work on an access control system. I have also worked on mining, banking and other commercial systems. For my entry, I tried to reuse the code provided by Entelect so my program is very short.`,
            imageFile: 'MikeY.jpg',
        }, {
            name: 'Leonard Seymore',
            description: `I've been a professional software developer for about 10 years now and have worked with a variety of technologies and industries. I'm always keen to try on new techs and challenges and use the opportunities to grow. For me the most important aspect is to deliver a quality solution. I find it satisfying knowing that I have software running and chugging along in the wild that is performing and reliable.`,
            imageFile: 'LeonardS.jpg',
        }, {
            name: 'Pierre Pretorius',
            description: `I'm a 29 year old software engineer from Pretoria working at EPI-USE Labs. My passion is to create high quality, elegantly designed and well engineered web applications. I went to extreme lengths to ensure I have a well rounded entry that can evaluate a very large search space effectively and efficiently. After investigated quite a few international A.I. competitions I can say that the R100k challenge really is a top world class competition in both execution and prize money. Well done Entelect.`,
            imageFile: 'PierreP.jpg',
        }],
        'Finalists - Pool B': [
            {
                name: 'Matthew Mullin',
                description: `I'm currently in my second year, studying Electronic Engineering at Stellenbosch University. At 20 years of age, with barely a full year of programming experience, I consider myself "The Underdog". I have gone into this tournament with survival as my number one goal. My strategy is to bog down the opponent as fast as possible with the extra Alien Controller and from there I just try to survive.`,
                imageFile: 'MatthewM.jpg',
            }, {
                name: 'Jaco van Niekerk',
                description: `I have been introduced to programming about 25 years ago on a ZX Spectrum which my dad bought and never looked back since! I work as a Java programmer for Mediswitch and I'm also affiliated with the University of Johannesburg. Apart from programming, I also try and make time for bonsai, aquascaping and Numismatics. I tried something different than the vanilla game tree-based searches and opted for a rules-based engine, based on a series of look-aheads and priority sorting. The result is a bot that can play brilliant combinations at times, while making real facepalm moves when I'm not looking!`,
                imageFile: 'JacoVN.jpg',
            }, {
                name: 'Jack Wright',
                description: `I am currently a 2nd year B.Accounting student at the University of the Free State. I am very interested in just about all things technological, and I hope to someday make a positive and growing impact on the world by using this passion for technology (and engineering). I took IT and programming classes when I was attending school (about five years ago), and was recently motivated to get into it again. I saw the advertisement for Entelect's competition on YouTube and couldn't resist the temptation to give it a go. As soon as I started I couldn't stop. It was one of the most fun things I had done in some time. I only wish I had found it sooner. These events are amazing opportunities for learning and growing talent and I definitely think we need more of them in SA.`,
                imageFile: 'JackW.jpg',
            }, {
                name: 'Leon Botha',
                description: 'No bio description.',
            },
        ],
        'Gooey Award': [{
            name: 'Eric Louw',
            description: `I completed my Hons. degree in Information Science with specialization in multimedia in 2012. I've been in the industry for 3 years. I've been at my current employer (Fifth Dimension Technologies) since 2014. Games programming is something I've been doing for several years as a hobby, but never professionally. I'm afraid it will ruin the fun. My Gooey entry this year was inspired by a talk called 'Juice it or lose it' by Martin Jonasson &amp; Petri Purho. Juice is all the nice-to-have details added on top of a working product (camera shake, splats, sounds, particles, animations, etc). The Gooey challenge provides an excellent opportunity to exercise these techniques without having to worry about game logic and related problems. I'm also a space nerd so enjoyed the space theme a lot.`,
            imageFile: 'EricL.jpg',
        }],
    },
    playOffVideoIds: [
        'https://www.youtube.com/embed/4WFgK9D-pgQ',
        'm9JGsCu7ohg',
    ],
    content: `<p>2015 was another successful year for the Entelect R100K Challenge with an overwhelming amount of entries submitted. "Entries have increased exponentially each year" says Tim Kroon, General Manager of Resourcing at Entelect. The 2015 Entelect R100k Challenge returned with a new official product sponsor, Sony Mobile. "We are really excited for what this year's challenge will bring, and this is set to be our biggest R100K Challenge yet. We are sure that our carefully selected 2015 Space Invaders theme will inspire innovative and creative ideas from all entrants," says Kroon.</p>
<br><p>The Entelect R100k Challenge series has gained increasing popularity since the original launch in 2012. This year the entrants were tasked to write an A.I. player - or bot - to test their skills against opponents on the classical arcade game, Space Invaders. "Each entrant's bot will need to be designed specifically to outsmart and outplay all other opponents, and only the most intelligent, effective and innovative bots will survive and make it into the semi-finals," explains Kroon.</p>
<br><p>"From the tournament side, we had a range of contestants - from school-goers to senior citizens," continues Kroon. "This is fantastic and reinforces the fact that the competition really does appeal to a broad audience."</p>
<br><p>Entrants were categorised into novice and advanced skill pools, A and B respectively, and a separate category for GUI (Graphical User Interface) was also made available. A particular focus was placed on targeting students for the Challenge to tap into the range of A.I. skills among this network. "We invite all students to take part, and test their theoretic knowledge on a practical platform - not only is this a great opportunity to enjoy a challenging gaming experience and network with some of the best South Africa has to offer in the field, but you could be the next winner!"</p>
<br><p>For the fourth consecutive year, the R100K Challenge finals took place at the rAge Expo is October of 2015. A massive R165 000 in cash prizes was up for grabs, and reigning champion, Pierre Pretorius, held on to his title for the third year in a row, winning the grand prize of R100 000 in the advanced group. Jack Wright was victorious over his opponents in the novice group winning the R50 000 cash prize. The R15 000 Gooey Award recipient was Eric Louw for his innovative GUI entry - his amazing GUI can be viewed in the videos below.</p>
<br><p>"We are also incredibly proud to partner once again with leading publication, NAG, who continues to provide fantastic support and visibility for our R100K Challenge," says Kroon. "In addition, we are pleased to announce that Sony Mobile have come on board as this year&acirc;&euro;&trade;s product sponsor for the Entelect R100K Challenge and will be providing a host of fantastic prizes from their latest Xperia range.</p>
<br><p>The Space Invaders theme for this year's challenge was received exceptionally, with entrants praising it for the challenging them in resource management and path finding abilities. "One could say that merely experiencing this level of competition was enough to push me to new heights," says one of the entrants on the Entelect R100k Challenge Forums. "It gave me an opportunity to really learn new stuff that I otherwise would probably have not been exposed to," says another entrant.</p>`,
};

const _2016: yearDetailModel.YearDetails = {
    title: 'Bomberman',
    video: 'Ir5eNssOT1w',
    galleryImageCount: 32,
    categoryFinalists: {
        'Finalists': [{
            name: 'Faruk Can Kaya',
            description: `A Software Engineer that loves to do A.I. competitions.`,
            imageFile: 'Faruk Can Kaya.jpg',
        }, {
            name: 'Christian Reeve',
            description: `Identifies as a Linux Lover, Python Ninja, A.I. Dabbler and most of all a Software Engineer.`,
            imageFile: 'Christian Reeve.jpg',
        }, {
            name: 'Rethabile Mokoena',
            description: `A returning competitor that gets joy out of cracking programming problems. He works as a Software Engineer.`,
            imageFile: 'Rethabile Mokoena.jpg',
        }, {
            name: 'Willie Theron',
            description: `An owner and Software Engineer at Cyberdevs that intends to start writing games and simulations for VR in the near future.`,
            imageFile: 'Willie Theron.jpg',
        }, {
            name: 'Charl van Niekerk',
            description: `This guys manages a companies infrastructure, as for his programming skills. Well, he is in the finals!`,
            imageFile: 'Charl van Niekerk.jpg',
        }, {
            name: 'Ralf Kistner',
            description: `A Software Engineer that decided that he wants to take part to win some money!`,
            imageFile: 'Ralf Kistner.jpg',
        }, {
            name: 'Thinus Potgieter',
            description: `A pensioner that proved that a retired engineer can still beat the young guns of today.`,
            imageFile: 'Thinus Potgieter.jpg',
        }, {
            name: 'Riaan Nel',
            description: `Riaan says he is a geek and competitive, the Entelect Challenge appeals to both.`,
            imageFile: 'Riaan Nel.jpg',
        }],
    },
    playOffVideoIds: [
        'https://www.youtube.com/embed/Ir5eNssOT1w',
        'Gpzq4kanhY8',
        '9TiEMZNVI24',
        'EXKh9bVY43M',
    ],
    content: `<p>The theme of the 2016 challenge centred around the classic 1980's strategy maze-based game, Bomberman - with a twist. Multiple players will be abandoned in a maze arena, armed with bombs, wit and the desire to be the best. Their goal - to outlast their opponents to be the last one standing. Taking advantage of various power-ups, players must explore the dark corners of the maze to accumulate points by bombing everything and everyone in their path.</p>
<br><p>For those who were more interested in building a GUI than entering the A.I. arena, the Entelect Challenge also offered a GUI specific competition. GUI specialists or hobbyists entered the Gooey Award challenge, to build their own graphical user interface for the game, and stand to win R15 000.</p>
<br><p>On Saturday 08 October 2016, the top 8 entrants went head-to-head in The Entelect Challenge Finals on the main stage at rAge, where their bots battled it out for that top spot and epic cash prizes. The eight finalists in the 2016 competition were Charl van Niekerk, Christian Reeve, Faruk Can Kaya, Ralf Kistner, Rethabile Mokoena, Riaan Nel, Thinus Potgieter, and Willie Theron.</p>
<br><p>The top prize for the winning bot went to Ralf Kistner, with second and third places going to Rethabile Mokoena and Riaan Nel respectively.</p>
<br><p>Prize money was awarded to all the top eight performing bots competing in the A.I. Challenge, with the top three positions being awarded R70 000, R35 000 and R25 000.</p>
<br><p>"Hosting the Finals event at rAge is always great fun - for both the team and the contestants. For the bots to compete on the main stage in front of a crowd makes the whole event so exciting. rAge continues to be the ultimate place for developers to compete and showcase their creations. We're already looking forward to 2017." concludes Ravic.</p>`,
};

const _2017: yearDetailModel.YearDetails = {
    title: 'Battleships',
    video: 'wN-V7GufoMw',
    galleryImageCount: 5,
    categoryFinalists: {
        'finalists': [
            {
                name: 'Ryan Louw',
                imageFile: '1. Ryan Louw.jpg',
            },
            {
                name: 'Dion van Huysteen',
                imageFile: '2. Dion van Huysteen.jpg',
            },
            {
                name: 'Anesu Jairosi',
                imageFile: '3. Anesu Jairosi.jpg',
            },
            {
                name: 'Jaco Mosert',
                imageFile: '4. Jaco Mosert.jpg',
            },
            {
                name: 'Willie Theron',
                imageFile: '5. Willie Theron.jpg',
            },
            {
                name: 'Wynand Wolmarans',
                imageFile: '6. Wynand Wolmarans.jpg',
            },
            {
                name: 'Andre Nel',
                imageFile: '7. Andre Nel.jpg',
            },
            {
                name: 'Mark-Anthony Fouche',
                imageFile: '8. Mark-Anthony Fouche.jpg',
            },
        ],
    },
    playOffVideoIds: [
        'https://www.youtube.com/embed/MYAUHxWn_Vk',
        'https://www.youtube.com/embed/lG6Bmy-hE9Q',
    ],
    content: `<p>Based on the hugely popular ‘Battleships’ classic game, the 2017 Entelect Challenge is a fun competition that brings together everyone from novice coders to experienced professionals as they compete for their spot in the finals – which will be played out on the main stage at rAge in October – and their share of the R200,000 prize bounty.</p>
<br><p>Contestants battle it out by coding an artificial intelligence (AI) player, or bot, to challenge other AI players. As with every year, a starter pack will be available to all players and includes:</p>
<br><p>Every year, the Entelect Challenge is based on a theme from popular classic games. This year’s ‘Battleships’ theme not only appeals to those who love retro gaming but also provides a game with simple and familiar rules and mechanics that allows anyone with a basic understanding of coding to enter.</p>
<br><p>“This year, we’ve improved the player experience and have introduced a mini playoffs series, a staggered strategy component, and a webinar series with hands-on tutorials. Players will not only have more chances to win but will also have an opportunity to test and refine their coding skills as the game progresses,” says Tomislav Ravić, Team Lead for the Entelect Challenge.</p>
<br><p>The two mini playoffs are designed to help players prepare for the finals. After each round robin stage, a new game dynamic will be introduced, which will open new possible strategies that contestants can use to outsmart and outplay their opponents. Winners of each of the playoffs will receive a Golden Ticket to the finals, while second- and third-placed players will win a cash prize.</p>
<br><p>The remaining six Golden Tickets will be awarded to the top-performing contestants that haven’t already made it to the finals, in the third and final play-off event. This double elimination event takes the form of a full tournament and will pit the winners of the first two playoffs against the remaining contestants, who will battle it out for a spot in the finals.</p>
<br><p>“This year, we’re excited to introduce Hackathon-style meet-ups prior to each mini playoff, which will allow contestants to refine their submissions before entering the playoffs,” says Ravić.</p>
<br><p>As with past Challenges, the Player Portal will be available to contestants to test their bots. This year, Entelect is introducing a new feature that allows players to create clans that can challenge each other for bragging rights. The community forum has been revamped and Entelect will also launch a webinar series that will feature tutorials as well as guidance on how to run the game, get started and submit entries.</p>
<br><p>“We wanted to offer contestants as many features as possible to help them with their builds,” says Ravić. “This year, we will record mini webisodes, each with a different topic, which will be live-streamed for anyone to tune in. These will include tips and ideas on how to build a bot and how to make your bot more competitive on the game board.” More information about these tutorials will be shared on social media closer to the time.</p>
<br><p>“Competing successfully in the Challenge is not about the best or cleanest code,” says Ravić. “It’s all about the player strategies. Your bot may be really simple in design but its strategy could be highly effective. That’s what makes the Entelect Challenge so appealing to coders of all levels.”</p>
<br><p>Total prize money for this year’s event is just over R200,000, which will be split across the mini playoffs and the finals. First prize will be R70,000, second place will be awarded R35,000 and third place R25,000.</p>
`,
};

const _2018: yearDetailModel.YearDetails = {
    title: 'Tower Defence',
    video: 'NRWJxwK7SW8',
    galleryImageCount: 37,
    categoryFinalists: {
        'Main Challenge': [
            {
                name: 'Louis Lotter',
                imageFile: '1. Louis Lotter.jpg',
            },
            {
                name: 'Edward Steere',
                imageFile: '2. Edward Steere.jpg',
            },
            {
                name: 'Justin Worthe',
                imageFile: '3. Justin Worthe.jpg',
            },
            {
                name: 'Andre Nel',
                imageFile: '4. Andre Nel.jpg',
            },
            {
                name: 'Leon Wessels',
                imageFile: '5. Leon Wessels.jpg',
            },
            {
                name: 'Riaan Nel',
                imageFile: '6. Riaan Nel.jpg',
            },
            {
                name: 'Ralf Kistner',
                imageFile: '7. Ralf Kistner.jpg',
            },
            {
                name: 'Willie Theron',
                imageFile: '8. Willie Theron.jpg',
            },
        ],
        'University Cup Winners': [
            {
                name: 'Mark Coetzer',
                imageFile: 'Mark Coetzer.jpg',
            },
            {
                name: 'Tobias Bester',
                imageFile: 'Tobias Bester.jpg',
            },
            {
                name: 'Ruslynn Appana',
                imageFile: 'Ruslynn Appana.jpg',
            },
        ],
    },
    playOffVideoIds: [
        'https://www.youtube.com/embed/VnAMwOTdGyM',
        'https://www.youtube.com/embed/Lk96rNClMV8',
    ],
    content: `<p>Tower Defence, this years’ Entelect Challenge (https://challenge.entelect.co.za/) launches today. The event will run from 25th April, and the finals will take place at Comic Con Africa (www.comicconafrica.co.za) on the 15th September at 11am on the centre stage.</p>
  <br><p>“Each year we look to build the challenge around a popular game theme, and this year is Tower Defence” says Rishal Hurbans, Solutions Architect, Entelect. “The idea behind the theme is not just to make it appealing to those of us who love gaming, but to also provide a game with simple enough rules that anyone with a basic understanding of programming can enter.”</p>
  <br><p>This year, we will again be running with a mini-playoffs series, a staggered strategy component, and the grand final playoff event.</p>
  <br><p>The mini-playoffs series will provide more opportunities for contestants to win. Unlike previous years where contestants submitted their bots to the play-offs in September and then the final 8 competed at the Finals event, the series of mini-playoffs will allow contestants to pitch their bots against other players throughout the year and stand a chance of winning golden tickets to the finals.</p>
  <br><p>There will be 3 mini-playoffs during the year. The first will be in June, known as Codename Renegade and the second in July, called Operation Firestorm. At these playoffs, contestants will be able to pit their bots against one another in a Round-Robin format and possibly win a golden ticket to the finals. The third playoff is Countdown to Zero Hour, this will be a Double-Elimination round to determine six of the eight finalists.</p>
  <br><p>What is particularly exciting, is that the first submission date for Codename Renegade will take the format of a Hackathon. ‘Hurbans’ says details will be announced nearer the time, but in simple terms, contestants will be able to utilize the Hackathon environment to refine their submissions before entering the first Round-Robin event.</p>
  <br><p>“There will be a few small cash prizes up for grabs and a golden ticket to the finals at ComicCon Africa, however, no one will be eliminated in these sessions.” Explains Hurbans.</p>`,
};

const _2019: yearDetailModel.YearDetails = {
    title: 'Worms',
    video: 'P61Ulao0btg',
    galleryImageCount: 47,
    categoryFinalists: {
        'Main Challenge': [
            {
                name: 'Willie Theron',
                imageFile: 'WillieTheron.jpeg',
            },
            {
                name: 'Marvin Thobejane',
                imageFile: 'MarvinThobejane.jpeg',
            },
            {
                name: 'Jean-Paul Da Conceicao',
                imageFile: 'JeanPaulDaConceicao.jpeg',
            },
            {
                name: 'Andre Maakal',
                imageFile: 'AndreMakaal.jfif',
            },
            {
                name: 'Mallin Moolman',
                imageFile: 'MallinMoolman.jpg',
            },
            {
                name: 'Gawie Jooste',
                imageFile: 'GawieJooste.jpg',
            },
            {
                name: 'Leon Wessels',
                imageFile: 'LeonWessels.jpg',
            },
            {
                name: 'Riaan Nel',
                imageFile: 'RiaanNel.jpg',
            },
        ],
        'University Cup Winners': [
            {
                name: 'Bronson Rudner, Emile Tredoux & AndrewMcGregor',
                imageFile: 'UniversityCupWinners.jpg',
            },
        ],
    },
    playOffVideoIds: [
        'https://www.youtube.com/embed/w_pS3s4iEjg',
    ],
    content: `<p>This year's Entelect Challenge was based on the game of Worms and adjusted to take place on an asteroid in space. The Challenge brought together novice coders and experienced professionals, as they competed against each other by coding a bot to challenge all other bots.
              The goal of the Challenge is to bring the software community together and demystify the world of coding and AI.</p>
  <br><p>The mini-playoffs series and staggered strategy component that was used in previous year, was again very successful.</p>
  <br><p>The University Cup was hosted for a second time this year and almost doubled the amount of students registering for it.
     This was to get more students involved and gave them the chance to compete without spending too much of their study time. Not only did the winning team win a gaming laptop for each of its members, but the University also won a 3D printer for their labs.</p>
  <br><p>This year, the competition ran the same to allow relatively low-level coders the opportunity to enter. There were three tournament battles, each one increasing the level of complexity.</p>
  <br><p>“The staggered strategy component that we introduced to the Challenge continues to be well received by the players, as it gives them a chance to refine and test their bots before entering the playoffs,” said McFadyen.</p>`,
};

const _2020: yearDetailModel.YearDetails = {
    title: 'Overdrive',
    video: 'BXWoGjYkZmY',
    galleryImageCount: 1,
    categoryFinalists: {
        'finalists': [
            {
                name: 'JP da Conceicao',
                imageFile: 'JPdaConceicao',
            },
            {
                name: 'Gawie Jooste',
                imageFile: 'GawieJooste',
            },
            {
                name: 'Willie Theron',
                imageFile: 'WillieTheron',
            },
            {
                name: 'Kobus van Schoor',
                imageFile: 'KobusvanSchoor',
            },
            {
                name: 'Riaan Nel',
                imageFile: 'RiaanNel',
            },
            {
                name: 'Louis Lotter',
                imageFile: 'LouisLotter',
            },
            {
                name: 'Mallin Moolman',
                imageFile: 'MallinMoolman',
            },
            {
                name: 'Edward Steere',
                imageFile: 'EdwardSteere',
            },
        ],
    },
    playOffVideoIds: [
        'https://www.youtube.com/embed/7_g1XJiidyk',
    ],
    content: `<p>This year's Entelect Challenge was based on the game Overdrive. The Challenge brought together novice coders and experienced professionals, as they competed against one another to build a bot that would cross the finish line first in a Knight-rider themed racing game. 
  The goal of the Challenge is to bring the software community together and demystify the world of coding and AI. The game was designed with rules simple enough such that someone with a basic understanding of programming would be able to take part.</p>
  <br><p>The format of the Challenge was the same as in 2019. There were three round-robin tournament battles, each with increasing the level of complexity, throughout the year which determined the top 8 contestants who would compete in the final tournament. 
   The winners of the first and second tournament each received a golden ticket to compete in the final tournament, along with the top 6 from the third. </p>
  <br><p>The finals took place at Comic Con Africa Online. The winner for the 2020 Challenge was Jean-Paul da Conceicao. His strategy was not to specialize his bot, but rather to keep it flexible enough to play the long-game throughout the four tournaments. Second and third place went to Gawie Jooste and Willie Theron, respectively. 
   None of the top three contestants are strangers to the finals as they all competed against each other in the 2019 finals. Ultimately JP's bot was the best bot in the final tournament this year and so he was awarded the grand prize of R70 000. </p>
  <br><p>The University Cup was hosted for a third time this year. The goal of the Cup is to allow students to compete in a one day hackathon, which does not take up as much of their study time as the main challenge would. 
   Not only did the winning team win a gaming laptop for each of its members, but the University of Cape Town also won a 3D printer for their labs.</p>
  <br><p>In addition to the University Cup we rebooted the internal Company Cup, as a four hour hackathon. This gave Entelectuals the chance the get involved with the Challenge and win some great prizes. The winning team, Jeffrey Russell, Joshua Willmot and Stephan Nel each walked away a Nintendo Switch.</p>
`,
};

const _2021: yearDetailModel.YearDetails = {
    title: 'Galaxio',
    video: 'hnYIlOk5QxM',
    galleryImageCount: 0,
    categoryFinalists: {
        'finalists': [
            {
                name: 'Riaan Nel',
                imageFile: 'RiaanNel'
            },
            {
                name: 'Gawie Jooste',
                imageFile: 'GawieJooste'
            },
            {
                name: 'Aman Yasin',
                imageFile: 'AmanYasin'
            }
        ],
    },
    playOffVideoIds: [
        'https://www.youtube.com/embed/ICoPyQA2Fhc',
    ],
    content: `<p>No Detail Yet</p>
`,
};

export const HISTORY_CONTENT: { [key: string]: yearDetailModel.YearDetails } = {
    '2012': _2012,
    '2013': _2013,
    '2014': _2014,
    '2015': _2015,
    '2016': _2016,
    '2017': _2017,
    '2018': _2018,
    '2019': _2019,
    '2020': _2020,
    '2021': _2021,
};
