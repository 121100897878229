import * as React from 'react';
import {useState} from 'react';
import {Box, Button, Checkbox, Text, useToast} from "@chakra-ui/react";
import {SettingItemSection} from "../SettingItemSection";

interface INotifications {
}

export const Notifications: React.FunctionComponent<INotifications> = props => {
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const updateNotifications = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            toast({
                title: `Updated your notifications.`,
                position: 'top-right',
                status: 'success',
            });
        }, 1000);
    };

    return (
        <Box>
            <SettingItemSection
                heading={'Email Notifications'}
                subTexts={['Choose which notifications your receive. We only send you relevant notifications based on your entries.']}
            >
                <Box my={4}>
                    <Checkbox colorScheme={'primary'} defaultIsChecked>Submission Updates</Checkbox>
                    <Text ml={6} fontSize={'sm'} color={'gray.500'}>Notifications when we receive your bot
                        submission.</Text>
                </Box>
                <Box my={4}>
                    <Checkbox colorScheme={'primary'} defaultIsChecked>Tournament Updates</Checkbox>
                    <Text ml={6} fontSize={'sm'} color={'gray.500'}>Notifications when the current tournament changes
                        status.</Text>
                </Box>
                <Box my={4}>
                    <Checkbox colorScheme={'primary'} defaultIsChecked>Tournament Events</Checkbox>
                    <Text ml={6} fontSize={'sm'} color={'gray.500'}>Notifications about tournament events.</Text>
                </Box>
                <Button colorScheme={'primary'} isLoading={loading} loadingText={'Updating notifications'}
                        onClick={() => updateNotifications()}>Update notifications</Button>
            </SettingItemSection>
        </Box>
    )
};