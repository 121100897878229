export default interface TournamentModel {
    tournamentId: string;
    tournamentName: string;
    submissionDeadline: Date;
    startDate: Date;
    endDate: Date;
    ruleName: string; // This must match the CloudWatch rule name to be updated and used;
    tournamentYear: number;
    tournamentHidden: boolean; // This is mostly for tournaments to group them with a tournament. Will not see this one on the portals.
    tournamentStatus: TournamentStatusType;
    statusReason: string;
    minMatchPlayerSize: number;
    maxMatchPlayerSize: number;
    numberOfMatches: number;
    randomNumberGeneratorToUse: string;
    tournamentType: TournamentType;
    finalTotalMatches: number;
    finalMatchSize: number;
    finalTotalMatchesCompleted: number;
    prizeList: string[] | null;
}

export enum TournamentType {
    OFFICIAL = "official",
    FRIENDLY = "friendly"
}

export enum TournamentStatusType {
    CREATED = "created",
    QUEUED = "queued",
    SCHEDULED = "scheduled",
    STARTED = "started",
    FAILED = "failed",
    VERIFYING = "verifying",
    TERMINATED = "terminated",
    FINISHED = "finished"
}
