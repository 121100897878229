import * as React from 'react';
import {Box, ListItem, Text, UnorderedList} from "@chakra-ui/react";
import {WikiSection} from "../WikiSection";
import {WikiSubSection} from "../WikiSubSection";

interface IWikiPrivacyPolicy {}

export const WikiPrivacyPolicySection: React.FunctionComponent<IWikiPrivacyPolicy> = _ => {
    return (
        <Box>
            <WikiSection heading={'Privacy Policy'} >
                <WikiSubSection heading={'General'}>
                    <Text color={'primary.800'}>
                        This is the privacy policy of Entelect Software (Pty) Ltd, registration number 2007/001837/07.
                        <br /><br />
                        This policy details how we collect, store, protect and use personal information – that is information that can be used to identify an individual or a company (juristic person) – in connection with the services we may offer through our www.entelect.co.za website (referred to as the “Site”) and through your dealings with us (referred to as the “Services”).
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'Audience'}>
                    <Text color={'primary.800'}>
                        Personal information submitted to us by using this Site or our Services is governed by this Privacy Policy.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'As the Operator or Processor'}>
                    <Text color={'primary.800'}>
                        Where we have been contracted by another company, such as your employer or a company you are dealing with directly, please be aware that we are not the entity responsible for making decisions on your data, but instead are rendering services to the company that you are dealing with directly. Please refer to the Privacy Policy of the company you are dealing with in these cases.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'As the Controller or Responsible Party'}>
                    <Text color={'primary.800'}>
                        Where you are dealing with us directly by means of our Services or Site, this Privacy Policy applies. You are not required to provide us with any personal information when using the Site, unless you choose to engage with us in a manner that requires us to have your information (such as by subscribing to our newsletter or enquiring about our Services). Should you engage us for our Services, you will be required to provide further information as detailed in this Privacy Policy.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'What is personal information?'}>
                    <Text color={'primary.800'}>
                        Personal information includes:
                    </Text>
                    <UnorderedList color={'primary.800'}>
                        <ListItem>certain information that is collected automatically when you visit our website,</ListItem>
                        <ListItem>certain information collected on engaging our Services;</ListItem>
                        <ListItem>certain information collected on submission; and</ListItem>
                        <ListItem>optional information that you provide to us voluntarily.</ListItem>
                    </UnorderedList>
                    <br />
                    <br />
                    <Text color={'primary.800'}>
                        Additional information captured on the website that does not classify as personal information may also be collected and processed, including but not limited to:
                    </Text>
                    <UnorderedList color={'primary.800'}>
                        <ListItem>anonymised information,</ListItem>
                        <ListItem>de-identified information that cannot be associated with an individual,</ListItem>
                        <ListItem>statistical information,</ListItem>
                        <ListItem>information that is public knowledge, which has been publicly and voluntarily disclosed.</ListItem>
                    </UnorderedList>
                </WikiSubSection>
                <WikiSubSection heading={'Who is processing your data?'}>
                    <Text color={'primary.800'}>
                        In the case that we are the Data Controller or Responsible Party, then data processing is processed by us, an entity incorporated within the laws of South Africa, registration number 2007/001837/07, with the following contact details:
                        <br />
                        <br />
                        Entelect Software (Pty) Ltd
                        <br />
                        <br />
                        Unit 13, 3 Melrose Boulevard, Melrose Arch, Johannesburg, South Africa
                        <br />
                        <br />
                        General Email: enquiries@entelect.co.za Data Related Queries: dataprivacy@entelect.co.za
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'What are we processing and why?'}>
                    <Text color={'primary.800'}>
                        There are various ways in which we may process your data. This section details our purposes for processing, our legal basis for the processing, and our storage periods
                        <br />
                        <br />
                        In certain cases, we will process your data after obtaining your consent. You are free to withdraw your consent at any time. We may process your data with your consent for the following purposes:
                        <UnorderedList color={'primary.800'}>
                            <ListItem>Electronic communications, such as subscribing to our mailing list or by contacting us via a form or email address upon the Site.</ListItem>
                            <ListItem>Cookies, in order to grant you a personalised experience when dealing with us. You may decline the installation of cookies; however, this may affect the functionality of our website or prevent you from using the Site entirely. If you opt out of using cookies, we may store a single cookie in order to remember this preference.</ListItem>
                            <ListItem>For website analytics, storing details such as your IP address, bounce rate, page time, cost per click, most visited pages, and device information. These tracking cookies may be declined at any time.</ListItem>
                            <ListItem>For promotional campaigns, in which case additional information may be volunteered by you in order to participate in the promotion or to receive a delivery.</ListItem>
                        </UnorderedList>
                        <br />
                        We will process your data when we have to <b>perform a contract</b>; for as long as the contractual relationship is in place, and for any additional duration specified by the contract or legal requirement. In order to fulfil our obligations to you in terms of the Services you have engaged us for, we will need to process your data.
                        <br />
                        <br />
                        We will process your data when we are <b>required by law to do so</b>; such as complying with an obligation imposed on us, handling claims, complying with regulation, or pursuing good governance.
                        <br />
                        <br />
                        We may also process your data in terms of <b>legitimate interest</b>, as long as the data being processed is strictly necessary, proportional, and does not infringe on your individual rights to privacy. Processing may take place in terms of the following necessary scenarios:
                        <UnorderedList color={'primary.800'}>
                            <ListItem>To monitor and analyse how to improve our Service and/or our Site, as well as to keep the same secure and free from abuse. This may include communications such as surveys or direct electronic communications.</ListItem>
                            <ListItem>To keep active communications with you while you are our contracted client. Certain communications are necessary during delivery of our Services, and we have a legitimate interest in keeping you information about our Services.</ListItem>
                        </UnorderedList>
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'Where is your data processed?'}>
                    <Text color={'primary.800'}>
                        In some cases, your information may be stored and processed outside of the country or region where it was originally collected in order to complete our obligations to you. In some of these countries, you may have fewer rights in respect of your information than you do in your country of residence.
                        <br />
                        <br />
                        Your agreement to the terms of this Privacy Policy, followed by your submission of information in connection with our Services, represents your agreement to this cross-border transfer of personal data. Our primary region for storing and process data is South Africa.
                        <br />
                        <br />
                        We shall enter into written agreements with any sub processors engaged in the provision of the Services including the safeguards and guarantees required by the General Data Protection Regulation (EU Regulation no. 679\2016, the “GDPR”), particularly in respect of implementing the security measures required in the GDPR. Where necessary, Standard Contractual Clauses (SCCs) may be used.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'Who else will have access to your personal information?'}>
                    <Text color={'primary.800'}>
                        We may share your information with our service providers and processors in order to deliver the Services to you. These will often be providers that offer solutions for hosting, marketing and other business critical operations. In the case of third parties processing data, we require that they comply with our internal standards, policies, technical and organisational measures, ensuring that your data is protected and remains confidential. We will only share data in accordance with this Privacy Policy and with approved and contractually appointed third parties.
                        <br />
                        <br />
                        When you authorise us to do so, we may also share your data with other companies so that they can process the data for other purposes, as explained in more detail when we request your prior consent. In addition, if you provide consent for the installation of cookies, your data may be processed by third parties. These cookies are subject to the third parties' respective Privacy Policies
                        <br />
                        <br />
                        Where necessary and compelled by authorities to do so, we may share your information with law enforcement and competent courts. We may also share your information where we have to take legal action to protect our, or third-party rights.
                        <br />
                        <br />
                        We will not sell your information. Our contracts dictate that third-party service providers may not use your information for their own benefit or for any services other than those requested by us.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'How are we processing your data?'}>
                    <Text color={'primary.800'}>
                        We will only process personal data as a result of the provision of our Services, or in operating our Site. These instructions are included in our standard Terms and Conditions and engagement contracts. Should we have reason to believe that your instructions infringe on data protection regulation, we will inform you promptly.
                        <br />
                        <br />
                        We will ensure that our employees are subject to confidentiality agreements and any statutory privacy obligations.
                        <br />
                        <br />
                        To provide you with the Services, we may need to use other service providers, as well as hire new providers in the future. Those companies will only process the data to the extent necessary to render the Services, and we will enter into written agreements with them to make sure that said companies comply with the obligations included in this Privacy Policy and implement all necessary security measures to ensure adequate protection of the data.
                        <br />
                        <br />
                        In the event that we should change upstream service providers that are involved in providing you with Services, or where we may need to hire additional companies to provide the Services, you have the right to reasonably oppose such an appointment. Such opposition shall be addressed on a case-by-case basis in accordance with the appropriate data protection regulation.
                        <br />
                        <br />
                        We will implement appropriate technical and organisational measures to ensure a level of security appropriate to the risk. At your request and expense and taking into account the nature of processing and the information available to us, we shall reasonably assist you in compliance with the security obligations set forth by data protection regulation.
                        <br />
                        <br />
                        A list of our sub processors (and their respective processing regions) is available on request and is included in our Data Processing Agreements.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'What happens in the event of a breach?'}>
                    <Text color={'primary.800'}>
                        In the event of a confirmed breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data, we shall promptly assess the risk to people’s rights and freedoms and without undue delay report this breach to the appropriate authorities, controllers, responsible parties, and subjects as required by law.
                        <br />
                        <br />
                        We will cooperate with you and take such reasonable commercial steps as are directed by you to assist in the investigation, mitigation, and remediation of each such data breach.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'Your rights'}>
                    <Text color={'primary.800'}>
                        You have the right to request access to, and rectification or erasure of your personal information. You may also restrict process, or object to processing in accordance with relevant data protection regulation.
                        <br />
                        <br />
                        In cases where consent was given, you have the right to withdraw consent at any time.
                        <br />
                        <br />
                        If you do request that your information is deleted, all information will be permanently erased, except for information that we are required to keep by law. You may exercise your rights at any time by lodging a request with our Information Officer at dataprivacy@entelect.co.za.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'Updating your information'}>
                    <Text color={'primary.800'}>
                        You may update your information in writing at any time by submitting a support request or sending an email to our Information Officer at dataprivacy@entelect.co.za. Please remember that it is your duty to keep information updated so we can correctly provide you with the Services, and you undertake to verify the information you have handed us from time to time to make sure that it is accurate.
                    </Text>
                </WikiSubSection>
                <WikiSubSection heading={'Changes to this policy'}>
                    <Text color={'primary.800'}>
                        This policy may be updated from time to time. You may be required to accept the update Privacy Policy in order to keep using our Services. Alternatively, we may post non-material changes to this Privacy Policy on our Site, with a notice advising of the effective date of the changes. We may notify you of material changes to the Privacy Policy by email (or otherwise) prior to the effective date of the changes.
                    </Text>
                </WikiSubSection>
            </WikiSection>
        </Box>
    )
};
