import {atom, DefaultValue, RecoilState, selector} from "recoil";
import Cookies from "universal-cookie";
import {getAsBoolean} from "../utils/hooks/general-utils";

export const COOKIE_VIEWED = 'law_info_viewed_cookie';
export const COOKIE_NECESSARY = 'law_info_necessary';
export const COOKIE_NON_NECESSARY = 'law_info_non_necessary';

export interface ICookiePreferences {
    hasViewed: boolean;
    necessary: boolean;
    nonNecessary: boolean;
}

export const cookiePreferences = selector<ICookiePreferences>({
    key: 'collector_cookie_selector',
    get: (): ICookiePreferences => {
        const cookies = new Cookies();

        return {
            hasViewed: getAsBoolean(cookies.get(COOKIE_VIEWED)),
            necessary: true,
            nonNecessary: getAsBoolean(cookies.get(COOKIE_NON_NECESSARY)),
        }
    }
});

export const cookieAtom: RecoilState<ICookiePreferences> = atom<ICookiePreferences>({
    key: 'cookie_atom',
    default: cookiePreferences,
    effects_UNSTABLE: [
        ({onSet}) => {
            onSet((cookiePreferences: ICookiePreferences | DefaultValue) => {

                if (!(cookiePreferences instanceof DefaultValue)) {
                    const cookies = new Cookies();

                    const expirationDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

                    cookies.set(
                        COOKIE_NECESSARY,
                        cookiePreferences.necessary ? 'yes' : 'no',
                        {
                            path: '/',
                            expires: expirationDate
                        });

                    cookies.set(
                        COOKIE_NON_NECESSARY,
                        cookiePreferences.nonNecessary ? 'yes' : 'no',
                        {
                            path: '/',
                            expires: expirationDate
                        });

                    cookies.set(
                        COOKIE_VIEWED,
                        cookiePreferences.hasViewed ? 'yes' : 'no',
                        {
                            path: '/',
                            expires: expirationDate
                        });
                }

            })
        },
    ]
});


