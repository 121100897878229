import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Center,
    FormControl,
    Grid,
    GridItem,
    Input,
    ListItem,
    Stack,
    StackDivider,
    Text,
    UnorderedList
} from '@chakra-ui/react'
import { AxiosError } from 'axios';
import { Field, FieldAttributes, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from "recoil";
import { HackathonApi } from '../../api/hackathon.api';
import { hackathonProfileAtom } from "../../atoms/hackathon-profile.atom";
import { CreateTeam, JoinTeam } from '../../models/company.model';
import { EntryType } from '../../models/entry.model';
import ErrorModel from '../../models/error.model';
import { HackathonProfileStatusEnum } from "../../models/hackathon-profile-status.enum";
import { FancyHeading } from "../../shared/FancyHeading";
import { IntermediateMessageLoader } from '../../shared/loading/IntermediateMessageLoader';
import { goToWiki } from "../../utils/hooks/general-utils";

interface IHackathonTeamCreateJoin {
}

interface TeamStatusStateInfo {
    color: 'info' | 'warning' | 'success' | 'error' | undefined;
    title: string;
    message: string;
}

export const HackathonTeamCreateJoin: React.FunctionComponent<IHackathonTeamCreateJoin> = props => {
    const {type} = useParams();

    const [hackathonProfile, setHackathonProfile] = useRecoilState(hackathonProfileAtom);

    const initValues = {
        teamName: '',
        hackathonType: type
    } as CreateTeam;

    const initJoinValues = {
        teamCode: '',
        hackathonType: type
    } as JoinTeam;

    const [joinErrorText, setJoinErrorText] = useState('');
    const [createErrorText, setCreateErrorText] = useState('');
    const [createLoading, setCreateLoading] = useState(false);
    const [joinLoading, setJoinLoading] = useState(false);
    const [placeLoading, setPlaceLoading] = useState(false);

    const createTeam = (values: CreateTeam) => {
        setCreateErrorText('');
        setCreateLoading(true);
        HackathonApi.createTeam(values)
            .then(result => {
                setCreateLoading(false);
                setHackathonProfile(result.data);
            })
            .catch((error: AxiosError<ErrorModel>) => {
                setCreateErrorText(error.response?.data.message as string)
                setCreateLoading(false);
            });
    };

    const JoinTeam = (values: JoinTeam) => {
        setJoinErrorText('');
        setJoinLoading(true);
        HackathonApi.joinTeam(values)
            .then(result => {
                setJoinLoading(false);
                setHackathonProfile(result.data);
            })
            .catch((error: AxiosError<ErrorModel>) => {
                setJoinErrorText(error.response?.data.message as string)
                setJoinLoading(false);
            });
    }

    const placeMe = () => {
        setJoinErrorText('');
        setPlaceLoading(true);
        HackathonApi.placeMeInTeam(type)
            .then(result => {
                setPlaceLoading(false);
                setHackathonProfile(result.data);
            })
            .catch((error: AxiosError<ErrorModel>) => {
                setJoinErrorText(error.response?.data.message as string)
                setPlaceLoading(false);
            });
    }

    let teamStatus: TeamStatusStateInfo = {color: 'info', title: '', message: ''};

    if (hackathonProfile.hackathonTeamMember && (
        hackathonProfile.hackathonTeamMember.joinStatus === HackathonProfileStatusEnum.PENDING
        || hackathonProfile.hackathonTeamMember.joinStatus === HackathonProfileStatusEnum.REJECTED
        || hackathonProfile.hackathonTeamMember.joinStatus === HackathonProfileStatusEnum.KICKED
        || hackathonProfile.hackathonTeamMember.joinStatus === HackathonProfileStatusEnum.UNASSIGNED
        || hackathonProfile.hackathonTeamMember.joinStatus === HackathonProfileStatusEnum.PLACE)
    ) {
        switch (hackathonProfile.hackathonTeamMember.joinStatus) {
            case HackathonProfileStatusEnum.PENDING:
                teamStatus = {
                    color: 'info',
                    title: 'Invite Pending!',
                    message: `Your ${hackathonProfile.hackathonTeam.teamName} invite is currently pending.`
                };
                break;
            case HackathonProfileStatusEnum.KICKED:
                teamStatus = {
                    color: 'warning',
                    title: 'Kicked!',
                    message: `Sadly, you have been kicked out of the team. Not to worry, you can now create your own team and show them who's boss!`
                };
                break;
            case HackathonProfileStatusEnum.REJECTED:
                teamStatus = {
                    color: 'warning',
                    title: 'Invite Rejected!',
                    message: `Sadly, your invite has been rejected Not to worry, you can now create your own team and show them who's boss!`
                };
                break;
            case HackathonProfileStatusEnum.UNASSIGNED:
                teamStatus = {
                    color: 'error',
                    title: 'Not registered yet!',
                    message: `You have not registered for the Company Cup yet.!`
                };
                break;
            case HackathonProfileStatusEnum.PLACE:
                teamStatus = {
                    color: 'info',
                    title: 'Not yet placed!',
                    message: `You are still in the progress of being placed into a team. If you found a team yourself, or want to create a team, you can go ahead and do either one.`
                };
                break;
            default:
                teamStatus = {color: 'info', title: '', message: ''};
                break;
        }

        if (type === EntryType.IITPSA) {
            return (<IntermediateMessageLoader loadingMessage={'Creating IITPSA Data'}/>)
        }
        if (hackathonProfile.hackathonTeamMember.joinStatus === HackathonProfileStatusEnum.PENDING || hackathonProfile.hackathonTeamMember.joinStatus === HackathonProfileStatusEnum.UNASSIGNED) {
            return (
                <Grid templateColumns={'repeat(12, 1fr)'} h={'100%'}>
                    <GridItem colSpan={[1, 1, 2, 4]}/>
                    <GridItem colSpan={[10, 10, 6, 4]}>
                        <Center h={'100%'}>
                            <Alert
                                borderRadius={'md'}
                                status={teamStatus.color}
                                variant="subtle"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                textAlign="center"
                                height="200px"
                                width={"100%"}
                            >
                                <AlertIcon boxSize="40px" mr={0}/>
                                <AlertTitle mt={4} mb={1} fontSize="lg">
                                    {teamStatus.title}
                                </AlertTitle>
                                <AlertDescription maxWidth="sm">
                                    {teamStatus.message}
                                </AlertDescription>
                            </Alert>
                        </Center>
                    </GridItem>
                </Grid>
            );
        }
    }

    return (
        <Box
            h={'100%'}
            mb={10}>
            <Grid templateColumns={'repeat(12, 1fr)'} h={'100%'}>
                <GridItem colSpan={[1, 1, 2, 3]}/>
                <GridItem colSpan={[10, 10, 8, 6]}>
                    <Box mt={20}>
                        <Box>
                            {
                                (teamStatus && (hackathonProfile.hackathonTeamMember.joinStatus === 'rejected' || hackathonProfile.hackathonTeamMember.joinStatus === 'kicked' || hackathonProfile.hackathonTeamMember.joinStatus === 'place')) &&
                                <Alert status={teamStatus.color} variant="left-accent" mb={5}>
                                    <AlertIcon/>
                                    {teamStatus.message}
                                </Alert>
                            }
                            <Box>
                                <FancyHeading
                                    heading={'Start your hackathon journey'}
                                    headingSize={'3xl'}/>
                            </Box>
                            <Box mt={4} mb={[8, 8, 16, 16]}>
                                <Text
                                    mr={[0, 0, 32, 32]}
                                    color={'gray.600'}
                                    textAlign={['center', 'center', 'start', 'start']}>
                                    To start your hackathon {new Date().getFullYear()} journey, simply create your own
                                    unique team, or ask your friend to send you the team code to join their team.
                                </Text>
                                <Text mt={2} w={'75%'} color={'gray.600'}>
                                    Team rules: <br/>
                                </Text>
                                <UnorderedList pl={3} color={'gray.600'}>
                                    <ListItem>Maximum of 3 members per team.</ListItem>

                                    <ListItem>
                                        {type === EntryType.COMPANY ? ('Team members must ALL be Entelectuals (to be eligible for prizes).') : (`Team members must be in the same ${type}.`)}
                                    </ListItem>

                                    <ListItem>Your team name must be unique.</ListItem>
                                    <ListItem>You must be in a team to upload solutions.</ListItem>
                                    <ListItem>You must enjoy yourselves.</ListItem>
                                </UnorderedList>

                            </Box>

                            <Stack
                                direction={['column', 'column', 'row', 'row']}
                                spacing={[4, 4, 8, 8]}
                                divider={<StackDivider/>}
                            >
                                <Box w={'100%'}>
                                    <FancyHeading
                                        heading={'Create your team'}
                                        headingSize={'2xl'}
                                        lines={[true, false]}
                                        cProps={{mb: 2}}/>
                                    <Text
                                        mb={4}
                                        color={'gray.500'}
                                        fontSize={14}>
                                        Enter an unique team name for you and your friends to get started.
                                    </Text>
                                    <Formik
                                        initialValues={initValues}
                                        onSubmit={(values) => createTeam(values)}>
                                        {({values}) => (
                                            <Form>
                                                <Field name={'teamName'}>
                                                    {({field, form}: FieldAttributes<any>) => (
                                                        <FormControl isRequired>
                                                            <Input
                                                                colorScheme={'primary'}
                                                                type="text" size="md"
                                                                variant="filled"
                                                                placeholder={'Team Name'}
                                                                id={'teamName'} {...field} />
                                                        </FormControl>
                                                    )}
                                                </Field>

                                                <Button w={'100%'} type={'submit'} colorScheme={'primary'}
                                                        isLoading={createLoading} loadingText={'Creating your team'}
                                                        mt={4}>Create your team</Button>
                                            </Form>
                                        )}
                                    </Formik>
                                    {
                                        createErrorText.length !== 0 &&
                                        <Box mt={4}>
                                            <Alert status="error" variant="left-accent" borderRadius={'md'}>
                                                <AlertIcon/>
                                                {createErrorText}
                                            </Alert>
                                        </Box>
                                    }
                                </Box>
                                <Box w={'100%'}>
                                    <FancyHeading
                                        heading={'Join a team'}
                                        headingSize={'2xl'}
                                        lines={[true, false]}
                                        cProps={{mb: 2}}/>
                                    <Text
                                        mb={4}
                                        color={'gray.500'}
                                        fontSize={14}>
                                        Ask your friend to send you their team code, to join their team.
                                    </Text>
                                    <Formik
                                        initialValues={initJoinValues}
                                        onSubmit={(values) => JoinTeam(values)}>
                                        {({values}) => (
                                            <Form>
                                                <Field name={'teamCode'}>
                                                    {({field, form}: FieldAttributes<any>) => (
                                                        <FormControl isRequired>
                                                            <Input
                                                                colorScheme={'primary'}
                                                                type="text" size="md"
                                                                variant="filled"
                                                                placeholder={'Team Code'}
                                                                id={'teamCode'} {...field} />
                                                        </FormControl>
                                                    )}
                                                </Field>

                                                <Button w={'100%'} type={'submit'} colorScheme={'primary'}
                                                        isLoading={joinLoading} loadingText={'Joining the team'}
                                                        mt={4}>Join a team</Button>
                                            </Form>
                                        )}
                                    </Formik>
                                    {
                                        joinErrorText.length !== 0 &&
                                        <Box mt={4}>
                                            <Alert status="error" variant="left-accent" borderRadius={'md'}>
                                                <AlertIcon/>
                                                {joinErrorText}
                                            </Alert>
                                        </Box>
                                    }
                                </Box>
                                <Box w={'100%'}>
                                    <FancyHeading
                                        heading={'Place me in a team'}
                                        headingSize={'2xl'}
                                        lines={[true, false]}
                                        cProps={{mb: 2}}/>
                                    <Text
                                        mb={4}
                                        color={'gray.500'}
                                        fontSize={14}>
                                        The Entelect Challenge hackathon admins will place you in an accepting team.
                                        <QuestionOutlineIcon
                                            ml={1}
                                            fontSize={'lg'}
                                            color={'primary.500'}
                                            cursor={'pointer'}
                                            onClick={() => goToWiki('no-hackathon-team')}/>
                                    </Text>
                                    <Button
                                        w={'100%'}
                                        colorScheme={'primary'}
                                        isLoading={placeLoading}
                                        loadingText={'Placing you in a team'}
                                        mt={4}
                                        disabled={hackathonProfile.hackathonTeamMember.joinStatus === HackathonProfileStatusEnum.PLACE}
                                        onClick={() => placeMe()}>Place me in a team</Button>
                                </Box>

                            </Stack>
                        </Box>
                    </Box>
                </GridItem>
            </Grid>

        </Box>
    );

}
