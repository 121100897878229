import { Center, Divider, IconButton, Spacer, Text } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";
import { CSSPosition } from "../utils/constants";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

interface IPagination {
    items: any[];
    pageSize: number;
    currentPage: number;
    onPageChange: Function;

    paginationPosition?: CSSPosition;
}

export const Pagination: FunctionComponent<IPagination> = props => {
    const firstPageNumber = 1;

    const totalCount = props.items.length;
    const totalPages = Math.ceil(totalCount / props.pageSize) || 1;
    const position = props?.paginationPosition ?? "initial";

    const onNext = () => {
        props.onPageChange(props.currentPage + 1);
    };

    const onPrevious = () => {
        props.onPageChange(props.currentPage - 1);
    };

    return (
        <>
            <Spacer/>

            <Divider mb={5}/>

            <Center
                position={position}
                bottom={0}
                mb={5}
                w={"100%"}>
                <IconButton
                    variant="solid"
                    colorScheme="navigation"
                    textAlign={"center"}
                    onClick={onPrevious}
                    icon={<FaChevronLeft/>}
                    disabled={props.currentPage === firstPageNumber}
                    aria-label={"previous page"}/>

                <Text mx={5} fontSize={"1em"} fontWeight={"semibold"}>
                    Page: {props.currentPage} of {totalPages}
                </Text>

                <IconButton
                    variant="solid"
                    colorScheme="navigation"
                    textAlign={"center"}
                    icon={<FaChevronRight/>}
                    onClick={onNext}
                    disabled={props.currentPage === totalPages}
                    aria-label={"next page"}/>
            </Center>
        </>
    )
}
