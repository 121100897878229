import { ButtonGroup, ButtonGroupProps, IconButton } from '@chakra-ui/react'
import * as React from 'react'
import { FaGithub, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa'
import { FaFacebook, FaXTwitter } from "react-icons/fa6";

interface ISocialMediaLinks extends ButtonGroupProps {
    social_icon_size: string;
    button_props?: any;
}

export const SocialMediaLinks: React.FunctionComponent<ISocialMediaLinks> = props => {
    return (
        <ButtonGroup variant="ghost" color={'navigation.900'} {...props}>
            <IconButton as="a" href="https://www.linkedin.com/company/entelect" target='_blank' aria-label="LinkedIn"
                        icon={<FaLinkedin fontSize={props.social_icon_size}/>} {...props.button_props} />
            <IconButton as="a" href="https://github.com/EntelectChallenge" target='_blank' aria-label="GitHub"
                        icon={<FaGithub fontSize={props.social_icon_size}/>} {...props.button_props} />
            <IconButton as="a" href="https://twitter.com/entelect" target='_blank' aria-label="X (formerly known as Twitter)"
                        icon={<FaXTwitter fontSize={props.social_icon_size}/>} {...props.button_props} />
            <IconButton as="a" href="https://www.facebook.com/EntelectSoftware" target='_blank' aria-label="Facebook"
                        icon={<FaFacebook fontSize={props.social_icon_size}/>} {...props.button_props} />
            <IconButton as="a" href="https://www.youtube.com/channel/UCZ7UxIl2zOD34MVGEKitdMg" target='_blank'
                        aria-label="Youtube"
                        icon={<FaYoutube fontSize={props.social_icon_size}/>} {...props.button_props} />
            <IconButton as="a" href="https://www.instagram.com/entelectza/" target='_blank' aria-label="Instagram"
                        icon={<FaInstagram fontSize={props.social_icon_size}/>} {...props.button_props} />
        </ButtonGroup>
    )
};
