import * as React from 'react';
import {Box, Flex, Image, Square, Text} from "@chakra-ui/react";
import {finalists2021} from "../../../../assets/images/history/2021/finalists/images";
import images2021 from "../../../../assets/images/history/2021/finalists";
import {finalists2020} from '../../../../assets/images/history/2020/finalists/images';
import images2020 from '../../../../assets/images/history/2020/finalists';
import {finalists2019} from '../../../../assets/images/history/2019/finalists/images';
import images2019 from '../../../../assets/images/history/2019/finalists';
import {finalists2018} from '../../../../assets/images/history/2018/finalists/images';
import images2018 from '../../../../assets/images/history/2018/finalists';
import {finalists2017} from '../../../../assets/images/history/2017/finalists/images';
import images2017 from '../../../../assets/images/history/2017/finalists';
import {finalists2016} from '../../../../assets/images/history/2016/finalists/images';
import images2016 from '../../../../assets/images/history/2016/finalists';
import {finalists2015} from '../../../../assets/images/history/2015/finalists/images';
import images2015 from '../../../../assets/images/history/2015/finalists';
import {finalists2014} from '../../../../assets/images/history/2014/finalists/images';
import images2014 from '../../../../assets/images/history/2014/finalists';
import {finalists2013} from '../../../../assets/images/history/2013/finalists/images';
import images2013 from '../../../../assets/images/history/2013/finalists';
import {finalists2012} from '../../../../assets/images/history/2012/finalists/images';
import images2012 from '../../../../assets/images/history/2012/finalists';

interface ILegacyYearFinalists {
    year: string
}

export const LegacyYearFinalists: React.FunctionComponent<ILegacyYearFinalists> = props => {
    let otherTextFontSize = 'calc(0.7em + 1vmin)';
    let mainFontColor = 'navigation.700';

    let finalistImages = null;
    let images: any = null;

    switch (props.year) {
        case '2021':
            finalistImages = finalists2021;
            images = images2021;
            break;
        case '2020':
            finalistImages = finalists2020;
            images = images2020;
            break;
        case '2019':
            finalistImages = finalists2019;
            images = images2019;
            break;
        case '2018':
            finalistImages = finalists2018;
            images = images2018;
            break;
        case '2017':
            finalistImages = finalists2017;
            images = images2017;
            break;
        case '2016':
            finalistImages = finalists2016;
            images = images2016;
            break;
        case '2015':
            finalistImages = finalists2015;
            images = images2015;
            break;
        case '2014':
            finalistImages = finalists2014;
            images = images2014;
            break;
        case '2013':
            finalistImages = finalists2013;
            images = images2013;
            break;
        case '2012':
            finalistImages = finalists2012;
            images = images2012;
            break;
    }

    if (finalistImages == null) {
        return (
            <Box color={'whiteAlpha.900'}>
                No Images available! Come back later
            </Box>
        )
    } else {
        return (
            <Flex flexDirection={"row"} maxWidth={'100%'} wrap={"wrap"} justify={"center"}>
                {finalistImages.map((finalist, index) => (
                    <Square flexDirection={"column"} p={2}>
                        <Image width='200px' src={images[finalist.image]} key={finalist.id}/>
                        <Text
                            fontSize={otherTextFontSize}
                            color={mainFontColor}
                            pt={5}
                            align={"center"}
                            colorScheme="primary">
                            {finalist.id} - {finalist.finalist}
                        </Text>
                    </Square>
                ))}
            </Flex>
        );
    }
};
