import { Box, Center, HStack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { ReactNode } from 'react';
import Countdown from 'react-countdown';
import { CountdownRenderProps } from 'react-countdown/dist/Countdown';
import { SingleDateBox } from './SingleDateBox';

interface ICountDown {
    date: Date;
    alternativeProps?: any;
}

export const CountDown: React.FunctionComponent<ICountDown> = props => {

    const alternativeProps = props.alternativeProps;

    const displayCompletedBox = (): ReactNode => {
        return (
            <>
                <Box>
                    <Box
                        px={6}
                        py={2}
                        background={'primary.900'}
                        borderRadius={'md'}
                        color={'white'}
                        fontSize={'xl'}
                        fontWeight={'semibold'}
                    >
                        Completed
                    </Box>

                    <Center>
                        <Text
                            color={'whiteAlpha.800'}
                            fontSize={'14px'}
                            fontWeight={'semibold'} {...alternativeProps}>
                            {'Hackathon concluded'}
                        </Text>
                    </Center>
                </Box>
            </>
        );
    };

    const renderer = (props: CountdownRenderProps): React.ReactNode => {
        if (props.completed) {
            // Render a completed state
            return displayCompletedBox()
        } else {
            // Render a countdown
            return (
                <HStack spacing={5}>
                    <SingleDateBox date={props.days.toString()} dateName={'days'} alternativeProps={alternativeProps}/>
                    <SingleDateBox date={props.hours.toString()} dateName={'hours'} alternativeProps={alternativeProps}/>
                    <SingleDateBox date={props.minutes.toString()} dateName={'minutes'} alternativeProps={alternativeProps}/>
                </HStack>
            );
        }
    };


    return (
        <Countdown
            date={props.date}
            renderer={renderer}
        />
    );
};
