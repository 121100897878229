import * as React from 'react';
import {
    Box,
    Center,
    Image,
    keyframes,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    usePrefersReducedMotion
} from "@chakra-ui/react";
import {HackathonLeaderboardModel} from "../../../models/hackathon-leaderboard.model";
import Empty from "../../../assets/svg/no-data.svg";
import {Text} from "@chakra-ui/layout";

interface IHackathonLeaderboardGridTable {
    leaderboardItems: Array<HackathonLeaderboardModel>;
    hideOrigin?: boolean;
    type?: string;
}

export const HackathonLeaderboardGridTable: React.FunctionComponent<IHackathonLeaderboardGridTable> = props => {

    const pulsate = keyframes`
      from { color: #4b5fd3 }
      to { color: #A5AFE9 }
    `

    const prefersReducedMotion = usePrefersReducedMotion();

    const animation = prefersReducedMotion ? undefined : `${pulsate} infinite 0.75s alternate`;

    const sortFunction = (a: HackathonLeaderboardModel, b: HackathonLeaderboardModel) => {
        if (a.hackathonSubmission.score < b.hackathonSubmission.score) {
            return 1
        } else if (a.hackathonSubmission.score > b.hackathonSubmission.score) {
            return -1
        } else {
            return 0
        }
    }

    const leaderboardItems: Array<HackathonLeaderboardModel> = props.leaderboardItems.sort(sortFunction);

    if (props.leaderboardItems.length === 0) {
        return (
            <Box mt={4} mb={4}>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Position</Th>
                            <Th>{props.type === "iitpsa" ? "Name" : "Team"}</Th>
                            <Th>Origin</Th>
                            <Th isNumeric>Score</Th>
                        </Tr>
                    </Thead>
                </Table>
                <Text w={'100%'} textAlign={'center'} mb={5} fontSize={'lg'} fontWeight={'semibold'} color={'gray.700'} mt={4}>No submissions yet</Text>
                <Center w={'100%'}>
                    <Image boxSize={'10em'} src={Empty} alt={'empty'} />
                </Center>
            </Box>
        );
    }

    return (
        <Box mt={4} mb={4}>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Position</Th>
                        <Th>{props.type === "iitpsa" ? "Name" : "Team"}</Th>
                        {
                            !props.hideOrigin &&
                            <Th>Origin</Th>
                        }
                        <Th isNumeric>Score</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        leaderboardItems.map((leaderboardItem, index) => (
                            <Tr key={index} fontWeight={index === 0 ? 'semibold' : 'normal'} animation={index === 0 ? animation : undefined}>
                                <Td>{index + 1}</Td>
                                <Td>{leaderboardItem.hackathonTeam.teamName}</Td>
                                {
                                    !props.hideOrigin &&
                                    <Td>{leaderboardItem.hackathonTeam.occupationName}</Td>
                                }
                                <Td isNumeric>{leaderboardItem.hackathonSubmission.score}</Td>
                            </Tr>
                        ))
                    }
                </Tbody>
            </Table>
        </Box>
    )
};
