import {Alert, AlertDescription, AlertIcon, AlertTitle, Box, Grid, GridItem,} from "@chakra-ui/react";
import moment from "moment";
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {HackathonApi} from "../../../api/hackathon.api";
import {getSettingAsHackathonData, SETTING_NAMES_HACKATHON} from "../../../atoms/settings.atom";
import {HackathonLeaderboardModel} from "../../../models/hackathon-leaderboard.model";
import HackathonTournamentModel from "../../../models/hackathon-tournament.model";
import {getValueForEntryType, isEventOpen} from "../../../utils/hooks/general-utils";
import {HackathonComponentSwitch} from "../HackathonComponentSwitch";
import {HackathonPrivateLeaderboard} from "./HackathonPrivateLeaderboard";
import {HackathonPublicLeaderboard} from "./HackathonPublicLeaderboard";

interface IHackathonLeaderboards {
    isPublic: boolean;
}

export const HackathonLeaderboards: React.FunctionComponent<IHackathonLeaderboards> = props => {

    const { type } = useParams();

    const hackathonCompanyTournament: HackathonTournamentModel = useRecoilValue(getSettingAsHackathonData(SETTING_NAMES_HACKATHON.COMPANY_TOURNAMENT_DATA));
    const hackathonUniversityTournament: HackathonTournamentModel = useRecoilValue(getSettingAsHackathonData(SETTING_NAMES_HACKATHON.UNIVERSITY_TOURNAMENT_DATA));
    const hackathonIitpsaTournament: HackathonTournamentModel = useRecoilValue(getSettingAsHackathonData(SETTING_NAMES_HACKATHON.IITPSA_TOURNAMENT_DATA));

    const [leaderboardLoading, setLeaderboardLoading] = useState(false);
    const [leaderboardQuestionCombined, setLeaderboardQuestionCombined] = useState<Array<HackathonLeaderboardModel>>([]);

    const [isLeaderboardOpen, setIsLeaderboardOpen] = useState(
        getValueForEntryType(
            type,
            {
                university: isEventOpen(hackathonUniversityTournament.leaderboardOpenDate, hackathonUniversityTournament.leaderboardCloseDate),
                company: isEventOpen(hackathonCompanyTournament.leaderboardOpenDate, hackathonCompanyTournament.leaderboardCloseDate),
                iitpsa: isEventOpen(hackathonIitpsaTournament.leaderboardOpenDate, hackathonIitpsaTournament.leaderboardCloseDate)
            }
        )
    );

    const loadLeaderboard = useCallback(() => {
        setIsLeaderboardOpen(
            getValueForEntryType(
                type,
                {
                    university: isEventOpen(hackathonUniversityTournament.leaderboardOpenDate, hackathonUniversityTournament.leaderboardCloseDate),
                    company: isEventOpen(hackathonCompanyTournament.leaderboardOpenDate, hackathonCompanyTournament.leaderboardCloseDate),
                    iitpsa: isEventOpen(hackathonIitpsaTournament.leaderboardOpenDate, hackathonIitpsaTournament.leaderboardCloseDate)
                }
            )
        );

        setLeaderboardLoading(true);
        HackathonApi.getHackathonLeaderboard(type)
            .then((response) => {
                setLeaderboardLoading(false);
                setLeaderboardQuestionCombined(response.data);
            })
            .catch(() => setLeaderboardLoading(false));

    }, [hackathonCompanyTournament.leaderboardOpenDate, hackathonCompanyTournament.leaderboardCloseDate, hackathonUniversityTournament.leaderboardOpenDate, hackathonUniversityTournament.leaderboardCloseDate, hackathonIitpsaTournament.leaderboardOpenDate, hackathonIitpsaTournament.leaderboardCloseDate, type]);

    useEffect(() => {
        if (isLeaderboardOpen) {
            loadLeaderboard();
        }
    }, [loadLeaderboard, isLeaderboardOpen]);

    return (
        <HackathonComponentSwitch
            switchDate={
                getValueForEntryType(
                    type,
                    {
                        university: hackathonUniversityTournament.leaderboardOpenDate,
                        company: hackathonCompanyTournament.leaderboardOpenDate,
                        iitpsa: hackathonIitpsaTournament.leaderboardOpenDate
                    }
                ) as Date
            }
            busyComponent={
                <Grid templateColumns={'repeat(12, 1fr)'} h={'100%'}>
                    <GridItem colSpan={[1, 1, 1, 3]}/>
                    <GridItem colSpan={[10, 10, 10, 6]}>
                        <Box mt={10}>
                            <Alert status="warning">
                                <AlertIcon/>
                                <Box flex="1">
                                    <AlertTitle>Leaderboard closed!</AlertTitle>
                                    <AlertDescription display="block">
                                        The leaderboard will be open
                                        between <b>{moment(getValueForEntryType(type, {
                                        university: hackathonUniversityTournament.leaderboardOpenDate,
                                        company: hackathonCompanyTournament.leaderboardOpenDate,
                                        iitpsa: hackathonIitpsaTournament.leaderboardOpenDate
                                    }) as Date).format('YYYY-MM-DD HH:mm')}</b> and <b>{moment(getValueForEntryType(type, {
                                        university: hackathonUniversityTournament.leaderboardCloseDate,
                                        company: hackathonCompanyTournament.leaderboardCloseDate,
                                        iitpsa: hackathonIitpsaTournament.leaderboardCloseDate
                                    }) as Date).format('YYYY-MM-DD HH:mm')}</b>
                                    </AlertDescription>
                                </Box>
                            </Alert>
                        </Box>
                    </GridItem>
                </Grid>
            }
            completedComponent={
                props.isPublic
                    ?
                    <HackathonPublicLeaderboard
                        leaderboardLoading={leaderboardLoading}
                        refreshLeaderboard={() => loadLeaderboard()}
                        leaderboardQuestions={leaderboardQuestionCombined}
                        openDate={
                            getValueForEntryType(type, {
                                university: hackathonUniversityTournament.solutionOpenDate,
                                company: hackathonCompanyTournament.solutionOpenDate,
                                iitpsa: hackathonIitpsaTournament.solutionOpenDate
                            }) as Date
                        }
                        closeDate={
                            getValueForEntryType(type, {
                                university: hackathonUniversityTournament.solutionCloseDate,
                                company: hackathonCompanyTournament.solutionCloseDate,
                                iitpsa: hackathonIitpsaTournament.solutionCloseDate
                            }) as Date
                        }
                    />
                    :
                    <HackathonPrivateLeaderboard
                        leaderboardLoading={leaderboardLoading}
                        refreshLeaderboard={() => loadLeaderboard()}
                        leaderboardQuestions={leaderboardQuestionCombined}
                        openDate={
                            getValueForEntryType(type, {
                                university: hackathonUniversityTournament.solutionOpenDate,
                                company: hackathonCompanyTournament.solutionOpenDate,
                                iitpsa: hackathonIitpsaTournament.solutionOpenDate
                            }) as Date
                        }
                        closeDate={
                            getValueForEntryType(type, {
                                university: hackathonUniversityTournament.solutionCloseDate,
                                company: hackathonCompanyTournament.solutionCloseDate,
                                iitpsa: hackathonIitpsaTournament.solutionCloseDate
                            }) as Date
                        }
                    />
            }/>
    );
};
