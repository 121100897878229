import * as React from 'react';
import {Box} from "@chakra-ui/react";
import {photos2021} from "../../../../assets/images/history/2021/gallary/images";
import {photos2020} from '../../../../assets/images/history/2020/gallery/images';
import {photos2019} from '../../../../assets/images/history/2019/gallery/images';
import {photos2018} from '../../../../assets/images/history/2018/gallery/images';
import {photos2017} from '../../../../assets/images/history/2017/gallery/images';
import {photos2016} from '../../../../assets/images/history/2016/gallery/images';
import {photos2015} from '../../../../assets/images/history/2015/gallery/images';
import {photos2014} from '../../../../assets/images/history/2014/gallery/images';
import {photos2013} from '../../../../assets/images/history/2013/gallery/images';
import {photos2012} from '../../../../assets/images/history/2012/gallery/images';
import Gallery from "react-photo-gallery";


interface ILegacyYearImages {
    year: string
}

export const LegacyYearImages: React.FunctionComponent<ILegacyYearImages> = props => {
    let photos = null;
    switch (props.year) {
        case '2021':
            photos = photos2021;
            break;
        case '2020':
            photos = photos2020;
            break;
        case '2019':
            photos = photos2019;
            break;
        case '2018':
            photos = photos2018;
            break;
        case '2017':
            photos = photos2017;
            break;
        case '2016':
            photos = photos2016;
            break;
        case '2015':
            photos = photos2015;
            break;
        case '2014':
            photos = photos2014;
            break;
        case '2013':
            photos = photos2013;
            break;
        case '2012':
            photos = photos2012;
            break;
    }

    if (photos == null) {
        return (
            <Box color={'whiteAlpha.900'}>
                No Images available! Come back later
            </Box>
        )
    } else {
        return (
            <>
                <Box mt={4} mb={4}>
                    <Gallery photos={photos} />
                </Box>
            </>
        );
    }
};
