import { Box, Divider, Flex, Image, Link, ListItem, Spacer, Square, Text, UnorderedList, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import image_right from "../../../assets/images/the-game/the_game_right.png";
import middleImage from "../../../assets/images/the-game/game_rules_middle_banner.png";
import goldenTicket from "../../../assets/images/the-game/golden-ticket.png";
import bug_hunt_the_game from "../../../assets/images/the-game/millennium_falcon_glitch.png";
import background from "../../../assets/images/main/main_background.png";

interface IUnauthenticatedGame {
}

export const UnauthenticatedGame: React.FunctionComponent<IUnauthenticatedGame> = props => {
    let textColumnMaxWidth = "96em";
    let titleFontSize = 45;
    let minViewH = "50vh";
    let otherTextFontSize = "calc(1.2em + 1vmin)";
    let titleFontColor = "navigation.700";
    let mainFontColor = "navigation.700";
    let gameIconSize = "10em";
    let linkColor = "primary.400";
    const isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false});
    const entelectChallengeForumLink = process.env.REACT_APP_ENTELECT_CHALLENGE_FORUM;

    return (
        <Box
            background={`url(${background})`}
            backgroundSize={'cover'}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={"center"}
            as="main">
            <Flex flexDirection={"column"}>

                <Square
                    p={5} shadow="md" borderWidth="0.1em"
                    background={"whiteAlpha.800"}
                    borderRadius={"0.5em"}
                    mt={25}
                    width={"100%"}
                    pb={5}
                    maxWidth={textColumnMaxWidth}
                    minH={minViewH}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Box
                        align={"center"}
                        py={5}
                        width={"80%"}>
                        <Text
                            fontWeight={"bold"}
                            fontSize={titleFontSize}
                            color={titleFontColor}
                            align={"center"}
                            maxWidth={textColumnMaxWidth}
                            colorScheme="primary"
                            as={"span"}>
                            The Game
                        </Text>
                    </Box>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Every year, the Entelect Challenge revolves around a popular game.
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        In Entelect Challenge 2024, we've cultivated a garden-inspired twist to a beloved favorite on Discord.
                    </Text>
                    <Divider colorScheme={linkColor}/>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        fontWeight={"bold"}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Meet the headliner of Entelect Challenge 2024: <br/>
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={"accent.600"}
                        width={"80%"}
                        fontWeight={"bold"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Sproutopia
                    </Text>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        my={5}
                        textAlign={"left"}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        Sproutopia is a multiplayer land gathering game blossomed from the essence of Land-io.
                        Your aim is to outshine fellow players by swiftly cultivating land to claim it as your own, whilst fending off weeds and outmaneuvering your rivals.
                        As each round unfolds, the available claimable land varies alongside the power-ups and weeds, presenting a growing horticultural challenge.
                    </Text>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        py={5}
                        my={5}
                        textAlign={"left"}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        To claim victory, harness your coding skills to cultivate a bot that gracefully outmaneuvers rivals and efficiently nurtures land.
                        Unlock your bot's potential by embracing game mechanics, strategizing, and triumphing over the unique challenges each round may present.
                        Brace yourself for an extraordinary gaming journey through a garden of delights!
                    </Text>
                </Square>

                {!isMobile &&
                    <Square
                        shadow="md" borderWidth="0.1em"
                        width={"100%"}
                        maxWidth={textColumnMaxWidth}
                        alignSelf={"center"}
                        flexDirection={"column"}
                    >
                        <Image
                            pos="inherit"
                            width={"100%"}
                            objectFit={"contain"}
                            src={middleImage}
                        />
                    </Square>
                }

                <Square
                    py={"5em"}
                    mt={isMobile ? 5 : 0}
                    shadow="md" borderWidth="0.1em"
                    background={"whiteAlpha.800"}
                    borderRadius={"0.5em"}
                    width={"100%"}
                    maxWidth={textColumnMaxWidth}
                    minH={"20vh"}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Flex direction={"column"} width={"80%"}>
                        <Text
                            fontSize={otherTextFontSize}
                            color={mainFontColor}
                            align={"left"}
                            fontWeight={"bold"}
                            colorScheme="primary"
                            pb={5}>
                            A <Link color={linkColor}
                                    _hover={{cursor: "pointer"}}
                                    href="https://github.com/EntelectChallenge/2024-Sproutopia/releases/latest" isExternal>starter
                            pack</Link> will be available
                            to all players and includes:
                        </Text>
                        <UnorderedList align={"left"} fontSize={otherTextFontSize}>
                            <ListItem>Game rules</ListItem>
                            <ListItem>A readme file on how to interact with the game engine</ListItem>
                            <ListItem>Sample bots to use as reference for different programming languages</ListItem>
                            <ListItem>Access to an {" "}
                                <Link
                                    color={linkColor}
                                    _hover={{cursor: "pointer"}}
                                    href={entelectChallengeForumLink}
                                    isExternal>
                                    active forum
                                </Link>
                                {" "} for guidance
                            </ListItem>
                        </UnorderedList>
                    </Flex>
                </Square>

                {/* TODO: Add an icon on the LEFT  */}
                {!isMobile &&
                    <Square
                        width={"20%"}
                        maxWidth={textColumnMaxWidth}
                        h={"0vh"}
                        alignSelf={"center"}
                        flexDirection={"column"}>
                        <Image
                            w={"80vw"}
                            objectFit={"contain"}
                            pos="relative" top="-2vh" left="36vw"
                            fit={"contain"}
                            transform={"scaleX(-1)"}
                            h={"20em"}
                            src={image_right}
                        />
                    </Square>
                }

                <Square
                    p={isMobile ? "1em" : "5em"} shadow="md" borderWidth="0.1em"
                    background={"whiteAlpha.800"}
                    borderRadius={"0.5em"}
                    m={5}
                    mt={isMobile ? 5 : 20}
                    width={"100%"}
                    maxWidth={textColumnMaxWidth}
                    minH={minViewH}
                    alignSelf={"center"}
                    flexDirection={"column"}>

                    <Flex width={"100%"} alignItems={"center"} direction={isMobile ? "column" : "row"}>
                        <Text
                            fontSize={titleFontSize}
                            color={mainFontColor}
                            fontWeight={"bold"}
                            colorScheme="primary"
                            textAlign={"center"}
                            width={isMobile ? "95%" : "33%"}>
                            The Entelect Challenge
                        </Text>
                        <Spacer/>
                        <Image src={goldenTicket} boxSize={gameIconSize}/>
                        <Spacer/>
                        <Text
                            fontSize={titleFontSize}
                            color={mainFontColor}
                            fontWeight={"bold"}
                            colorScheme="primary"
                            width={isMobile ? "95%" : "33%"}
                            textAlign={"center"}>
                            2 Tournaments <br/>
                            8 Golden tickets
                        </Text>
                    </Flex>

                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        pt={5}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"left"}
                        colorScheme="primary">
                        This year, we will be keeping the structure the same as last year.
                        We’ll have 2 qualifying tournaments before the finals at Comic Con Africa!
                        The top 4 in each of these tournaments will get a golden ticket
                        - automatically putting them through to the finals. <br/><br/>
                        After tournament 1, we’ll introduce friendly matches where you can
                        measure your bot against other players’ in a relaxed environment.
                        These “friendlies” will provide you with the opportunity to improve your bot
                        against real participants without counting towards any score.<br/><br/>
                    </Text>
                    <Divider/>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        width={"80%"}
                        fontWeight={"bold"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        * Please check out *
                    </Text>
                    <Text
                        fontSize={otherTextFontSize}
                        color={mainFontColor}
                        width={"80%"}
                        maxWidth={textColumnMaxWidth}
                        align={"center"}
                        colorScheme="primary">
                        <Link color={linkColor} _hover={{cursor: "pointer"}} href="https://github.com/EntelectChallenge/2024-Sproutopia/releases/latest">Sproutopia Github repository</Link> <br/>
                        <Link color={linkColor} _hover={{cursor: "pointer"}} href={entelectChallengeForumLink}>New Entelect Challenge structure</Link>
                    </Text>
                </Square>

                <Square
                    width={"68%"}
                    maxWidth={textColumnMaxWidth}
                    h={"0vh"}
                    alignSelf={"center"}
                    flexDirection={"column"}>
                    <Image
                        h={"5em"}
                        objectFit={"contain"}
                        pos="relative" top="-4vw" left="-50%"
                        fit={"contain"}
                        src={bug_hunt_the_game}
                    />
                </Square>

            </Flex>
        </Box>
    );
};
